import {useMediaQuery, useTheme} from '@mui/material';
import Paper, {PaperProps} from '@mui/material/Paper';
import React from 'react';
import Draggable from 'react-draggable';
import {
    ToiDialog,
    ToiDialogContent,
    ToiIconButton,
    ToiLink,
    ToiToolTip,
} from '@norkart/toi-components';
import {InfoIcon} from '@norkart/toi-icons';
import useTrackArealplanerEvent from '../../hooks/appinsights/useTrackArealplanerEvent';
import {MarkdownContent} from '../MarkdownContent';
import {helpObject} from '../../help/helpObject';
import Vertikalnivå from '../../assets/images/vertniv.png';

type Props = {
    helpText: string;
    type?: string;
    label?: string;
    size?: number;
    buttonVariant?: 'ikon' | 'tekst';
};

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const HelpButton = ({helpText, label, size, type, buttonVariant}: Props) => {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const trackReadMore = useTrackArealplanerEvent('helpButton', 'button');

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false);
    };

    const handleOnClick = (e) => {
        trackReadMore(`hjelpknapp_${type}`);
        setOpen(true);
        e.stopPropagation();
    };

    return (
        <>
            <ToiToolTip
                followCursor={true}
                placement='bottom-end'
                title='Trykk for å lese mer'
            >
                {!buttonVariant || buttonVariant === 'ikon' ? (
                    <div>
                        <ToiIconButton
                            aria-label='Kopier'
                            color='transparent'
                            onClick={handleOnClick}
                            size='small'
                        >
                            <InfoIcon />
                        </ToiIconButton>
                        {label && (
                            <p style={{margin: 0, marginLeft: '10px'}}>
                                {label}
                            </p>
                        )}
                    </div>
                ) : (
                    <ToiLink
                        sx={{alignSelf: 'flex-start'}}
                        component='button'
                        onClick={(e) => handleOnClick(e)}
                    >
                        Les mer
                    </ToiLink>
                )}
            </ToiToolTip>

            <ToiDialog
                fullScreen={fullScreen}
                open={open}
                aria-labelledby='draggable-dialog-title'
                PaperComponent={PaperComponent}
                onClose={handleClose}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                maxWidth='md'
                disableScrollLock={true}
            >
                <ToiDialogContent
                    sx={(theme) => ({
                        padding: '55px',
                        paddingTop: 0,
                        ' & h1, h2, h3': {
                            marginTop: 0,
                            fontWeight: 400,
                        },
                        [theme.breakpoints.down('md')]: {
                            padding: '24px',
                            paddingTop: 0,
                            marginTop: '-20px',
                        },
                    })}
                >
                    {type === 'GjeldendePlankart' ? (
                        <>
                            <MarkdownContent
                                markdown={
                                    helpObject.PlankartInfo.GjeldendePlankart
                                }
                            />
                            <img alt='vertikalnivå' src={Vertikalnivå} />
                            <MarkdownContent
                                markdown={
                                    helpObject.PlankartInfo.GjeldendePlankart2
                                }
                            />
                        </>
                    ) : (
                        <MarkdownContent markdown={helpText} />
                    )}
                </ToiDialogContent>
            </ToiDialog>
        </>
    );
};
export default HelpButton;
