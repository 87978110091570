import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const CoatOfArmsIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M12 1H5.5C4.67157 1 4 1.67157 4 2.5V9.68862C4 15.0775 6.77934 20.024 11.23 22.5576L11.2527 22.5707C11.7154 22.8365 12.2846 22.8365 12.7473 22.5707L12.77 22.5576C17.2207 20.0341 20 15.0775 20 9.68862V2.5C20 1.67157 19.3284 1 18.5 1H12Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.3999 10.415L13.2099 4.60498M10.7899 13.805L16.5999 7.99498'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default CoatOfArmsIcon;
