import {CircularProgressProps as MuiCircularProgressProps} from '@mui/material';
import {ToiTypography} from '../ToiTypography';
import {ToiBox} from '../pureMuiComponents';

import {StyledCircularProgressBackground, StyledCircularProgress} from './StyledCircularProgress';

type OwnProps = {
  halted?: boolean;
  withLabel?: boolean;
};

export type ToiCircularProgressProps = Omit<MuiCircularProgressProps, keyof OwnProps | 'component'> & OwnProps;

export const ToiCircularProgress = (props: ToiCircularProgressProps) => {
  return (
    <ToiBox sx={{position: 'relative', width: 'fit-content', height: '64px'}}>
      <ToiBox sx={{position: 'relative'}}>
        <StyledCircularProgressBackground
          variant='determinate'
          size={props.size ? props.size : '64px'}
          thickness={5.6}
          {...props}
          value={100}
        />
        <StyledCircularProgress
          disableShrink={props.variant === 'indeterminate'}
          size={props.size ? props.size : '64px'}
          thickness={5.6}
          {...props}
        />
      </ToiBox>
      <ToiBox
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {props.withLabel && props.value && <ToiTypography>{Math.round(props.value)}%</ToiTypography>}
      </ToiBox>
    </ToiBox>
  );
};

export default ToiCircularProgress;
