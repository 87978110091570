import {Feature, MultiPolygon, Polygon} from 'geojson';
import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ToiButton} from '@norkart/toi-components';
import {getMapState, getSearchState} from '../../../../store';
import {addFitToBounds} from '../../../../store/map/actions';
import {createFitBoundsForFeature} from '../../helpers/layerHelpers';

type Props = {
    type: 'property' | 'plan';
    zoomTo?: Feature<MultiPolygon | Polygon>;
    style?: any;
};

const ZoomButton = ({type, zoomTo, style}: Props) => {
    const mapState = useSelector(getMapState);
    const searchState = useSelector(getSearchState);
    const dispatch = useDispatch();

    const planAreaFitBounds = useMemo(
        () =>
            mapState.borderLayers?.combined &&
            createFitBoundsForFeature(
                zoomTo ||
                    (mapState.borderLayers?.combined.source as any).data
                        .geometry
            ),
        [mapState.borderLayers?.combined, zoomTo]
    );

    const propertyFitBounds = useMemo(
        () =>
            searchState.searchedPropertyGeom &&
            createFitBoundsForFeature(searchState.searchedPropertyGeom),
        [searchState.searchedPropertyGeom]
    );

    const onZoom = () => {
        if (type === 'property') {
            dispatch(addFitToBounds(propertyFitBounds));
        } else {
            dispatch(addFitToBounds(planAreaFitBounds));
        }
    };

    return (
        <ToiButton variant='secondary' onClick={onZoom} style={style}>
            {`Zoom til ${type === 'property' ? 'eiendom' : 'plan'}`}
        </ToiButton>
    );
};

export default ZoomButton;
