import {styled, Accordion} from '@mui/material';
import {ToiAccordionProps} from './ToiAccordion';

export const StyledAccordion = styled(Accordion, {
  // component is sent to accordionSummary, we need it here for styling
  shouldForwardProp: (prop) => !['component', 'size', 'subtitle'].includes(prop.toString()),
})<ToiAccordionProps>(({theme, size, subtitle}) => ({
  backgroundColor: theme.vars.toi.palette.backgrounds.component.fill,
  boxShadow: 'none',

  border: `1px solid ${theme.vars.toi.palette.border.component}`,
  '&:not(:last-of-type)': {
    borderBottom: 0,
  },

  '&::before': {
    display: 'none',
  },

  '&:hover, &.Mui-expanded': {
    borderColor: theme.vars.toi.palette.border.hover,
    '& + .MuiAccordion-root': {
      borderTopColor: theme.vars.toi.palette.border.hover,
    },
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
  },

  '& .MuiAccordionSummary-root': {
    minHeight: 0,
    padding: 0,
  },

  '& .MuiAccordionDetails-root': {
    padding: size === 'small' ? theme.toi.spacing.s : theme.toi.spacing.m,
    paddingTop: subtitle ? theme.toi.spacing.m : size === 'small' ? theme.toi.spacing.xs : theme.toi.spacing.s,
    borderTop: subtitle && `1px solid ${theme.vars.toi.palette.border.component}`,
  },

  '& .MuiList-root': {
    backgroundColor: 'inherit',
  },

  '&:last-of-type': {
    borderBottomLeftRadius: theme.toi.spacing['2xs'],
    borderBottomRightRadius: theme.toi.spacing['2xs'],
  },
  '&:first-of-type': {
    borderTopLeftRadius: theme.toi.spacing['2xs'],
    borderTopRightRadius: theme.toi.spacing['2xs'],
  },
}));
