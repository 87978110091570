import {useEffect, useState} from 'react';
import {Waypoint} from 'react-waypoint';
import {
    ToiBox,
    ToiButton,
    ToiSpinner,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import {VideoPlayIcon} from '@norkart/toi-icons';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import {
    KonsistenssjekkSearchParams,
    LogLines,
} from '../../../../hooks/konsistenssjekk/types';
import useKonsistenssjekk from '../../../../hooks/konsistenssjekk/useKonsistenssjekk';
import useRunKonsistenssjekk from '../../../../hooks/konsistenssjekk/useRunKonsistenssjekk';
import useMeta from '../../../../hooks/meta/useMeta';
import useDebounce from '../../../../hooks/useDebounce';
import useCan from '../../../../utils/auth/useCan';
import {setTitle} from '../../../../utils/setTitle';
import ReportPage from '../ReportPage';
import KonsistenssjekkFailedInfo from './components/KonsistenssjekkFailedInfo';
import KonsistenssjekkRunInfo from './components/KonsistenssjekkRunInfo';
import KonsistenssjekkIsRunning from './KonsistenssjekkIsRunning';
import KonsistenssjekkTable from './KonsistenssjekkTable';
import Toolbar from './Toolbar';

export default function Konsistenssjekk() {
    setTitle('Rapporter > Konsistenssjekk');
    const can = useCan();
    const meta = useMeta();
    const runKonsistenssjekk = useRunKonsistenssjekk();
    const [isRunClicked, setIsRunClicked] = useState(false);
    const [showRunningReport, setShowRunningReport] = useState(false);
    const defaultSearchParams: KonsistenssjekkSearchParams = {
        term: '',
        orderBy: '',
        order: '',
    };
    const [searchParams, setSearchParams] =
        useState<KonsistenssjekkSearchParams>(defaultSearchParams);
    const debouncedParams = useDebounce(searchParams, 500);
    const konsistenssjekk = useKonsistenssjekk(debouncedParams);
    const [nrOfLogLines, setNrOfLogLines] = useState<number>(
        konsistenssjekk.loglines?.length ?? 0
    );
    const BATCH_SIZE = 50;
    const [infiniteScrollCount, setInfiniteScrollCount] =
        useState<number>(BATCH_SIZE);
    const [infiniteScrollData, setInfiniteScrollData] = useState<LogLines[]>(
        []
    );

    // Handle updated loglines and switching between previous report and running report:
    useEffect(() => {
        if (showRunningReport) {
            // Avoid showing the error tempLoglines from the last failed run:
            if (
                konsistenssjekk.tempLoglines?.length === 1 &&
                konsistenssjekk.tempLoglines?.[0].planId == null
            ) {
                setInfiniteScrollData([]);
                setNrOfLogLines(0);
            } else {
                setInfiniteScrollData(
                    konsistenssjekk.tempLoglines?.slice(
                        0,
                        infiniteScrollCount
                    ) ?? []
                );
                setNrOfLogLines(konsistenssjekk.tempLoglines?.length ?? 0);
            }
        } else {
            setInfiniteScrollData(
                konsistenssjekk.loglines?.slice(0, infiniteScrollCount) ?? []
            );
            setNrOfLogLines(konsistenssjekk.loglines?.length ?? 0);
        }
    }, [
        konsistenssjekk.loglines,
        konsistenssjekk.tempLoglines,
        showRunningReport,
    ]);

    // Check for finished run:
    useEffect(() => {
        if (!konsistenssjekk.isRunning) {
            setShowRunningReport(false);
            setIsRunClicked(false);
        }
    }, [konsistenssjekk.isRunning]);

    // If search or sort changes:
    useEffect(() => {
        konsistenssjekk.refetch();
    }, [debouncedParams]);

    const handleRunKonsistenssjekk = async () => {
        setIsRunClicked(true);
        await runKonsistenssjekk.mutateAsync();
        konsistenssjekk.refetch();
    };

    const handleInfiniteScroll = () => {
        if (showRunningReport) {
            if (
                konsistenssjekk.tempLoglines &&
                infiniteScrollCount < konsistenssjekk.tempLoglines.length
            ) {
                const newCount = infiniteScrollCount + BATCH_SIZE;
                setInfiniteScrollCount(newCount);
                setInfiniteScrollData(
                    konsistenssjekk.tempLoglines.slice(0, newCount)
                );
            }
        } else if (
            konsistenssjekk.loglines &&
            infiniteScrollCount < konsistenssjekk.loglines.length
        ) {
            const newCount = infiniteScrollCount + BATCH_SIZE;
            setInfiniteScrollCount(newCount);
            setInfiniteScrollData(konsistenssjekk.loglines.slice(0, newCount));
        }
    };

    const handleSearch = (term: string) => {
        setSearchParams({...searchParams, term});
    };

    const handleSorting = (columnName: string) => {
        if (searchParams.orderBy === columnName) {
            setSearchParams({
                ...searchParams,
                order: searchParams.order === 'asc' ? 'desc' : 'asc',
            });
        } else {
            setSearchParams({...searchParams, orderBy: columnName});
        }
    };

    if (!can.edit || !meta.hasFeature('konsistenssjekk_tilgang')) {
        return (
            <ToiTypography>
                Du har ikke rettigheter til å se denne siden.
            </ToiTypography>
        );
    }
    return (
        <ReportPage
            title='Konsistenssjekk'
            subtitle='Viser ulikheter mellom delte egenskaper som ligger lagret i både arealplaner.no og QMS.'
        >
            {konsistenssjekk.isLoading ? (
                <LoadingSpinner />
            ) : (
                <ToiStack gap={2} py={3}>
                    <ToiStack gap={2} width={1}>
                        {konsistenssjekk.isRunning ? (
                            <KonsistenssjekkIsRunning
                                currentPage={
                                    konsistenssjekk.data.tempCurrentPage
                                }
                                totalPages={konsistenssjekk.data.tempNumPages}
                                showRunningReport={showRunningReport}
                                setShowRunningReport={setShowRunningReport}
                            />
                        ) : (
                            meta.hasFeature(
                                'konsistenssjekk_manuellkjoring'
                            ) && (
                                <>
                                    <ToiButton
                                        variant='secondary'
                                        sx={{width: 'fit-content'}}
                                        onClick={handleRunKonsistenssjekk}
                                        disabled={isRunClicked}
                                        startIcon={
                                            isRunClicked ? (
                                                <ToiSpinner />
                                            ) : (
                                                <VideoPlayIcon />
                                            )
                                        }
                                    >
                                        Kjør konsistenssjekk
                                    </ToiButton>
                                    {konsistenssjekk.reportFailed && (
                                        <KonsistenssjekkFailedInfo
                                            reportJobFailedMessage={
                                                konsistenssjekk.reportFailed
                                            }
                                        />
                                    )}
                                </>
                            )
                        )}
                    </ToiStack>
                    <KonsistenssjekkRunInfo />
                    <Toolbar
                        value={searchParams.term}
                        handleSearch={handleSearch}
                        showRunningReport={showRunningReport}
                        searchParams={searchParams}
                    />
                    {showRunningReport && (
                        <ToiTypography variant='subtitle2'>
                            Viser feil avdekket så langt i kjøringen
                        </ToiTypography>
                    )}
                    <ToiBox height={30}>
                        {konsistenssjekk.isFetching ? (
                            <ToiSpinner />
                        ) : (
                            <ToiTypography>
                                {`${nrOfLogLines} avvik funnet ${showRunningReport ? 'så langt' : ''}`}
                            </ToiTypography>
                        )}
                    </ToiBox>
                    <KonsistenssjekkTable
                        kundeId={meta.kundeId}
                        kommuneNr={meta.komnr}
                        data={infiniteScrollData}
                        handleSorting={handleSorting}
                        showRunningReport={showRunningReport}
                    />
                    <Waypoint
                        onEnter={handleInfiniteScroll}
                        bottomOffset='-400px'
                    />
                </ToiStack>
            )}
        </ReportPage>
    );
}
