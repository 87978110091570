import {
  Paper,
  Table as MuiTable,
  TableContainerProps,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
} from '@mui/material';
import React, {CSSProperties, forwardRef, Fragment} from 'react';
import {Table, RowData, flexRender, Row, Cell} from '@tanstack/react-table';
import clsx from 'clsx';
import {ToiTablePagination} from '../ToiTablePagination';
import {ToiTableContainer} from './ToiTableContainer';
import {ToiTableSummary} from './ToiTableSummary';
import {ToiHeaderContent} from './ToiHeaderContent';

type OwnProps<TData extends RowData> = {
  table: Table<TData>;
  ariaLabel: string;
  minWidth?: CSSProperties['minWidth'];
  renderSummary?: (row: Row<TData>) => React.ReactNode;
  onRowClick?: (row: Row<TData>) => void;
};

export type ToiTableProps<TData extends RowData> = Omit<TableContainerProps, keyof OwnProps<TData> | 'component'> &
  OwnProps<TData>;

export const ToiTable = forwardRef(function ToiTableInner<TData extends RowData>(
  props: ToiTableProps<TData>,
  ref: React.Ref<HTMLDivElement>,
) {
  const {ariaLabel, minWidth, table, renderSummary} = props;

  const hasPagination = table.options.getPaginationRowModel !== undefined;

  const onlyOneRowAction =
    [Boolean(props.onRowClick), table.options.enableExpanding, table.options.enableRowSelection].filter(Boolean)
      .length === 1;

  function handleRowClick(row: Row<TData>, cell: Cell<TData, unknown>) {
    if (!onlyOneRowAction) {
      if (props.onRowClick) {
        console.warn('The onRowClick prop is only allowed when the table is not selectable or expandable');
      } else {
        return;
      }
    }

    const columnDef = cell.column.columnDef;
    if (columnDef.id === 'actions' || columnDef.meta?.disableRowClick) return;

    if (props.onRowClick) {
      props.onRowClick(row);
    } else if (row.getCanExpand()) {
      row.toggleExpanded();
    } else if (row.getCanSelect()) {
      row.toggleSelected();
    }
  }

  return (
    <ToiTableContainer ref={ref} component={Paper} small={table.options.meta?.small}>
      <MuiTable sx={{minWidth}} aria-label={ariaLabel}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell key={header.id} width={`${header.getSize()}px`}>
                  <ToiHeaderContent header={header} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow
                className={clsx({
                  'ToiTableRow-selected': row.getIsSelected(),
                  'ToiTableRow-canExpand': row.getCanExpand(),
                  'ToiTableRow-clickable': onlyOneRowAction,
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    onClick={() => handleRowClick(row, cell)}
                    className={clsx(`ToiTableCell-id-${cell.column.id}`, {
                      'ToiTableCell-clickable':
                        onlyOneRowAction &&
                        cell.column.id !== 'actions' &&
                        !cell.column.columnDef.meta?.disableRowClick,
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
              {row.getCanExpand() && renderSummary && (
                <ToiTableSummary isExpanded={row.getIsExpanded()} colSpan={row.getAllCells().length}>
                  {renderSummary(row)}
                </ToiTableSummary>
              )}
            </Fragment>
          ))}
        </TableBody>
        {(table.options.meta?.showFooter || hasPagination) && (
          <TableFooter>
            {table.options.meta?.showFooter &&
              table.getFooterGroups().map((footerGroup) => (
                <TableRow key={footerGroup.id}>
                  {footerGroup.headers.map((footer) => (
                    <TableCell key={footer.id}>
                      {flexRender(footer.column.columnDef.footer, footer.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {hasPagination && (
              <TableRow>
                <TableCell colSpan={5} sx={{paddingBlock: '0px !important'}}>
                  <ToiTablePagination
                    component='div'
                    sx={{
                      justifyContent: 'flex-end',
                    }}
                    count={table.getRowCount()}
                    page={table.getState().pagination.pageIndex}
                    rowsPerPage={table.getState().pagination.pageSize}
                    onPageChange={(e, pageIndex) => table.setPageIndex(pageIndex)}
                    onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
                    rowsPerPageOptions={table.options.meta?.rowsPerPageOptions}
                    showFirstButton
                    showLastButton
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        )}
      </MuiTable>
    </ToiTableContainer>
  );
}) as <TData extends RowData>(props: ToiTableProps<TData> & {ref?: React.Ref<HTMLDivElement>}) => JSX.Element;

export default ToiTable;
