import {AccordionSummary, styled, Theme} from '@mui/material';
import {ToiAccordionSummaryProps} from './ToiAccordionSummary';

type StyleProps = {theme: Theme} & ToiAccordionSummaryProps;

export const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) =>
    !['summaryContent', 'numberOfListElements', 'badgeValue', 'hasChildren', 'title', 'subtitle'].includes(
      prop.toString(),
    ),
})(({theme, hasChildren, status, size}: StyleProps) => {
  return {
    width: '100%',
    boxShadow: 'none',

    '& .MuiAccordionSummary-content': {
      display: 'flex',
      gap: {
        small: theme.toi.spacing.xs,
        normal: theme.toi.spacing.s,
        large: theme.toi.spacing.l,
      }[size ?? 'normal'],
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding:
        size === 'small'
          ? `${theme.toi.spacing.xs} ${theme.toi.spacing.s}`
          : `${theme.toi.spacing.s} ${theme.toi.spacing.m}`,
      paddingRight: 0,
    },

    '.accordionStatus': {
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& .MuiAccordionSummary-expandIconWrapper': {
      marginLeft: status ? theme.toi.spacing.l : theme.toi.spacing.s,
      marginRight: size === 'small' ? theme.toi.spacing.s : theme.toi.spacing.m,
    },

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: hasChildren ? 'rotate(180deg)' : 'none !important',
    },

    '& .summary-content': {
      marginLeft: 'auto',
    },

    '&.Mui-focusVisible': {
      backgroundColor: 'inherit',
      outline: `1px dashed ${theme.vars.toi.palette.focus.border}`,
      outlineOffset: '2px',
      borderRadius: '2px',
    },
  };
});
