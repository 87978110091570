import {CSSObject, Theme} from '@mui/material';
import {ToiMenuSize} from '../../ToiMenu/types';

export const toiMenuItemBaseStyle = (theme: Theme): CSSObject =>
  ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    outline: 0,
    userSelect: 'none',
    padding: theme.toi.spacing.s,
    gap: theme.toi.spacing.s,
    noWrap: true,
    ...theme.typography.body2,
  }) satisfies CSSObject;

export const toiMenuItemStyle = (theme: Theme, size?: ToiMenuSize, disabled?: boolean): CSSObject =>
  ({
    ...toiMenuItemBaseStyle(theme),
    '&[data-highlighted]': {
      backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,
    },
    ...(size === 'small' && {
      ...theme.typography.body3,
    }),
    ...(disabled && {
      color: theme.vars.toi.palette.disabled.textOnWhite,
    }),
  }) satisfies CSSObject;
