import {RedirectRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';

const loginRedirectRequestDefault: RedirectRequest = {
  scopes: [],
};

const loginRedirectRequestDefaultB2c: RedirectRequest = {
  scopes: [],
};

const useLoginRedirectWithLoginRequest = (loginRequest: RedirectRequest) => {
  const msal = useMsal();

  return () => msal.instance.loginRedirect(loginRequest);
};

export const useLoginRedirectAzureAD = (loginRequest?: RedirectRequest) =>
  useLoginRedirectWithLoginRequest(loginRequest ?? loginRedirectRequestDefault);

export const useLoginRedirectNorkartId = (loginRequest?: RedirectRequest) =>
  useLoginRedirectWithLoginRequest(loginRequest ?? loginRedirectRequestDefaultB2c);
