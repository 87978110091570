import {useTheme} from '@mui/material';
import {LogoProps} from './ToiLogo';

export function IconLogoWithoutSpacing({blackWhite, title, ...svgProps}: LogoProps) {
  const brandColors = useTheme().vars.toi.palette.brand;

  return (
    <svg
      width='68'
      height='74'
      viewBox='0 0 68 74'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMidYMid meet'
      {...svgProps}
    >
      <title>{title ?? 'Norkart Logo Icon'}</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M54.2911 38.9461L53.5026 37.205L37.7562 2.36471C36.3343 -0.788237 31.6673 -0.788237 30.2454 2.36471L20.5469 23.8264L54.2911 38.9506V38.9461ZM52.4846 44.0795L18.312 28.7658L14.4973 37.2052L0.355216 68.5002C-1.2536 72.0591 2.93024 75.4556 6.33018 73.3536L52.4846 44.0795ZM39.0729 59.3887L57.9276 46.9979V46.9934L67.6443 68.4957C69.2531 72.0546 65.0693 75.4511 61.6693 73.3491L39.0729 59.3887Z'
        fill={blackWhite ? brandColors.wordmark : brandColors.logo}
      />
    </svg>
  );
}
