import {useQuery} from 'react-query';
import {
    arealplanerGetMidlertidigForbud,
    MidlertidigForbudDto,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useMidlertidigForbud(arealplanId?: number) {
    const kundeId = useSelectedKundeId();
    const {client} = useArealplanerApiClientContext();

    const getMidlertidigForbud = async (arealplanId?: number) => {
        if (!arealplanId) return [];
        const {data, error} = await arealplanerGetMidlertidigForbud({
            path: {kundeId, arealplanId},
            client,
        });
        if (error) throw new Error(String(error.message));
        return data;
    };

    const query = useQuery<MidlertidigForbudDto[]>(
        ['midlertidigforbud', {kundeId, arealplanId}],
        async () => await getMidlertidigForbud(arealplanId),
        {
            enabled: !!arealplanId,
        }
    );

    return {
        ...query,
        data: query?.data || [],
    };
}
