import CircleIcon from '@mui/icons-material/Circle';
import {CardHeader, Grid, Icon} from '@mui/material';
import {padStart} from 'lodash-es';
import {DateTime} from 'luxon';
import DOMPurify from 'dompurify';
import {EditIcon, DeleteRecycleIcon} from '@norkart/toi-icons';
import {useIsTabletOrMobile} from '@norkart/design-responsive';
import {
  ToiBox,
  ToiCard,
  ToiCardContent,
  ToiChip,
  ToiIconButton,
  ToiStack,
  ToiTypography,
} from '@norkart/toi-components';
import {useTags} from '../Hooks';
import postType from '../Types/PostType';

type props = {
  post: postType;
  onEdit: (post: postType) => void;
  onDelete: (post: postType) => void;
  isAuthenticated: boolean;
};

function Post({post, onEdit, onDelete, isAuthenticated}: props) {
  const tags = useTags();
  const isTabletOrMobile = useIsTabletOrMobile();

  let date = DateTime.fromISO(post.date.toString(), {zone: 'UTC'});
  date = date.setZone('local');

  if (isTabletOrMobile) {
    return (
      <>
        <ToiBox
          sx={{
            background: 'linear-gradient(#000, #000) no-repeat center/2px 100%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            justifyItems: 'center',
            alignItems: 'end',
          }}
        >
          <ToiTypography sx={{marginX: 4, justifySelf: 'start'}} variant='subtitle2'>
            {date.day + '.' + date.month + '.' + date.year}
          </ToiTypography>
          <Icon>
            <CircleIcon />
          </Icon>
          <ToiTypography sx={{marginX: 4, justifySelf: 'end'}} variant='body1'>
            {padStart(date.hour.toString(), 2, '0') + ':' + padStart(date.minute.toString(), 2, '0')}
          </ToiTypography>
        </ToiBox>
        <ToiBox
          sx={{
            background: 'linear-gradient(#000, #000) no-repeat center/2px 100%',
          }}
        >
          <ToiCard variant='outlined' sx={{marginY: 2, marginX: 4}}>
            <CardHeader
              title={post.title}
              action={
                isAuthenticated ? (
                  <ToiStack direction={'row'} gap={(theme) => theme.toi.spacing.xs}>
                    <ToiIconButton aria-label='Endre post' onClick={() => onEdit(post)}>
                      <EditIcon />
                    </ToiIconButton>
                    <ToiIconButton aria-label='Slett post' onClick={() => onDelete(post)}>
                      <DeleteRecycleIcon />
                    </ToiIconButton>
                  </ToiStack>
                ) : null
              }
            />
            <ToiCardContent>
              <ToiTypography
                variant='body1'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.content),
                }}
              ></ToiTypography>
              {tags.data.map((tag) =>
                post.tagIds.includes(tag.id) ? <ToiChip label={tag.name} key={tag.id} size='small' disableDot /> : null,
              )}
            </ToiCardContent>
          </ToiCard>
        </ToiBox>
      </>
    );
  } else {
    return (
      <Grid container>
        <Grid
          item
          xs={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'right',
            textAlign: 'right',
            justifyContent: 'center',
            paddingRight: '10px',
          }}
        >
          <ToiTypography variant='body1'>
            {padStart(date.hour.toString(), 2, '0') + ':' + padStart(date.minute.toString(), 2, '0')}
          </ToiTypography>
          <ToiTypography variant='subtitle2'>{date.day + '.' + date.month + '.' + date.year}</ToiTypography>
        </Grid>
        <Grid
          item
          xs={0.25}
          style={{
            background: 'linear-gradient(#000, #000) no-repeat center/2px 100%',
            display: 'flex',
            placeItems: 'center',
          }}
        >
          <Icon sx={{flexGrow: 1}}>
            <CircleIcon />
          </Icon>
        </Grid>
        <Grid item xs={10}>
          <ToiCard variant='outlined' sx={{marginY: 2, marginX: 4}}>
            <CardHeader
              title={post.title}
              action={
                isAuthenticated ? (
                  <ToiStack direction={'row'} gap={(theme) => theme.toi.spacing.xs}>
                    <ToiIconButton aria-label='Endre post' onClick={() => onEdit(post)}>
                      <EditIcon />
                    </ToiIconButton>
                    <ToiIconButton aria-label='Slett post' onClick={() => onDelete(post)}>
                      <DeleteRecycleIcon />
                    </ToiIconButton>
                  </ToiStack>
                ) : null
              }
            />
            <ToiCardContent>
              <ToiTypography
                variant='body1'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.content),
                }}
              ></ToiTypography>
              {tags.data.map((tag) =>
                post.tagIds.includes(tag.id) ? <ToiChip label={tag.name} key={tag.id} size='small' disableDot /> : null,
              )}
            </ToiCardContent>
          </ToiCard>
        </Grid>
      </Grid>
    );
  }
}

export default Post;
