import {ChipPropsVariantOverrides, ChipTypeMap, ChipProps as MuiChipProps} from '@mui/material';
import {forwardRef} from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {OverridableStringUnion} from '@mui/types';
import {ToiBadge} from '../ToiBadge/ToiBadge';
import {ToiChipCloseButton} from './ToiChipCloseButton';
import {StyledChip} from './StyledToiChip';

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    grey: true;
  }

  interface ChipPropsSizeOverrides {
    large: false;
  }

  interface ChipOwnProps {
    /**
     * @deprecated
     * All chips (except for the default chip) should have no outline.
     * This is handled by the component itself.
     */
    variant?: OverridableStringUnion<'filled' | 'outlined', ChipPropsVariantOverrides>;
  }
}

type OwnProps = {
  selected?: boolean;
  disableDot?: boolean;
  color?: 'default' | 'grey' | 'error' | 'info' | 'success' | 'warning';
};

type DefaultComponent = ChipTypeMap['defaultComponent'];
type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiChipProps = MuiChipProps<DefaultComponent, OwnProps>;

export const ToiChip = forwardRef<RefType, ToiChipProps>((props, ref) => {
  const {deleteIcon, disableDot, ...restProps} = props;

  return (
    <StyledChip
      ref={ref}
      tabIndex={props.clickable || props.onClick ? 0 : -1}
      deleteIcon={deleteIcon ?? <ToiChipCloseButton aria-label={`Lukk ${props.label}`} onDelete={props.onDelete} />}
      icon={
        !disableDot ? (
          <ToiBadge sx={{mx: '4px !important'}} variant='dot' color={props.selected ? 'white' : props.color} />
        ) : undefined
      }
      aria-label={`${props.label}${props.selected !== undefined ? ', markérbar' : ''}${props.onDelete ? ', lukkbar' : ''}`}
      aria-pressed={props.selected}
      {...restProps}
    />
  );
}) as OverridableComponent<ChipTypeMap<OwnProps>>;

export default ToiChip;
