import * as React from 'react';
import {ToiButton} from '@norkart/toi-components';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import useMeta from '../../../../hooks/meta/useMeta';
import {setTitle} from '../../../../utils/setTitle';
import ReportPage from '../ReportPage';
import {DispensasjonerFilter} from './DispensasjonerFilter';
import DispensasjonerReport from './DispensasjonerReport';

export type DispensasjonerQueryVariables = {
    first?: number;
    after?: string;
    filter?: {and: any[]} | undefined;
    sort?: any[] | undefined;
};

export default function Dispensasjoner() {
    setTitle('Rapporter > Dispensasjoner');
    const initialVariables: DispensasjonerQueryVariables = {first: 60};
    const meta = useMeta();
    const [filterValues, setFilterValues] = React.useState<any | undefined>(
        undefined
    );
    const [vars, setVars] =
        React.useState<DispensasjonerQueryVariables>(initialVariables);

    //Oppdaterer variabler til graphql request når "filterValues" oppdateres i "FilterDispensasjoner" komponent
    React.useMemo(() => {
        const newVars: DispensasjonerQueryVariables = {
            ...vars,
            filter: filterValues,
        };
        setVars((prevValue) => {
            if (newVars === initialVariables) return prevValue;
            else if (!newVars.filter && !newVars.sort) return initialVariables;
            else return newVars;
        });
    }, [filterValues]);

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <ReportPage title='Dispensasjoner'>
            <DispensasjonerFilter
                setFilterValues={setFilterValues}
                clearFilter={filterValues ? false : true}
                vedtaksstatuser={meta.data.vedtakstyper}
                planstatuser={meta.data.planstatuser}
                plantyper={meta.data.plantyper}
            />
            <ToiButton
                variant='secondary'
                onClick={() => setFilterValues(undefined)}
                sx={{
                    display: filterValues ? 'flex' : 'none',
                    marginTop: '10px',
                }}
            >
                Nullstill filter
            </ToiButton>
            <DispensasjonerReport
                setVars={setVars}
                kundeId={meta.kundeId}
                kommunenummer={meta.komnr}
                variables={vars}
            />
        </ReportPage>
    );
}
