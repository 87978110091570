import {Grid} from '@mui/material';
import {
    ToiBox,
    ToiButton,
    ToiIconButton,
    ToiSpinner,
    ToiTextField,
    ToiTooltip,
    ToiTypography,
} from '@norkart/toi-components';
import {CloseIcon, DownloadIcon, SearchIcon} from '@norkart/toi-icons';
import {ToolbarProps} from './ReactTable';

export function ReactTableToolbar({
    clearSearchFilter,
    onChange,
    onKeyUp,
    value = '',
    disabled,
    exportCsv,
    enableFilter,
    dataInfo,
}: ToolbarProps) {
    if (disabled) return null;
    return (
        <ToiBox>
            <Grid container={true}>
                {enableFilter && (
                    <Grid xs={6} item={true}>
                        <Grid container={true}>
                            <ToiTextField
                                onChange={onChange}
                                onKeyUp={onKeyUp}
                                placeholder='Filter...'
                                value={value}
                                size='small'
                                endAdornment={
                                    <div
                                        style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <ToiIconButton
                                            title='Clear'
                                            aria-label='Clear'
                                            size='small'
                                            color='transparent'
                                            style={{
                                                visibility:
                                                    value !== ''
                                                        ? 'visible'
                                                        : 'hidden',
                                            }}
                                            onClick={clearSearchFilter}
                                        >
                                            <CloseIcon size='small' />
                                        </ToiIconButton>
                                        <SearchIcon fontSize='small' />
                                    </div>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                {exportCsv && (
                    <Grid sx={{}} xs={12} item={true}>
                        <Grid container={true} sx={{alignItems: 'center'}}>
                            <Grid xs={6} item={true}>
                                {
                                    /*Valgfri informasjon kan dyttes inn her, feks total count */
                                    dataInfo && (
                                        <ToiTypography
                                            fontSize={'0.85rem'}
                                            variant='caption'
                                            justifyContent={'flex-end'}
                                        >
                                            {dataInfo}
                                        </ToiTypography>
                                    )
                                }
                            </Grid>
                            <Grid
                                display='flex'
                                justifyContent='flex-end'
                                xs={6}
                                item={true}
                            >
                                {exportCsv && (
                                    <ToiTooltip
                                        color='primary'
                                        title={
                                            disabled
                                                ? exportCsv?.tooltipDisabled
                                                    ? exportCsv.tooltipDisabled
                                                    : ''
                                                : 'Eksporter data til CSV'
                                        }
                                    >
                                        <span>
                                            <ToiButton
                                                onClick={
                                                    exportCsv.handleDownloadClick
                                                }
                                                startIcon={
                                                    exportCsv.loadingRequest ? (
                                                        <ToiSpinner />
                                                    ) : (
                                                        <DownloadIcon size='small' />
                                                    )
                                                }
                                            >
                                                Eksporter
                                            </ToiButton>
                                        </span>
                                    </ToiTooltip>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </ToiBox>
    );
}
