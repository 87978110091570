import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Typography,
} from '@mui/material';
import axios, {AxiosPromise} from 'axios';
import {ArealplanDto, PlanforholdDto} from '@norkart/nkapi-arealplaner-api';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog} from '../../../store/dialog/actions';
import {updatePartialArealplan} from '../../../services/api';
import {openSnackbarMessage} from '../../../components/SnackbarMessage';
import SpinnerButton from '../../../components/SpinnerButton';
import useArealplan from '../../../hooks/arealplaner/useArealplan';
import useMeta from '../../../hooks/meta/useMeta';

export interface PlanRelationshipConfirmUpdatePlanDialogProps
    extends ConnectedReduxProps {
    open: boolean;
    planforhold: PlanforholdDto[];
    plan: ArealplanDto;
}

export interface PlanforholdExtended extends PlanforholdDto {
    shouldUpdate: boolean;
    newPlanStatusId: number;
    newPlanStatus: string;
}

const PlanRelationshipConfirmUpdatePlanDialog: React.FC<
    PlanRelationshipConfirmUpdatePlanDialogProps
> = ({
    open,
    dispatch,
    ...props
}: PlanRelationshipConfirmUpdatePlanDialogProps) => {
    const meta = useMeta();
    const {data: plan, refetch} = useArealplan();
    const [planforhold, setPlanforhold] = React.useState(
        [] as PlanforholdExtended[]
    );

    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        setPlanforhold(
            props.planforhold.map((pf) => {
                const newPlanStatusId = pf.id == 2 ? 8 : 5;
                return {
                    ...pf,
                    shouldUpdate: true,
                    newPlanStatusId: newPlanStatusId,
                    newPlanStatus: meta.getPlanstatus(newPlanStatusId),
                };
            })
        );
    }, [props.planforhold]);

    function handleClose() {
        hideDialog(dispatch);
    }

    function updateArealplanStatus(
        ArealplanId?: number,
        PlanStatusId?: number
    ): AxiosPromise | undefined {
        if (!ArealplanId || !PlanStatusId) return;
        return updatePartialArealplan({
            ArealplanId,
            PlanStatusId,
        });
    }

    function handleSave() {
        setSubmitting(true);
        const reqs = [] as AxiosPromise[];
        planforhold
            .filter((pf) => pf.shouldUpdate == true)
            .map((p) => {
                const req = updateArealplanStatus(
                    p.arealplanId,
                    p.newPlanStatusId
                );
                req && reqs.push(req);
            });
        axios
            .all(reqs)
            .catch((err) =>
                openSnackbarMessage({
                    message: 'Noe gikk galt under oppdateringen',
                    variant: 'error',
                })
            )
            .finally(() => {
                handleClose();
                setSubmitting(false);
                refetch();
            });
    }

    function toggleShouldUpdate(pf: PlanforholdExtended) {
        const updatedPlanforhold = [...planforhold];
        const index = planforhold.indexOf(pf);
        const updatedPf = {
            ...updatedPlanforhold[index],
            shouldUpdate: !updatedPlanforhold[index].shouldUpdate,
        };
        updatedPlanforhold[index] = updatedPf;
        setPlanforhold(updatedPlanforhold);
    }

    function renderPlanforholdRow(pf: PlanforholdExtended) {
        return (
            <TableRow key={`${pf.arealplanId}-${pf.id}`}>
                <TableCell>{meta.getPlanforholdstypeForloper(pf.id)}</TableCell>
                <TableCell>{pf.planId}</TableCell>
                <TableCell>{pf.planNavn}</TableCell>
                <TableCell>{pf.planType}</TableCell>
                <TableCell>{pf.planStatus}</TableCell>
                <TableCell>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>{pf.newPlanStatus}</span>
                        <Checkbox
                            name='shouldUpdate'
                            checked={pf.shouldUpdate}
                            onChange={() => toggleShouldUpdate(pf)}
                        />
                    </div>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Dialog open={open} maxWidth='xl'>
            <DialogTitle>Endre planstatus</DialogTitle>
            <DialogContent>
                <Typography>
                    Kryss av hvis du ønsker å endre planstatus
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Type forhold</TableCell>
                            <TableCell>PlanId</TableCell>
                            <TableCell>Plannavn</TableCell>
                            <TableCell>Plantype</TableCell>
                            <TableCell>Eksisterende planstatus</TableCell>
                            <TableCell>Aksepter ny planstatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {planforhold.map((pf) => renderPlanforholdRow(pf))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <SpinnerButton
                    onClick={handleSave}
                    loading={submitting}
                    disabled={submitting}
                    label='Ok'
                />
            </DialogActions>
        </Dialog>
    );
};

export default PlanRelationshipConfirmUpdatePlanDialog;
