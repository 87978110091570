import {Grid} from '@mui/material';
import {orderBy} from 'lodash-es';
import {useSelector} from 'react-redux';
import {SakArkivJournalDto} from '@norkart/nkapi-arealplaner-api';
import ExpansionPanel from '../../../components/ExpansionPanel';
import GenericTable from '../../../components/GenericTable';
import {GTableColumn} from '../../../components/GenericTable/GenericTable';
import LoadingSpinner from '../../../components/LoadingSpinner';
import DesktopContainer from '../../../containers/DesktopContainer';
import MobileContainer from '../../../containers/MobileContainer';
import useArealplan from '../../../hooks/arealplaner/useArealplan';
import useJournaler from '../../../hooks/journaler/useJournaler';
import {getActiveSakId} from '../../../store';
import {formatDate} from '../../../utils/formatDate';
import JournalDocumentList from '../JournalDocumentList';
import SakSelector from '../SakSelector/SakSelector';

function JournalList() {
    const selectedSakId = useSelector(getActiveSakId);
    const {data: plan} = useArealplan();
    const {data: journals, isLoading, isError} = useJournaler(selectedSakId);

    const columns = [
        {
            title: 'Journaldato',
            field: 'journalDato',
            render: (journal: SakArkivJournalDto) => {
                return formatDate(journal.journalDato);
            },
            cellStyle: {paddingRight: '20px'},
        },
        {
            title: 'Journalpost',
            field: 'title',
            cellStyle: {},
        },
        {
            title: 'Fra/til',
            field: 'korrespondansepart',
        },
    ] as GTableColumn[];

    const renderDetailPanel = (journal: SakArkivJournalDto) => (
        <div style={{padding: '4px 20px 20px 80px'}}>
            <JournalDocumentList journal={journal} />
        </div>
    );

    const renderJournalList = () => (
        <>
            <DesktopContainer>
                <div className='tabbable-table-wrapper'>
                    <GenericTable
                        columns={columns}
                        idColumn='id'
                        data={orderBy(journals, 'journalpostNummer', 'desc')}
                        title='Journaler'
                        onRowClick={(e, rowData, toggleExpand) =>
                            toggleExpand(rowData)
                        }
                        detailPanel={[
                            {
                                tooltip: 'Dokumenter',
                                render: renderDetailPanel,
                            },
                        ]}
                        options={{
                            allowMultipleExpanded: true,
                            header: true,
                        }}
                    />
                </div>
            </DesktopContainer>
            <MobileContainer>
                {orderBy(journals, 'journalpostNummer', 'desc').map(
                    (journal) => (
                        <ExpansionPanel
                            key={journal.id}
                            title={journal.title}
                            bgColor='white'
                            extraInfo={formatDate(journal.journalDato)}
                        >
                            <JournalDocumentList journal={journal} />
                        </ExpansionPanel>
                    )
                )}
            </MobileContainer>
        </>
    );

    const renderContent = () =>
        isLoading ? (
            <LoadingSpinner />
        ) : !journals.length || isError ? (
            <div>Ingen journalposter å vise for sak.</div>
        ) : (
            renderJournalList()
        );

    return (
        <Grid container={true}>
            <Grid item={true} xs={12} md={2}>
                <SakSelector />
            </Grid>
            <Grid item={true} xs={12} md={plan.saker?.length === 1 ? 12 : 10}>
                {renderContent()}
            </Grid>
        </Grid>
    );
}

export default JournalList;
