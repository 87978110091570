import {DatePickerProps as MuiDatePickerProps, PickerValidDate} from '@mui/x-date-pickers';
import React, {ForwardedRef, useState} from 'react';
import {nbNO} from '@mui/x-date-pickers/locales';
import {prepareForSlot} from '@mui/base/utils';
import {CalendarIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon} from '@norkart/toi-icons';
import {useIsTabletOrMobile} from '@norkart/design-responsive';
import {OpenPickerButton} from '../ToiTimePicker/OpenPickerButton';
import {ToiTextField} from '../ToiTextField/ToiTextField';
import {StyledDatePicker, StyledPopper} from './StyledDatePicker';

// Remove ownerState: https://mui.com/base-ui/getting-started/customization/#overriding-subcomponent-slots
// Norkart icons does not have all mui icon props
const CalendarIconSlot = prepareForSlot(CalendarIcon);

const dateFormat = nbNO.components.MuiLocalizationProvider.defaultProps.localeText;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type OwnProps = {};

export type ToiDatePickerProps<
  TDate extends PickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false,
> = Omit<MuiDatePickerProps<TDate, TEnableAccessibleFieldDOMStructure>, keyof OwnProps> & OwnProps;

function ToiDatePickerInner<TDate extends PickerValidDate>(
  props: ToiDatePickerProps<TDate>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const isMobile = useIsTabletOrMobile();
  const [open, setOpen] = useState(false);

  const mobileTextFieldProps = {
    onClick: () => {
      setOpen((prev) => !prev);
    },
    onFocusCapture: (e: React.FocusEvent<HTMLDivElement, Element>) => {
      e.target.blur();
      e.stopPropagation();
    },
    inputProps: {
      readOnly: true,
    },
  };

  return (
    <StyledDatePicker
      {...props}
      ref={ref}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      slots={{
        textField: ToiTextField,
        openPickerIcon: CalendarIconSlot,
        popper: StyledPopper,
        switchViewIcon: ChevronDownIcon,
        rightArrowIcon: ChevronRightIcon,
        leftArrowIcon: ChevronLeftIcon,
        openPickerButton: OpenPickerButton,
      }}
      format={props.format}
      localeText={dateFormat}
      slotProps={{
        ...props.slotProps,
        textField: {
          ...(isMobile ? mobileTextFieldProps : {}),
          ...props.slotProps?.textField,
        },
        popper: {
          placement: isMobile ? 'bottom' : 'bottom-start',
          ...props.slotProps?.popper,
        },
      }}
    />
  );
}

export type ToiDatePickerComponent = <
  TDate extends PickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false,
>(
  props: ToiDatePickerProps<TDate, TEnableAccessibleFieldDOMStructure> & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element;

export const ToiDatePicker = React.forwardRef(ToiDatePickerInner) as ToiDatePickerComponent;

export default ToiDatePicker;
