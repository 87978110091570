import {LinkTypeMap, styled} from '@mui/material';
import {CSSObject} from '@mui/material';
import {forwardRef} from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import clsx from 'clsx';
import {ToiLink, ToiLinkProps} from '../ToiLink';
import {ToiTypography} from '../ToiTypography';

type OwnProps = {
  active?: boolean;
};

type ToiLinkTypeMap = LinkTypeMap<OwnProps>;

export type ToiLinkListItemProps = Omit<ToiLinkProps, keyof OwnProps | 'variant'> & OwnProps;

const StyledComponent = styled(ToiLink, {
  shouldForwardProp: (prop) => !['active'].includes(prop.toString()),
})<ToiLinkListItemProps>(({theme, active}): CSSObject => {
  return {
    cursor: 'pointer',
    paddingBlock: theme.toi.spacing.xs,
    paddingInline: theme.toi.spacing.s,
    maxWidth: 'fit-content',
    textDecoration: 'none',
    borderRadius: '19px',

    '&:hover, &.ToiLinkListItem-active, &:has(+ div > a.ToiLinkListSubItem-active)': {
      textDecoration: 'underline',
    },

    '&:focus-visible': {
      borderRadius: '19px',
    },

    '&.ToiLinkListItem-active, &:has(+ div > a.ToiLinkListSubItem-active)': {
      backgroundColor: theme.vars.toi.palette.links.default,
      '&:hover': {
        backgroundColor: theme.vars.toi.palette.links.hover,
      },
      '& .MuiTypography-root': {
        color: theme.vars.toi.palette.text.inverted,
        fontWeight: 600,
      },
    },
  };
});

export const ToiLinkListItem = forwardRef<HTMLAnchorElement, ToiLinkListItemProps>((props, ref) => {
  const {active = false, ...restProps} = props;

  return (
    <StyledComponent className={clsx(active && 'ToiLinkListItem-active')} ref={ref} {...restProps}>
      <ToiTypography variant='body2' sx={{display: 'flex', gap: (theme) => theme.toi.spacing.xs}}>
        {props.children}
      </ToiTypography>
    </StyledComponent>
  );
}) as OverridableComponent<ToiLinkTypeMap>;

export default ToiLinkListItem;
