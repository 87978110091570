import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const RotateIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M20.2498 12C20.2498 12.4142 20.5856 12.75 20.9998 12.75C21.414 12.75 21.7498 12.4142 21.7498 12H20.2498ZM3.82701 6.68128C3.60078 7.02825 3.69866 7.49293 4.04564 7.71916C4.39261 7.9454 4.85729 7.84751 5.08352 7.50054L3.82701 6.68128ZM4.97705 3C4.97705 2.58579 4.64126 2.25 4.22705 2.25C3.81284 2.25 3.47705 2.58579 3.47705 3H4.97705ZM4.22705 7.09091H3.47705C3.47705 7.50512 3.81284 7.84091 4.22705 7.84091V7.09091ZM8.31796 7.84091C8.73217 7.84091 9.06796 7.50512 9.06796 7.09091C9.06796 6.6767 8.73217 6.34091 8.31796 6.34091V7.84091ZM11.9998 3.75C16.5561 3.75 20.2498 7.44365 20.2498 12H21.7498C21.7498 6.61522 17.3846 2.25 11.9998 2.25V3.75ZM5.08352 7.50054C6.5568 5.24097 9.10437 3.75 11.9998 3.75V2.25C8.57627 2.25 5.56543 4.01506 3.82701 6.68128L5.08352 7.50054ZM3.47705 3V7.09091H4.97705V3H3.47705ZM4.22705 7.84091H8.31796V6.34091H4.22705V7.84091Z'
      fill='var(--toi-palette-icons-secondary, #2E2D30)'
    />
    <path
      d='M3.75022 12C3.75022 11.5858 3.41444 11.25 3.00022 11.25C2.58601 11.25 2.25022 11.5858 2.25022 12L3.75022 12ZM20.173 17.3187C20.3992 16.9717 20.3013 16.5071 19.9544 16.2808C19.6074 16.0546 19.1427 16.1525 18.9165 16.4995L20.173 17.3187ZM19.0229 21C19.0229 21.4142 19.3587 21.75 19.7729 21.75C20.1872 21.75 20.5229 21.4142 20.5229 21L19.0229 21ZM19.7729 16.9091L20.5229 16.9091C20.5229 16.4949 20.1872 16.1591 19.7729 16.1591L19.7729 16.9091ZM15.682 16.1591C15.2678 16.1591 14.932 16.4949 14.932 16.9091C14.932 17.3233 15.2678 17.6591 15.682 17.6591L15.682 16.1591ZM12.0002 20.25C7.44387 20.25 3.75022 16.5563 3.75022 12L2.25022 12C2.25022 17.3848 6.61545 21.75 12.0002 21.75L12.0002 20.25ZM18.9165 16.4995C17.4432 18.759 14.8956 20.25 12.0002 20.25L12.0002 21.75C15.4237 21.75 18.4346 19.9849 20.173 17.3187L18.9165 16.4995ZM20.5229 21L20.5229 16.9091L19.0229 16.9091L19.0229 21L20.5229 21ZM19.7729 16.1591L15.682 16.1591L15.682 17.6591L19.7729 17.6591L19.7729 16.1591Z'
      fill='var(--toi-palette-icons-secondary, #2E2D30)'
    />
  </ToiIcon>
));

export default RotateIcon;
