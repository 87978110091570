import {BadgeProps as MuiBadgeProps, BadgeTypeMap} from '@mui/material';
import {forwardRef} from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {ToiTypography} from '../ToiTypography';
import {StyledBadge} from './StyledBadge';

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    grey: true;
    white: true;
  }

  interface BadgePropsVariantOverrides {
    number: true;
  }
}

type DefaultComponent = BadgeTypeMap['defaultComponent'];
type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiBadgeProps = MuiBadgeProps;

export const ToiBadge = forwardRef<RefType, ToiBadgeProps>((props, ref) => {
  const {badgeContent, max, ...restProps} = props;
  const hide = props.variant !== 'dot' && !badgeContent && !(badgeContent === 0 && props.showZero);

  return (
    <StyledBadge
      ref={ref}
      max={max || 9999}
      hide={hide}
      badgeContent={
        ['number', 'string'].includes(typeof badgeContent) ? (
          <ToiTypography variant='body4'>{badgeContent}</ToiTypography>
        ) : (
          badgeContent
        )
      }
      invisible={false}
      {...restProps}
    />
  );
}) as OverridableComponent<BadgeTypeMap>;

export default ToiBadge;
