import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import {ToiButton} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {BehandlingDto} from '@norkart/nkapi-arealplaner-api';
import useDeleteBehandling from '../../../hooks/behandlinger/useDeleteBehandling';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog, showDialog} from '../../../store/dialog/actions';

export interface DeleteBehandlingDialogProps extends ConnectedReduxProps {
    behandling: BehandlingDto;
    plan: ArealplanDto;
    open: boolean;
}

const DeleteBehandlingDialog = ({
    behandling,
    plan,
    dispatch,
    open,
}: DeleteBehandlingDialogProps) => {
    const {mutate: deleteBehandling} = useDeleteBehandling();
    const handleClose = () => {
        hideDialog(dispatch).then(() =>
            dispatch(
                showDialog({
                    dialogType: 'EDIT_BEHANDLING',
                    dialogProps: {behandling, plan},
                })
            )
        );
    };

    const handleOk = () => {
        behandling.id && deleteBehandling(behandling.id);
        hideDialog(dispatch);
    };
    return (
        <Dialog
            open={open}
            aria-labelledby='confirm-dialog-title'
            disableScrollLock={true}
        >
            <DialogTitle id='confirm-dialog-title'>
                Vil du virkelig slette behandlingen?
            </DialogTitle>
            <DialogActions>
                <ToiButton onClick={handleClose}>Nei</ToiButton>
                <ToiButton onClick={handleOk}>Ja</ToiButton>
            </DialogActions>
        </Dialog>
    );
};
export default DeleteBehandlingDialog;
