import {DrawerProps} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import clsx from 'clsx';
import React, {forwardRef} from 'react';
import {StyledDrawer} from './StyledDrawer';
import {PersistentDrawerToggleButton} from './PersistentDrawerToggleButton';

type OwnProps = {
  open?: boolean;
  collapsible?: boolean;
  fullScreen?: boolean;
  window?: Window | null;
  onToggle?: React.MouseEventHandler;
};

export type ToiDrawerProps = Omit<DrawerProps, keyof OwnProps> & OwnProps;

type ToiDrawerType = OverridableComponent<{
  props: Omit<DrawerProps, keyof OwnProps> & OwnProps;
  defaultComponent: 'nav';
}>;

export const ToiDrawer = forwardRef<HTMLDivElement, ToiDrawerProps>((props, ref) => {
  const {children, window, ...rest} = props;

  const className = clsx({[`ToiDrawer-${props.variant}`]: props.variant, 'ToiDrawer-collapsible': props.collapsible});

  return (
    <StyledDrawer
      classes={{
        root: className,
        paper: className,
      }}
      ref={ref}
      {...rest}
    >
      {props.variant === 'persistent' && props.onToggle && (
        <PersistentDrawerToggleButton anchor={props.anchor} onToggle={props.onToggle} open={props.open} />
      )}
      {children}
    </StyledDrawer>
  );
}) as ToiDrawerType;

export default ToiDrawer;
