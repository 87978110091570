import {ToiButton} from '@norkart/toi-components';

export interface ActionButtonProps {
    title: string | JSX.Element;
    label: string;
    onClick: (event) => any;
}

function ActionButton({title, onClick, label}: ActionButtonProps) {
    return (
        <ToiButton onClick={onClick} variant='secondary' aria-label={label}>
            {title}
        </ToiButton>
    );
}

export default ActionButton;
