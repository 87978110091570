import {
    Grid,
    Icon,
    TableCell,
    TableRow,
    Theme,
    Typography,
} from '@mui/material';
import {amber} from '@mui/material/colors';
import {sortBy} from 'lodash-es';
import * as React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Dispatch} from 'redux';
import {ToiBox, ToiButton, ToiTextField} from '@norkart/toi-components';
import {BehandlingDto} from '@norkart/nkapi-arealplaner-api';
import {DispensasjonDto} from '@norkart/nkapi-arealplaner-api';
import StyledCheckbox from '../../../components/Checkbox/StyledCheckbox';
import DatePicker from '../../../components/DatePicker';
import FileIcon from '../../../components/FileIcon';
import Error from '../../../components/FormError/Error';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import useMeta from '../../../hooks/meta/useMeta';
import {ApplicationState} from '../../../store';
import {
    addFile,
    removeFile,
    resetErrorForField,
    resetErrorForFile,
    updateFile,
} from '../../../store/fileImport/actions';
import {FileData, FileImportRowErrors} from '../../../store/fileImport/types';
import {formatDate} from '../../../utils/formatDate';
import {getFileExtension} from '../../../utils/getFileExtension';
import {isValidDate} from '../../../utils/isValidDate';
import useDocumentHelpers from '../useDocumentHelpers';
import {ImportDocumentListOptions} from './ImportDocumentList';

type Options = ImportDocumentListOptions & {
    showDokumentTittel?: boolean;
};

type Props = ConnectedProps<typeof connector> & {
    document: FileData;
    behandling?: BehandlingDto;
    dispensasjon?: DispensasjonDto;
    options: Options;
};

const errorStyle = (theme: Theme) => ({
    color: theme.palette.error.main,
});

export const ImportDocumentListItem = ({
    document,
    behandling,
    dispensasjon,
    options,
    ...props
}: Props) => {
    const [errors, setErrors] = React.useState<FileImportRowErrors | null>();
    const meta = useMeta();
    const helpers = useDocumentHelpers();

    const fileExtension = '.' + getFileExtension(document.dokumentNavn);

    const dokumentNavnError = errors && errors['dokumentNavn'];

    const parseDocname = (value: string): string => {
        return value ? value + fileExtension : fileExtension;
    };

    const formatDocname = (value: string) => {
        const ix = value.lastIndexOf(fileExtension);
        return value ? value.substring(0, ix) : '';
    };

    React.useEffect(() => {
        const error = props.errors && props.errors[document.id];
        setErrors(error);
    }, [props.errors]);

    const handleChange = (updatedData: FileData) => {
        const _updatedData = updatedData;

        if (
            isValidDate(updatedData.dokumentDato) ||
            updatedData.dokumentDato == null
        ) {
            props.resetFieldError(_updatedData, 'dokumentDato');
        }

        if (_updatedData.dokumentNavn != document.dokumentNavn) {
            updatedData.overwrite = false;
            updatedData.exists = false;
            props.resetFieldError(_updatedData, 'dokumentNavn');
        }

        if (!document.selected) {
            _updatedData.selected = true;
            props.addFile(_updatedData);
        }
        if (document.selected) {
            if (!_updatedData.selected) props.removeFile(_updatedData);
            else props.updateFile(_updatedData);
        }

        if (
            _updatedData.dokumentTypeId &&
            !isNaN(_updatedData.dokumentTypeId)
        ) {
            props.resetFieldError(_updatedData, 'dokumentTypeId');
        }
    };

    const handleOverwrite = () => {
        const updatedData = {
            ...document,
            overwrite: true,
        };
        handleChange(updatedData);
    };

    const handleRemove = (data: FileData) => {
        props.removeFile(data);
    };

    const renderOverwriteDialog = () => (
        <TableRow style={{height: '57px', border: '1px solid ' + amber[700]}}>
            <TableCell
                colSpan={5}
                style={{width: '100%', wordBreak: 'keep-all'}}
            >
                <Grid
                    container={true}
                    justifyContent='space-between'
                    wrap='wrap'
                    alignItems='center'
                    style={{textAlign: 'center'}}
                >
                    <Grid item={true} style={{width: '100%'}}>
                        <Grid container={true} alignItems='center'>
                            <Grid item={true}>
                                <Icon
                                    style={{display: 'flex', color: amber[700]}}
                                >
                                    warning
                                </Icon>
                            </Grid>
                            <Grid item={true} style={{marginLeft: '10px'}}>
                                {`Vil du overskrive ${document.dokumentNavn} som finnes fra før?`}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true}>
                        <ToiButton
                            variant='secondary'
                            onClick={() => handleOverwrite()}
                        >
                            Ja
                        </ToiButton>
                        <ToiButton
                            variant='secondary'
                            onClick={() => handleRemove(document)}
                        >
                            Nei
                        </ToiButton>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

    const renderRow = () => (
        <TableRow>
            {options.allowMultiple && (
                <TableCell style={{textAlign: 'center'}}>
                    <StyledCheckbox
                        name='selected'
                        checked={document.selected}
                        onChange={(e, checked) =>
                            handleChange({
                                ...document,
                                selected: checked,
                            })
                        }
                    />
                </TableCell>
            )}
            <TableCell>
                <div style={{width: '100%'}}>
                    <StyledSelect
                        error={!!(errors && errors['dokumentTypeId'])}
                        name='dokumentTypeId'
                        value={document.dokumentTypeId || ''}
                        onChange={(e) => {
                            const value = parseInt(e.target.value as any);
                            handleChange({
                                ...document,
                                dokumentTypeId: value,
                                bestemmelser:
                                    helpers.shouldBestemmelserBeActive(value)
                                        ? document.bestemmelser
                                        : false,
                            });
                        }}
                        keyCol='id'
                        valueCol='beskrivelse'
                        nullOption={true}
                        options={sortBy(
                            meta.data.dokumenttyper.filter(
                                (dokumenttype) => dokumenttype.id != 22
                            ),
                            (dokumenttype) => dokumenttype.beskrivelse
                        )}
                    />
                </div>
                <Error error={errors && errors['dokumentTypeId']} />
            </TableCell>
            <TableCell>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        wordBreak: 'break-word',
                    }}
                >
                    <ToiBox mr={1}>
                        <FileIcon
                            extension={getFileExtension(document.dokumentNavn)}
                        />
                    </ToiBox>
                    <ToiTextField
                        value={formatDocname(document.dokumentNavn)}
                        error={!!dokumentNavnError}
                        onChange={(e) =>
                            handleChange({
                                ...document,
                                dokumentNavn: parseDocname(
                                    e.currentTarget.value
                                ),
                            })
                        }
                    />

                    <Typography style={{wordBreak: 'keep-all'}}>
                        {fileExtension}
                    </Typography>
                </div>
                {dokumentNavnError && <Error error={dokumentNavnError} />}
            </TableCell>
            {options.showDokumentTittel && (
                <TableCell>
                    <Typography>{document.dokumentTittel}</Typography>
                </TableCell>
            )}
            <TableCell>
                <ToiTextField
                    type='text'
                    value={document.beskrivelse}
                    onChange={(e) =>
                        handleChange({
                            ...document,
                            beskrivelse: e.currentTarget.value,
                        })
                    }
                />
            </TableCell>
            <TableCell>
                {options.editableDate ? (
                    <DatePicker
                        input={{
                            name: 'dato',
                            value: document.dokumentDato
                                ? document.dokumentDato.toISOString()
                                : '',
                            onChange: (date) => {
                                isValidDate(date) &&
                                    handleChange({
                                        ...document,
                                        dokumentDato: date,
                                    });
                            },
                        }}
                        onClear={() =>
                            handleChange({...document, dokumentDato: null})
                        }
                    />
                ) : (
                    formatDate(new Date(document.dokumentDato || ''))
                )}
            </TableCell>
            {helpers.shouldHoringsdokumentBeActive(behandling) && (
                <TableCell style={{textAlign: 'center'}}>
                    <StyledCheckbox
                        name='horingsdokument'
                        checked={document.horingsdokument}
                        onChange={(e, checked) =>
                            handleChange({
                                ...document,
                                horingsdokument: checked,
                            })
                        }
                        sx={(theme) => ({
                            ...(helpers.shouldHoringsdokumentBeActive(
                                behandling
                            ) && errorStyle(theme)),
                        })}
                    />
                </TableCell>
            )}
            <TableCell style={{textAlign: 'center'}}>
                <StyledCheckbox
                    name='bestemmelser'
                    checked={document.bestemmelser}
                    onChange={(e, checked) =>
                        handleChange({
                            ...document,
                            bestemmelser: checked,
                            plandokument: checked
                                ? false
                                : document.plandokument,
                        })
                    }
                    disabled={
                        !helpers.shouldBestemmelserBeActive(
                            document.dokumentTypeId
                        )
                    }
                    sx={(theme) => ({
                        ...(helpers.shouldBestemmelserBeActive(
                            document.dokumentTypeId
                        ) && errorStyle(theme)),
                    })}
                />
            </TableCell>
            {(behandling || dispensasjon) && (
                <TableCell style={{textAlign: 'center'}}>
                    <StyledCheckbox
                        name='plandokument'
                        checked={document.plandokument}
                        onChange={(e, checked) =>
                            handleChange({
                                ...document,
                                plandokument: checked,
                            })
                        }
                        disabled={document.bestemmelser}
                    />
                </TableCell>
            )}
            <TableCell>
                <StyledSelect
                    name='tilgangId'
                    value={document.tilgangId || ''}
                    onChange={(e) => {
                        const value = parseInt(e.target.value as any);
                        handleChange({
                            ...document,
                            tilgangId: value,
                        });
                    }}
                    options={helpers.getAvailableTilgangstyper(behandling)}
                    keyCol='id'
                    valueCol='navn'
                />
            </TableCell>
        </TableRow>
    );
    return (
        <>
            {document.exists && !document.overwrite
                ? renderOverwriteDialog()
                : renderRow()}
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    errors: state.fileImport.errors,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    addFile: (data: FileData) => dispatch(addFile(data)),
    updateFile: (data: FileData) => dispatch(updateFile(data)),
    removeFile: (data: FileData) => dispatch(removeFile(data)),
    resetFieldError: (data: FileData, field: string) =>
        dispatch(resetErrorForField(data, field)),
    resetError: (data: FileData) => dispatch(resetErrorForFile(data)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ImportDocumentListItem);
