import {intersection} from 'lodash-es';
import {useNkAuth} from '@norkart/nk-auth';
import useKunder from '../../hooks/kunder/useKunder';
import useSelectedKundeId from '../../hooks/kunder/useSelectedKundeId';

export default function useCan() {
    const selectedKundeId = useSelectedKundeId();
    const {data: kunder} = useKunder();
    const nkAuth = useNkAuth();

    //   // Debug:
    //   useEffect(() => {
    //     console.log("manage:", manage())
    //     console.log("admin:", admin())
    //     console.log("edit:", edit())
    //     console.log("editDisp:", editDisp())
    //     console.log("view:", view())
    //     console.log(auth)
    //   }, [authorization, selectedKundeId])

    const getSelectedCustomerAliases = () => {
        const aliases = [] as string[];
        const kunde = kunder.filter(
            (k) =>
                k?.id == selectedKundeId || k?.kommunenummer == selectedKundeId
        )[0];
        if (kunde) {
            if (kunde.id) aliases.push(kunde.id);
            if (kunde.kommunenummer) aliases.push(kunde.kommunenummer);
        } else {
            aliases.push(selectedKundeId);
        }
        return aliases;
    };

    function checkCustomerParameter(parameter: string): boolean {
        // Alle kunder tillatt
        const setting =
            (nkAuth.customerContextData?.currentCustomerContext &&
                nkAuth.customerContextData?.currentCustomerContext.getStringSetting(
                    parameter
                )) ||
            '';
        if (setting === '*') return true;

        const allowCustomers = setting.split(',');
        const aliases = getSelectedCustomerAliases();
        return intersection(allowCustomers, aliases).length > 0;
    }

    function manage() {
        return (
            (nkAuth.customerContextData?.currentCustomerContext &&
                nkAuth.customerContextData?.currentCustomerContext.getBooleanSetting(
                    'AllowManageAll'
                )) ||
            false
        );
    }

    function admin() {
        return manage() || checkCustomerParameter('AllowAdminCustomers');
    }

    function edit() {
        return admin() || checkCustomerParameter('AllowEditCustomers');
    }

    function editDisp() {
        return edit() || checkCustomerParameter('AllowEditDispCustomers');
    }

    function view() {
        return editDisp() || checkCustomerParameter('AllowViewCustomers');
    }

    const can = () => ({
        admin: admin(),
        edit: edit(),
        editDisp: editDisp(),
        view: view(),
    });

    return can();
}
