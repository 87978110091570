import {useMutation, useQueryClient} from 'react-query';
import {InnstillingDto} from '@norkart/nkapi-arealplaner-api';
import {updateInnstillingTyped} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useFrontendSettings from './useFrontendSettings';

export default function useUpdateFrontendSetting() {
    const {notify} = useErrorHandler();
    const queryClient = useQueryClient();
    const frontend = useFrontendSettings();

    return useMutation(
        ['update_innstilling'],
        async (data: InnstillingDto | undefined) =>
            updateInnstillingTyped(data).then((res) => res.data),
        {
            onError: notify,
            onSuccess: () => {
                frontend.refetch();
                queryClient.invalidateQueries(['update_innstilling']);
            },
        }
    );
}
