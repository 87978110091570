import {Chip, styled, Theme} from '@mui/material';
import {merge} from 'lodash-es';
import {ToiChipProps} from './ToiChip';

const getColorDefault = ({color, theme, selected, size}: Partial<ToiChipProps> & {theme: Theme}) => {
  const signal = theme.vars.toi.palette.signal;

  type ColorMap = Record<NonNullable<ToiChipProps['color']>, string>;
  const backgroundColorMap: ColorMap = {
    default: theme.vars.toi.palette.backgrounds.component.fill,
    grey: signal.background.normal,
    info: signal.background.info,
    warning: signal.background.warning,
    success: signal.background.success,
    error: signal.background.error,
  };

  const foregroundColorMap: ColorMap = {
    default: theme.vars.toi.palette.text.default,
    grey: theme.vars.toi.palette.text.default,
    info: signal.foreground.info,
    warning: signal.foreground.warning,
    success: signal.foreground.success,
    error: signal.foreground.error,
  };

  const hoverColorMap: ColorMap = {
    default: theme.vars.toi.palette.backgrounds.component.fill,
    grey: signal.background.normalHover,
    info: signal.background.infoHover,
    warning: signal.background.warningHover,
    success: signal.background.successHover,
    error: signal.background.errorHover,
  };

  const defaultStyles = {
    backgroundColor: backgroundColorMap[color ?? 'default'],

    '&:hover, &.Mui-focusVisible': {
      backgroundColor: hoverColorMap[color ?? 'default'],
    },

    '& .MuiBadge-badge': {
      backgroundColor: foregroundColorMap[color ?? 'default'],
    },

    '& button.ToiChipCloseButton-root': {
      width: size === 'small' ? '14px' : '18px',
      height: size === 'small' ? '14px' : '18px',
      borderRadius: size === 'small' ? '12px' : '15px',

      backgroundColor: backgroundColorMap[color ?? 'default'],

      '&.Mui-focusVisible, &:hover': {
        backgroundColor: hoverColorMap[color ?? 'grey'],
      },

      '&:hover, &.Mui-focusVisible': {
        outlineOffset: 0,
        outline: `1px dashed ${theme.vars.toi.palette.focus.border}`,
      },
    },
  };

  const whiteDefaultStyles = {
    boxShadow: `inset 0 0 0 1px ${theme.vars.toi.palette.border.input}`,
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `inset 0 0 0 2px ${theme.vars.toi.palette.border.input}`,
    },
  };

  const selectedStyles = {
    backgroundColor: theme.vars.toi.palette.links.default,

    '& .MuiChip-label': {
      color: theme.vars.toi.palette.text.inverted,
    },

    '&.MuiChip-clickable:hover, &.Mui-focusVisible': {
      backgroundColor: theme.vars.toi.palette.links.hover,
    },

    '& .MuiBadge-badge': {
      border: 'none !important',
      backgroundColor: theme.vars.toi.palette.backgrounds.component.fill,
    },

    '& button.ToiChipCloseButton-root': {
      backgroundColor: theme.vars.toi.palette.links.default,

      '& path': {
        stroke: theme.vars.toi.palette.focus.inverted,
      },

      '&:hover, &.Mui-focusVisible': {
        backgroundColor: theme.vars.toi.palette.links.hover,
        outlineColor: theme.vars.toi.palette.focus.inverted,
      },
    },
  };

  return merge(
    defaultStyles,
    (!color || color === 'default') && !selected && whiteDefaultStyles,
    selected && selectedStyles,
  );
};

export const StyledChip: React.FC<ToiChipProps> = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'selected' && prop !== 'clickable',
})<ToiChipProps>(({theme, color, selected, size, clickable}) => {
  const defaultStyle = {
    paddingInline: `calc(${theme.toi.spacing.xs} + 2px)`,
    height: size === 'small' ? '22px' : '30px',
    cursor: clickable ? 'pointer' : 'auto',

    '&.MuiButtonBase': {
      disableRipple: true,
    },

    '.MuiChip-label': {
      paddingInline: theme.toi.spacing.xs,
      ...(size === 'small' ? theme.typography.body3 : theme.typography.body2),
    },

    '&.Mui-focusVisible:has(.ToiChipCloseButton-root:not(.Mui-focusVisible)), &.Mui-focusVisible:not(:has(.ToiChipCloseButton-root))':
      theme.mixins.focus,

    '&:focus-visible': theme.mixins.focus,
  };

  const colorStyles = getColorDefault({color, theme, selected, size});

  return merge(defaultStyle, colorStyles);
});
