import {Drawer, styled} from '@mui/material';
import {ToiDrawerProps} from './ToiDrawer';

import {closedMixin, openedMixin} from './mixins';

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => !['collapsible', 'fullScreen'].includes(prop.toString()),
})<ToiDrawerProps>(({theme, open, variant, fullScreen}) => {
  const spacing = theme.toi.spacing;
  const palette = theme.vars.toi.palette;

  return {
    position: 'relative',
    zIndex: theme.zIndex.appBar - 1,

    '&.ToiDrawer-permanent.ToiDrawer-collapsible': {
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),

      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),

      '& .MuiAccordion-root': {
        borderInline: 'none',
      },

      ...(!open && {
        '& .MuiAccordionSummary-content ': {
          paddingLeft: `20px`,
          gap: '20px',
        },
      }),
    },

    '& > .MuiPaper-root.ToiDrawer-permanent': {
      boxShadow: 'unset',
    },

    '& > .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: variant === 'permanent' ? undefined : theme.shadows[4],

      ...(fullScreen && {
        height: '100vh',
        width: '100vw',
      }),
    },

    '& .MuiAccordion-root': {
      borderInline: 'none !important',
      backgroundColor: 'inherit',

      '& .MuiAccordionSummary-content': {
        paddingLeft: spacing.s,

        '& .MuiTypography-root': theme.typography.body2,
      },
    },

    '&.ToiDrawer-persistent .MuiPaper-root': {
      overflowY: 'visible',
    },
  };
});
