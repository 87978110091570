import {useState} from 'react';
import {
    ToiBox,
    ToiIconButton,
    ToiMenu,
    ToiMenuItem,
    ToiTooltip,
} from '@norkart/toi-components';
import {ContextMenuHorizontalIcon} from '@norkart/toi-icons';
import {DispensasjonDto} from '@norkart/nkapi-arealplaner-api';
import useMeta from '../../../hooks/meta/useMeta';
import {useDispensasjonActions} from './useDispensasjonActions';

type Props = {
    dispensasjon: DispensasjonDto;
    onOpen: (dispensasjon: DispensasjonDto) => void;
};

export const DispensasjonTableRowMenuButton = ({
    dispensasjon,
    onOpen,
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const actions = useDispensasjonActions();
    const meta = useMeta();

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
        onOpen(dispensasjon);
        e.stopPropagation();
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleEdit = (e) => {
        handleClose(e);
        actions.edit(dispensasjon);
    };

    const handleDocumentFromCatalog = (e) => {
        handleClose(e);
        document.getElementById(`file-upload`)!.click();
    };

    const handleDocumentFromArchive = (e) => {
        handleClose(e);
        actions.importFilesFromArchive(dispensasjon);
    };

    const handleRearrangeDocuments = (e) => {
        handleClose(e);
        actions.rearrangeDocuments(dispensasjon);
    };

    const shouldDisplayDocumentFromArchive =
        Boolean(dispensasjon.sak) && meta.hasArkivIntegrasjon();

    const shouldDisplayRearrangeDocuments =
        (dispensasjon.dokumenter?.length ?? 0) > 1;

    return (
        <ToiBox display='flex' justifyContent='flex-end'>
            <ToiTooltip title={'Handlinger'}>
                <ToiIconButton
                    aria-label='Handlinger'
                    color='transparent'
                    onClick={(e) => {
                        handleMenu(e);
                    }}
                >
                    <ContextMenuHorizontalIcon />
                </ToiIconButton>
            </ToiTooltip>

            <ToiMenu
                id={`${dispensasjon.id}-dispensasjon-menu`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock={true}
            >
                <ToiMenuItem
                    onClick={handleEdit}
                    aria-label='Endre dispensasjon'
                >
                    Endre dispensasjon
                </ToiMenuItem>
                <ToiMenuItem
                    onClick={handleDocumentFromCatalog}
                    aria-label='Last opp dokument'
                >
                    Last opp dokument
                </ToiMenuItem>
                {shouldDisplayDocumentFromArchive && (
                    <ToiMenuItem
                        onClick={handleDocumentFromArchive}
                        aria-label='Last opp dokument fra arkiv'
                    >
                        Velg dokument fra arkiv
                    </ToiMenuItem>
                )}
                {shouldDisplayRearrangeDocuments && (
                    <ToiMenuItem
                        onClick={handleRearrangeDocuments}
                        aria-label='Endre dokumentrekkefølge'
                    >
                        Endre dokumentrekkefølge
                    </ToiMenuItem>
                )}
            </ToiMenu>
        </ToiBox>
    );
};
