import {
    ToiAccordion,
    ToiBox,
    ToiButton,
    ToiLink,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import FileIcon from '../../../../components/FileIcon';
import HelpButton from '../../../../components/HelpButton/HelpButton';
import {helpObject} from '../../../../help/helpObject';
import useArealplanDokumenter from '../../../../hooks/dokumenter/useArealplanDokumenter';
import useDokuments from '../../../../hooks/dokumenter/useDokuments';
import {getFileExtension} from '../../../../utils/getFileExtension';
import InfoIconWrapper from './InfoIconWrapper';

const GjeldendeBestemmelser = () => {
    const dokumenter = useArealplanDokumenter();
    const {openDokument} = useDokuments();

    if (!dokumenter.gjeldendeBestemmelser.length) return null;

    function GjeldendeBestemmelserLink() {
        const gjeldendeBestemmelserDokument =
            dokumenter.gjeldendeBestemmelser[0];
        return (
            <ToiButton
                component={'a'}
                variant='secondary'
                onClick={() => openDokument(gjeldendeBestemmelserDokument.id)}
                sx={{
                    flexGrow: 1,
                    width: 'calc(100% - 60px)',
                    height: '60px',
                    justifyContent: 'flex-start',
                }}
            >
                Se gjeldende bestemmelser
            </ToiButton>
        );
    }

    function GjeldendeBestemmelserAccordian() {
        return (
            <ToiAccordion
                status='success'
                summaryContent={
                    <ToiTypography>Gjeldende bestemmelser</ToiTypography>
                }
                sx={(theme) => ({
                    flexGrow: 1,
                    width: 'calc(100% - 60px)',
                    border: `1px solid ${theme.vars.toi.palette.border.component} !important`,
                })}
            >
                <ToiBox width='fit-content' maxWidth={'100%'} pr={1} pl={1}>
                    <ToiStack gap={2}>
                        {dokumenter.gjeldendeBestemmelser.map((d) => (
                            <ToiLink
                                sx={(theme) => ({
                                    '& span': {
                                        wordBreak: 'break-all',
                                        whiteSpace: 'normal',
                                    },
                                    '& svg': {
                                        color: theme.toi.palette.text.default,
                                    },
                                })}
                                key={d.id}
                                onClick={() => openDokument(d.id)}
                                startIcon={
                                    <FileIcon
                                        extension={getFileExtension(
                                            d.dokumentnavn ?? ''
                                        )}
                                    />
                                }
                            >
                                {d.dokumentnavn}
                            </ToiLink>
                        ))}
                    </ToiStack>
                </ToiBox>
            </ToiAccordion>
        );
    }

    return (
        <ToiStack direction='row' justifyContent={'space-between'}>
            {dokumenter.gjeldendeBestemmelser.length === 1 ? (
                <GjeldendeBestemmelserLink />
            ) : (
                <GjeldendeBestemmelserAccordian />
            )}
            <InfoIconWrapper>
                <HelpButton helpText={helpObject.GjeldendeBestemmelser} />
            </InfoIconWrapper>
        </ToiStack>
    );
};
export default GjeldendeBestemmelser;
