import {useEffect, useState} from 'react';
import {
    ToiBox,
    ToiButton,
    ToiDialog,
    ToiDialogActions,
    ToiDialogContent,
    ToiDialogTitle,
} from '@norkart/toi-components';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import {useKontaktpersoner} from '../../../hooks/kontaktpersoner/useKontaktpersoner';
import useMeta from '../../../hooks/meta/useMeta';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onOk: (values) => void;
};

const EditOrganisasjonspersonDialog = ({isOpen, onClose, onOk}: Props) => {
    const {data: kontaktpersoner} = useKontaktpersoner();
    const {data: metaData} = useMeta();
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedNameValue, setSelectedNameValue] = useState<string | number>(
        ''
    );
    const [selectedRolleValue, setSelectedRolleValue] = useState<
        string | number
    >('');

    useEffect(() => {
        setErrorMessage('');
        setSelectedNameValue('');
        setSelectedRolleValue('');
    }, [isOpen]);

    const handleSubmit = () => {
        if (selectedNameValue !== 0 && selectedRolleValue !== 0) {
            onOk([
                {
                    kontaktperson: selectedNameValue,
                    rolletype: selectedRolleValue,
                },
            ]);
        } else setErrorMessage('Må fylle ut navn og rolle');
    };
    const handleNameChange = (e) => {
        setSelectedNameValue(e.target.value);
    };
    const handleRolleChange = (e) => {
        setSelectedRolleValue(e.target.value);
    };

    return (
        <ToiDialog open={isOpen} onClose={onClose} maxWidth='sm'>
            <ToiDialogTitle id='edit-organisasjonperson-title'>
                Legg til kontaktperson
            </ToiDialogTitle>

            <ToiDialogContent>
                <StyledSelect
                    label='Kontaktperson'
                    sx={{
                        minWidth: '80%',
                    }}
                    value={selectedNameValue}
                    onChange={handleNameChange}
                    options={kontaktpersoner}
                    valueCol='navn'
                    keyCol='id'
                />

                <StyledSelect
                    label='Rolle'
                    sx={{
                        minWidth: '80%',
                    }}
                    value={selectedRolleValue}
                    onChange={handleRolleChange}
                    options={metaData.rolletyper}
                    valueCol='beskrivelse'
                    keyCol='id'
                />
                <ToiBox sx={{color: 'red', marginTop: 2}}>
                    {errorMessage}
                </ToiBox>
            </ToiDialogContent>
            <ToiDialogActions>
                <div style={{display: 'flex', gap: '10px'}}>
                    <ToiButton onClick={onClose} variant='secondary'>
                        Avbryt
                    </ToiButton>
                    <ToiButton onClick={handleSubmit}>Legg til</ToiButton>
                </div>
            </ToiDialogActions>
        </ToiDialog>
    );
};

export default EditOrganisasjonspersonDialog;
