import {Field} from 'react-final-form';
import {ToiStack, ToiTag, ToiTypography} from '@norkart/toi-components';
import DatePicker from '../../../../components/DatePicker';
import Error from '../../../../components/FormError/Error';
import {parseDate} from '../../../../utils/parseDate';
import {isIKraftEnabled, isIKraftRequired} from '../helpers';
import {ArealplanFormValues} from '../PlanForm';
import Warning from './Warning';

export default function Ikraft({values}: {values: ArealplanFormValues}) {
    const disabled = values.planStatusId
        ? !isIKraftEnabled(values.planStatusId)
        : true;
    const optional = !isIKraftRequired(values.planStatusId);

    return (
        <Field
            name='iKraft'
            parse={parseDate}
            render={({input: {name, value, onChange}, meta}) => (
                <div>
                    <ToiStack
                        direction={'row'}
                        justifyContent={'space-between'}
                        pb={0.5}
                    >
                        <ToiTypography
                            variant='label'
                            component={'label'}
                            htmlFor={name}
                        >
                            Ikrafttredelsesdato
                        </ToiTypography>
                        {!disabled && optional && <ToiTag value={'Valgfri'} />}
                    </ToiStack>
                    <DatePicker
                        input={{
                            name,
                            value,
                            onChange,
                        }}
                        disabled={disabled}
                    />
                    {(meta.error || meta.submitError) && (
                        <Error error={meta.error || meta.submitError} />
                    )}
                    {values.iKraft && !isIKraftEnabled(values.planStatusId) && (
                        <Warning message='Ikrafttredelsesdato vil bli slettet' />
                    )}
                </div>
            )}
        />
    );
}
