import React from 'react';
import {ToiBox, ToiLink, ToiTypography} from '@norkart/toi-components';
import {ExternalIcon} from '@norkart/toi-icons';
import {useMittInnspillProjects} from '../../../hooks/mittinnspill/useMittInnspillProjects';
import arealplanerConfig from '../../../config';

const BASE_URL = arealplanerConfig.mittinnspill.url;

function MittInnspillLink() {
    const mittInnspillProjects = useMittInnspillProjects();
    if (mittInnspillProjects.isLoading) return null;
    if (mittInnspillProjects?.data.totalCount === 0) return null;

    if (!mittInnspillProjects.data.elements.length) return null;
    const project = mittInnspillProjects?.data?.elements[0];

    return (
        <ToiBox>
            <ToiTypography mb={2}>
                Kommunen har lagt ut planen på MittInnspill.
                <br /> Gå inn på MittInnspill for å gi innspill og se planen i
                en mer visuell form.
            </ToiTypography>
            <ToiLink
                href={`${BASE_URL}/prosjekter/${project.id}`}
                target='_blank'
                mb={2}
                endIcon={<ExternalIcon />}
            >
                Gå til MittInnspill
            </ToiLink>
        </ToiBox>
    );
}

export default MittInnspillLink;
