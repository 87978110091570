import {Menu} from '@base-ui-components/react/menu';
import {styled} from '@mui/material/styles';
import {ToiMenuSize} from '../types';
import {toiMenuItemStyle} from './utils';

export type ToiMenuItemProps = Menu.Item.Props & {size?: ToiMenuSize; disabled?: boolean};
export const ToiMenuItem = styled(Menu.Item)<ToiMenuItemProps>(({theme, size, disabled}) => {
  return {
    ...toiMenuItemStyle(theme, size, disabled),
  };
});
