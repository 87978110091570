import * as React from 'react';
import {
    ToiCircularProgressProps,
    ToiCircularProgress,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';

export interface LoadingSpinnerProps {
    text?: string;
    circularProgressProps?: ToiCircularProgressProps;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
    text,
    circularProgressProps,
}: LoadingSpinnerProps) => {
    return (
        <ToiStack gap={2} alignItems={'center'} justifyContent={'center'}>
            <ToiCircularProgress {...circularProgressProps} />
            {text ? (
                <ToiTypography>{text}</ToiTypography>
            ) : (
                <ToiTypography>Henter informasjon...</ToiTypography>
            )}
        </ToiStack>
    );
};

export default LoadingSpinner;
