import {IconButton, Link} from '@mui/material';
import {ColumnDef} from '@tanstack/react-table';
import * as React from 'react';
import {format} from 'date-fns';
import saveAs from 'file-saver';
import {KostraRapportDto} from '@norkart/nkapi-arealplaner-api';
import {DownloadIcon} from '@norkart/toi-icons';
import useSelectedKundeId from '../../../../hooks/kunder/useSelectedKundeId';

export default function KostraColumns() {
    const kundeId = useSelectedKundeId();

    const downloadReport = async (url: string, filename: string) => {
        if (url && filename) {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                saveAs(blob, filename);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        }
    };

    return React.useMemo<ColumnDef<KostraRapportDto>[]>(
        () => [
            {
                id: 'kostraRapportId',
                accessorKey: 'id',
                header: () => 'Id',
            },
            {
                id: 'rapport',
                accessorKey: 'path',
                header: () => 'Rapport',
                cell: (r) => (
                    <Link
                        key={r.row.id + 'link'}
                        href={r.row.original.path ?? ''}
                        target='_blank'
                        underline='none'
                    >
                        {r.row.original?.navn ? r.row.original?.navn : null}
                    </Link>
                ),
            },
            {
                id: 'datoOpprettet',
                accessorKey: 'datoOpprettet',
                header: () => 'Dato opprettet',
                cell: (r) => (
                    <>
                        {r.row.original?.datoOpprettet
                            ? format(
                                  new Date(r.row.original.datoOpprettet),
                                  'dd.MM.yyyy'
                              )
                            : ''}
                    </>
                ),
            },
            {
                id: 'lastNed',
                header: () => '',
                cell: (r) => {
                    return (
                        <IconButton
                            onClick={() =>
                                downloadReport(
                                    r.row.original.path ?? '',
                                    r.row.original.navn ?? ''
                                )
                            }
                        >
                            <DownloadIcon size={'small'} />
                        </IconButton>
                    );
                },
            },
        ],
        [kundeId]
    );
}
