import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const HelpQuestionIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M9 9.5C9 8.5 9.5 7 12 7C14.5 7 15 8.5 15 9.5C15 12 12 12 12 14.5M12 17.5C12 17.5 12 17.5 12 18'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='12' cy='12' r='11' stroke='var(--toi-palette-icons-secondary, #2E2D30)' strokeWidth='1.5' />
  </ToiIcon>
));

export default HelpQuestionIcon;
