import React, {useEffect} from 'react';
import {
    ToiButton,
    ToiDialogSimple,
    ToiTextField,
} from '@norkart/toi-components';
import {InnstillingDto} from '@norkart/nkapi-arealplaner-api';
import useUpdateFrontendSetting from '../../../hooks/settings/useUpdateFrontendSetting';

type Props = {
    dialogOpen: boolean;
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    innstilling: InnstillingDto | undefined;
};

function FrontendSettingDialog({
    dialogOpen,
    setDialogOpen,
    innstilling,
}: Props) {
    const [setting, setSetting] = React.useState<InnstillingDto | undefined>();
    const {mutateAsync, isLoading} = useUpdateFrontendSetting();

    useEffect(() => {
        if (innstilling) setSetting(innstilling);
    }, [innstilling]);

    const handleSave = async () => {
        await mutateAsync(setting);
        setDialogOpen(false);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    if (!setting) return null;
    return (
        <ToiDialogSimple
            onClose={handleClose}
            open={dialogOpen}
            title={`Oppdater ${setting?.beskrivelse?.toLocaleLowerCase()}`}
            fullWidth={true}
            onConfirm={handleSave}
            actions={
                <>
                    <ToiButton
                        onClick={handleClose}
                        disabled={isLoading}
                        variant='secondary'
                    >
                        Avbryt
                    </ToiButton>
                    <ToiButton onClick={handleSave} disabled={isLoading}>
                        Oppdater
                    </ToiButton>
                </>
            }
        >
            <form>
                <ToiTextField
                    name='verdi'
                    value={setting ? setting.verdi : ''}
                    autoComplete='false'
                    onChange={(e) => {
                        setting &&
                            setSetting({
                                ...setting,
                                verdi: e.target.value,
                            });
                    }}
                />
            </form>
        </ToiDialogSimple>
    );
}

export default FrontendSettingDialog;
