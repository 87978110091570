import React from 'react';
import {orderBy} from 'lodash-es';
import {DispensasjonDto} from '@norkart/nkapi-arealplaner-api';
import {DokumentDto} from '@norkart/nkapi-arealplaner-api';
import {ToiDataDisplay, ToiStack, ToiTypography} from '@norkart/toi-components';
import DocumentTable from '../../../components/DocumentTable';
import JournalpostItem from '../../sak/Journalpost/JournalpostItem';
import DetailPanelContent from '../../../components/DetailPanelContent';

type Props = {
    dispensasjon: DispensasjonDto;
};
const DispensasjonListDetailPanel = ({dispensasjon}: Props) => {
    const {dispFra, journalpostnummer} = dispensasjon;
    const header = {fontWeight: 500};

    const hasDetailFields =
        dispensasjon.dispFra ||
        dispensasjon.dokumenter?.length ||
        dispensasjon.journalpostnummer;

    if (!hasDetailFields)
        return (
            <DetailPanelContent>
                <ToiTypography>Ingen detaljer</ToiTypography>
            </DetailPanelContent>
        );

    return (
        <DetailPanelContent>
            <ToiStack direction={'row'} spacing={2} sx={{width: 'fit-content'}}>
                {dispFra && (
                    <ToiDataDisplay
                        label='Formål/hensynsone det dispenseres fra'
                        value={dispFra}
                    />
                )}
                {journalpostnummer && (
                    <ToiDataDisplay
                        label='Journalpostnummer'
                        value={
                            <JournalpostItem
                                journalpostData={
                                    {
                                        journalpostnummer:
                                            dispensasjon.journalpostnummer,
                                        journalpostUrl:
                                            dispensasjon.journalpostUrl,
                                        journalpostUrlIntern:
                                            dispensasjon.journalpostUrlIntern,
                                    } as Partial<DispensasjonDto>
                                }
                            />
                        }
                    />
                )}
            </ToiStack>

            {dispensasjon.dokumenter && dispensasjon.dokumenter.length != 0 && (
                <ToiStack>
                    <ToiTypography variant='h3'>Dokumenter</ToiTypography>
                    <DocumentTable
                        dokumenter={
                            orderBy(
                                dispensasjon.dokumenter,
                                ['rekkefolgeDispensasjon', 'dokumenttype'],
                                ['asc', 'asc']
                            ) as DokumentDto[]
                        }
                        dispensasjon={dispensasjon}
                    />
                </ToiStack>
            )}
        </DetailPanelContent>
    );
};
export default DispensasjonListDetailPanel;
