import * as React from 'react';
import {Field, FieldProps} from 'react-final-form';
import FormError from '../FormError';
import './FormInputField.css';
import {
    ToiStack,
    ToiTextField,
    ToiTextFieldProps,
} from '@norkart/toi-components';

export type FormInputFieldProps = {
    name: string;
    fieldProps?: Partial<FieldProps2>;
    submitted?: boolean | undefined;
} & ToiTextFieldProps;
type FieldProps2 = FieldProps<any, any, HTMLElement>;

const FormInputField: React.FC<FormInputFieldProps> = ({
    name,
    fieldProps = {},
    submitted = false,
    ...rest
}: FormInputFieldProps) => (
    <Field
        name={name}
        subscription={{value: true, touched: true, error: true, initial: true}}
        {...fieldProps}
        render={({input, meta}) => (
            <ToiStack width={'100%'}>
                <ToiTextField
                    error={meta.touched && meta.error}
                    {...rest}
                    {...input}
                />
                <FormError name={name} />
            </ToiStack>
        )}
    />
);
export default FormInputField;
