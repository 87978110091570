import {Slider} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ToiSliderProps} from './ToiSlider';

export const StyledSlider = styled(Slider)<ToiSliderProps>(({theme, size}) => {
  return {
    color: theme.vars.toi.palette.bars.active,
    height: size === 'small' ? theme.toi.spacing['2xs'] : 6,
    '& .Mui-focusVisible': {
      outline: '1px dashed',
      outlineColor: theme.toi.palette.focus.border,
      outlineOffset: '2px',
    },

    '& .MuiSlider-markLabel': {
      marginTop: theme.toi.spacing['s'],
    },

    '& .MuiSlider-rail': {
      backgroundColor: theme.vars.toi.palette.bars.fill,
      border: 'none',
    },
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: size === 'small' ? theme.toi.spacing['s'] : theme.toi.spacing.m,
      width: size === 'small' ? theme.toi.spacing['s'] : theme.toi.spacing.m,
      backgroundColor: theme.vars.toi.palette.bars.handle,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      color: theme.vars.toi.palette.text.default,
      height: theme.toi.spacing.l,
      boxShadow: theme.toi.shadow[2],
      borderRadius: '4px',
      backgroundColor: theme.vars.toi.palette.backgrounds.component.overlay,
    },
  };
});
