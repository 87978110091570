import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {mapKeys} from 'lodash-es';
import {
    ArkivVerdiDto,
    BehandlingstypeDto,
    DispensasjonstypeVerdiDto,
    DokumenttypeVerdiDto,
    EndringstypeVerdiDto,
    FormatVerdiDto,
    ForslagsstillertypeVerdiDto,
    KartlagInnstillingDto,
    KommuneVerdiDto,
    LovreferanseVerdiDto,
    metaGet,
    MyndighetVerdiDto,
    PlanbestemmelseVerdiDto,
    PlanforholdtypeVerdiDto,
    PlanstatusVerdiDto,
    PlantypeVerdiDto,
    RolletypeVerdiDto,
    TilgangVerdiDto,
    VedtakVerdiDto,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {Vertikalniva} from '../../store/shared/types';
import {regelsettConst} from '../../features/map/helpers/constants';
import useErrorHandler from '../errors/useErrorHandler';
import {reset as resetKunde} from '../../store/kunder/actions';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import {LagKategorier, Meta} from './types';

function parseKartlagInnstillinger(kartlaginnstillinger) {
    return kartlaginnstillinger?.map((innstilling) => {
        return {
            ...innstilling,
            kartlagRegelsett: innstilling.kartlagRegelsett.map(
                (rs) => rs.kartlagRegelsettId
            ),
        };
    });
}

const placeholder = {
    innstillinger: {},
    moduler: {},
    featuretoggles: {},
    formater: [] as FormatVerdiDto[],
    forslagsstillertyper: [] as ForslagsstillertypeVerdiDto[],
    planstatuser: [] as PlanstatusVerdiDto[],
    plantyper: [] as PlantypeVerdiDto[],
    planbestemmelser: [] as PlanbestemmelseVerdiDto[],
    vertikalniva: [] as Vertikalniva[],
    kommuner: [] as KommuneVerdiDto[],
    lovreferanser: [] as LovreferanseVerdiDto[],
    myndigheter: [] as MyndighetVerdiDto[],
    behandlingstyper: [] as BehandlingstypeDto[],
    dispensasjonstyper: [] as DispensasjonstypeVerdiDto[],
    vedtakstyper: [] as VedtakVerdiDto[],
    endringstyper: [] as EndringstypeVerdiDto[],
    dokumenttyper: [] as DokumenttypeVerdiDto[],
    tilgangstyper: [] as TilgangVerdiDto[],
    arkiver: [] as ArkivVerdiDto[],
    planforholdtyper: [] as PlanforholdtypeVerdiDto[],
    kartlaginnstillinger: [] as KartlagInnstillingDto[],
    rolletyper: [] as RolletypeVerdiDto[],
} as Meta;

export default function useMeta() {
    const navigate = useNavigate();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const dispatch = useDispatch();
    const {client} = useArealplanerApiClientContext();
    const getMeta = async () => {
        const {data, error} = await metaGet({path: {kundeId}, client});
        if (error) throw new Error('Fant ikke metadata');
        if (!data) return {} as Meta;

        let key: string;
        const keys = Object.keys(data);
        let n = keys.length;
        const res = {} as Partial<Meta>;
        while (n--) {
            key = keys[n];
            res[key.toLowerCase()] = data[key];
        }
        const {
            innstillinger,
            moduler,
            featuretoggles,
            kartlaginnstillinger,
            ...listData
        } = res;
        return {
            innstillinger: mapKeys(innstillinger, 'id'),
            moduler: mapKeys(moduler, 'id'),
            featuretoggles: mapKeys(featuretoggles, 'id'),
            kartlaginnstillinger:
                parseKartlagInnstillinger(kartlaginnstillinger),
            ...listData,
        } as Meta;
    };

    const query = useQuery<Meta>(['meta', {kundeId}], getMeta, {
        onError: (e) => {
            navigate('/');
            dispatch(resetKunde());
            notify(e);
        },
        enabled: !!kundeId,
    });

    const getInnstilling = (id?: string | null) => {
        if (!id) return;
        return query.data?.innstillinger[id].verdi;
    };
    const komnr = getInnstilling('komnr') || '';
    const referansesystemKode = getInnstilling('referansesystem_kode');
    const aktivtArkiv = parseInt(getInnstilling('aktivt_arkiv_id') || '1');
    const visplanUrl = getInnstilling('visplan_url');
    const visplanTekst = getInnstilling('visplan_tekst');
    const visplanInternUrl = getInnstilling('visplan_intern_url');
    const visplanInternTekst = getInnstilling('visplan_intern_tekst');
    const relevanteLinker = getInnstilling('relevante_linker');
    const sporsmaalUrl = getInnstilling('sporsmaal_url');
    const sporsmaalInnhold = getInnstilling('sporsmaal_innhold');
    const brukerveiledningUrl = getInnstilling('brukerveiledning_url');
    const tilbakemeldingEgetSkjemaUrl =
        getInnstilling('tilbakemelding_eget_skjema_url') || '';
    const konsistenssjekkScheduler =
        getInnstilling('konsistenssjekk_scheduler_cron') || '';
    const tilgjengelighetUrl = getInnstilling('tilgjengelighet_url') || '';

    const hasModul = (id: string) => {
        return Boolean(query.data?.moduler[id].verdi);
    };

    const hasArkivIntegrasjon = () => {
        return hasModul('arkivintegrasjon');
    };

    const hasPlandialog = () => {
        return hasModul('plandialog');
    };

    const hasApiTilgang = () => {
        return hasModul('api_tilgang');
    };

    const hasPlanprat = () => {
        return hasModul('planprat');
    };

    const hasMittInnspill = () => {
        return hasModul('mittinnspill');
    };

    const hasTilbakemelding = () =>
        hasModul('tilbakemelding') &&
        Boolean(getInnstilling('tilbakemelding_epost'));

    const hasTilbakemeldingEgetSkjema = () =>
        hasModul('tilbakemelding_eget_skjema') &&
        Boolean(getInnstilling('tilbakemelding_eget_skjema_url'));

    const hasKostra = () => {
        return hasModul('kostra');
    };

    const hasFeature = (id: string) => {
        return Boolean(query.data?.featuretoggles[id].verdi);
    };

    const hasTilbakemeldingBegrensetHoringsperiode = () =>
        hasFeature('tilbakemelding_begrenset_horingsperiode');

    const hasKontaktPerson = () => hasFeature('vis_kontaktperson');

    const getPlanstatus = (PlanStatusId: number | string): string => {
        for (const planstatus of meta.data.planstatuser) {
            if (planstatus.id === Number(PlanStatusId))
                return planstatus.beskrivelse ?? '';
        }
        return '';
    };

    const getRolletypeBeskrivelse = (id: number): string | null | undefined => {
        for (const rolle of meta.data.rolletyper) {
            if (rolle.id === id) {
                return rolle.beskrivelse;
            }
        }
        return '';
    };

    const getPlanforholdstypeForloper = (id?: number): string => {
        for (const planforholdstype of meta.data.planforholdtyper) {
            if (planforholdstype.id === Number(id))
                return planforholdstype.beskrivelseForloper ?? '';
        }
        return '';
    };

    const getPlanforholdstypeEtterkommer = (id?: number): string => {
        for (const planforholdstype of meta.data.planforholdtyper) {
            if (planforholdstype.id === Number(id))
                return planforholdstype.beskrivelseEtterkommer ?? '';
        }
        return '';
    };

    const lagKategorier: LagKategorier = {
        vertikalMapping: {
            1: regelsettConst.RegUnderGrunnen, // Under grunnen (tunnel),
            2: regelsettConst.RegPaaGrunnen, //"På grunnen/vannoverflaten"
            3: regelsettConst.RegOverGrunnen, //"Over grunnen (bru)"
            4: regelsettConst.RegPaaBunnen, //På bunnen (vann/sjø),
            5: regelsettConst.RegIVannsoylen, //I vannsøylen
        },
    };

    const meta = {
        ...query,
        data: query?.data || placeholder,
        getInnstilling,
        komnr,
        kundeId,
        referansesystemKode,
        aktivtArkiv,
        hasModul,
        hasKontaktPerson,
        hasArkivIntegrasjon,
        hasPlandialog,
        hasTilbakemelding,
        hasApiTilgang,
        hasPlanprat,
        hasMittInnspill,
        hasTilbakemeldingEgetSkjema,
        hasTilbakemeldingBegrensetHoringsperiode,
        hasKostra,
        hasFeature,
        konsistenssjekkScheduler,
        visplanUrl,
        visplanTekst,
        visplanInternUrl,
        visplanInternTekst,
        relevanteLinker,
        sporsmaalUrl,
        sporsmaalInnhold,
        brukerveiledningUrl,
        tilbakemeldingEgetSkjemaUrl,
        getPlanstatus,
        getPlanforholdstypeForloper,
        getPlanforholdstypeEtterkommer,
        getRolletypeBeskrivelse,
        tilgjengelighetUrl,
        lagKategorier,
    };
    return meta;
}
