import {styled, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {ToiBox, ToiTypography} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {formatDate} from '../../../utils/formatDate';
import {formatNasjonalArealplanId} from '../../../utils/formatNasjonalArealplanId';
import PlanStatusChip from '../../plan/PlanStatusChip';

const StyledTableCell = styled(TableCell)({
    border: 0,
});

type Props = {plan: ArealplanDto};

export default function PlanSummary({plan}: Props) {
    return (
        <ToiBox>
            <ToiTypography variant='subtitle1'>{plan.planNavn}</ToiTypography>
            <PlanStatusChip
                planStatusId={plan.planStatusId}
                planStatus={plan.planStatus}
            />
            <Table
                sx={{
                    width: '100%',
                    tableLayout: 'fixed',
                    head: {
                        whiteSpace: 'nowrap',
                    },
                }}
                padding='none'
            >
                <TableBody>
                    <TableRow>
                        <StyledTableCell component='th' scope='row'>
                            Nasjonal arealplanid:
                        </StyledTableCell>
                        <StyledTableCell>
                            {formatNasjonalArealplanId(plan)}
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell component='th' scope='row'>
                            Plantype:
                        </StyledTableCell>
                        <StyledTableCell>{plan.planType}</StyledTableCell>
                    </TableRow>
                    {plan.iKraft && (
                        <TableRow>
                            <StyledTableCell component='th' scope='row'>
                                Ikrafttredelsesdato:
                            </StyledTableCell>
                            <StyledTableCell>
                                {formatDate(plan.iKraft)}
                            </StyledTableCell>
                        </TableRow>
                    )}
                    {plan.sistBehandlet && (
                        <TableRow>
                            <StyledTableCell component='th' scope='row'>
                                Sist behandlet:
                            </StyledTableCell>
                            <StyledTableCell>
                                {formatDate(plan.sistBehandlet)}
                            </StyledTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </ToiBox>
    );
}
