import {TableCell, TableRow} from '@mui/material';
import {ToiCheckbox} from '@norkart/toi-components';
import {SakArkivJournalDokumentDtoExtened} from '../../../hooks/journaler/types';

type Props = {
    document: SakArkivJournalDokumentDtoExtened;
    handleToggleSingleDocument: (
        value: SakArkivJournalDokumentDtoExtened
    ) => void;
    isSelected: boolean;
};

export const ImportDocumentFromArchiveSelectFilesListItem = ({
    document,
    handleToggleSingleDocument,
    isSelected,
}: Props) => {
    const renderRow = () => (
        <TableRow
            sx={{
                '&:last-child td': {borderBottom: 'none'},
            }}
        >
            <TableCell
                onClick={() => handleToggleSingleDocument(document)}
                sx={{
                    cursor: 'pointer',
                }}
            >
                <ToiCheckbox name='selected' checked={isSelected} />
            </TableCell>
            <TableCell
                onClick={() => handleToggleSingleDocument(document)}
                sx={{cursor: 'pointer'}}
            >
                {document.dokumentTittel}
            </TableCell>
        </TableRow>
    );
    return <>{renderRow()}</>;
};

export default ImportDocumentFromArchiveSelectFilesListItem;
