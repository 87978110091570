import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const DeleteRecycleIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M8.5 8.99999V18M12 8.99999V18M15.5 8.99999V18M3 5.00006H4.5M9 5.00006V3.50006C9 2.67163 9.67157 2.00006 10.5 2.00006H13.5C14.3284 2.00006 15 2.67163 15 3.50006L15 5.00006M9 5.00006H15M9 5.00006H4.5M15 5.00006H19.5M21 5.00006H19.5M19.5 5.00006V20.5C19.5 21.3284 18.8284 22 18 22H6C5.17157 22 4.5 21.3284 4.5 20.5V5.00006'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default DeleteRecycleIcon;
