import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const SendIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M4.5 12L1.43714 19.487C0.922147 20.7459 2.21399 21.9853 3.45044 21.4185L21.0249 13.3636C22.1916 12.8289 22.1916 11.1711 21.0249 10.6364L3.45044 2.58145C2.214 2.01475 0.922148 3.25414 1.43714 4.513L4.5 12ZM4.5 12H14'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </ToiIcon>
));

export default SendIcon;
