import {memo} from 'react';
import {FilterFn} from '@tanstack/react-table';
import {ToiFormControl} from '../../pureMuiComponents';
import {ToiCheckboxOption} from '../../ToiCheckbox/ToiCheckboxOption';
import {ToiFormGroup} from '../../ToiFormGroup';
import {ToiFormLabel} from '../../ToiFormLabel/ToiFormLabel';
import {ToiFilterComponentProps} from './types';

type Selection = Record<string, boolean>;
type Options = Record<string, string>;

export type SelectFilterOption = {label: string; value: string};

// --- Filter function ---
export const selectFilterFn: FilterFn<any> = (row, columnId, filterValue) =>
  filterValue.includes(row.getValue(columnId));

selectFilterFn.resolveFilterValue = (filterValue: Selection) => {
  return Object.entries(filterValue)
    .filter(([, checked]) => checked)
    .map(([key]) => key);
};

selectFilterFn.autoRemove = (val: Selection) => {
  return Object.values(val).every((checked) => !checked);
};

// --- Filter component ---
export const SelectFilter = memo(
  ({column}: ToiFilterComponentProps) => {
    const options = useOptions(column);
    const defaultValue = (column.getFilterValue() as Selection) ?? getInitialValues(options);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      column.setFilterValue((oldValue: Selection) => ({...oldValue, [event.target.name]: event.target.checked}));
    };

    return (
      <ToiFormControl component='fieldset'>
        <ToiFormLabel component='legend'>Filter</ToiFormLabel>
        <ToiFormGroup>
          {Object.entries(options).map(([name, label]) => (
            <ToiCheckboxOption
              key={name}
              name={name}
              defaultChecked={defaultValue[name]}
              label={label}
              onChange={handleChange}
            />
          ))}
        </ToiFormGroup>
      </ToiFormControl>
    );
  },
  () => true, // Never re-render when filter is open. This is to prevent default value to change. When filter is reopened, the component will be remounted, ensuring the options are correct.
);

// --- Resolve current selection ---
function useOptions(column: ToiFilterComponentProps['column']): Options {
  // Return static options if set
  const staticOptions = column.columnDef.meta?.filterOptions?.options;
  if (staticOptions !== undefined) {
    const options = staticOptions.map((options) =>
      typeof options === 'string' ? {label: options, value: options} : options,
    );
    return optionsToSelection(options);
  }

  // Calculate unique values to use as options
  if (column._getFacetedRowModel === undefined || column._getFacetedUniqueValues === undefined) {
    throw new Error(
      `Could not calculate unique values for column ${column.id}.
      Remember to either set "options" in the "meta.filterOptions" property of the column
      or add the "getFacetedRowModel" and "getFacetedUniqueValues" to the useToiTable hook to automatically calculate unique values.`,
    );
  }

  const options = Array.from(column.getFacetedUniqueValues().keys())
    .sort()
    .map((value) => ({label: value, value}));
  return optionsToSelection(options);
}

// --- Helper functions ---
function optionsToSelection(options: SelectFilterOption[]): Options {
  return options.reduce((acc, {value, label}) => ({...acc, [value]: label}), {});
}

function getInitialValues(options: Options): Selection {
  return Object.keys(options).reduce((acc, key) => ({...acc, [key]: false}), {});
}
