import React, {useState} from 'react';
import {ChevronDownIcon, ChevronUpIcon} from '@norkart/toi-icons';
import {
    ToiFormGroup,
    ToiFormControlLabel,
    ToiCheckbox,
    ToiLink,
} from '@norkart/toi-components';
import {Option} from '../AdvancedFilters';

type Props = {
    options: any[];
    selectedOptions: string[];
    keyCol?: string;
    valueCol?: string;
    optionsToShow?: number;
    disabled?: boolean;
    handleChange: (value: string) => any;
};

export type CheckboxSectionOption = {
    label: string;
    value: string;
};

const CheckboxSection = ({
    options,
    keyCol = 'label',
    valueCol = 'value',
    optionsToShow = 4,
    disabled,
    ...props
}: Props) => {
    const [displayHiddenOptions, setDisplayHiddenOptions] = useState(false);
    // const [selectedOptions, setSelectedOptions] = useState(
    //   new Set(props.selectedOptions)
    // )

    const hiddenOptions =
        optionsToShow < options.length ? options.length - optionsToShow : false;
    const visibleOptions = displayHiddenOptions
        ? options
        : options.slice(0, optionsToShow);

    const isChecked = (value: string) => {
        return props.selectedOptions.includes(value);
    };

    const handleChange = (option: Option) => {
        const value = option[valueCol];
        const selectedOptions = new Set(props.selectedOptions);
        selectedOptions.has(value)
            ? selectedOptions.delete(value)
            : selectedOptions.add(value);
        props.handleChange(
            Array.from(selectedOptions)
                .filter((e) => e)
                .join(',')
        );
    };

    return (
        <div className='CheckboxSection'>
            <ToiFormGroup row={true}>
                {visibleOptions.map((option) => {
                    const value = option[valueCol];
                    return (
                        <ToiFormControlLabel
                            style={{
                                width: '350px',
                                fontWeight: 400,
                            }}
                            key={option[keyCol]}
                            control={
                                <ToiCheckbox
                                    disabled={disabled}
                                    checked={isChecked(value)}
                                    onChange={() => handleChange(option)}
                                    value={value}
                                />
                            }
                            label={option.label.split(':')[1]}
                        />
                    );
                })}
            </ToiFormGroup>
            {hiddenOptions && (
                <ToiLink
                    component={'button'}
                    sx={(theme) => ({mt: theme.toi.spacing.xs})}
                    endIcon={
                        displayHiddenOptions ? (
                            <ChevronUpIcon />
                        ) : (
                            <ChevronDownIcon />
                        )
                    }
                    style={{
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        setDisplayHiddenOptions(!displayHiddenOptions)
                    }
                >
                    {displayHiddenOptions
                        ? `Skjul ${hiddenOptions}`
                        : `Vis alle ${options.length}`}
                </ToiLink>
            )}
        </div>
    );
};
export default CheckboxSection;
