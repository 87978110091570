import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';

export const toMoreMapFunctions = (
    plan: ArealplanDto,
    visplanUrl: string | null | undefined
) => {
    if (plan)
        if (visplanUrl?.includes('?')) {
            window.open(
                `${visplanUrl}&funksjon=visplan&kommunenummer=${plan.komnr}&planidentifikasjon=${plan.planId}`,
                '_blank'
            );
        } else {
            window.open(
                `${visplanUrl}?funksjon=visplan&kommunenummer=${plan.komnr}&planidentifikasjon=${plan.planId}`,
                '_blank'
            );
        }
};
