import {Autocomplete} from '@mui/material';
import * as React from 'react';
import {ToiStack, ToiTextField} from '@norkart/toi-components';
import {
    PlanstatusVerdiDto,
    PlantypeVerdiDto,
    VedtakVerdiDto,
} from '@norkart/nkapi-arealplaner-api';
import DateRangePickerMui, {
    StartOrEndDate,
} from '../../../../components/DatePicker/DateRangePickerMui/DateRangePickerMui';
import {useFilterQL} from '../ReportTools/FilterQL';
import FilterWrapper from '../FilterWrapper';

type FilterDispensasjonerProps = {
    //filterFn: (any) => any
    planstatuser?: PlanstatusVerdiDto[];
    plantyper?: PlantypeVerdiDto[];
    vedtaksstatuser?: VedtakVerdiDto[];
    setFilterValues: React.Dispatch<any>;
    clearFilter: boolean;
};

type SelectedFiltersProps = {
    vedtaksstatus?: VedtakVerdiDto[];
    planstatus?: PlanstatusVerdiDto[];
    plantype?: PlantypeVerdiDto[];
    dispensasjonerBeskrivelse?: string;
    fraDato?: Date;
    tilDato?: Date;
};

export function DispensasjonerFilter({
    vedtaksstatuser,
    plantyper,
    planstatuser,
    setFilterValues,
    clearFilter,
}: FilterDispensasjonerProps) {
    const filterQl = useFilterQL();
    const [selectedFilters, setSelectedFilters] = React.useState<
        SelectedFiltersProps | undefined
    >();

    const handleDatoChange = (dates: StartOrEndDate | undefined) => {
        setSelectedFilters({
            ...selectedFilters,
            tilDato: dates?.end,
            fraDato: dates?.start,
        });
    };

    //Lager array av objekter som skal brukes som filtere
    const handleFiltering = React.useCallback(
        (filters: SelectedFiltersProps | undefined) => {
            const newFilter: any[] = [];
            filters?.vedtaksstatus &&
                filters?.vedtaksstatus.length !== 0 &&
                newFilter.push({
                    or: filters.vedtaksstatus.map((vs) => ({
                        vedtakId: {eq: vs.id},
                    })),
                });

            filters?.planstatus &&
                filters.planstatus.length !== 0 &&
                newFilter.push({
                    or: filters.planstatus.map((ps) => ({
                        arealplan: {
                            planStatusId: {eq: ps.id},
                        },
                    })),
                });

            filters?.plantype &&
                filters.plantype.length !== 0 &&
                newFilter.push({
                    or: filters.plantype.map((pt) => ({
                        arealplan: {
                            planTypeId: {eq: pt.id},
                        },
                    })),
                });

            filters?.fraDato &&
                newFilter.push({
                    vedtaksdato: {
                        gte: filters.fraDato.toJSON(),
                    },
                });

            filters?.tilDato &&
                newFilter.push({
                    vedtaksdato: {
                        lte: filters.tilDato.toJSON(),
                    },
                });

            filters?.dispensasjonerBeskrivelse &&
                filters.dispensasjonerBeskrivelse !== '' &&
                newFilter.push({
                    beskrivelse: {contains: filters?.dispensasjonerBeskrivelse},
                });

            if (newFilter.length === 0) filterQl.updateFilter(undefined);
            else filterQl.updateFilter(newFilter);
        },
        [selectedFilters]
    );

    //Cleare beskrivelse feltet
    const clearSearch = () => {
        setSelectedFilters({...selectedFilters, dispensasjonerBeskrivelse: ''});
    };

    //Debounce for beskrivelse feltet
    React.useMemo(() => {
        handleFiltering(selectedFilters);
    }, [
        selectedFilters?.vedtaksstatus,
        selectedFilters?.planstatus,
        selectedFilters?.plantype,
        selectedFilters?.fraDato,
        selectedFilters?.tilDato,
    ]);

    React.useMemo(() => {
        if (
            selectedFilters?.dispensasjonerBeskrivelse === '' ||
            !selectedFilters?.dispensasjonerBeskrivelse
        ) {
            return handleFiltering(selectedFilters);
        }

        const filterTimeout = setTimeout(() => {
            handleFiltering(selectedFilters);
        }, 500);

        return () => clearTimeout(filterTimeout);
    }, [selectedFilters?.dispensasjonerBeskrivelse]);

    //Setter oppdaterte filterverdier så det kan brukes som variabler i graphql request
    React.useEffect(() => {
        setFilterValues(filterQl.filterReturn);
    }, [filterQl.filterReturn]);

    React.useEffect(() => {
        if (clearFilter) setSelectedFilters(undefined);
    }, [clearFilter]);

    return (
        <FilterWrapper>
            <ToiStack direction='row' gap={(theme) => theme.toi.spacing.m}>
                <ToiStack width={'100%'}>
                    <Autocomplete
                        size='small'
                        options={vedtaksstatuser ? vedtaksstatuser : []}
                        limitTags={1}
                        fullWidth={true}
                        multiple={true}
                        value={
                            selectedFilters?.vedtaksstatus
                                ? selectedFilters.vedtaksstatus
                                : []
                        }
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                vedtaksstatus: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiTextField
                                {...params}
                                size='small'
                                label='Vedtaksstatus'
                                variant='outlined'
                            />
                        )}
                    />
                    <Autocomplete
                        size='small'
                        options={planstatuser ? planstatuser : []}
                        limitTags={1}
                        fullWidth={true}
                        multiple={true}
                        value={
                            selectedFilters?.planstatus
                                ? selectedFilters.planstatus
                                : []
                        }
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                planstatus: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiTextField
                                {...params}
                                size='small'
                                label='Planstatus'
                                variant='outlined'
                            />
                        )}
                    />
                    <Autocomplete
                        size='small'
                        options={plantyper ? plantyper : []}
                        limitTags={1}
                        fullWidth={true}
                        multiple={true}
                        value={
                            selectedFilters?.plantype
                                ? selectedFilters.plantype
                                : []
                        }
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                plantype: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiTextField
                                {...params}
                                size='small'
                                label='Plantype'
                                variant='outlined'
                            />
                        )}
                    />
                </ToiStack>
                <ToiStack width={'100%'}>
                    <ToiTextField
                        fullWidth={true}
                        size='small'
                        variant='outlined'
                        label='Beskrivelse'
                        onChange={(e) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                dispensasjonerBeskrivelse: e.target.value,
                            })
                        }
                        onKeyUp={(e) => {
                            if (e.key === 'Escape')
                                setSelectedFilters({
                                    ...selectedFilters,
                                    dispensasjonerBeskrivelse: '',
                                });
                        }}
                        value={
                            selectedFilters?.dispensasjonerBeskrivelse
                                ? selectedFilters.dispensasjonerBeskrivelse
                                : ''
                        }
                    />
                    <DateRangePickerMui
                        onChange={(d) => {
                            handleDatoChange(d);
                        }}
                        label='Vedtaksdato'
                        startDate={selectedFilters?.fraDato}
                        endDate={selectedFilters?.tilDato}
                    />
                </ToiStack>
            </ToiStack>
        </FilterWrapper>
    );
}
