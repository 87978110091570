import {ColumnDef} from '@tanstack/react-table';
import {useMemo} from 'react';
import {TokenDto} from '@norkart/nkapi-arealplaner-api';
import {
    ToiIconButton,
    ToiStack,
    ToiTable,
    ToiTooltip,
    useToiTable,
} from '@norkart/toi-components';
import {EditIcon, ShareIcon} from '@norkart/toi-icons';

type Props = {
    data: TokenDto[];
    handleShare: (token: TokenDto) => void;
    handleEdit: (token: TokenDto) => void;
};

function TokensTable({data, handleShare, handleEdit}: Props) {
    const columns = useMemo<ColumnDef<TokenDto>[]>(
        () => [
            {
                accessorKey: 'description',
                header: 'Beskrivelse',
            },
            {
                id: 'actions',
                header: '',
                cell: ({row}) => (
                    <ToiStack
                        direction={'row'}
                        justifyContent={'flex-end'}
                        gap={(theme) => theme.toi.spacing.xs}
                    >
                        <ToiIconButton
                            color='transparent'
                            aria-label='Del'
                            onClick={() => handleShare(row.original)}
                        >
                            <ToiTooltip title='Del'>
                                <ShareIcon />
                            </ToiTooltip>
                        </ToiIconButton>
                        <ToiIconButton
                            color='transparent'
                            aria-label='Endre'
                            onClick={() => handleEdit(row.original)}
                        >
                            <ToiTooltip title='Endre'>
                                <EditIcon />
                            </ToiTooltip>
                        </ToiIconButton>
                    </ToiStack>
                ),
            },
        ],
        []
    );

    const table = useToiTable<TokenDto>({
        data: data,
        columns,
    });

    return <ToiTable table={table} ariaLabel='Tilganger table' />;
}

export default TokensTable;
