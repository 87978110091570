import {Menu} from '@base-ui-components/react/menu';
import {RadioGroup} from '@base-ui-components/react/radio-group';
import {styled, useTheme} from '@mui/material';
import {CheckmarkCheckDoneIcon} from '@norkart/toi-icons';
import {ToiMenuSize} from '../types';
import {toiMenuItemStyle} from './utils';

// DISCLAIMER: Menu.RadioGroup.Props is not exported: https://base-ui.com/react/components/menu#radio-group
// we use the very similar RaidoGroup.Props instead: https://base-ui.com/react/components/radio#api-reference
export type ToiMenuRadioGroupProps = Omit<RadioGroup.Props, 'name' | 'required' | 'readOnly' | 'className' | 'render'>;
export const ToiMenuRadioGroup = (props: ToiMenuRadioGroupProps) => {
  return <Menu.RadioGroup {...props} />;
};

export type ToiMenuRadioItemProps = Menu.RadioItem.Props & {size?: ToiMenuSize};
export const StyledMenuRadioItem = styled(Menu.RadioItem)<ToiMenuRadioItemProps>(({theme, size, disabled}) => {
  return {
    ...toiMenuItemStyle(theme, size, disabled),
  };
});
export const ToiMenuRadioItem = (props: ToiMenuRadioItemProps) => {
  const {children, ...rest} = props;
  const spacing = useTheme().toi.spacing;
  return (
    <StyledMenuRadioItem {...rest}>
      {children}
      <Menu.RadioItemIndicator style={{gridColumnEnd: 1, marginLeft: 'auto', marginTop: spacing['2xs']}}>
        <CheckmarkCheckDoneIcon />
      </Menu.RadioItemIndicator>
    </StyledMenuRadioItem>
  );
};
