import {AvatarTypeMap, AvatarProps as MuiAvatarProps} from '@mui/material';
import {forwardRef} from 'react';
import {OverridableComponent} from '@mui/types';
import {isString} from 'lodash-es';
import {StyledAvatar, ToiAvatarColor} from './StyledAvatar';

export type ToiAvatarSize = 'small' | 'medium' | 'large' | 'xl' | 'xxl';

type OwnProps = {
  size?: ToiAvatarSize;
  initials?: string;
  fullname?: string;
  colorOverride?: ToiAvatarColor;
};

type DefaultComponent = AvatarTypeMap['defaultComponent'];
type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiAvatarProps = MuiAvatarProps<DefaultComponent, OwnProps>;

const initialsFromName = (fullName: string) => {
  const names = fullName.trim().split(' ');
  let initials = names[0][0];
  if (names.length > 1) initials += names[names.length - 1][0];
  return initials;
};

const ariaLabelFromProps = (props: ToiAvatarProps) => {
  if (props.fullname) return `Avatar for ${props.fullname}`;
  if (props.initials || isString(props.children)) return `Avatar med initialer ${props.initials || props.children}`;
  return undefined;
};

export const ToiAvatar = forwardRef<RefType, ToiAvatarProps>((props, ref) => {
  const {children, ...restProps} = props;
  const ariaLabel = ariaLabelFromProps(props);
  const initials = props.fullname ? initialsFromName(props.fullname) : props.initials;

  return (
    <StyledAvatar
      initials={initials}
      ref={ref}
      {...restProps}
      aria-label={ariaLabelFromProps(props)}
      role={ariaLabel && 'img'}
    >
      {initials || children}
    </StyledAvatar>
  );
}) as OverridableComponent<AvatarTypeMap<OwnProps>>;

export default ToiAvatar;
