import {ToiButton} from '@norkart/toi-components';
import {ChevronDownIcon} from '@norkart/toi-icons';

type ReferenceButtonProps = {
    onClick: () => void;
};

export const ReferenceButton = ({onClick}: ReferenceButtonProps) => {
    return (
        <ToiButton
            sx={{backgroundColor: 'transparent'}}
            size='medium'
            variant='secondary'
            endIcon={<ChevronDownIcon />}
            onClick={onClick}
        >
            Referanser
        </ToiButton>
    );
};
