import {Grid} from '@mui/material';
import React, {useState} from 'react';
import {EditIcon, PlusIcon} from '@norkart/toi-icons';
import {ToiIconButton, ToiTypography} from '@norkart/toi-components';
import FormInputField from '../../../../components/FormInputField';
import OnChange from '../../../../components/OnChange';
import useMeta from '../../../../hooks/meta/useMeta';
import SakList from '../../../sak/SakList';
import {ArealplanFormValues} from '../PlanForm';
import EditSakDialog from './EditSakDialog';

export default function EditSak({
    values,
    initialValues,
    setInitialValues,
}: {
    values: ArealplanFormValues;
    initialValues: ArealplanFormValues;
    setInitialValues: React.Dispatch<React.SetStateAction<ArealplanFormValues>>;
}) {
    const [editSakOpen, setEditSakOpen] = useState(false);
    const meta = useMeta();

    const handleEditSak = () => {
        setEditSakOpen(true);
    };

    const handleEditSakClose = () => {
        setEditSakOpen(false);
    };

    const handleEditSakOk = (values: ArealplanFormValues) => {
        setInitialValues({
            ...initialValues,
            saker:
                values.saker?.filter(
                    (sak) =>
                        sak.sakAar &&
                        sak.sakSeknr &&
                        (sak.sakAar != null || sak.sakSeknr != null)
                ) ?? [],
        });
        handleEditSakClose();
    };

    return (
        <>
            <ToiTypography variant='label' mb={0.5}>
                Saksnummer (saksår + sekvensnummer)
            </ToiTypography>
            {values.saker.length <= 1 ? (
                <Grid
                    container={true}
                    spacing={2}
                    justifyContent='space-between'
                    wrap='nowrap'
                >
                    <Grid item={true} xs={4}>
                        <FormInputField
                            name='saker[0].sakAar'
                            inputProps={{maxLenght: 4}}
                            fieldProps={{
                                parse: (value) => {
                                    if (value == '') return null;
                                    return Number(value.replace(/[^\d]/g, ''));
                                },
                            }}
                        />
                        <OnChange name='saker[0].sakAar'>
                            {(value, prev) => {
                                const saker = initialValues.saker;
                                if (saker[0]) saker[0].sakAar = value;
                                else if (value != '')
                                    saker.push({
                                        sakAar: value,
                                        arkivId: meta.aktivtArkiv,
                                    });
                                setInitialValues({
                                    ...initialValues,
                                    saker,
                                });
                            }}
                        </OnChange>
                    </Grid>
                    <Grid item={true}>
                        <FormInputField
                            name='saker[0].sakSeknr'
                            fieldProps={{
                                parse: (value) => {
                                    if (value == '') return null;
                                    return Number(value.replace(/[^\d]/g, ''));
                                },
                            }}
                        />
                        <OnChange name='saker[0].sakSeknr'>
                            {(value, prev) => {
                                const saker = initialValues.saker;
                                if (saker[0]) saker[0].sakSeknr = value;
                                else if (value != '')
                                    saker.push({
                                        sakSeknr: value,
                                        arkivId: meta.aktivtArkiv,
                                    });
                                setInitialValues({
                                    ...initialValues,
                                    saker,
                                });
                            }}
                        </OnChange>
                    </Grid>
                    <Grid item={true}>
                        <ToiIconButton
                            aria-label='Legg til saksnummer'
                            color='transparent'
                            onClick={handleEditSak}
                            size='small'
                        >
                            <PlusIcon fontSize='small' />
                        </ToiIconButton>
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    container={true}
                    spacing={4}
                    justifyContent='space-between'
                    wrap='nowrap'
                >
                    <Grid item={true}>
                        <ToiTypography
                            component='div'
                            style={{padding: '6px 8px'}}
                        >
                            <SakList saker={values.saker} />
                        </ToiTypography>
                    </Grid>
                    <Grid item={true}>
                        <ToiIconButton
                            aria-label='Endre saksnummer'
                            color='transparent'
                            onClick={handleEditSak}
                            size='small'
                        >
                            <EditIcon fontSize='small' />
                        </ToiIconButton>
                    </Grid>
                </Grid>
            )}

            <EditSakDialog
                saker={initialValues.saker}
                open={editSakOpen}
                onClose={handleEditSakClose}
                onOk={handleEditSakOk}
                arkiver={meta.data.arkiver}
                defaultValue={meta.aktivtArkiv}
            />
        </>
    );
}
