import {
    Grid,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
} from '@mui/material';
import * as React from 'react';
import {connect} from 'react-redux';
import {ToiButton, ToiStack, ToiTypography} from '@norkart/toi-components';
import {WarningIcon} from '@norkart/toi-icons';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {BehandlingDto} from '@norkart/nkapi-arealplaner-api';
import useMidlertidigForbud from '../../../hooks/midlertidigForbud/useMidlertidigForbud';
import {ConnectedReduxProps} from '../../../store';
import {showDialog} from '../../../store/dialog/actions';
import {formatDate} from '../../../utils/formatDate';
import SakItem from '../../sak/SakList/SakItem';
import PlanNotification from './PlanNotification';
import usePlanNotificationHelpers, {
    Horingsperiode,
} from './usePlanNotificationHelpers';

export interface PlanNotificationsProps extends ConnectedReduxProps {
    plan: ArealplanDto;
    behandlinger?: BehandlingDto[] | null;
    midlertidigForbud?: ReturnType<typeof useMidlertidigForbud>;
    variant: 'blocks' | 'icon';
}

type Notification = {
    title: string;
    description?: string | React.ReactElement;
};

const Bold = styled(ToiTypography)({fontWeight: 500});

const NoMaxWidthTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

const PlanNotifications = ({
    plan,
    behandlinger,
    midlertidigForbud,
    variant,
    dispatch,
}: PlanNotificationsProps) => {
    const helpers = usePlanNotificationHelpers();
    const [notifications, setNotifications] = React.useState(
        [] as Notification[]
    );
    React.useEffect(() => {
        const notifications = [] as Notification[];
        if (helpers.showKlagefristWithoutDate({plan}))
            variant === 'icon'
                ? notifications.push({
                      title: 'Klagefrist ikke utløpt, planen har ikke blitt kunngjort.',
                  })
                : notifications.push({
                      title: 'Klagefrist ikke utløpt, planen har ikke blitt kunngjort.',
                      description: 'Klagefristen har ikke begynt å løpe ennå.',
                  });
        if (helpers.showKlagefristWithDate({plan}))
            variant === 'icon'
                ? notifications.push({
                      title: `Klagefrist ikke utløpt. Klagefrist: ${formatDate(
                          plan.klagefrist
                      )}`,
                  })
                : notifications.push({
                      title: 'Klagefrist ikke utløpt',
                      description: (
                          <Grid container={true}>
                              <Grid item={true} xs={12} lg={6}>
                                  <Bold>
                                      {`Annonseringsdato: ${helpers.getAnnouncementDate(
                                          {
                                              behandlinger,
                                          }
                                      )}`}
                                  </Bold>
                              </Grid>
                              <Grid item={true} xs={12} lg={6}>
                                  <Bold>{`Klagefrist: ${formatDate(
                                      plan.klagefrist
                                  )}`}</Bold>
                              </Grid>
                          </Grid>
                      ),
                  });
        if (helpers.showKlagefristExpiredLessThanaWeek({plan}))
            notifications.push({
                title:
                    'Klagefristen er nettopp utgått. Klagefrist: ' +
                    formatDate(plan.klagefrist),
                description:
                    'Vær oppmerksom på at det kan komme inn klager som er sendt via ordinær postgang.',
            });
        if (helpers.showKlageUtsatt({plan}))
            notifications.push({
                title: 'Klage med oppsettende(utsatt) virkning',
                description: 'Planen får først virkning når klagen er avgjort.',
            });
        if (helpers.showPlandokumentasjonIkkeOppdatert({plan}))
            notifications.push({
                title: 'Plandokumentasjonen ikke oppdatert i henhold til vedtak',
                description:
                    'Endelig planvedtak forutsetter endringer i plandokumentene som ikke er på plass ennå. Disse må oppdateres før planvedtaket kan kunngjøres.',
            });
        if (helpers.showMottattKlage({plan}))
            notifications.push({
                title: 'Klage mottatt',
                description:
                    'Planen er under klagebehandling. Planens virkninger trer likevel i kraft straks den er vedtatt, selv om det foreligger klage. Tiltak utføres på egen risiko.',
            });
        if (helpers.showUavklartInnsigelse({plan}))
            notifications.push({
                title: 'Uavklart innsigelse',
                description:
                    'Planen har blitt vedtatt med uløst innsigelse. Innsigelsen er gitt fra myndighet med innsigelseskompetanse og har blitt fremmet mot hele eller deler av planen. Dette innebærer at departementet må avgjøre om innsigelsen skal tas til følge, eller at innsigelsesmyndighet trekker innsigelsen, før planens status er endelig avklart. Hele eller deler av planen har derfor ikke rettsvirkning. Mer informasjon finner du under Planbehandlinger.',
            });
        if (midlertidigForbud?.isFetched) {
            midlertidigForbud.data.forEach((mf) => {
                notifications.push({
                    title: 'Hele eller deler av planområdet overlapper et område med midlertidig forbud mot tiltak.',
                    description: (
                        <ToiTypography>
                            Aktuell eiendom eller område er ikke nødvendigvis
                            berørt. Se kart for forbudets utstrekning (skravert
                            grått område). Dersom du trykker i kartet vil du få
                            beskjed i sidemenyen om punktet er berørt eller
                            ikke. Vedtaksdato: {mf.datoForVedtak}. Gyldig til:{' '}
                            {mf.gyldigTil}.
                            {mf.url && (
                                <>
                                    {' '}
                                    Saksnummer: <SakItem sak={mf} />
                                </>
                            )}
                        </ToiTypography>
                    ),
                });
            });
        }
        const hps = helpers.getHoringsperioder({behandlinger});
        if (hps) {
            hps.map((hp) => {
                notifications.push({
                    title: 'Planen er i høringsperiode',
                    description: renderHoringsperiodeNotification(hp),
                });
            });
        }
        if (helpers.shouldShowHoringsperiode({plan}) && variant === 'icon') {
            notifications.push({
                title: `Planen er i høringsperiode med frist ${formatDate(
                    plan.horingsfrist
                )}`,
            });
        }
        setNotifications(notifications);
    }, [plan, midlertidigForbud]);

    function renderHoringsperiodeNotification(hp: Horingsperiode) {
        const horingsdokumenter = hp.behandling.dokumenter?.filter(
            (b) => b.horingsdokument
        );
        return (
            <Grid container={true}>
                <Grid container={true} justifyContent='flex-start'>
                    <Grid item={true} xs={12}>
                        <ToiTypography>
                            {'Plan lagt ut til '}
                            <em>{hp.behandlingsType}</em>
                            {' med høringsfrist '}
                            {hp.tilDato}.
                        </ToiTypography>
                    </Grid>
                    {(horingsdokumenter?.length ?? 0) > 0 && (
                        <Grid item={true} xs={12}>
                            <Bold>
                                <ToiButton
                                    variant='secondary'
                                    onClick={() =>
                                        dispatch(
                                            showDialog({
                                                dialogType: 'DOKUMENT_LIST',
                                                dialogProps: {
                                                    documents:
                                                        horingsdokumenter,
                                                    title: 'Høringsdokumenter',
                                                },
                                            })
                                        )
                                    }
                                >
                                    Vis dokumenter
                                </ToiButton>
                            </Bold>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    }

    function renderTooltipTitle() {
        return notifications.map((notification, index) => (
            <ToiTypography key={index} style={{color: 'white'}}>
                {notification.title}
            </ToiTypography>
        ));
    }
    if (notifications.length === 0) return null;
    if (variant === 'blocks')
        return (
            <ToiStack gap={1}>
                {notifications.map((notification, index) => (
                    <PlanNotification
                        key={index}
                        title={notification.title}
                        description={notification.description}
                    />
                ))}
            </ToiStack>
        );
    if (variant === 'icon')
        return (
            <NoMaxWidthTooltip title={renderTooltipTitle()}>
                <WarningIcon fontSize='small' />
            </NoMaxWidthTooltip>
        );
    return null;
};

export default connect()(PlanNotifications);
