import React, {useEffect, useRef} from 'react';
import {createRoot} from 'react-dom/client';
import PropTypes from 'prop-types';
import {CheckmarkCheckDoneIcon, CloseIcon} from '@norkart/toi-icons';
import './DrawTextfield.css';

//https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md
const DrawTextfield = {};

DrawTextfield.onSetup = function () {
    const point = this.newFeature({
        type: 'Feature',
        properties: {
            textpoint: 'true',
        },
        geometry: {
            type: 'Point',
            coordinates: [],
        },
    });
    this.addFeature(point);
    this.clearSelectedFeatures();
    this.setActionableState({
        trash: true,
    });

    //create and render text input element
    const container = document.createElement('div');
    container.id = 'textinput-container';
    container.style.display = 'contents';
    document.getElementById('modalcontent').appendChild(container);
    const root = createRoot(container);
    root.render(
        <InputDiv
            show={false}
            onDone={() => this.changeMode('simple_select')}
        />
    );

    return {point};
};

DrawTextfield.onTap = DrawTextfield.onClick = function (state, e) {
    state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat);

    const textInputContainer = document.getElementById('textinput-container');
    if (!textInputContainer._root) {
        textInputContainer._root = createRoot(textInputContainer);
    }
    textInputContainer._root.render(
        <InputDiv
            show={true}
            onDone={() => this.changeMode('simple_select')}
            x={e.originalEvent.clientX}
            y={e.originalEvent.clientY}
        />
    );
};

DrawTextfield.onStop = function (state) {
    if (
        !state.point.getCoordinate().length ||
        document.getElementById('textinput-input').value.length < 1
    ) {
        this.deleteFeature([state.point.id], {silent: true});
    } else {
        state.point.properties.title =
            document.getElementById('textinput-input').value;
    }

    const textInputContainer = document.getElementById('textinput-container');
    if (textInputContainer._root) {
        textInputContainer._root.unmount();
    }
};

DrawTextfield.toDisplayFeatures = function (state, geojson, display) {
    display(geojson);
};

function InputDiv({show, onDone, x = 0, y = 0}) {
    const inputRef = useRef();

    useEffect(() => {
        inputRef?.current?.focus();
    }, [x, y]);

    function handleKeypress(e) {
        if (e.key === 'Enter') {
            onDone();
        } else if (e.key === 'Escape') {
            inputRef.current.value = '';
            onDone();
        }
    }
    return show ? (
        <div
            className='textinput'
            style={{position: 'fixed', top: y + 'px', left: x + 'px'}}
        >
            <input
                id='textinput-input'
                ref={inputRef}
                onKeyDown={handleKeypress}
            ></input>
            <button
                className='textinput-button'
                onClick={() => {
                    inputRef.current.value = '';
                    onDone();
                }}
            >
                <CloseIcon />
            </button>
            <button className='textinput-button' onClick={onDone}>
                <CheckmarkCheckDoneIcon />
            </button>
        </div>
    ) : null;
}

InputDiv.propTypes = {
    show: PropTypes.bool,
    onDone: PropTypes.func,
    x: PropTypes.number,
    y: PropTypes.number,
};

export default DrawTextfield;
