import {ListItemButton, ListItemButtonTypeMap, styled} from '@mui/material';
import {OverridableComponent} from '@mui/types';

export const ToiListItemButton = styled(ListItemButton)(({theme}) => ({
  '&[role="listitem"]': {
    pointerEvents: 'none',
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,
  },
  '&:hover': {
    backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,
  },
  '&.Mui-selected:': {
    backgroundColor: theme.vars.toi.palette.backgrounds.component.selected,
  },
  '&.Mui-disabled': {
    color: theme.vars.toi.palette.disabled.textOnWhite,
  },
  '& .MuiListItemText-root > .MuiListItemText-primary': {
    fontWeight: 500,
    '&:has(~ .MuiListItemText-secondary)': {
      fontWeight: 500,
    },
  },
  '& .MuiFormControlLabel-root .MuiTypography-root': theme.typography.body2,
})) as OverridableComponent<ListItemButtonTypeMap>;
