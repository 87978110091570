import {styled, Typography, TypographyProps, TypographyTypeMap} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';

const fontWeightMap = {
  bold: 700,
  semibold: 600,
  medium: 500,
  regular: 400,
  light: 300,
} as const;

function getFontWeight(fontWeight: FontWeight | undefined, bold: boolean, semibold: boolean, light: boolean) {
  if (fontWeight !== undefined) {
    if (typeof fontWeight === 'number') return fontWeight;
    return fontWeightMap[fontWeight];
  }
  if (bold) return 700;
  if (semibold) return 600;
  if (light) return 300;
  return undefined;
}

type FontWeight = keyof typeof fontWeightMap | number;

export interface OwnProps {
  fontWeight?: FontWeight;
  /** @deprecated('Use fontStyle="italic" instead') */
  italic?: boolean;
  /** @deprecated('Use textTransform="uppercase" instead') */
  uppercase?: boolean;
  /** @deprecated('Use fontWeight="bold" instead') */
  bold?: boolean;
  /** @deprecated('Use fontWeight="light" instead') */
  light?: boolean;
  /** @deprecated('Use fontWeight="semibold" instead') */
  semibold?: boolean;
  noSpacing?: boolean;
  gutterBottom?: boolean;
}

type DefaultComponent = TypographyTypeMap['defaultComponent'];

export type ToiTypographyProps = TypographyProps<DefaultComponent, OwnProps>;

export const ToiTypography = styled(Typography, {
  shouldForwardProp: (prop) =>
    !['uppercase', 'bold', 'italic', 'light', 'semibold', 'fontWeight', 'noSpacing'].includes(prop.toString()),
})<ToiTypographyProps>(
  ({
    fontWeight,
    italic,
    uppercase,
    bold = false,
    light = false,
    semibold = false,
    noSpacing = false,
    gutterBottom = false,
    theme,
  }) => {
    return {
      fontWeight: getFontWeight(fontWeight, bold, semibold, light),
      fontStyle: italic ? 'italic' : undefined,
      textTransform: uppercase ? 'uppercase' : undefined,
      margin: noSpacing ? 0 : undefined,
      marginBottom: gutterBottom ? theme.toi.spacing.s : undefined,

      '&.MuiTypography-body1, &.MuiTypography-body2, &.MuiTypography-body3, &.MuiTypography-body4': {
        maxWidth: theme.toi.textMaxWidth,
      },
    };
  },
) as OverridableComponent<TypographyTypeMap<OwnProps>>;
