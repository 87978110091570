import orderBy from 'lodash-es/orderBy';
import {BehandlingDto} from '@norkart/nkapi-arealplaner-api';
import {ToiDataDisplay, ToiStack, ToiTypography} from '@norkart/toi-components';
import DetailPanelContent from '../../../components/DetailPanelContent';
import DocumentTable from '../../../components/DocumentTable';
import JournalpostItem from '../../sak/Journalpost/JournalpostItem';

interface BehandlingDetailPanelProps {
    behandling: BehandlingDto;
}

const BehandlingDetailPanel = ({behandling}: BehandlingDetailPanelProps) => {
    const {myndighet, endringstype, posisjon, journalpostnummer} = behandling;
    const behandlingHasDetailsField =
        behandling.posisjon ||
        behandling.endringstype ||
        (behandling.dokumenter && behandling.dokumenter.length) ||
        behandling.myndighet ||
        behandling.journalpostnummer;

    if (!behandlingHasDetailsField)
        return (
            <DetailPanelContent>
                <ToiTypography>Ingen detaljer</ToiTypography>
            </DetailPanelContent>
        );

    return (
        <DetailPanelContent>
            <ToiStack direction={'row'} gap={1} sx={{width: 'fit-content'}}>
                {myndighet && (
                    <ToiDataDisplay label='Myndighet' value={myndighet} />
                )}
                {endringstype && (
                    <ToiDataDisplay label='Endringstype' value={endringstype} />
                )}
                {(journalpostnummer || journalpostnummer === 0) && (
                    <ToiDataDisplay
                        label='Journalpostnummer'
                        value={
                            <JournalpostItem
                                journalpostData={
                                    {
                                        journalpostnummer:
                                            behandling.journalpostnummer,
                                        journalpostUrl:
                                            behandling.journalpostUrl,
                                        journalpostUrlIntern:
                                            behandling.journalpostUrlIntern,
                                    } as Partial<BehandlingDto>
                                }
                            />
                        }
                    />
                )}

                {posisjon && (
                    <ToiDataDisplay
                        label='Posisjon'
                        value={`${posisjon.x}N ${posisjon.y}Ø ${posisjon.koordsys}`}
                    />
                )}
            </ToiStack>

            {behandling.dokumenter && behandling.dokumenter.length !== 0 && (
                <ToiStack>
                    <ToiTypography variant='subtitle2'>
                        Dokumenter
                    </ToiTypography>
                    <DocumentTable
                        dokumenter={orderBy(
                            behandling.dokumenter,
                            ['rekkefolgeBehandling', 'dokumenttype'],
                            ['asc', 'asc']
                        )}
                        behandling={behandling}
                    />
                </ToiStack>
            )}
        </DetailPanelContent>
    );
};

export default BehandlingDetailPanel;
