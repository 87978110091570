import {ToiStack, ToiTypography} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import PlanStatusChip from '../../PlanStatusChip';

type Props = {plan: ArealplanDto};
const PlanInfoHeader = ({plan}: Props) => {
    return (
        <ToiStack alignItems={'flex-start'}>
            <ToiTypography variant='h3' marginBottom={1} component='h1'>
                {plan.planNavn}
            </ToiTypography>
            <PlanStatusChip
                planStatusId={plan.planStatusId}
                planStatus={plan.planStatus}
            />
        </ToiStack>
    );
};
export default PlanInfoHeader;
