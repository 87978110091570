import {getSortedRowModel, SortingState} from '@tanstack/react-table';
import {useEffect, useState} from 'react';
import {ToiTable, useToiTable} from '@norkart/toi-components';
import {LogLines} from '../../../../hooks/konsistenssjekk/types';
import KonsistenssjekkColumns from './KonsistenssjekkColumns';

type Props = {
    kundeId: string;
    kommuneNr: string;
    data: LogLines[];
    handleSorting: (columnName: string) => void;
    showRunningReport: boolean;
};

export default function KonsistenssjekkTable({
    kundeId,
    kommuneNr,
    data,
    handleSorting,
    showRunningReport,
}: Props) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const columns = KonsistenssjekkColumns(kundeId, kommuneNr);
    const table = useToiTable({
        data,
        columns,
        manualSorting: true,
        getSortedRowModel: showRunningReport ? undefined : getSortedRowModel(),
        onSortingChange: setSorting,
        state: {
            sorting,
        },
    });

    useEffect(() => {
        sorting.length > 0 ? handleSorting(sorting[0].id) : handleSorting('');
    }, [sorting]);

    return <ToiTable table={table} ariaLabel={'konsistenssjekk table'} />;
}
