import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    styled,
} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ToiButton} from '@norkart/toi-components';
import {ArealplanDto, PosisjonDto} from '@norkart/nkapi-arealplaner-api';
import LoadingSpinner from '../../components/LoadingSpinner';
import useMeta from '../../hooks/meta/useMeta';
import {getMapState} from '../../store';
import * as actions from '../../store/map/actions';
import {toLatLng} from '../behandling/BehandlingFormDialog/helpers';
import BaseMap from './BaseMap';
import PlanensKartlag from './Components/Menus/Kartlag/PlanensKartlag';
import PositionMenu from './Components/Menus/PositionMenu';
import {Coords} from './types';

const DialogWrapper = styled(Dialog)({
    '& .MuiDialog-paper': {
        maxWidth: '80vw',
        width: '80vw',
        height: '90vh',
    },
});

type Props = {
    onCloseMap: () => void;
    position: PosisjonDto;
    vertikalnivaId?: number | null;
    title?: string;
    plan?: ArealplanDto;
};

const DisplayPosInMap = (props: Props) => {
    const meta = useMeta();
    const mapState = useSelector(getMapState);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (props.vertikalnivaId && mapState.wmsLayerState.availableWmsLayers) {
            const correspondingWmsLayerName =
                meta.lagKategorier.vertikalMapping[props.vertikalnivaId];
            const layer = mapState.wmsLayerState.availableWmsLayers.find(
                (b) => b.ruleset == correspondingWmsLayerName
            );
            if (layer) {
                dispatch(actions.addWmsLayer(layer));
            }
        }
    }, [props.vertikalnivaId]);

    const latLngCoords: Coords | undefined =
        props.position && props.position.x && props.position.y
            ? toLatLng(
                  {x: props.position.x, y: props.position.y},
                  meta.referansesystemKode
              )
            : undefined;

    return (
        <DialogWrapper fullWidth={true} open={true}>
            <DialogTitle style={{textAlign: 'center'}}>
                <Typography style={{fontSize: 22}}>
                    {props.title || 'Posisjonen'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {mapState.wmsLayerState.loading ||
                !mapState.borderLayers?.combined ? (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <LoadingSpinner />
                    </div>
                ) : (
                    <>
                        <Box
                            sx={{
                                maxHeight: '95vh',
                                minWidth: '252px',
                                position: 'absolute',
                                zIndex: 1,
                                overflow: 'hidden',
                                display: 'flex',
                                flexDirection: 'column' as any,
                                height: 'auto',
                                right: '30px',
                                top: '95px',
                                '& > .section': {
                                    marginTop: '10px',
                                },
                            }}
                        >
                            <PlanensKartlag defaultClosed={false} />
                            {props.position.x && props.position.y && (
                                <PositionMenu
                                    selectedPosition={{
                                        x: props.position.x,
                                        y: props.position.y,
                                    }}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                top: 0,
                                left: 0,
                                '& .nkm-mapbox-map, .mapboxgl-map': {
                                    width: '100%',
                                    height: '100%',
                                },
                            }}
                        >
                            <BaseMap
                                plan={props.plan}
                                displaySearchedPropertyGeom={false}
                                displaySurroundingPlanWms={false}
                                mapboxMarker={latLngCoords}
                            />
                        </Box>
                    </>
                )}
            </DialogContent>
            <DialogActions
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 20,
                }}
            >
                <ToiButton
                    onClick={props.onCloseMap}
                    style={{marginLeft: 10, marginRight: 10, width: 200}}
                >
                    Lukk
                </ToiButton>
            </DialogActions>
        </DialogWrapper>
    );
};

export default DisplayPosInMap;
