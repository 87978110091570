import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const ShareIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M15.5036 5.66428C16.0415 6.46961 16.9588 7 18 7C19.6569 7 21 5.65685 21 4C21 2.34315 19.6569 1 18 1C16.3431 1 15 2.34315 15 4C15 4.61569 15.1855 5.18805 15.5036 5.66428ZM15.5036 5.66428L8.49642 10.3357M8.49642 10.3357C7.95846 9.53039 7.04117 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15C7.04117 15 7.95846 14.4696 8.49642 13.6643M8.49642 10.3357C8.81453 10.8119 9 11.3843 9 12C9 12.6157 8.81453 13.1881 8.49642 13.6643M8.49642 13.6643L15.5036 18.3357M15.5036 18.3357C15.1855 18.8119 15 19.3843 15 20C15 21.6569 16.3431 23 18 23C19.6569 23 21 21.6569 21 20C21 18.3431 19.6569 17 18 17C16.9588 17 16.0415 17.5304 15.5036 18.3357Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='square'
    />
  </ToiIcon>
));

export default ShareIcon;
