import * as React from 'react';
import {connect} from 'react-redux';
import {Grid} from '@mui/material';
import {ToiTypography} from '@norkart/toi-components';
import MobileContainer from '../../../containers/MobileContainer';
import DesktopContainer from '../../../containers/DesktopContainer';
import ExpansionPanel from '../../../components/ExpansionPanel';
import DocumentList from '../../../components/DocumentList';
import {ImportDocumentDialogProps} from '../../dokumenter/ImportDocumentDialog/ImportDocumentDialog';
import {showDialog} from '../../../store/dialog/actions';
import HelpButton from '../../../components/HelpButton/HelpButton';
import {helpObject} from '../../../help/helpObject';
import HelpText from '../../../components/HelpText/HelpText';
import {ApplicationState} from '../../../store/index';
import {OwnProps as RearrangeDocumentsDialogProps} from '../../dokumenter/RearrangeDocumentDialog/RearrangeDocumentDialog';
import useArealplan from '../../../hooks/arealplaner/useArealplan';
import useArealplanDokumenter from '../../../hooks/dokumenter/useArealplanDokumenter';
import useCan from '../../../utils/auth/useCan';
import TableMenu from './TableMenu';
import PlanDocumentListDocuments from './PlanDocumentListDocuments';

export interface PlanDocumentListProps {}

export type DocumentTableType = 'GjeldendeBest' | 'Other';

const PlanDocumentList = ({
    ...props
}: PlanDocumentListProps & DispatchProps & StateProps) => {
    const can = useCan();
    const {data: plan} = useArealplan();
    const dokumenter = useArealplanDokumenter();

    const gjeldendeBest = dokumenter.data.filter(
        (b) => b.visIGjeldendeBestemmelser
    );
    const resten = dokumenter.data.filter((b) => !b.visIGjeldendeBestemmelser);
    const handleDocumentFromCatalog = () => {
        document.getElementById('file-upload-doc')!.click();
    };

    const handleDocumentFromArchive = () => {
        props.displayImportDialogFromArchive(plan);
    };

    const handleRearrangeDocuments = () => {
        props.displayRearrangeDocuments(plan, dokumenter.data);
    };

    const handleFilesSelected = (event) => {
        const files = Array.from(event.target.files) as File[];
        props.displayImportDialogFromCatalog(files, plan);
        // Trick input to fire onChange every time
        event.target.value = '';
    };

    return (
        <>
            <MobileContainer>
                <ExpansionPanel
                    title={`Plandokumenter (${dokumenter.data.length})`}
                    helpButtonText={
                        helpObject.Faner.Plandokumenter.MeromPlandokumenter
                    }
                >
                    {can.edit ? (
                        <>
                            <ExpansionPanel
                                title={`Gjeldende bestemmelser (${gjeldendeBest.length})`}
                                bgColor='white'
                                showBorderWhenOpen={true}
                            >
                                <DocumentList documents={gjeldendeBest} />
                            </ExpansionPanel>
                            <ExpansionPanel
                                title={`Andre dokumenter (${resten.length})`}
                                bgColor='white'
                                showBorderWhenOpen={true}
                            >
                                <DocumentList documents={resten} />
                            </ExpansionPanel>
                        </>
                    ) : (
                        <DocumentList documents={resten} />
                    )}
                </ExpansionPanel>
            </MobileContainer>
            <DesktopContainer>
                <Grid
                    container={true}
                    direction={'column'}
                    style={{display: 'flex', marginBottom: 40}}
                >
                    <HelpText
                        style={{marginRight: 50}}
                        helpText={helpObject.Faner.Plandokumenter.hovedtekst}
                    />
                    <HelpButton
                        helpText={
                            helpObject.Faner.Plandokumenter.MeromPlandokumenter
                        }
                        type='MeromPlandokumenter'
                        buttonVariant='tekst'
                    />
                </Grid>
                <TableMenu
                    plan={plan}
                    selectedDocuments={props.selectedDocuments}
                    handleDocumentFromCatalog={handleDocumentFromCatalog}
                    handleDocumentFromArchive={
                        plan.saker && plan.saker[0]
                            ? handleDocumentFromArchive
                            : undefined
                    }
                    handleRearrangeDocuments={handleRearrangeDocuments}
                />
                {gjeldendeBest.length > 0 && (
                    <>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <ToiTypography variant='h3' component='h2'>
                                Gjeldende bestemmelser
                            </ToiTypography>
                        </div>

                        <PlanDocumentListDocuments
                            dokumenter={gjeldendeBest}
                            tableType={'GjeldendeBest'}
                        />
                        <div style={{height: '20px'}} />
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <ToiTypography variant='h3' component='h2'>
                                Andre dokumenter
                            </ToiTypography>
                        </div>
                    </>
                )}
                <PlanDocumentListDocuments
                    dokumenter={resten}
                    tableType={'Other'}
                />
                <input
                    id='file-upload-doc'
                    type='file'
                    multiple={true}
                    style={{display: 'none'}}
                    onChange={handleFilesSelected}
                />
            </DesktopContainer>
        </>
    );
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch) => ({
    displayImportDialogFromCatalog: (files, plan) =>
        dispatch(
            showDialog({
                dialogType: 'IMPORT_DOCUMENTS',
                dialogProps: {
                    files,
                    plan,
                } as ImportDocumentDialogProps,
            })
        ),
    displayImportDialogFromArchive: (plan) =>
        dispatch(
            showDialog({
                dialogType: 'IMPORT_DOCUMENTS_FROM_ARCHIVE',
                dialogProps: {
                    plan,
                } as ImportDocumentDialogProps,
            })
        ),
    displayRearrangeDocuments: (plan, documents) =>
        dispatch(
            showDialog({
                dialogType: 'REARRANGE_DOCUMENTS',
                dialogProps: {plan, documents} as RearrangeDocumentsDialogProps,
            })
        ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => ({
    selectedDocuments: state.tableSelection.selectedDocuments,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanDocumentList);
