import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {ToiButton} from '@norkart/toi-components';
import useDeleteArealplan from '../../../hooks/arealplaner/useDeleteArealplan';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog, showDialog} from '../../../store/dialog/actions';

export interface DeletePlanDialogProps extends ConnectedReduxProps {
    open: boolean;
    plan: ArealplanDto;
}

const DeletePlanDialog = ({dispatch, open, plan}: DeletePlanDialogProps) => {
    const {mutate: deleteArealplan} = useDeleteArealplan();
    const handleClose = () => {
        hideDialog(dispatch).then(() =>
            dispatch(showDialog({dialogType: 'EDIT_PLAN', dialogProps: {plan}}))
        );
    };
    const handleOk = () => {
        hideDialog(dispatch).then(() => plan.id && deleteArealplan(plan.id));
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='delete-dialog-title'
        >
            <DialogTitle id='delete-dialog-title'>
                Vil du virkelig slette arealplanen?
            </DialogTitle>
            <DialogActions>
                <ToiButton onClick={handleClose}>Nei</ToiButton>
                <ToiButton onClick={handleOk}>Ja</ToiButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeletePlanDialog;
