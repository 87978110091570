import ReactMarkdown from 'react-markdown';
import {ToiBox, ToiLink, ToiTypography} from '@norkart/toi-components';

export const MarkdownContent = ({markdown}: {markdown: string}) => (
    <ToiBox
        sx={(theme) => ({
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body2,
        })}
    >
        <ReactMarkdown
            children={markdown}
            components={{
                h1({...props}) {
                    return (
                        <ToiTypography sx={{mb: 1}} variant='h3'>
                            {props.children}
                        </ToiTypography>
                    );
                },
                p({...props}) {
                    return (
                        <ToiTypography
                            variant='body2'
                            sx={{display: 'block', mb: 1}}
                        >
                            {props.children}
                        </ToiTypography>
                    );
                },
                a: ({href, children}) => {
                    return (
                        <ToiLink
                            sx={{fontSize: 16}}
                            href={href}
                            target='_blank'
                        >
                            {children}
                        </ToiLink>
                    );
                },
                // ul({...props}) {
                //     return <ToiList>{props.children}</ToiList>;
                // },
                // li({...props}) {
                //     return (
                //         <ToiListItem>
                //             <ToiListItemIcon>
                //                 <ArrowRightIcon size={'small'} />
                //             </ToiListItemIcon>
                //             <ToiListItemText>{props.children}</ToiListItemText>
                //         </ToiListItem>
                //     );
                // },
            }}
        />
    </ToiBox>
);
