import {Breakpoint, AppBarProps, SxProps, Theme, useMediaQuery, useTheme} from '@mui/material';
import {createContext, forwardRef, useContext, useState, CSSProperties} from 'react';
import React from 'react';
import clsx from 'clsx';
import {MenuIcon} from '@norkart/toi-icons';
import {ToiToolbar} from '../../ToiToolbar/ToiToolbar';
import {ToiStack} from '../../pureMuiComponents';
import {ToiIconButton} from '../../ToiIconButton';
import {ToiAppBarTitle} from '../ToiAppBarTitle';
import {StyledAppBar} from './StyledAppBar';
import {MenuDrawer} from './MenuDrawer';

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    neutral: true;
  }
}

type OwnProps = {
  /**
   *  @deprecated
   * No longer in use - avoid custom styling on the toolbar.
   */
  toolbarSx?: SxProps<Theme>;
  appMenu?: React.ReactNode;
  /**
   * @internal
   * This prop is for demo purposes use only.
   */
  window?: Window | null;
  title?: string | React.ReactNode;
  endComponent?: React.ReactNode;
  collapsePoint?: CollapsePoint;
  justifyContent?: CSSProperties['justifyContent'];
};

export type ToiAppBarProps = Omit<AppBarProps, keyof OwnProps | 'component'> & OwnProps;

export type CollapsePoint = Breakpoint | number;

export const APP_BAR_HEIGHT = 60;
export const APP_HEADER_HEIGHT_PX = `${APP_BAR_HEIGHT}px`;

const DEFAULT_COLLAPSE_POINT: Breakpoint = 'laptop';

const ToiAppBarCollapsePointContext = createContext<{
  collapsePoint: CollapsePoint;
  isCollapsed: boolean;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  collapsePoint: DEFAULT_COLLAPSE_POINT,
  isCollapsed: false,
  drawerOpen: false,
  setDrawerOpen: () => {},
});

const ToiAppBarChildrenContext = createContext<'appbar' | 'drawer'>('appbar');

export function useToiAppBarCollapse() {
  return useContext(ToiAppBarCollapsePointContext);
}

export function useToiAppBarChildrenEnvironment() {
  return useContext(ToiAppBarChildrenContext);
}

export const ToiAppBar = forwardRef<HTMLDivElement, ToiAppBarProps>((props, ref) => {
  const {
    children,
    appMenu,
    title,
    endComponent,
    collapsePoint = DEFAULT_COLLAPSE_POINT,
    justifyContent = 'space-between',
    ...restProps
  } = props;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const contentWindow = props.window || window;

  const shouldCollapse = useMediaQuery(useTheme().breakpoints.down(collapsePoint), {
    matchMedia: contentWindow?.matchMedia,
  });

  const showBurgerMenu = shouldCollapse && Boolean(children);

  return (
    <ToiAppBarCollapsePointContext.Provider
      value={{collapsePoint, isCollapsed: shouldCollapse, drawerOpen, setDrawerOpen}}
    >
      <StyledAppBar color='neutral' ref={ref} collapsePoint={collapsePoint} {...restProps}>
        <ToiAppBarChildrenContext.Provider value='appbar'>
          <ToiToolbar
            className={clsx(shouldCollapse && 'ToiToolbar-collapsed')}
            sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
          >
            {!shouldCollapse && appMenu}
            <ToiStack className='ToiToolbar-flexContainer' direction='row'>
              {/* App menu */}
              {showBurgerMenu && (
                <ToiIconButton
                  id='hamburger-menu'
                  onClick={toggleDrawer}
                  color='transparent'
                  aria-label='hamburger menu'
                >
                  <MenuIcon />
                </ToiIconButton>
              )}

              {/* Title/left side */}
              {typeof title === 'string' ? <ToiAppBarTitle title={title} /> : title}

              {/* Children (desktop appbar middle content/burger menu) */}
              <ToiStack direction='row' alignItems='center' justifyContent={justifyContent} flex={1}>
                {!shouldCollapse && children}
              </ToiStack>

              {/* EndComponent/right side */}
              {endComponent}
            </ToiStack>
          </ToiToolbar>
        </ToiAppBarChildrenContext.Provider>

        <ToiAppBarChildrenContext.Provider value='drawer'>
          {showBurgerMenu && (
            <MenuDrawer toggleDrawer={toggleDrawer} open={drawerOpen} window={contentWindow}>
              {appMenu}
              {children}
            </MenuDrawer>
          )}
        </ToiAppBarChildrenContext.Provider>
      </StyledAppBar>
    </ToiAppBarCollapsePointContext.Provider>
  );
});

export default ToiAppBar;
