import {forwardRef, ReactNode} from 'react';
import {styled} from '@mui/material';
import clsx from 'clsx';
import {ToiStack, ToiStackProps} from '../pureMuiComponents';
import {ToiDivider} from '../ToiDivider';
import {useToiAppBarChildrenEnvironment} from './ToiAppBar';

interface OwnProps {
  children?: ReactNode;
  grow?: boolean;
  noSpacing?: boolean;
}

const StyledToiStack = styled(ToiStack, {
  shouldForwardProp: (prop) => prop !== 'noSpacing',
})<OwnProps>(({theme, noSpacing, flexDirection}) => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    gap: noSpacing ? 0 : theme.toi.spacing.s,

    '& .MuiDivider-root': {
      width: '100%',
      marginBlock: theme.toi.spacing.xs,
    },

    '&.ToiAppBarGroup-drawer': {
      width: '100%',
      gap: 0,
      flexDirection: flexDirection ?? 'column',
    },
  };
});

type ToiAppBarGroupProps = Omit<ToiStackProps, keyof OwnProps> & OwnProps;

export const ToiAppBarGroup = forwardRef<HTMLDivElement, ToiAppBarGroupProps>((props, ref) => {
  const {children, grow, ...restProps} = props;

  const env = useToiAppBarChildrenEnvironment();

  return (
    <StyledToiStack
      ref={ref}
      className={clsx('ToiAppBarGroup-root', env && `ToiAppBarGroup-${env}`)}
      flexGrow={grow ? 1 : undefined}
      divider={env === 'drawer' ? <ToiDivider /> : undefined}
      {...restProps}
    >
      {children}
    </StyledToiStack>
  );
});
