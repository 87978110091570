import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const TextIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M14 19H12M10 19H12M12 19V5M12 5H6.5C5.67157 5 5 5.67157 5 6.5V7M12 5H17.5C18.3284 5 19 5.67157 19 6.5V7'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path d='M17 19H18' stroke='var(--toi-palette-icons-secondary, #2E2D30)' strokeWidth='1.5' strokeLinecap='round' />
  </ToiIcon>
));

export default TextIcon;
