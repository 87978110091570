import React, {useState} from 'react';
import {Box} from '@mui/material';
import {useDispatch} from 'react-redux';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import SearchMap from '../map/SearchMap';
import LoadingSpinner from '../../components/LoadingSpinner';
import DesktopContainer from '../../containers/DesktopContainer';
import {setUseAltFrontpage} from '../../store/session/actions';
import useMeta from '../../hooks/meta/useMeta';
import useTrackArealplanerEvent from '../../hooks/appinsights/useTrackArealplanerEvent';
import useSelectedArealplanId from '../../hooks/arealplaner/useSelectedArealplanId';
import MobileContainer from '../../containers/MobileContainer';
import {useNavigateToArealplan} from '../../hooks/useNavigateToArealplan';
import SidebarSearch from './SidebarSearch';
import SidebarPlan from './SidebarPlan';

function AlternativeHome() {
    const dispatch = useDispatch();
    const meta = useMeta();
    const selectedArealplanId = useSelectedArealplanId();
    const navigate = useNavigateToArealplan();

    const trackHome = useTrackArealplanerEvent('forside', 'button');
    const [displaySurroundingPlanWms, setDisplaySurroundingPlanWms] =
        useState(false);

    const handlechangeToList = () => {
        trackHome('byttTilListeSøk');
        dispatch(setUseAltFrontpage(false));
    };

    const handlePlanClick = (plan: ArealplanDto) => {
        navigate.toArealplanFullscreen(plan.id);
    };

    if (meta.isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <DesktopContainer>
                <Box
                    sx={{
                        position: 'fixed',
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    {selectedArealplanId ? (
                        <SidebarPlan
                            setDisplaySurroundingPlanWms={
                                setDisplaySurroundingPlanWms
                            }
                            displaySurroundingPlanWms={
                                displaySurroundingPlanWms
                            }
                        />
                    ) : (
                        <SidebarSearch
                            handlePlanClick={handlePlanClick}
                            handlechangeToList={handlechangeToList}
                        />
                    )}

                    <SearchMap
                        displaySurroundingPlanWms={displaySurroundingPlanWms}
                    />
                </Box>
            </DesktopContainer>
            <MobileContainer>
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                    }}
                >
                    {selectedArealplanId ? (
                        <>
                            <Box sx={{height: '400px'}}>
                                <SearchMap
                                    displaySurroundingPlanWms={
                                        displaySurroundingPlanWms
                                    }
                                />
                            </Box>

                            <SidebarPlan
                                setDisplaySurroundingPlanWms={
                                    setDisplaySurroundingPlanWms
                                }
                                displaySurroundingPlanWms={
                                    displaySurroundingPlanWms
                                }
                            />
                        </>
                    ) : (
                        <SidebarSearch
                            handlePlanClick={handlePlanClick}
                            handlechangeToList={handlechangeToList}
                        />
                    )}
                </Box>
            </MobileContainer>
        </>
    );
}

export default AlternativeHome;
