import {AccordionDetails, styled} from '@mui/material';

export const ToiAccordionDetails = styled(AccordionDetails)(() => ({
  '&:has(> .MuiList-root)': {
    padding: '0 !important',
  },

  '& > .MuiList-root': {
    width: '100%',
    borderRadius: 0,
    padding: 0,
  },
}));

export default ToiAccordionDetails;
