import {Toolbar, Typography} from '@mui/material';
import qs from 'query-string';
import {useDispatch} from 'react-redux';
import {Link, Route, Routes, useNavigate} from 'react-router-dom';
import {SearchIcon} from '@norkart/toi-icons';
import {
    ToiBox,
    ToiDrawer,
    ToiLink,
    ToiLinkListGroup,
    ToiLinkListItem,
    ToiStack,
} from '@norkart/toi-components';
import MainContent from '../../../components/DrawerComponents/MainComponent';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import useMeta from '../../../hooks/meta/useMeta';
import useSearch from '../../../hooks/search/useSearch';
import {resetMapState} from '../../../store/map/actions';
import useCan from '../../../utils/auth/useCan';
import {setTitle} from '../../../utils/setTitle';
import useReportTypes from './useReportTypes';

const drawerWidth = 240;

function Reports() {
    const can = useCan();
    const kundeId = useSelectedKundeId();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reportTypes = useReportTypes();
    const search = useSearch();
    setTitle('Rapporter');
    const meta = useMeta();

    const handleBackToSearch = () => {
        dispatch(resetMapState());
        navigate({
            pathname: `/${kundeId}/arealplaner/search`,
            search: qs.stringify({...search.searchState.urlSearchParams}),
        });
    };

    const activeRoute = (linkPath: string) => {
        return window.location.pathname === linkPath;
    };

    if (can.view && reportTypes.filter((rt) => rt.tilgang).length > 0)
        return (
            <ToiBox
                sx={{
                    display: 'flex',
                }}
            >
                <ToiDrawer
                    variant='permanent'
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiPaper-root': {
                            width: drawerWidth,
                        },
                    }}
                >
                    <Toolbar />
                    <ToiStack gap={2} p={1}>
                        <ToiLink
                            component='button'
                            onClick={handleBackToSearch}
                            startIcon={<SearchIcon size='small' />}
                        >
                            Til plansøk
                        </ToiLink>
                        <ToiLinkListGroup>
                            {reportTypes
                                .filter((rt) => rt.tilgang)
                                .map((report) => (
                                    <ToiLinkListItem
                                        key={report.Navn}
                                        to={report.to}
                                        component={Link}
                                        active={activeRoute(report.to)}
                                        sx={{
                                            '&.Mui-selected': {fontWeight: 500},
                                        }}
                                    >
                                        {report.Navn}
                                    </ToiLinkListItem>
                                ))}
                        </ToiLinkListGroup>
                    </ToiStack>
                </ToiDrawer>

                <MainContent>
                    <Routes>
                        {reportTypes
                            .filter((rt) => rt.tilgang)
                            .map((reporttype) => (
                                <Route
                                    key={reporttype.Id}
                                    path={reporttype.path}
                                    element={<reporttype.component />}
                                />
                            ))}
                    </Routes>
                </MainContent>
            </ToiBox>
        );

    return (
        <Typography>
            Du mangler rettigheter til å se denne siden/har ingen rapporter å
            vise.
        </Typography>
    );
}

export default Reports;
