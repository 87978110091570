import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const ThumbsUpIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M1 9.5C1 8.67157 1.67157 8 2.5 8H5V20H2.5C1.67157 20 1 19.3284 1 18.5V9.5Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5421 1C14.198 1 13.8711 1.15035 13.6472 1.41158L8 8V20H18.9605C19.5858 20 20.1454 19.6121 20.365 19.0267L22.9045 12.2547C22.9676 12.0863 23 11.9079 23 11.728V9.5C23 8.67157 22.3284 8 21.5 8H16.3555C15.4005 8 14.6887 7.11947 14.8888 6.18571L15.6945 2.42556C15.8517 1.69186 15.2924 1 14.5421 1V1Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default ThumbsUpIcon;
