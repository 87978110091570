import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const FullscreenIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M4 9V5.5C4 4.67157 4.67157 4 5.5 4H9M4 15V18.5C4 19.3284 4.67157 20 5.5 20H9M15 4L18.5 4C19.3284 4 20 4.67157 20 5.5V9M15 20H18.5C19.3284 20 20 19.3284 20 18.5V15'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </ToiIcon>
));

export default FullscreenIcon;
