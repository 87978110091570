import {InputBase, styled} from '@mui/material';

export const ToiBaseInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'focused',
})<{variant?: 'map' | 'outlined'}>(({theme, variant}) => {
  const colors = theme.vars.toi.palette;
  return {
    ...theme.typography.body2,
    paddingInline: theme.toi.spacing.xs,
    paddingBlock: '13px',
    backgroundColor: colors.backgrounds.component.fill,
    border: 'none',
    outline: variant !== 'map' ? `1px solid ${colors.border.input}` : undefined,
    borderRadius: 4,
    boxSizing: 'content-box',
    marginInline: 2, // Needed to give room for outline

    '& .MuiInputBase-input:not(.MuiInputBase-inputMultiline) ': {
      maxHeight: '22px',
    },

    '& .MuiInputBase-input': {
      height: 'auto',
      padding: 0,
      overflow: 'visible',

      '&:focus': {
        background: 'none',
      },

      '::placeholder': {
        opacity: 1,
        color: colors.text.placeholder,
      },

      '&[type=number]': {
        MozAppearance: 'textfield',
      },
      '::-webkit-outer-spin-button,::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },

    '& svg': {
      top: 'auto',
      bottom: 'auto',
      right: theme.toi.spacing.xs,
    },

    '&.MuiInputBase-sizeSmall': {
      ...theme.typography.body3,
      padding: 10,

      '& svg': {
        width: 20,
        height: 20,
      },
    },

    '&:hover': {
      outlineColor: colors.border.inputHover,
      outlineWidth: 2,
    },

    '&.Mui-focused': {
      outlineColor: colors.border.active,
      outlineWidth: 2,
    },

    '&.Mui-error': {
      outlineColor: colors.border.error,
      '&:hover': {
        outlineColor: colors.border.errorHover,
        outlineWidth: 2,
      },
    },

    '&.Mui-disabled': {
      backgroundColor: colors.disabled.fill,
      border: 'none',
      outline: 'none',
      '& .MuiInputBase-input': {
        color: colors.disabled.text,
        WebkitTextFillColor: colors.disabled.text,
      },
      '--toi-palette-icons-secondary': colors.disabled.text,
    },

    ...(variant === 'map' && {
      boxShadow: theme.toi.shadow[4],
    }),
  };
});
