import {CSSProperties, FC, forwardRef, SVGProps} from 'react';
import {styled} from '@mui/material';
import {ToiDialog, ToiDialogProps} from './ToiDialog';
import {ToiDialogContent} from './ToiDialogContent';
import {ToiDialogActions} from './ToiDialogActions';
import {DefaultActions} from './internal/DefaultActions';
import {DialogHeader} from './internal/DialogHeader';
import {CommonDialogProps} from './internal/types';

export type ToiDialogSimpleProps = ToiDialogProps &
  CommonDialogProps & {
    Illustration?: FC<SVGProps<SVGSVGElement>>;
    width?: CSSProperties['width'];
  };

export const ToiDialogSimple = forwardRef<HTMLDivElement, ToiDialogSimpleProps>((props, ref) => {
  const {title, description, children, onConfirm, confirmText, cancelText, actions, Illustration, ...restProps} = props;

  return (
    <StyledDialogSimple ref={ref} {...restProps}>
      <DialogHeader title={title} description={description} />
      <ToiDialogContent>{children}</ToiDialogContent>
      <ToiDialogActions>
        {actions ?? (
          <DefaultActions
            confirmText={confirmText}
            cancelText={cancelText}
            onConfirm={onConfirm}
            onClose={props.onClose}
          />
        )}
      </ToiDialogActions>
      {Illustration && <Illustration className='ToiDialog-illustration' />}
    </StyledDialogSimple>
  );
});

const StyledDialogSimple = styled(ToiDialog)<{width?: CSSProperties['width']}>(({theme, width}) => ({
  '& .MuiDialog-paper': {
    width: width ?? '500px',
    ':has(> .ToiDialog-illustration)': {
      width: width ?? '700px',
      minHeight: '200px',
      paddingRight: `calc(200px + ${theme.toi.spacing.l})`,
      '& .MuiDialogContent-root': {
        marginBlock: theme.toi.spacing.s,
      },
    },
    '& .ToiDialog-illustration': {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: 200,
      height: 200,
      pointerEvents: 'none', // Prevents the illustration from blocking clicks to close the dialog
    },
  },
  '& .MuiDialogContent-root': {
    padding: 0,
    paddingTop: theme.toi.spacing.s,
    marginBlock: theme.toi.spacing.m,
    ':empty': {
      marginTop: 0,
    },
  },
}));
