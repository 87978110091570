import React from 'react';
import {ToiStack} from '@norkart/toi-components';

export default function DetailPanelContent({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <ToiStack
            spacing={2}
            sx={{
                paddingLeft: '80px',
                paddingRight: '16px',
                marginTop: '8px',
                marginBottom: '8px',
            }}
        >
            {children}
        </ToiStack>
    );
}
