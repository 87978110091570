import {
    useLoginPopupAzureAD,
    useLoginPopupNorkartId,
    useLoginRedirectAzureAD,
    useLoginRedirectNorkartId,
} from '@norkart/nk-auth';
import {ToiButton, ToiButtonProps} from '@norkart/toi-components';
import {AuthenticationMethod, LoginOrOutInteractionProcess} from './types';

export type LogInButtonProps = ToiButtonProps & {
    loginInteractionProcess?: LoginOrOutInteractionProcess;
    authenticationMethod: AuthenticationMethod;
};

export const useGetLogin = (
    idp: AuthenticationMethod,
    loginType = LoginOrOutInteractionProcess.REDIRECT
) => {
    const norkartIDRedirectLogin = useLoginRedirectNorkartId();
    const norkartIDPopupLogin = useLoginPopupNorkartId();
    const entraIDRedirectLogin = useLoginRedirectAzureAD();
    const entraIdPopupLogin = useLoginPopupAzureAD();

    if (idp === AuthenticationMethod.NORKARTID) {
        return loginType === LoginOrOutInteractionProcess.POPUP
            ? norkartIDPopupLogin
            : norkartIDRedirectLogin;
    } else {
        return loginType === LoginOrOutInteractionProcess.POPUP
            ? entraIdPopupLogin
            : entraIDRedirectLogin;
    }
};

/**
 * ToiButton that logs in using the selected provider (NorkartID or EntraID). Must be used within a NkNorkartIdAuthProvideror NkAzureADAuthProvider.
 */
export function LoginButton({
    authenticationMethod,
    loginInteractionProcess,
    ...buttonProps
}: LogInButtonProps) {
    const login = useGetLogin(authenticationMethod, loginInteractionProcess);

    return (
        <ToiButton {...buttonProps} onClick={login}>
            {buttonProps.children ?? 'Logg inn'}
        </ToiButton>
    );
}
