import * as React from 'react';
import {
    ToiBox,
    ToiDialog,
    ToiDialogContent,
    ToiDialogTitle,
    ToiIconButton,
} from '@norkart/toi-components';
import {
    CloseIcon,
    FullscreenExitIcon,
    FullscreenIcon,
} from '@norkart/toi-icons';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import useCreateArealplan from '../../../hooks/arealplaner/useCreateArealplan';
import useUpdateArealplan from '../../../hooks/arealplaner/useUpdateArealplan';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog, showDialog} from '../../../store/dialog/actions';
import PlanForm from '../PlanForm';

export interface PlanFormDialogProps extends ConnectedReduxProps {
    plan: ArealplanDto;
    open: boolean;
}

const PlanFormDialog = ({open, plan, dispatch}: PlanFormDialogProps) => {
    const {mutateAsync: createArealplan} = useCreateArealplan();
    const {mutateAsync: updateArealplan} = useUpdateArealplan();
    const [fullscreen, setFullscreen] = React.useState(false);
    const onClose = () => {
        hideDialog(dispatch);
    };
    const onDelete = () =>
        hideDialog(dispatch).then(() =>
            dispatch(
                showDialog({
                    dialogType: 'DELETE_PLAN',
                    dialogProps: {plan},
                })
            )
        );

    const onSubmit = async (plan) => {
        if (plan.id) {
            return updateArealplan({
                arealplanForUpdate: plan,
                arealplanId: plan.id,
            });
        } else {
            return createArealplan(plan);
        }
    };

    return (
        <ToiDialog
            open={open}
            aria-labelledby='plan-form-dialog-title'
            fullScreen={fullscreen}
            fullWidth={true}
            disableScrollLock={true}
            sx={{
                '& .MuiDialog-paper': {
                    maxWidth: fullscreen ? '100vw' : '900px',
                },
            }}
        >
            <ToiIconButton
                aria-label={'Lukk'}
                onClick={onClose}
                color='transparent'
                sx={{position: 'absolute', top: 8, right: 8}}
            >
                <CloseIcon size='small' />
            </ToiIconButton>
            <ToiBox sx={{position: 'absolute', top: 8, right: 48}}>
                {fullscreen ? (
                    <ToiIconButton
                        aria-label='Lukk fullskjermvisning'
                        color='transparent'
                        onClick={() => setFullscreen(false)}
                    >
                        <FullscreenExitIcon size='small' />
                    </ToiIconButton>
                ) : (
                    <ToiIconButton
                        aria-label='Åpne fullskjermvisning'
                        color='transparent'
                        onClick={() => setFullscreen(true)}
                    >
                        <FullscreenIcon size='small' />
                    </ToiIconButton>
                )}
            </ToiBox>
            <ToiDialogTitle id='plan-form-dialog-title'>
                {plan.id ? 'Redigere arealplan' : 'Registrere ny plan'}
            </ToiDialogTitle>
            <ToiDialogContent>
                <PlanForm
                    plan={plan}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    onClose={onClose}
                    onDelete={onDelete}
                />
            </ToiDialogContent>
        </ToiDialog>
    );
};

export default PlanFormDialog;
