import {Grid} from '@mui/material';
import {FormApi} from 'final-form';
import * as React from 'react';
import {FileRejection, useDropzone} from 'react-dropzone';
import {Form} from 'react-final-form';
import {DeleteRecycleIcon, ExternalIcon, PlusIcon} from '@norkart/toi-icons';
import {
    ToiBox,
    ToiButton,
    ToiCheckboxOption,
    ToiIconButton,
    ToiLink,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import FormInputField from '../../../components/FormInputField';
import FormTextareaField from '../../../components/FormTextareaField';
import {openSnackbarMessage} from '../../../components/SnackbarMessage';
import SpinnerButton from '../../../components/SpinnerButton';
import useTrackArealplanerEvent from '../../../hooks/appinsights/useTrackArealplanerEvent';
import useArealplan from '../../../hooks/arealplaner/useArealplan';
import useCreateTilbakemelding from '../../../hooks/tilbakemeldinger/useCreateTilbakemelding';
import PlanFeedbackMap from '../../map/PlanFeedbackMap';

function PlanFeedbackForm() {
    const tilbakemelding = useCreateTilbakemelding();
    const {data: plan} = useArealplan();
    const trackPlanFeedback = useTrackArealplanerEvent('planFeedback', 'event');

    const [hasApprovedTerms, setHasApprovedTerms] =
        React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [files, setFiles] = React.useState<File[]>([]);
    const [rejectedFiles, setRejectedFiles] = React.useState<FileRejection[]>(
        []
    );
    const [showFeedbackMap, setShowFeedbackMap] =
        React.useState<boolean>(false);
    const [drawnFeedback, setDrawnFeedback] = React.useState<any>(null);
    const [imageDataURI, setImageDataURI] = React.useState<string>('');
    const acceptedFileTypes = {
        'image/*': ['.png', '.jpeg', '.jpg'],
        'text/*': ['.html', '.htm', '.txt'],
        'application/*': ['.docx', '.rtf', '.pdf'],
    };
    const acceptedFileSize = 4 * 10 ** 6; //Bytes

    const onSubmit = async (values, form: FormApi<any>) => {
        if (!submitted) {
            setSubmitted(true);
        }
        const valid = validate(values);

        if (Object.keys(valid).length !== 0) {
            return;
        }
        const arealplanId = plan.id;
        const nasjonalArealplanId = plan.komnr + '_' + plan.planId;
        const arkivSaksNummer =
            plan.saker && plan.saker[0].sakAar + '/' + plan.saker[0].sakSeknr;

        const formData = new FormData();
        formData.append('navn', values.Navn);
        formData.append('adresse', values.Adresse);
        formData.append('poststed', values.Poststed);
        formData.append('epost', values.Epost);
        formData.append('beskrivelse', values.Beskrivelse);
        formData.append('arealplanId', arealplanId?.toString() ?? '');
        formData.append('nasjonalArealplanId', nasjonalArealplanId);
        formData.append('arkivSaksNummer', arkivSaksNummer ?? '');
        formData.append('imageDataURI', imageDataURI);
        files.forEach((file) => {
            formData.append('file', file);
        });

        trackPlanFeedback('feedBackSent');

        const onSuccess = (res) => {
            if (!res.data.ResponseStatus) {
                openSnackbarMessage({
                    message: 'Tilbakemelding sendt inn',
                    variant: 'success',
                    verticalAnchor: 'bottom',
                });
                initialValues = {};
                clearForm(form);
            }
        };

        const onError = () => {
            openSnackbarMessage({
                message: 'Noe gikk galt, prøv igjen senere',
                variant: 'error',
                verticalAnchor: 'top',
            });
        };

        return tilbakemelding.mutateAsync(formData, {
            onSuccess,
            onError,
        });
    };

    React.useEffect(() => {
        setSubmitted(false);
    }, []);

    const onDrop = React.useCallback(
        (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            setFiles(files.concat(acceptedFiles));
            setRejectedFiles(rejectedFiles);
        },
        [files]
    );

    let initialValues = {};

    const validate = (values: any) => {
        const errors = {} as any;
        if (!values.Navn) {
            errors.Navn = 'Navn er påkrevd';
        } else if (values.Navn.length > 100) {
            errors.Navn = 'Navn kan maks ha 100 tegn';
        }

        if (!values.Adresse) {
            errors.Adresse = 'Adresse er påkrevd';
        } else if (values.Adresse.length > 100) {
            errors.Adresse = 'Adresse kan maks ha 100 tegn';
        }

        if (!values.Poststed) {
            errors.Poststed = 'Postnummer og sted er påkrevd';
        } else if (values.Adresse.length > 100) {
            errors.Poststed = 'Postnummer og sted kan maks ha 100 tegn';
        }

        const expression =
            /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        if (!values.Epost) {
            errors.Epost = 'E-postadresse er påkrevd';
        } else if (values.Epost.length > 100) {
            errors.Epost = 'Epost kan maks ha 100 tegn';
        } else if (!expression.test(String(values.Epost).toLowerCase())) {
            errors.Epost = 'Epost er ikke gyldig';
        }

        if (!values.Beskrivelse) {
            errors.Beskrivelse = 'Tilbakemelding er påkrevd';
        } else if (values.Beskrivelse.length > 1000) {
            errors.Beskrivelse = 'Beskrivelse kan maks ha 1000 tegn';
        }

        if (!hasApprovedTerms) {
            errors.IsApproved = 'Du må godta vilkår';
        }
        return errors;
    };

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFileDialogActive,
        rootRef,
        open,
    } = useDropzone({
        accept: acceptedFileTypes,
        onDrop: onDrop,
        maxSize: acceptedFileSize,
        maxFiles: 0,
    });

    const renderFormField = (label: string, name: string) => {
        return (
            <Grid item={true} xs={12}>
                <FormInputField
                    name={name}
                    required={true}
                    label={label}
                    submitted={submitted}
                />
            </Grid>
        );
    };

    const renderFormTextarea = (label: string, name: string) => {
        return (
            <Grid item={true} mt={'8px'} xs={12}>
                <label>
                    <ToiTypography>{label}</ToiTypography>
                    <FormTextareaField name={name} rows={6} />
                </label>
            </Grid>
        );
    };

    const clearForm = (form) => {
        form.reset();
        setHasApprovedTerms(false);
        setSubmitted(false);
        setFiles([]);
        setDrawnFeedback(null);
        setImageDataURI('');
    };

    const onCloseMap = (dataToPersist: any) => {
        if (dataToPersist) {
            const map = (window as any).mmap.current;
            map.once('idle', () => {
                setDrawnFeedback(dataToPersist);
                if (dataToPersist?.features.length !== 0) {
                    const reader = new FileReader();
                    reader.onload = function (e) {
                        setImageDataURI(e.target?.result as string);
                    };

                    map.getCanvas().toBlob((blob) => {
                        reader.readAsDataURL(blob);
                    });
                } else {
                    setImageDataURI('');
                }
                setShowFeedbackMap(false);
            });
            map.triggerRepaint();
        } else {
            setShowFeedbackMap(false);
        }
    };

    const handleAttachFileClick = (e) => {
        e.preventDefault();
        open();
    };

    return (
        <>
            {showFeedbackMap ? (
                <PlanFeedbackMap
                    onCloseMap={onCloseMap}
                    featureCollection={drawnFeedback}
                />
            ) : null}
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={(values: any) => {
                    const errors = {} as any;
                    if (submitted) {
                        return validate(values);
                    }
                    return errors;
                }}
                render={({
                    handleSubmit,
                    values,
                    submitting,
                    pristine,
                    errors,
                    visited,
                    submitErrors,
                    form,
                }) => (
                    <ToiBox
                        sx={(theme) => ({
                            form: {
                                padding: '10px',
                                '& .importantFormLabel': {fontWeight: 600},
                                '& .formInput': {
                                    width: '100%',
                                    padding: '5px',
                                    fontFamily: theme.typography.fontFamily,
                                },
                                '& .formInput:disabled': {
                                    backgroundColor: '#f0f0f0',
                                },
                                '& .formInputError': {border: '1px solid red'},
                                '& .textarea': {
                                    fontSize: 'inherit',
                                    borderWidth: 2,
                                },
                                '& .nka-text-input-wrapper': {
                                    '& input': {
                                        backgroundColor: 'unset',
                                    },
                                    '& .text-input-icons': {
                                        display: 'none',
                                    },
                                },
                            },
                        })}
                    >
                        <form onSubmit={handleSubmit}>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={12} sm={10} md={6} lg={4}>
                                    {renderFormField('Navn', 'Navn')}
                                    {renderFormField('Adresse', 'Adresse')}
                                    {renderFormField(
                                        'Postnummer og sted',
                                        'Poststed'
                                    )}
                                    {renderFormField('E-postadresse', 'Epost')}
                                    {renderFormTextarea(
                                        'Alle innspill er offentlige og vil bli arkivert på saken. Ikke send sensitive personopplysninger, for eksempel personnummer og helseopplysninger, via denne tjenesten.',
                                        'Beskrivelse'
                                    )}
                                    <Grid item={true} xs={12}>
                                        <>
                                            <button
                                                type='button'
                                                className={`dropzone files`}
                                                {...getRootProps()}
                                                onClick={handleAttachFileClick}
                                            >
                                                <div>
                                                    <PlusIcon size={2} />
                                                </div>
                                                <input
                                                    id='files'
                                                    {...getInputProps()}
                                                />
                                                <label
                                                    htmlFor='files'
                                                    style={{fontSize: 16}}
                                                >
                                                    Dra filer hit, eller trykk
                                                    for å velge filer
                                                </label>
                                                <ToiTypography
                                                    style={{
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    (Kun{' '}
                                                    {Object.values(
                                                        acceptedFileTypes
                                                    )
                                                        .flat()
                                                        .join(',')}{' '}
                                                    på maks{' '}
                                                    {acceptedFileSize / 10 ** 6}
                                                    MB er tillatt)
                                                </ToiTypography>
                                            </button>
                                            {files.length > 0 && (
                                                <ToiTypography
                                                    style={{
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    Valgte filer:
                                                </ToiTypography>
                                            )}
                                            {files.map((file) => (
                                                <ToiTypography
                                                    key={file.name}
                                                    style={{marginLeft: '10px'}}
                                                >
                                                    {file.name}
                                                    <ToiIconButton
                                                        color='transparent'
                                                        aria-label='Delete file'
                                                        style={{padding: 0}}
                                                        onClick={(e) =>
                                                            setFiles(
                                                                files.filter(
                                                                    (f) =>
                                                                        f !=
                                                                        file
                                                                )
                                                            )
                                                        }
                                                    >
                                                        &nbsp;
                                                        <DeleteRecycleIcon fontSize='small' />
                                                    </ToiIconButton>
                                                </ToiTypography>
                                            ))}
                                            {rejectedFiles &&
                                                rejectedFiles.length > 0 && (
                                                    <div
                                                        style={{
                                                            marginBottom:
                                                                '20px',
                                                        }}
                                                    >
                                                        <ToiTypography
                                                            style={{
                                                                color: 'red',
                                                                marginBottom:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Kan ikke laste opp
                                                            følgende filer
                                                            grunnet ugyldig
                                                            type, størrelse
                                                            eller antall:
                                                        </ToiTypography>
                                                        <ul
                                                            style={{
                                                                marginLeft:
                                                                    '20px',
                                                            }}
                                                        >
                                                            {rejectedFiles.map(
                                                                (
                                                                    rejectedFile,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                        className='normalParagraph'
                                                                    >
                                                                        {
                                                                            rejectedFile
                                                                                .file
                                                                                .name
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                        </>
                                        <ToiStack gap={2} mt={'8px'}>
                                            <ToiButton
                                                type='button'
                                                variant='secondary'
                                                onClick={() =>
                                                    setShowFeedbackMap(true)
                                                }
                                            >
                                                Tegn i kart
                                            </ToiButton>
                                            {imageDataURI ? (
                                                <>
                                                    <ToiButton
                                                        type='button'
                                                        variant='secondary'
                                                        onClick={() => {
                                                            setImageDataURI('');
                                                            setDrawnFeedback(
                                                                null
                                                            );
                                                        }}
                                                    >
                                                        Slett påtegninger
                                                    </ToiButton>
                                                    <img
                                                        alt='Påtegnet kart'
                                                        className='paategninger-preview'
                                                        src={imageDataURI}
                                                    />
                                                </>
                                            ) : null}
                                        </ToiStack>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <ToiCheckboxOption
                                label='Jeg har lest og samtykker til'
                                name='isApproved'
                                onChange={(e, checked) =>
                                    setHasApprovedTerms(!hasApprovedTerms)
                                }
                                checked={hasApprovedTerms}
                                helpText={
                                    <ToiLink
                                        href='https://www.norkart.no/personvernerklaering/'
                                        rel='noreferrer'
                                        target='_blank'
                                        endIcon={<ExternalIcon size='small' />}
                                    >
                                        vilkår for bruk
                                    </ToiLink>
                                }
                            />

                            <br />
                            {!hasApprovedTerms && submitted && (
                                <span style={{color: 'red'}}>
                                    Du må godta vilkår
                                </span>
                            )}

                            <Grid style={{marginTop: '20px'}} container={true}>
                                <Grid item={true}>
                                    <SpinnerButton
                                        type='submit'
                                        label='Send inn'
                                        disabled={submitting}
                                        loading={submitting}
                                    />
                                </Grid>

                                <Grid item={true} style={{marginLeft: '10px'}}>
                                    <ToiButton
                                        variant='secondary'
                                        onClick={() => clearForm(form)}
                                    >
                                        Nullstill
                                    </ToiButton>
                                </Grid>
                            </Grid>
                            <Grid
                                container={true}
                                xs={12}
                                sm={10}
                                md={6}
                                lg={4}
                                spacing={2}
                                mt={'8px'}
                            >
                                <Grid item={true} xs={12}>
                                    <ToiTypography>
                                        Du vil ikke motta eget svarbrev på
                                        merknaden din. Merknaden vil bli vurdert
                                        og kommentert som del av
                                        saksbehandlingen, og følger saken til
                                        politisk behandling.
                                    </ToiTypography>
                                </Grid>
                            </Grid>

                            {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre>
			  <pre>Pristine: {JSON.stringify(pristine, null, 2)}</pre>
			  <pre>Submitting: {JSON.stringify(submitting, null, 2)}</pre>
			  <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
			  <pre>SubmitErrors: {JSON.stringify(submitErrors, null, 2)}</pre>
			  <pre>tilbakemeldingerData: {JSON.stringify(tilbakemeldingerData, null, 2)}</pre> */}
                        </form>
                    </ToiBox>
                )}
            />
        </>
    );
}

export default PlanFeedbackForm;
