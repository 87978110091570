import * as React from 'react';
import {ToiButton} from '@norkart/toi-components';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import useMeta from '../../../../hooks/meta/useMeta';
import {setTitle} from '../../../../utils/setTitle';
import ReportPage from '../ReportPage';
import {PlanforholdFilter} from './PlanforholdFilter';
import PlanforholdReport from './PlanforholdReport';

export type PlanforholdQueryVariables = {
    first?: number;
    after?: string;
    filter?: {and: any[]} | undefined;
    sort?: any[] | undefined;
};

export default function Planforhold() {
    setTitle('Rapporter > Planforhold');
    const initialVariables: PlanforholdQueryVariables = {first: 60};
    const meta = useMeta();
    const [filterValues, setFilterValues] = React.useState<any | undefined>(
        undefined
    );
    const [vars, setVars] =
        React.useState<PlanforholdQueryVariables>(initialVariables);

    React.useMemo(() => {
        const newVars: PlanforholdQueryVariables = {
            ...vars,
            filter: filterValues,
        };
        setVars((prevValue) => {
            if (newVars === initialVariables) return prevValue;
            else if (!newVars.filter && !newVars.sort) return initialVariables;
            else return newVars;
        });
    }, [filterValues]);

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <ReportPage title='Planforhold'>
            <PlanforholdFilter
                planforholdtyper={meta.data.planforholdtyper}
                setFilterValues={setFilterValues}
                clearFilter={filterValues ? false : true}
            />

            <ToiButton
                variant='secondary'
                onClick={() => setFilterValues(undefined)}
                sx={{
                    display: filterValues ? 'flex' : 'none',
                    marginTop: '10px',
                }}
            >
                Nullstill filter
            </ToiButton>
            <PlanforholdReport
                setVars={setVars}
                kundeId={meta.kundeId}
                kommunenummer={meta.komnr}
                variables={vars}
            />
        </ReportPage>
    );
}
