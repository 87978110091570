import {forwardRef} from 'react';
import {TypographyTypeMap} from '@mui/material';
import {ToiTypography, ToiTypographyProps} from '../ToiTypography';

type RefType = HTMLElementTagNameMap[TypographyTypeMap['defaultComponent']];

export const ToiDialogTitle = forwardRef<RefType, ToiTypographyProps>((props, ref) => {
  const {variant = 'h3', component = 'h2', ...restProps} = props;
  return <ToiTypography ref={ref} variant={variant} component={component} sx={{mb: 0}} {...restProps} />;
});
