import * as React from 'react';
import {ToiAccordion, ToiStack, ToiTypography} from '@norkart/toi-components';
import InfoIconWrapper from '../PlanInfo/Components/InfoIconWrapper';

export interface PlanNotificationProps {
    title: string;
    description?: string | React.ReactElement;
}

const PlanNotification: React.FC<PlanNotificationProps> = ({
    title,
    description,
}: PlanNotificationProps) => {
    return (
        <ToiStack direction='row' justifyContent={'space-between'}>
            <ToiAccordion
                status='warning'
                summaryContent={
                    <ToiTypography align='left'>{title}</ToiTypography>
                }
                sx={(theme) => ({
                    flexGrow: 1,
                    width: 'calc(100% - 60px)',
                    border: `1px solid ${theme.vars.toi.palette.border.component} !important`,
                })}
            >
                {typeof description === 'string' ? (
                    <ToiTypography>{description}</ToiTypography>
                ) : (
                    description
                )}
            </ToiAccordion>
            <InfoIconWrapper />
        </ToiStack>
    );
};

export default PlanNotification;
