import {Tooltip, styled, TooltipProps} from '@mui/material';
import React, {forwardRef} from 'react';

export type ToiTooltipProps = TooltipProps;

export const ToiTooltip = styled(
  forwardRef<HTMLDivElement, ToiTooltipProps>(({className, title, ...props}, ref) => (
    <Tooltip
      {...props}
      title={title}
      aria-label={undefined}
      aria-description={title?.toString()}
      classes={{popper: className}}
      ref={ref}
    />
  )),
)(({theme}) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.vars.toi.palette.backgrounds.component.overlay,
    color: theme.vars.toi.palette.text.default,
    boxShadow: theme.shadows[2],
    borderRadius: theme.toi.radius.borderRadius,
    ...theme.typography.body2,
  },

  '& .MuiTooltip-arrow': {
    '&::before': {
      backgroundColor: theme.vars.toi.palette.backgrounds.component.overlay,
      boxShadow: theme.shadows[2],
    },
  },
}));

/**
 * @deprecated Renamed to ToiTooltip
 */
export {ToiTooltip as ToiToolTip};
