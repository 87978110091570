import React from 'react';
import {Box, Grid} from '@mui/material';
import {ToiTypography} from '@norkart/toi-components';
import MapsImage from '../../assets/images/MAPS.png';
import DesktopContainer from '../../containers/DesktopContainer';
import MobileContainer from '../../containers/MobileContainer';

const Header = () => {
    const headerText =
        'Et planregister som gir innsyn i hvordan arealene i kommunen kan benyttes';
    return (
        <>
            <DesktopContainer>
                <Box
                    sx={{
                        width: '100%',
                        height: 220,
                        marginBottom: '40px',
                    }}
                >
                    <Grid
                        style={{height: '100%'}}
                        container={true}
                        spacing={0}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Grid
                            item={true}
                            xs={12}
                            md={6}
                            lg={4}
                            xl={4}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                            }}
                        >
                            <ToiTypography
                                align='right'
                                variant='h1'
                                sx={{
                                    width: '100%',
                                    maxWidth: 500,
                                    marginBottom: '20px',
                                    fontSize: 45,
                                    fontWeight: 300,
                                    letterSpacing: '0.05em',
                                }}
                            >
                                arealplaner.no
                            </ToiTypography>
                            <ToiTypography align='right' sx={{maxWidth: 350}}>
                                {headerText}
                            </ToiTypography>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={4} xl={4}>
                            <img
                                alt='arealplaner kart'
                                style={{
                                    maxWidth: 350,
                                    width: '30vw',
                                    minWidth: 100,
                                }}
                                src={MapsImage}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DesktopContainer>
            <MobileContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        marginBottom: '50px',
                    }}
                >
                    <Grid
                        style={{height: '100%'}}
                        container={true}
                        spacing={2}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Grid
                            item={true}
                            xs={12}
                            md={12}
                            style={{display: 'flex', justifyContent: 'center'}}
                        >
                            <ToiTypography
                                variant='h1'
                                align='center'
                                sx={{
                                    marginTop: '30px',
                                    fontSize: '33px',
                                    maxWidth: '500px',
                                    marginBottom: '20px',
                                    fontWeight: 300,
                                    letterSpacing: '0.05em',
                                }}
                            >
                                arealplaner.no
                            </ToiTypography>
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                            md={12}
                            style={{
                                height: 'auto',
                                minWidth: 250,
                                maxWidth: '35vw',
                            }}
                        >
                            <img
                                style={{marginBottom: 20, width: '100%'}}
                                alt='arealplaner kart'
                                src={MapsImage}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <ToiTypography
                            align='center'
                            sx={{
                                maxWidth: 500,
                            }}
                        >
                            {headerText}
                        </ToiTypography>
                    </Grid>
                </Box>
            </MobileContainer>
        </>
    );
};
export default Header;
