import React from 'react';
import {useIsMobile} from '@norkart/design-responsive';
import {
    ToiBox,
    ToiDataDisplay,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import HelpButton from '../../../../components/HelpButton/HelpButton';
import {helpObject} from '../../../../help/helpObject';
import {formatNasjonalArealplanId} from '../../../../utils/formatNasjonalArealplanId';
import SakList from '../../../sak/SakList';
import IkraftDatoDisplay from '../../IkraftDatoDisplay';
import {useOrganisasjonsPersoner} from '../../../../hooks/organisasjonspersoner/useOrganisasjonsPersoner';
import useMeta from '../../../../hooks/meta/useMeta';

export interface Props {
    plan: ArealplanDto;
}

//// Ala gammel versjon:
// function DataDisplay({
//     label,
//     value,
// }: {
//     label: string;
//     value: string | React.ReactNode;
// }) {
//     return (
//         <Grid
//             container
//             spacing={{lg: 2, md: 0}}
//             maxWidth={600}
//             direction={{lg: 'row', md: 'column', xs: 'column'}}
//         >
//             <Grid item md={12} lg={6}>
//                 <ToiTypography
//                     variant='label'
//                     textAlign={{lg: 'right', md: 'left'}}
//                     noWrap
//                 >
//                     {label}:
//                 </ToiTypography>
//             </Grid>
//             <Grid item md={12} lg={6}>
//                 {typeof value == 'string' ? (
//                     <ToiTypography>{value}</ToiTypography>
//                 ) : (
//                     <>{value}</>
//                 )}
//             </Grid>
//         </Grid>
//     );
// }

function DataDisplay({
    label,
    value,
}: {
    label: string;
    value: string | React.ReactNode;
}) {
    const isMobile = useIsMobile();
    return (
        <ToiDataDisplay
            label={label}
            value={value}
            direction={isMobile ? 'column' : 'row'}
        />
    );
}

const PlanInfoDetaljer = ({plan}: Props) => {
    const meta = useMeta();
    const {data: organisasjonspersoner} = useOrganisasjonsPersoner(plan.id);

    return (
        <ToiStack direction='row' justifyContent={'space-between'}>
            <ToiStack gap={1} alignItems={'flex-start'} sx={{flexGrow: 1}}>
                <DataDisplay
                    label='Nasjonal arealplanid'
                    value={formatNasjonalArealplanId(plan)}
                />
                {plan.opprinneligAdministrativEnhet &&
                    plan.opprinneligPlanId && (
                        <DataDisplay
                            label='Opprinnelig nasjonal arealplanid'
                            value={formatNasjonalArealplanId({
                                komnr: plan.opprinneligAdministrativEnhet,
                                planId: plan.opprinneligPlanId,
                            })}
                        />
                    )}
                <DataDisplay label='Plantype' value={plan.planType} />
                {plan.planBestemmelse && (
                    <DataDisplay
                        label='Planbestemmelser'
                        value={plan.planBestemmelse}
                    />
                )}

                {plan.lovreferanse && (
                    <DataDisplay
                        label='Lovreferanse'
                        value={plan.lovreferanse}
                    />
                )}

                {(plan.vertikalniva?.length ?? 0) > 0 && (
                    <DataDisplay
                        label='Vertikalnivå'
                        value={
                            plan.vertikalniva &&
                            plan.vertikalniva
                                .map((vn) => vn.beskrivelse)
                                .join(', ')
                        }
                    />
                )}

                {plan.forslagsstiller && (
                    <DataDisplay
                        label='Forslagsstillertype'
                        value={plan.forslagsstiller}
                    />
                )}

                {plan.saker && (plan.saker.length ?? 0) > 0 && (
                    <DataDisplay
                        label='Saksnummer'
                        value={
                            <ToiTypography>
                                <SakList saker={plan.saker} />
                            </ToiTypography>
                        }
                    />
                )}

                {plan.iKraft && (
                    <DataDisplay
                        label='Ikrafttredelsesdato'
                        value={<IkraftDatoDisplay plan={plan} />}
                    />
                )}
                {meta.hasKontaktPerson() &&
                    organisasjonspersoner &&
                    organisasjonspersoner.length > 0 &&
                    plan.planStatusId &&
                    [0, 1, 2].includes(plan.planStatusId) && (
                        <DataDisplay
                            label={
                                organisasjonspersoner.length > 1
                                    ? 'Kontaktpersoner'
                                    : 'Kontaktperson'
                            }
                            value={
                                <ToiStack>
                                    {organisasjonspersoner?.map(
                                        (person, index) => (
                                            <ToiTypography key={index}>
                                                {person.rolle?.beskrivelse}
                                                {': '}
                                                {person.kontaktperson?.navn}
                                                {' - '}
                                                {person.kontaktperson?.telefon}
                                                {' - '}
                                                {person.kontaktperson?.epost}
                                            </ToiTypography>
                                        )
                                    )}{' '}
                                </ToiStack>
                            }
                        />
                    )}
            </ToiStack>
            <ToiBox
                width={'60px'}
                display={'flex'}
                justifyContent={'center'}
                alignContent={'center'}
            >
                <HelpButton
                    helpText={helpObject.KortPlaninfo}
                    type='Planinfo'
                />
            </ToiBox>
        </ToiStack>
    );
};
export default PlanInfoDetaljer;
