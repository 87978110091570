import {forwardRef} from 'react';
import ToiRadio, {ToiRadioProps} from '../ToiRadio/ToiRadio';
import {InputDescription} from '../internal/InputDescription';
import {ToiFormControlLabel} from '../ToiFormControlLabel';

interface OwnProps {
  label: string | React.ReactNode | null;
  helpText?: string | React.ReactNode;
  displayHelpTextInline?: boolean;
}

export type ToiRadioOptionProps = ToiRadioProps & OwnProps;

export const ToiRadioOption = forwardRef<HTMLButtonElement, ToiRadioOptionProps>((props, ref) => {
  const {label, helpText, disabled, displayHelpTextInline, ...restProps} = props;
  const descriptionProps = {label, helpText};

  return (
    <ToiFormControlLabel
      label={<InputDescription {...descriptionProps} />}
      disabled={disabled}
      control={<ToiRadio ref={ref} {...restProps} />}
      displayHelpTextInline={displayHelpTextInline}
    />
  );
});

export default ToiRadioOption;
