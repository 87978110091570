import React from 'react';
import {Field} from 'react-final-form';
import {parseDate} from '../utils/parseDate';
import DatePicker from './DatePicker';

export default function FormDateInputField({
    name,
    label,
    fullWidth = true,
    optional = false,
    minDate,
    maxDate,
}: {
    name: string;
    label?: string;
    fullWidth?: boolean;
    optional?: boolean;
    minDate?: string;
    maxDate?: string;
}) {
    const min = minDate ? new Date(minDate) : undefined;
    const max = maxDate ? new Date(maxDate) : undefined;

    return (
        <Field
            name={name}
            parse={parseDate}
            render={({input, meta}) => (
                <DatePicker
                    label={label}
                    input={input}
                    fullWidth={fullWidth}
                    onClear={() => input.onChange(null)}
                    optional={optional}
                    minDate={min}
                    maxDate={max}
                />
            )}
        />
    );
}
