import {DocumentIcon, PictureImageIcon} from '@norkart/toi-icons';

type Props = {
    extension?: string;
};

function FileIcon({extension}: Props) {
    if (extension) {
        const ext = extension.toLowerCase();
        switch (ext) {
            case 'pdf':
                return <DocumentIcon />;

            case 'doc':
            case 'docx':
                return <DocumentIcon />;

            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'jfif':
            case 'tiff':
            case 'bmp':
            case 'gif':
            case 'svg':
                return <PictureImageIcon />;

            default:
                break;
        }
    }
    return <DocumentIcon />;
}

export default FileIcon;
