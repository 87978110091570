import {CSSProperties, forwardRef, SVGProps} from 'react';

type PictogramSize = 'small' | 'default' | 'large' | `${number}${'px' | 'em' | 'rem'}` | number;

interface ColorOverrides {
  stroke?: string;
  background?: string;
  fill?: string;
  highlight1?: string;
  highlight2?: string;
  highlight3?: string;
}

interface InternalToiPictogramProps extends SVGProps<SVGSVGElement> {
  size?: PictogramSize;
  colorOverrides?: ColorOverrides;
}

export interface ToiPictogramProps extends InternalToiPictogramProps {
  title?: string;
  desc?: string;
}

const getSize = (size: PictogramSize): string | number => {
  if (typeof size === 'number') {
    return size > 0 ? size * 64 : 64; // Prevents negative icon size
  }

  switch (size) {
    case 'small':
      return '48px';
    case 'default':
      return '64px';
    case 'large':
      return '72px';
    default:
      return size;
  }
};

function useColorOverrides(colorOverrides?: ColorOverrides, style?: CSSProperties) {
  if (!colorOverrides) {
    return style;
  }
  return Object.entries(colorOverrides).reduce<Record<string, string | number>>(
    (acc, [key, value]) => {
      if (value) {
        acc[`--toi-palette-pictograms-${key}`] = value;
      }
      return acc;
    },
    (style as Record<string, string | number>) ?? {},
  );
}

export const ToiPictogram = forwardRef<SVGSVGElement, InternalToiPictogramProps>((props, ref) => {
  const {size = 'default', colorOverrides, style: styleProp, ...restProps} = props;
  const sizeForSVG = getSize(size);
  const style = useColorOverrides(colorOverrides, styleProp);

  return (
    <svg
      ref={ref}
      width={sizeForSVG}
      height={sizeForSVG}
      viewBox='0 0 64 64'
      overflow='visible'
      preserveAspectRatio='xMidYMid meet'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      {...restProps}
    />
  );
});
