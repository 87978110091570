import {
    ToiButton,
    ToiDialog,
    ToiDialogActions,
    ToiDialogTitle,
} from '@norkart/toi-components';
import useDeleteKontaktperson from '../../../hooks/kontaktpersoner/useDeleteKontaktperson';

export interface DeleteKontaktpersonDialogProps {
    id: number;
    name: string;
    isOpen: boolean;
    handleClose: () => void;
}

const ConfirmDeleteKontaktpersonDialog = ({
    id,
    name,
    isOpen,
    handleClose,
}: DeleteKontaktpersonDialogProps) => {
    const {mutateAsync: deleteKontaktperson} = useDeleteKontaktperson();
    const handleOk = () => {
        deleteKontaktperson(id);
        handleClose();
    };
    return (
        <ToiDialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby='delete-dialog-title'
        >
            <ToiDialogTitle id='delete-dialog-title'>
                Vil du virkelig slette {name}?
            </ToiDialogTitle>
            <ToiDialogActions>
                <ToiButton onClick={handleClose} variant='secondary'>
                    Nei
                </ToiButton>
                <ToiButton onClick={handleOk} color='error'>
                    Ja
                </ToiButton>
            </ToiDialogActions>
        </ToiDialog>
    );
};

export default ConfirmDeleteKontaktpersonDialog;
