import {
    ToiBox,
    ToiButton,
    ToiIconButton,
    ToiStack,
} from '@norkart/toi-components';
import {EditIcon, ExternalIcon, MapIcon, SearchIcon} from '@norkart/toi-icons';
import useMeta from '../../../../hooks/meta/useMeta';

type ParentProps = {
    handleEditClick: () => void;
    handleMapClick: (() => void) | undefined;
    handleSearch: () => void;
    handleMapClickVisGI: (() => void) | undefined;
    userCanEdit: boolean;
    userCanView: boolean;
};
type Props = ParentProps;

const TopButtonMenu = ({
    handleMapClick,
    handleSearch,
    handleMapClickVisGI,
    userCanEdit,
    userCanView,
    handleEditClick,
}: Props) => {
    const meta = useMeta();
    return (
        <ToiStack direction='row' justifyContent={'space-between'}>
            <ToiStack gap={2} direction={'row'} flexWrap={'wrap'}>
                <ToiButton
                    variant={'transparent'}
                    onClick={handleSearch}
                    startIcon={<SearchIcon size='small' />}
                    sx={{whiteSpace: 'nowrap'}}
                    size={'medium'}
                >
                    Til plansøk
                </ToiButton>

                {handleMapClick && meta.visplanUrl && (
                    <ToiButton
                        variant={'transparent'}
                        onClick={handleMapClick}
                        startIcon={<MapIcon size='small' />}
                        endIcon={<ExternalIcon size='small' />}
                        sx={{whiteSpace: 'nowrap'}}
                        size={'medium'}
                    >
                        {meta.visplanTekst || 'Flere kartfunksjoner'}
                    </ToiButton>
                )}

                {handleMapClickVisGI &&
                    meta.visplanInternUrl &&
                    userCanView && (
                        <ToiButton
                            variant={'transparent'}
                            onClick={handleMapClickVisGI}
                            startIcon={<MapIcon size='small' />}
                            endIcon={<ExternalIcon size='small' />}
                            sx={{whiteSpace: 'nowrap'}}
                            size={'medium'}
                        >
                            {meta.visplanInternTekst || 'VisGI'}
                        </ToiButton>
                    )}
            </ToiStack>
            {userCanEdit && (
                <ToiBox
                    width={'60px'}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    <ToiIconButton
                        aria-label='Endre plan'
                        color='transparent'
                        onClick={handleEditClick}
                    >
                        <EditIcon />
                    </ToiIconButton>
                </ToiBox>
            )}
        </ToiStack>
    );
};
export default TopButtonMenu;
