import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const RoutePinsIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M20.1526 7.98348C20.1557 7.98036 20.1571 7.97593 20.1565 7.97157C19.7883 5.47773 18.3915 3.42911 15.9463 1.99369C15.0446 1.46853 14.0141 1.14154 12.9737 1.04245C10.0705 0.784829 7.70232 1.70634 5.91876 3.67816C4.27392 5.49145 3.58032 7.63172 3.79831 10.0693C3.89739 11.1394 4.18474 11.9123 4.28383 12.1699C4.60091 13.0121 5.00716 13.6463 5.33415 14.0823C7.35366 16.787 9.38136 19.4917 11.4023 22.1964C11.7022 22.5976 12.3037 22.598 12.6039 22.197L18.5226 14.2903C18.9784 13.7057 19.672 12.6554 20.0287 11.1691C20.1276 10.7635 20.3748 9.54774 20.1486 7.99542C20.148 7.99106 20.1495 7.9866 20.1526 7.98348V7.98348Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9926 11.9025C13.4647 11.9025 14.658 10.7091 14.658 9.23702C14.658 7.76494 13.4647 6.57159 11.9926 6.57159C10.5205 6.57159 9.32715 7.76494 9.32715 9.23702C9.32715 10.7091 10.5205 11.9025 11.9926 11.9025Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default RoutePinsIcon;
