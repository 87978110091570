import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import {ToiButton} from '@norkart/toi-components';
import {DokumentDto} from '@norkart/nkapi-arealplaner-api';
import useDeleteDokument from '../../../hooks/dokumenter/useDeleteDokument';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog, showDialog} from '../../../store/dialog/actions';

export interface DeleteDocumentDialogProps extends ConnectedReduxProps {
    dokument: DokumentDto;
    open: boolean;
}

const DeleteDocumentDialog = ({
    dokument,
    dispatch,
    open,
}: DeleteDocumentDialogProps) => {
    const {mutate: deleteDokument} = useDeleteDokument();
    const handleClose = () => {
        hideDialog(dispatch).then(() =>
            dispatch(
                showDialog({
                    dialogType: 'EDIT_DOKUMENT',
                    dialogProps: {dokument},
                })
            )
        );
    };

    const handleOk = () => {
        dokument.id && deleteDokument(dokument.id);
        hideDialog(dispatch);
    };
    return (
        <Dialog open={open} aria-labelledby='confirm-dialog-title'>
            <DialogTitle id='confirm-dialog-title'>
                Dokumentet vil slettes helt fra planregisteret, og du kan ikke
                angre. Vil du virkelig slette dokumentet?
            </DialogTitle>
            <DialogActions>
                <ToiButton onClick={handleClose} variant='secondary'>
                    Nei
                </ToiButton>
                <ToiButton onClick={handleOk} color='error'>
                    Ja
                </ToiButton>
            </DialogActions>
        </Dialog>
    );
};
export default DeleteDocumentDialog;
