import {styled} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import qs from 'query-string';
import React from 'react';
import {useDispatch} from 'react-redux';
import {
    Link,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useResolvedPath,
} from 'react-router-dom';
import {SearchIcon} from '@norkart/toi-icons';
import {
    ToiBox,
    ToiDrawer,
    ToiLink,
    ToiLinkListGroup,
    ToiLinkListItem,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import useMeta from '../../../hooks/meta/useMeta';
import useSearch from '../../../hooks/search/useSearch';
import {resetMapState} from '../../../store/map/actions';
import useCan from '../../../utils/auth/useCan';
import {ArchiveIntegrationSettings} from '../ArchiveIntegrationSettings';
import FrontendSettings from '../FrontendSettings';
import Kontaktpersoner from '../Kontaktpersoner/Kontaktpersoner';
import Tokens from '../Tokens';

const drawerWidth = 240;

const MainContent = styled('main')(({theme}) => ({
    flexGrow: 1,
    padding: '10px',
    scrollBehavior: 'auto',
}));

function Settings() {
    const can = useCan();
    const kundeId = useSelectedKundeId();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const url = useResolvedPath('').pathname;
    const search = useSearch();
    const meta = useMeta();

    const handleBackToSearch = () => {
        dispatch(resetMapState());
        navigate({
            pathname: `/${kundeId}/arealplaner/search`,
            search: qs.stringify({...search.searchState.urlSearchParams}),
        });
    };

    const links = [
        {
            text: 'Tilganger',
            to: `${url}/tilganger`,
            path: `tilganger`,
            component: Tokens,
        },
        {
            text: 'Innstillinger',
            to: `${url}/innstillinger`,
            path: `innstillinger`,
            component: FrontendSettings,
        },
        {
            text: 'Arkivintegrasjon',
            to: `${url}/arkivintegrasjon`,
            path: `arkivintegrasjon`,
            component: ArchiveIntegrationSettings,
        },
        ...(meta.hasKontaktPerson()
            ? [
                  {
                      text: 'Kontaktpersoner',
                      to: `${url}/kontaktpersoner`,
                      path: `kontaktpersoner`,
                      component: Kontaktpersoner,
                  },
              ]
            : []),
    ] as {
        text: string;
        to: string;
        path: string;
        component: React.ComponentType<any>;
    }[];

    const activeRoute = (linkPath: string) => {
        return location.pathname === linkPath;
    };

    if (can.edit)
        return (
            <ToiBox
                sx={{
                    display: 'flex',
                }}
            >
                <ToiDrawer
                    variant='permanent'
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiPaper-root': {
                            width: drawerWidth,
                        },
                    }}
                >
                    <Toolbar />
                    <ToiStack gap={2} p={1}>
                        <ToiLink
                            component='button'
                            onClick={handleBackToSearch}
                            startIcon={<SearchIcon size='small' />}
                        >
                            Til plansøk
                        </ToiLink>
                        <ToiLinkListGroup>
                            {links.map((link) => (
                                <ToiLinkListItem
                                    key={link.text}
                                    to={link.to}
                                    component={Link}
                                    active={activeRoute(link.to)}
                                    sx={{
                                        '&.Mui-selected': {fontWeight: 500},
                                    }}
                                >
                                    {link.text}
                                </ToiLinkListItem>
                            ))}
                        </ToiLinkListGroup>
                    </ToiStack>
                </ToiDrawer>
                <MainContent>
                    <Routes>
                        {links.map((link) => (
                            <Route
                                key={link.text}
                                path={link.path}
                                element={<link.component />}
                            />
                        ))}
                    </Routes>
                </MainContent>
            </ToiBox>
        );

    return (
        <ToiTypography>
            Du har ikke rettigheter til å se denne siden.
        </ToiTypography>
    );
}

export default Settings;
