import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const InvisibleIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M7 6.3776C4.65463 7.75627 3.06681 9.88713 2.27511 11.4198C2.0867 11.7845 2.08613 12.2143 2.27448 12.579C3.53818 15.0262 6.83241 19 12 19C13.931 19 15.6004 18.4451 17 17.6224M10 5.20582C10.6319 5.07319 11.2987 5 12 5C17.1676 5 20.4618 8.97381 21.7255 11.421C21.9139 11.7857 21.9131 12.2159 21.7247 12.5807C21.2575 13.4849 20.5133 14.5972 19.5 15.6405'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M9.35418 9C8.52375 9.73294 8 10.8053 8 12C8 14.2091 9.79086 16 12 16C13.0144 16 13.9407 15.6224 14.6458 15'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
    />
    <path d='M4 3L20 21' stroke='var(--toi-palette-icons-secondary, #2E2D30)' strokeWidth='1.5' strokeLinecap='round' />
  </ToiIcon>
));

export default InvisibleIcon;
