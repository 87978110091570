import {styled} from '@mui/material';
import {ToiLogo} from '@norkart/toi-components';

export default function Crest({src}: {src?: string | null}) {
    const StartIcon = styled('img')({
        width: '26,66px',
        height: '32px',
        alignItems: 'flex-start',
        marginRight: 8,
    });
    return src != null ? (
        <StartIcon src={src} alt='kommunevåpen' />
    ) : (
        <ToiLogo iconOnly width={'32px'} height={'32px'} noSpacing />
    );
}
