export const colors = {
  greyscale: {
    5: '#FFFFFF',
    10: '#F5F5F5',
    20: '#EAEAEA',
    30: '#DCDCDC',
    40: '#C0C0C0',
    50: '#87888A',
    60: '#68686A',
    70: '#4B4A4C',
    80: '#2E2D30',
    90: '#1C1B1D',
    100: '#000000',
  },
  green: {
    5: '#EFF9F5',
    10: '#DDF3EB',
    20: '#C8EBDC',
    30: '#96D3B6',
    40: '#61C496',
    50: '#24BD76',
    60: '#1AA164',
    70: '#15804E',
    80: '#0F6842',
    90: '#034E31',
    100: '#003C2D',
  },
  red: {
    5: '#FFEDED',
    10: '#FFD0D0',
    20: '#FEB2B2',
    30: '#FA9494',
    40: '#F47777',
    50: '#EB5D59',
    60: '#DF4742',
    70: '#D2342E',
    80: '#C2221C',
    90: '#861609',
    100: '#400B00',
  },
  yellow: {
    5: '#FFF2CB',
    10: '#FFE9AE',
    20: '#FFE190',
    30: '#FFD972',
    40: '#FFCC52',
    50: '#FFC12A',
    60: '#FFB300',
    70: '#FFA700',
    80: '#FF9600',
    90: '#EE7500',
    100: '#B15500',
  },
  blue: {
    5: '#E8F6FF',
    10: '#CCE7FA',
    20: '#AFD6F5',
    30: '#92C2EE',
    40: '#75ADE5',
    50: '#5797DA',
    60: '#3D80CE',
    70: '#2868BF',
    80: '#1450AF',
    90: '#0E3380',
    100: '#091B51',
  },
  seaGreen: {
    5: '#F5FBF9',
    10: '#E7F4F1',
    20: '#CAE5E0',
    30: '#AED5CF',
    40: '#92C4BF',
    50: '#78B2AD',
    60: '#5E9F9C',
    70: '#468B8A',
    80: '#2E7576',
    90: '#1C5657',
    100: '#0E3434',
  },
  purple: {
    5: '#F7F6FF',
    10: '#EBE8FE',
    20: '#D4CCFA',
    30: '#BFB1F5',
    40: '#AC98EE',
    50: '#9C81E6',
    60: '#8D6CDC',
    70: '#7F58D0',
    80: '#7246C2',
    90: '#482785',
    100: '#200F41',
  },
} as const;

export type ToiColors = typeof colors;
