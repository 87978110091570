import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Radio,
    TableHead,
    Typography,
} from '@mui/material';
import * as React from 'react';
import {ToiButton} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {BehandlingDto} from '@norkart/nkapi-arealplaner-api';
import GenericTable from '../../../components/GenericTable';
import useBehandlinger from '../../../hooks/behandlinger/useBehandlinger';
import useArealplanDokumenter from '../../../hooks/dokumenter/useArealplanDokumenter';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog, showDialog} from '../../../store/dialog/actions';
import {SelectedDocument} from '../../../store/selectedDocumentsTable/reducer';
import {
    documentHasBehandling,
    documentIsArvet,
} from '../../../utils/documentIsArvet';
import {formatDate} from '../../../utils/formatDate';
import {sortBehandlingList} from '../../behandling/BehandlingList/helpers';
import {MoveDocumentDialogProps} from './MoveDocumentFormDialog';

export interface SelectBehandlingDialogProps extends ConnectedReduxProps {
    open: boolean;
    plan: ArealplanDto;
    selectedDocuments: SelectedDocument[];
}

function SelectBehandlingDialog({
    dispatch,
    open,
    selectedDocuments,
    plan,
}: SelectBehandlingDialogProps) {
    const {data: dokumenter} = useArealplanDokumenter();
    const {data: behandlinger} = useBehandlinger();
    const [selectedBehandling, setSelectedBehandling] =
        React.useState<BehandlingDto>();
    const refSelectedBehandling = React.useRef(selectedBehandling);
    refSelectedBehandling.current = selectedBehandling;
    const [
        hasAcceptedDocumentsThatCannotBeMoved,
        setHasAcceptedDocumentsThatCannotBeMoved,
    ] = React.useState(false);

    const isChecked = (beh): boolean => {
        return refSelectedBehandling.current
            ? refSelectedBehandling.current.id === beh.id
            : false;
    };
    const columns = [
        {
            title: null,
            tooltip: 'Velg behandling',
            render: (beh) => (
                <Radio
                    value={beh}
                    onChange={() => setSelectedBehandling(beh)}
                    checked={isChecked(beh)}
                />
            ),
        },
        {
            title: 'Dato',
            field: 'fraDato',
            cellStyle: {
                whiteSpace: 'nowrap',
            } as React.CSSProperties,
            render: (behandling) =>
                formatDate(behandling.fraDato) +
                (behandling.tilDato
                    ? ' - ' + formatDate(behandling.tilDato)
                    : ''),
        },
        {
            title: 'Behandlingstype',
            render: (behandling) => (
                <Typography>{behandling.behandlingstype}</Typography>
            ),
        },
        {
            title: 'Dokumenter',
            render: (behandling) => (
                <span
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {behandling.dokumenter.length === 0
                        ? ''
                        : behandling.dokumenter.length === 1
                          ? '1 dokument'
                          : `${behandling.dokumenter.length} dokumenter`}
                </span>
            ),
        },
        {
            title: 'Beskrivelse',
            field: 'beskrivelse',
            cellStyle: {
                wordBreak: 'keep-all',
                width: '100%',
            } as React.CSSProperties,
            headerStyle: {
                width: '100%',
            } as React.CSSProperties,
        },
    ];
    // Check and warn if any files are larger than file size limit
    function handleClose() {
        setHasAcceptedDocumentsThatCannotBeMoved(false);
        hideDialog(dispatch);
    }
    // Uploads all files and expand details of behandling after completion
    function handleSubmit() {
        // Filter which files should be sent
        dispatch(
            showDialog({
                dialogType: 'MOVE_DOCUMENTS',
                dialogProps: {
                    selectedDocuments: documentsThatCanBeMoved,
                    behandling: selectedBehandling,
                    plan,
                } as MoveDocumentDialogProps,
            })
        );
    }

    const documentsThatCanBeMoved = selectedDocuments.filter(
        (doc) =>
            doc.dokumentId &&
            !documentIsArvet(doc.dokumentId, dokumenter) &&
            !documentHasBehandling(doc.dokumentId, dokumenter)
    );
    const arvetDocuments = selectedDocuments.filter(
        (doc) => doc.dokumentId && documentIsArvet(doc.dokumentId, dokumenter)
    );

    const documentsWithBehandling = selectedDocuments.filter(
        (doc) =>
            doc.dokumentId && documentHasBehandling(doc.dokumentId, dokumenter)
    );

    const documentsThatCannotBeMoved = arvetDocuments.concat(
        documentsWithBehandling
    );

    const getPreInfoText = () => {
        //Check if any or all documents cannot be moved
        if (documentsThatCannotBeMoved.length) {
            if (documentsThatCanBeMoved.length) {
                return (
                    <div>
                        <p>{`${documentsThatCannotBeMoved.length} ${
                            documentsThatCannotBeMoved.length === 1
                                ? 'dokument kan ikke flyttes fordi det enten er arvet fra en annen plan, eller allerede er knyttet til en behandling:'
                                : 'dokumenter kan ikke flyttes fordi de enten er arvet fra en annen plan, eller allerede er knyttet til en behandling:'
                        }`}</p>
                        <ul>
                            {documentsThatCannotBeMoved.map((doc) => (
                                <li key={doc.dokumentId}>{doc.filename}</li>
                            ))}
                        </ul>
                        <p>{`${documentsThatCanBeMoved.length} kan flyttes:`}</p>
                        <ul>
                            {documentsThatCanBeMoved.map((doc) => (
                                <li key={doc.dokumentId}>{doc.filename}</li>
                            ))}
                        </ul>
                    </div>
                );
            } else {
                return `${
                    documentsThatCannotBeMoved.length === 1
                        ? 'Dokumentet'
                        : 'Dokumentene'
                } kan ikke flyttes fordi ${
                    documentsThatCannotBeMoved.length === 1 ? 'det' : 'de'
                } er arvet fra en annen plan, eller allerede er knyttet til en behandling`;
            }
        } else {
            return false;
        }
    };

    return (
        <Dialog
            open={open}
            aria-labelledby='import-document-title'
            maxWidth='md'
            scroll='paper'
            PaperProps={{style: {overflowY: 'visible'}}}
        >
            {documentsThatCannotBeMoved.length &&
            !hasAcceptedDocumentsThatCannotBeMoved ? (
                <>
                    <DialogContent>{getPreInfoText()}</DialogContent>
                    <DialogActions>
                        {documentsThatCanBeMoved.length ? (
                            <>
                                <ToiButton
                                    variant='secondary'
                                    onClick={handleClose}
                                >
                                    Avbryt
                                </ToiButton>
                                <ToiButton
                                    onClick={() =>
                                        setHasAcceptedDocumentsThatCannotBeMoved(
                                            true
                                        )
                                    }
                                >
                                    Fortsett
                                </ToiButton>
                            </>
                        ) : (
                            <ToiButton onClick={handleClose}>OK</ToiButton>
                        )}
                    </DialogActions>
                </>
            ) : (
                ''
            )}
            {(hasAcceptedDocumentsThatCannotBeMoved ||
                !documentsThatCannotBeMoved.length) && (
                <>
                    <DialogTitle id='import-document-title'>
                        {documentsThatCanBeMoved.length === 1
                            ? 'Flytt 1 dokument'
                            : `Flytt ${documentsThatCanBeMoved.length} dokumenter`}
                    </DialogTitle>
                    <DialogContent>
                        <GenericTable
                            title='Planbehandlinger'
                            data={sortBehandlingList(behandlinger)}
                            columns={columns}
                            idColumn='id'
                            onRowClick={(event, rowData) =>
                                setSelectedBehandling(rowData)
                            }
                            components={{
                                Header: (props) => (
                                    <>
                                        <colgroup>
                                            <col style={{width: '170px'}} />
                                            <col style={{width: '270px'}} />
                                            <col style={{width: '150px'}} />
                                            <col style={{width: '200px'}} />
                                            <col style={{width: 'auto'}} />
                                        </colgroup>
                                        <TableHead {...props} />
                                    </>
                                ),
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <>
                            <ToiButton onClick={handleClose}>Avbryt</ToiButton>
                            <ToiButton
                                onClick={handleSubmit}
                                disabled={!selectedBehandling}
                            >
                                Flytt til valgt behandling
                            </ToiButton>
                        </>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
export default SelectBehandlingDialog;
