import {useQuery} from 'react-query';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {fetchPlanLayerAsync} from '../../features/map/helpers/layerHelpers';
import {PlanLayerState} from '../../features/map/types';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

export default function usePlanLayer(plan?: ArealplanDto) {
    const kundeId = useSelectedKundeId();

    const query = useQuery<PlanLayerState>(
        ['arealplanLag', {kundeId, arealplanId: plan?.id}],
        () => fetchPlanLayerAsync(plan!, kundeId).then((res) => res),
        {enabled: !!plan}
    );
    return query;
}
