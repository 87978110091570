import React from 'react';
import {ToiFormLabel, ToiStack} from '@norkart/toi-components';

type Props = {children?: React.ReactNode};

export function FilterLabel({children}: Props) {
    return (
        <ToiFormLabel component={'legend'}>
            <ToiStack
                flexWrap={'nowrap'}
                direction={'row'}
                alignItems={'baseline'}
                gap={1}
            >
                {children}
            </ToiStack>
        </ToiFormLabel>
    );
}

export default FilterLabel;
