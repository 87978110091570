import {Grid, Typography} from '@mui/material';
import React, {useState} from 'react';
import {FieldArray} from 'react-final-form-arrays';
import {ToiIconButton, ToiLink} from '@norkart/toi-components';
import {CheckmarkCheckDoneIcon} from '@norkart/toi-icons';
import FormInputField from '../../../../components/FormInputField';
import FormSelectInput from '../../../../components/FormSelectInput';
import useMeta from '../../../../hooks/meta/useMeta';
import {formatNasjonalArealplanId} from '../../../../utils/formatNasjonalArealplanId';
import {ArealplanFormValues} from '../PlanForm';
import Error from '../../../../components/FormError/Error';

export default function NasjonalArealplanId({
    values,
}: {
    values: ArealplanFormValues;
}) {
    const [planidOpen, setPlanidOpen] = useState(false);
    const meta = useMeta();
    return (
        <FieldArray
            name='nasjonalPlanId'
            render={({meta: {error, submitError}}) => (
                <>
                    <Typography variant='label' mb={0.5}>
                        Nasjonal arealplanid
                    </Typography>
                    {!planidOpen ? (
                        <ToiLink
                            component='button'
                            onClick={(e) => {
                                e.preventDefault();
                                setPlanidOpen(!planidOpen);
                            }}
                        >
                            {/* {planidOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
                            {values.komnr && values.planId ? (
                                formatNasjonalArealplanId({
                                    komnr: values.komnr,
                                    planId: values.planId ?? '',
                                })
                            ) : (
                                <Error error='Nasjonal arealplanid er påkrevd' />
                            )}
                        </ToiLink>
                    ) : (
                        <>
                            <Grid container={true} spacing={2} wrap='nowrap'>
                                <Grid item={true} xs={12} md={6}>
                                    <FormSelectInput
                                        name='komnr'
                                        options={meta.data.kommuner}
                                        keyCol='kommuneNummer'
                                        valueCol='kommuneNavn'
                                    />
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    md={6}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    wrap='nowrap'
                                    spacing={1}
                                    alignItems={'center'}
                                >
                                    <FormInputField
                                        name='planId'
                                        inputProps={{maxLength: 16}}
                                    />
                                    <ToiIconButton
                                        aria-label='Ferdig med å redigere'
                                        color='transparent'
                                        onClick={() => setPlanidOpen(false)}
                                    >
                                        <CheckmarkCheckDoneIcon />
                                    </ToiIconButton>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12}>
                                {(error || submitError) && (
                                    <Error error={error || submitError} />
                                )}
                            </Grid>
                        </>
                    )}
                </>
            )}
        />
    );
}
