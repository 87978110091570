import * as React from 'react';
import {ToiBox, ToiButton} from '@norkart/toi-components';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import {BehandlingReportFilters} from '../../../../hooks/behandlinger/useBehandlingerReport';
import useMeta from '../../../../hooks/meta/useMeta';
import {setTitle} from '../../../../utils/setTitle';
import BehandlingerFilterV2 from '../BehandlingerV2/BehandlingerFilter';
import BehandlingerReportV2 from '../BehandlingerV2/BehandlingerReport';
import ReportPage from '../ReportPage';
import FilterWrapper from '../FilterWrapper';

export type BehandlingerQueryVariables = {
    first?: number;
    after?: string;
    filter?: {and: any[]} | undefined;
    sort?: any[] | undefined;
};

export default function Behandlinger() {
    setTitle('Rapporter > Behandlinger');
    const initialVariables: BehandlingerQueryVariables = {first: 60};
    const meta = useMeta();
    const [filterValues, setFilterValues] = React.useState<any | undefined>(
        undefined
    );
    const [vars, setVars] =
        React.useState<BehandlingerQueryVariables>(initialVariables);

    const [filterValuesV2, setFilterValuesV2] = React.useState<
        BehandlingReportFilters | undefined
    >(undefined);
    //Oppdaterer variabler til graphql request når "filterValues" oppdateres i "FilterBehandlinger" komponent
    React.useMemo(() => {
        const newVars: BehandlingerQueryVariables = {
            ...vars,
            filter: filterValues,
        };
        setVars((prevValue) => {
            if (newVars === initialVariables) return prevValue;
            else if (!newVars.filter && !newVars.sort) {
                return initialVariables;
            } else {
                return newVars;
            }
        });
    }, [filterValues]);

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <ReportPage title='Behandlinger'>
            <FilterWrapper>
                <ToiBox>
                    <BehandlingerFilterV2
                        filterValues={filterValuesV2}
                        setFilterValues={setFilterValuesV2}
                        clearFilter={filterValuesV2 ? false : true}
                    />

                    <ToiButton
                        variant='secondary'
                        onClick={() => setFilterValuesV2(undefined)}
                        sx={{
                            display: filterValues ? 'flex' : 'none',
                            marginTop: '10px',
                        }}
                    >
                        Nullstill filter
                    </ToiButton>
                </ToiBox>
            </FilterWrapper>

            <BehandlingerReportV2 filterValues={filterValuesV2} />
        </ReportPage>
    );
}
