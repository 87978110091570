import {PropsWithChildren} from 'react';

import {styled, useTheme} from '@mui/material';
import ToiDrawer from '../../ToiDrawer/ToiDrawer';
import {ToiStack} from '../../pureMuiComponents';
import {ToiDivider} from '../../ToiDivider';

type OwnProps = {
  window?: Window | null;
  toggleDrawer: (e: any) => void;
  open?: boolean;
};

type Props = PropsWithChildren<OwnProps>;

const StyledContainer = styled(ToiStack)(({theme}) => ({
  width: '100%',

  '& > .MuiButtonBase-root, & .ToiAppBarGroup-root > .MuiButtonBase-root': {
    height: '60px',
    width: '100%',
  },

  '& .MuiDivider-root': {
    marginBlock: '0 !important',
  },

  '& .MuiAccordion-root': {
    border: 'none',
    width: '100%',

    '& .MuiAccordionSummary-root': {
      height: '60px',
    },

    '& .MuiAccordionDetails-root': {
      marginBottom: theme.toi.spacing.s,
    },
  },
}));

export const MenuDrawer = (props: Props) => {
  const theme = useTheme();

  return (
    <ToiDrawer
      variant='temporary'
      anchor='left'
      open={props.open}
      onClose={props.toggleDrawer}
      ModalProps={{
        container: props.window?.document.body,
      }}
      PaperProps={{
        sx: {
          marginTop: `${theme.mixins.toolbar.minHeight}px`,
          height: `calc(100% - 60px)`,
          boxShadow: 'none !important',
          width: 'min(85%, 350px)',
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            top: '60px',
          },
        },
      }}
    >
      <StyledContainer divider={<ToiDivider sx={{marginBlock: theme.toi.spacing.xs}} />}>
        {props.children}
      </StyledContainer>
      <ToiDivider />
    </ToiDrawer>
  );
};
