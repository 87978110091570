import {Tab, styled, type TabProps, type TabTypeMap} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';

/** @deprecated Use the alternative boolean prop on the ToiTabs component instead */
export type ToiTabVariant = 'default' | 'alternative';

interface OwnProps {
  /** @deprecated This prop has no effect. Use the alternative boolean prop on the ToiTabs component instead */
  variant?: 'default' | 'alternative';
}

type DefaultComponent = TabTypeMap['defaultComponent'];
export type ToiTabProps = TabProps<DefaultComponent, OwnProps>;

export const ToiTab = styled(Tab)<ToiTabProps>(({theme}) => ({
  display: 'flex',
  textTransform: 'none',
  marginInline: theme.toi.spacing.xs,
  gap: theme.toi.spacing.xs,

  '&:hover, &.Mui-focusVisible': {
    backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,
  },

  '&.Mui-focusVisible': {
    ...theme.toi.focus,
    border: 'none',
    outlineOffset: '-2px',
  },

  '&.Mui-selected': {
    color: theme.vars.toi.palette.signal.foreground.success,
    fontWeight: 600,
  },

  ...theme.typography.body2,
})) as OverridableComponent<TabTypeMap<OwnProps>>;

export default ToiTab;
