import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    ToiIconButton,
    ToiListItemIcon,
    ToiListItemText,
    ToiMenu,
    ToiMenuItem,
} from '@norkart/toi-components';
import {ContextMenuHorizontalIcon, DownloadIcon} from '@norkart/toi-icons';
import arealplanerConfig from '../../../../config';

export default function SearchResultMenu() {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [downloadLink, setDownloadLink] = useState<URL | null>(null);
    const params = useParams();

    React.useEffect(() => {
        const link = new URL(
            arealplanerConfig.planregister.url +
                '/api/kunder/' +
                params['customerId'] +
                '/arealplaner/csv'
        );
        link.search = window.location.search;
        link.searchParams.append(
            'apitoken',
            arealplanerConfig.planregister.token
        );
        setDownloadLink(link);
    }, [
        params,
        window.location.search,
        arealplanerConfig.planregister.token,
        arealplanerConfig.planregister.url,
    ]);

    if (!downloadLink) return null;

    return (
        <>
            <ToiIconButton
                aria-label='Søkeresultatmeny'
                color='transparent'
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                <ContextMenuHorizontalIcon />
            </ToiIconButton>
            <ToiMenu
                id='search-results-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                disableScrollLock={true}
            >
                <ToiMenuItem
                    aria-label='Last ned som .csv'
                    component={'a'}
                    href={downloadLink.href}
                >
                    <ToiListItemText>Last ned som .csv</ToiListItemText>
                    <ToiListItemIcon>
                        <DownloadIcon />
                    </ToiListItemIcon>
                </ToiMenuItem>
            </ToiMenu>
        </>
    );
}
