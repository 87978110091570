import {Menu as MuiMenu, MenuProps as MuiMenuProps, styled} from '@mui/material';
import {ToiMenuOwnProps} from './types';
import {toiMenuStyle} from './toiMenuStyle';

/**
 * @deprecated Use ToiBaseUIMenu instead since it has better UU support
 */
export type ToiMenuProps = MuiMenuProps & ToiMenuOwnProps;

/**
 * @deprecated Use ToiBaseUIMenu instead since it has better UU support
 */
export const ToiMenu = styled(MuiMenu)<ToiMenuProps>(({theme, width, size}) => {
  return {
    marginTop: theme.toi.spacing['2xs'],
    '& .MuiMenu-list': toiMenuStyle(theme, width, size),
  };
});

export default ToiMenu;
