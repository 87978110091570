import React from 'react';
import {ToiAccordion, ToiBox} from '@norkart/toi-components';

export default function FilterWrapper({children}: {children: React.ReactNode}) {
    return (
        <ToiBox width={'800px'}>
            <ToiAccordion title='Filter' defaultExpanded>
                {children}
            </ToiAccordion>
        </ToiBox>
    );
}
