import {setTitle} from '../../../../utils/setTitle';
import ReportPage from '../ReportPage';
import KostraTable from './KostraTable';

export default function Kostra() {
    setTitle('Rapportert > Kostra');
    return (
        <ReportPage title='Kostra'>
            <KostraTable />
        </ReportPage>
    );
}
