import {CircularProgressProps as MuiCircularProgressProps} from '@mui/material';
import {ToiBox} from '../pureMuiComponents';
import {StyledSpinner, StyledSpinnerBackground} from './StyledSpinner';

type SpinnerSize = 'default' | 'large' | number;

type OwnProps = {
  size?: SpinnerSize;
};

export type ToiSpinnerProps = Omit<
  MuiCircularProgressProps,
  keyof OwnProps | 'component' | 'size' | 'color' | 'value' | 'variant' | 'thickness'
> &
  OwnProps;

export const ToiSpinner = (props: ToiSpinnerProps) => {
  const {size: sizeProp, sx, ...restProps} = props;

  const size = typeof sizeProp === 'number' ? sizeProp : sizeProp === 'large' ? '64px' : '24px';
  return (
    <ToiBox
      sx={{
        position: 'relative',
        width: size,
        height: size,
        ...(Array.isArray(sx) ? sx : [sx]),
      }}
    >
      <StyledSpinnerBackground variant='determinate' size={size} thickness={5.6} {...restProps} value={100} />
      <StyledSpinner variant='indeterminate' disableShrink size={size} thickness={5.6} {...restProps} />
    </ToiBox>
  );
};

export default ToiSpinner;
