import {AccordionTypeMap, AccordionProps as MuiAccordionProps} from '@mui/material';
import {ReactNode, forwardRef} from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';

import {ChevronDownIcon, ChevronRightIcon} from '@norkart/toi-icons';
import ToiAccordionDetails from '../SubComponents/ToiAccordionDetails';
import ToiAccordionSummary from '../SubComponents/ToiAccordionSummary/ToiAccordionSummary';
import {ToiAccordionStatusType} from '../SubComponents/ToiAccordionStatus';
import {StyledAccordion} from './StyledAccordion';

type OwnProps = {
  title?: string;
  subtitle?: string;
  badgeValue?: number;
  summaryContent?: ReactNode;
  icon?: ReactNode;
  expandIcon?: ReactNode;
  children?: ReactNode;
  status?: ToiAccordionStatusType | ReactNode;
  size?: AccordionSize;
};

export type AccordionSize = 'small' | 'normal';

type DefaultComponent = AccordionTypeMap['defaultComponent'];

type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiAccordionProps = MuiAccordionProps<DefaultComponent, OwnProps>;

// AccordionTypeMap<OwnProps> doesn't work because the children prop is overriden to NonNullabel by the AccordionTypeMap
type ToiAccordionTypeMap = Omit<AccordionTypeMap, 'props'> & {
  props: Omit<AccordionTypeMap['props'], 'children'> & OwnProps;
};

export const ToiAccordion = forwardRef<RefType, ToiAccordionProps>((props, ref) => {
  const {
    title,
    subtitle,
    children,
    summaryContent,
    icon,
    badgeValue,
    defaultExpanded,
    expanded,
    onChange,
    slots,
    slotProps,
    expandIcon,
    sx,
    status,
    component,
    size,
    ...restProps
  } = props;

  return (
    <StyledAccordion
      ref={ref}
      disableGutters
      elevation={0}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={onChange}
      slots={slots}
      slotProps={slotProps}
      component={component}
      subtitle={subtitle}
      size={size}
      sx={sx}
    >
      <ToiAccordionSummary
        component={component}
        title={title}
        subtitle={subtitle}
        icon={icon}
        expandIcon={expandIcon ? expandIcon : children ? <ChevronDownIcon /> : <ChevronRightIcon />}
        summaryContent={summaryContent}
        badgeValue={badgeValue}
        hasChildren={!!children}
        status={status}
        size={size}
        {...restProps}
      />
      {component !== 'a' && children ? <ToiAccordionDetails>{children}</ToiAccordionDetails> : null}
    </StyledAccordion>
  );
}) as OverridableComponent<ToiAccordionTypeMap>;

export default ToiAccordion;
