import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const CrossCloseIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <circle cx='12' cy='12' r='11' stroke='var(--toi-palette-icons-secondary, #2E2D30)' strokeWidth='1.5' />
    <path
      d='M16.9498 7.05029L12 12M12 12L7.05029 16.9498M12 12L7.05029 7.05029M12 12L16.9498 16.9498'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default CrossCloseIcon;
