import {styled} from '@mui/material/styles';
import {Menu} from '@base-ui-components/react/menu';
import {Theme} from '@mui/material/styles';
import {toiMenuItemStyle} from '../Item/utils';
import {ToiMenuSize} from '../../ToiMenu/types';

export type ToiSubMenuTriggerProps = Menu.SubmenuTrigger.Props & {size?: ToiMenuSize};
export const ToiSubMenuTrigger = styled(Menu.SubmenuTrigger)<ToiSubMenuTriggerProps>(({theme, size, disabled}) => {
  return {
    ...toiMenuItemStyle(theme as Theme, size, disabled),
  };
});
