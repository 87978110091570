import {useMutation, useQueryClient} from 'react-query';
import {TokenDto, TokenType} from '@norkart/nkapi-arealplaner-api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {updateToken} from '../../services/api';

type Props = {id: number; description: string};

export default function useUpdateToken(tokenType: TokenType) {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();

    return useMutation(
        async ({id, description}: Props): Promise<TokenDto> => {
            const {data} = await updateToken(id, description);
            return data;
        },
        {
            onError: notify,
            onSuccess: () => {
                queryClient.invalidateQueries(['tokens', {kundeId, tokenType}]);
            },
        }
    );
}
