import {useNavigate} from 'react-router-dom';
import {ToiButton} from '@norkart/toi-components';
import Header from '../../home/header';
import CustomerSelector from '../CustomerSelector';

export interface MunicipalitySelectorProps {}

function MunicipalitySelector(props: MunicipalitySelectorProps) {
    const navigate = useNavigate();

    const onReadMore = () => {
        navigate(`om-arealplaner`);
    };
    return (
        <>
            <Header />
            <img
                src='https://www.norkart.no/wp-content/uploads/2020/01/Copy_me_header-2048x164.png'
                alt=''
                style={{width: '100%', maxWidth: '100%'}}
            />
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <ToiButton
                    style={{
                        margin: 'auto',
                        minWidth: 250,
                        marginTop: 50,
                        fontSize: 20,
                    }}
                    onClick={onReadMore}
                >
                    Om arealplaner.no
                </ToiButton>
            </div>
            <div
                style={{
                    maxWidth: '800px',
                    margin: '60px auto',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <CustomerSelector />
                </div>
            </div>
        </>
    );
}

export default MunicipalitySelector;
