import {useQuery} from 'react-query';
import {
    ApplikasjonInnstillingerDto,
    applikasjonInnstillingerGetApplikasjonInnstillinger,
} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useApplikasjonInnstillinger() {
    const {client} = useArealplanerApiClientContext();
    const query = useQuery<ApplikasjonInnstillingerDto[], any>(
        ['applikasjonInnstillinger'],
        async () => {
            const res =
                await applikasjonInnstillingerGetApplikasjonInnstillinger({
                    client: client,
                });
            return res.data as ApplikasjonInnstillingerDto[];
        }
    );

    return {
        ...query,
        data: query?.data || [],
    };
}
