import {Menu} from '@base-ui-components/react/menu';
import {styled} from '@mui/material';
import {forwardRef} from 'react';
import {StyledButton} from '../../ToiButton/StyledButton';
import {ToiButton, ToiButtonProps} from '../../ToiButton';

export type ToiMenuTriggerProps = Menu.Trigger.Props & {button?: ToiButtonProps} & {avatar?: boolean};
export const ToiMenuTrigger = forwardRef<HTMLButtonElement, ToiMenuTriggerProps>((props, ref) => {
  const {avatar, button, ...rest} = props;
  const StyledComponent = avatar ? StyledAvatarTrigger : StyledButton;

  return <Menu.Trigger render={<StyledComponent {...button} ref={ref} />} {...rest} />;
});

const StyledAvatarTrigger = styled(ToiButton)(({theme}) => ({
  backgroundColor: 'transparent',
  borderRadius: 0,
  '--toi-palette-icons-secondary': theme.vars.toi.palette.text.default,

  '&:hover, &.Mui-focusVisible': {
    backgroundColor: theme.vars.toi.palette.backgrounds.component.highlighted,
  },

  '&.Mui-focusVisible': {
    ...theme.toi.focus,
    border: 'none',
    outlineOffset: '-2px',
  },
  ...theme.typography.body2,
  height: theme.mixins.toolbar.minHeight,
  paddingInline: theme.toi.spacing.s,
}));
