import {useMatch} from 'react-router-dom';

export default function useSelectedArealplanId() {
    const match = useMatch('/:customerId/arealplaner/:id/*');

    const id = parseInt(match?.params.id || '0');

    if (isNaN(id)) {
        return 0;
    } else {
        return id;
    }
}
