import {Popper, styled} from '@mui/material';
import {DesktopDatePicker} from '@mui/x-date-pickers';
import {ToiDatePickerComponent} from './ToiDatePicker';

export const StyledDatePicker = styled(DesktopDatePicker)({
  '& button.MuiIconButton-root': {
    borderRadius: 0,
  },
}) as ToiDatePickerComponent;

export const StyledPopper = styled(Popper)(({theme}) => {
  return {
    zIndex: theme.zIndex.modal + 1,
    '& .MuiPickersCalendarHeader-root': {
      padding: 0,
      margin: 0,
      marginBottom: theme.toi.spacing.m,
    },
    '& .MuiPickersCalendarHeader-label': {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.toi.spacing.s,
      textTransform: 'capitalize',
    },
    '& .MuiDayCalendar-weekContainer ': {
      justifyContent: 'space-between',
    },
    '& .MuiDayCalendar-header': {
      justifyContent: 'space-between',
    },
    '& .MuiDayCalendar-weekDayLabel': {
      fontSize: theme.typography.body3.fontSize,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiPickersYear-yearButton': {
      fontSize: theme.typography.body3.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      borderRadius: '30px',
      width: '64px',

      '&:focus-visible': {
        backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,

        borderColor: theme.vars.toi.palette.border.hover,
        borderStyle: 'dashed',
        borderWidth: '1px',
        color: theme.vars.toi.palette.text.default,
      },
      '&:hover': {
        backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,
      },
      '&.Mui-selected': {
        backgroundColor: theme.vars.toi.palette.backgrounds.component.active,
        color: theme.vars.toi.palette.text.inverted,
        '&:focus-visible': {
          backgroundColor: theme.vars.toi.palette.backgrounds.component.activeHover,
          color: theme.vars.toi.palette.text.inverted,
        },
        '&:hover': {
          backgroundColor: theme.vars.toi.palette.backgrounds.component.activeHover,
        },
      },
    },
    '& .MuiPickersArrowSwitcher-button': {
      backgroundColor: theme.vars.toi.palette.button.tertiary.fill,
      borderRadius: '4px',
      height: '32px',
      width: '32px',
      padding: 0,
      '&:hover': {
        backgroundColor: theme.vars.toi.palette.button.tertiary.hover,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.vars.toi.palette.button.tertiary.hover,
        borderColor: theme.vars.toi.palette.border.hover,
        borderStyle: 'dashed',
        borderWidth: '1px',
        color: theme.vars.toi.palette.text.default,
      },
    },

    '& .MuiPickersArrowSwitcher-root': {
      gap: '4px',
    },

    '& .MuiPickersDay-root': {
      fontSize: theme.typography.body3.fontSize,

      '&.MuiPickersDay-today': {
        borderStyle: 'solid',
        backgroundColor: 'initial',
      },

      '&.Mui-focusVisible': {
        backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,

        borderColor: theme.vars.toi.palette.border.hover,
        borderStyle: 'dashed',
        borderWidth: '1px',
        color: theme.vars.toi.palette.text.default,
      },
      '&:hover': {
        backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,
      },
      '&.Mui-selected': {
        backgroundColor: theme.vars.toi.palette.backgrounds.component.active,
        color: theme.vars.toi.palette.text.inverted,
        '&.Mui-focusVisible': {
          backgroundColor: theme.vars.toi.palette.backgrounds.component.activeHover,
        },
        '&:hover': {
          backgroundColor: theme.vars.toi.palette.backgrounds.component.activeHover,
        },
      },
    },

    '& .MuiPickersCalendarHeader-switchViewButton': {
      backgroundColor: theme.vars.toi.palette.button.tertiary.fill,
      borderRadius: '4px',
      height: '32px',
      width: '32px',

      '&:hover': {
        backgroundColor: theme.vars.toi.palette.button.tertiary.hover,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.vars.toi.palette.button.tertiary.hover,
        borderColor: theme.vars.toi.palette.border.hover,
        borderStyle: 'dashed',
        borderWidth: '1px',
        color: theme.vars.toi.palette.text.default,
      },
    },
    '& .MuiPickersLayout-root': {
      marginTop: '4px',
      marginBottom: '4px',
      padding: theme.toi.spacing.m,
    },
  };
});
