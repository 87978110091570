import {useAccount} from '@azure/msal-react';
import {PropsWithChildren} from 'react';
import {CommonEndSessionRequest} from '@azure/msal-common';
import {useIsLoggedIn, useNkAuth} from '@norkart/nk-auth';
import {
    ToiAvatar,
    ToiButton,
    ToiButtonProps,
    ToiDivider,
    ToiListItemText,
    ToiStack,
    ToiTypography,
    useToiAppBarCollapse,
} from '@norkart/toi-components';
import {ToiBaseUIMenu as Menu} from '@norkart/toi-components';
import {
    ChevronDownIcon,
    ChevronRightIcon,
    LogOutIcon,
    UserProfileIcon,
} from '@norkart/toi-icons';
import {useGetLogin} from './LoginButtons';
import {useGetLogOut} from './LogOutButton';
import {AuthenticationMethod, LoginOrOutInteractionProcess} from './types';

export type AppBarLoginLanguageOptions = {
    loginLabel?: string;
    logOutLabel?: string;
    menuBackLabel?: string;
    changeProfileLabel?: string;
};

export interface UserMenuProps {
    languageOptions?: AppBarLoginLanguageOptions;
    idp: AuthenticationMethod;
    loginType?: LoginOrOutInteractionProcess;
    logoutRequest?: Omit<CommonEndSessionRequest, 'correlationId'>;
    slotProps?: {
        menuPopup?: Partial<Menu.PopupProps>; // The menu is baseUI not mui, use style instead of sx
        button?: Partial<Omit<ToiButtonProps, 'endIcon'>>;
    };
}

/**
 * Menu that displays user information, enables customer select on multicustomer context applications, and allows user to log in and out.
 */
export function UserMenu({
    loginType = LoginOrOutInteractionProcess.REDIRECT,
    idp,
    languageOptions,
    logoutRequest,
    slotProps,
    children,
}: PropsWithChildren<UserMenuProps>) {
    const isLoggedIn = useIsLoggedIn();
    const {isCollapsed} = useToiAppBarCollapse();

    const logOut = useGetLogOut(loginType, logoutRequest);
    const login = useGetLogin(idp, loginType);

    const user = useAccount() ?? undefined;
    const name = user?.name ?? '';

    const {authData, customerContextData} = useNkAuth();

    const currentCustomerContext =
        customerContextData?.currentCustomerContext?.CustomerName;

    const availableCustomers =
        customerContextData?.availableCustomerContexts ?? [];

    const currentCustomer = customerContextData?.currentCustomerContext;

    const handleChange = (customerId: string) => {
        const selectedCustomer = availableCustomers.find(
            (c) => c.CustomerId === customerId
        );
        if (selectedCustomer) {
            customerContextData?.setCurrentCustomerContext(selectedCustomer);
        }
    };

    const multiCustomerContextProps = {
        changeProfile: handleChange,
        currentProfileId: currentCustomer?.CustomerId ?? '',
        profiles: availableCustomers.map((c) => ({
            id: c.CustomerId ?? '',
            name: c.CustomerName ?? '',
        })),
    };

    const secondaryTitle = authData.isMultiCustomerContext
        ? currentCustomerContext
        : user?.username;

    if (!isLoggedIn) {
        return (
            <ToiButton onClick={login} size='medium'>
                Logg inn
            </ToiButton>
        );
    }

    const ButtonTitle = isCollapsed ? (
        <ToiAvatar fullname={name} />
    ) : (
        <>
            <ToiAvatar fullname={name} />
            <ToiStack direction='column' alignItems='flex-start'>
                <ToiTypography variant='body3' fontWeight='bold'>
                    {name}
                </ToiTypography>
                <ToiTypography variant='body4'>{secondaryTitle}</ToiTypography>
            </ToiStack>
            <ChevronDownIcon />
        </>
    );

    return (
        <Menu.Root>
            <Menu.Trigger avatar button={slotProps?.button}>
                {ButtonTitle}
            </Menu.Trigger>
            <Menu.Popup
                {...slotProps?.menuPopup}
                position={{align: 'end', ...slotProps?.menuPopup?.position}}
            >
                {isCollapsed && (
                    <Menu.Item>
                        <ToiAvatar fullname={name} />

                        <ToiListItemText
                            primary={name}
                            secondary={secondaryTitle}
                        />
                    </Menu.Item>
                )}
                {isCollapsed && <ToiDivider />}
                {authData.isMultiCustomerContext &&
                    multiCustomerContextProps &&
                    multiCustomerContextProps.profiles.length > 1 && (
                        <ProfileSubMenu
                            languageOptions={languageOptions}
                            {...multiCustomerContextProps}
                        />
                    )}
                {children}
                {logOut && (
                    <Menu.Item onClick={logOut}>
                        {languageOptions?.logOutLabel ?? 'Logg ut'}
                        <LogOutIcon style={{marginLeft: 'auto'}} />
                    </Menu.Item>
                )}
            </Menu.Popup>
        </Menu.Root>
    );
}

interface ProfileSubMenuProps {
    profiles: {name: string; id: string}[];
    currentProfileId: string;
    changeProfile: (id: string) => void;
}

const ProfileSubMenu = ({
    changeProfile,
    currentProfileId,
    profiles,
    languageOptions,
}: ProfileSubMenuProps & {languageOptions?: AppBarLoginLanguageOptions}) => {
    return (
        <Menu.Root>
            <Menu.SubmenuTrigger>
                <UserProfileIcon />
                {languageOptions?.changeProfileLabel ?? 'Bytt profil'}
                <ChevronRightIcon style={{marginLeft: 'auto'}} />
            </Menu.SubmenuTrigger>
            <Menu.Popup width='285px'>
                <Menu.RadioGroup
                    value={currentProfileId}
                    onValueChange={(value) => changeProfile(value as string)}
                >
                    {profiles.map((p) => (
                        <Menu.RadioItem value={p.id} label={p.name} key={p.id}>
                            {p.name}
                        </Menu.RadioItem>
                    ))}
                </Menu.RadioGroup>
            </Menu.Popup>
        </Menu.Root>
    );
};
