import {UrlSearchParams} from '../store/search/types';

export const searchFiltersToQueryString = (filters: UrlSearchParams) => {
    let qs = '';
    if (filters.term) qs = qs + `&term=${filters.term}`;
    if (filters.saksnummer) qs = qs + `&saksnummer=${filters.saksnummer}`;
    if (filters.planStatusId) qs = qs + `&planStatusId=${filters.planStatusId}`;
    if (filters.planTypeId) qs = qs + `&planTypeId=${filters.planTypeId}`;
    if (filters.opprinneligAdministrativEnhet)
        qs =
            qs +
            `&opprinneligAdministrativEnhet=${filters.opprinneligAdministrativEnhet}`;
    if (filters.behandlingstypeId)
        qs = qs + `&behandlingstypeId=${filters.behandlingstypeId}`;
    if (filters.fromDate) qs = qs + `&fromDate=${filters.fromDate}`;
    if (filters.toDate) qs = qs + `&toDate=${filters.toDate}`;
    if (filters.order) qs = qs + `&order=${filters.order}`;
    if (filters.orderBy) qs = qs + `&orderBy=${filters.orderBy}`;
    if (filters.gnr !== undefined && filters.bnr !== undefined) {
        qs = qs + `&gnr=${filters.gnr}&bnr=${filters.bnr}`;
        if (filters.knr) qs = qs + `&knr=${filters.knr}`;
        if (filters.fnr) qs = qs + `&fnr=${filters.fnr}`;
        if (filters.snr) qs = qs + `&snr=${filters.snr}`;
        if (filters.teigid) qs = qs + `&teigid=${filters.teigid}`;
    }
    if (filters.lnglat) qs = qs + `&lnglat=${filters.lnglat}`;
    return qs;
};
