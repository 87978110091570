import {FormGroup, FormGroupProps, styled} from '@mui/material';

export type ToiFormGroupProps = FormGroupProps;

export const ToiFormGroup = styled(FormGroup)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.toi.spacing.s,
  marginBlock: theme.toi.spacing.s,
}));
