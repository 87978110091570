import React from 'react';
import {ToiBox, ToiStack, ToiTypography} from '@norkart/toi-components';

type Props = {
    title: string;
    subtitle?: string;
    children: React.ReactNode;
};

function ReportPage({title, subtitle, children}: Props) {
    return (
        <ToiStack>
            <ToiBox>
                <ToiTypography variant='h1'>{title}</ToiTypography>
                {subtitle && (
                    <ToiTypography variant='body1'>{subtitle}</ToiTypography>
                )}
            </ToiBox>
            <ToiBox>{children}</ToiBox>
        </ToiStack>
    );
}

export default ReportPage;
