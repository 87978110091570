import {useMemo} from 'react';
import {useInfiniteQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {
    arealplanerGetArealplaner,
    ArealplanSearchResultDto,
} from '@norkart/nkapi-arealplaner-api';
import useSearchHelpers from '../../features/search/Search/useSearchHelpers';
import {
    getActiveSortFilter,
    getSearchParams,
    getSearchState,
} from '../../store';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import useMeta from '../meta/useMeta';
import useDebounce from '../useDebounce';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

type SearchResult = {
    data: ArealplanSearchResultDto[];
    pagination: Pagination;
    pageParam: number;
};

type Pagination = {
    TotalCount: number;
    PageSize: number;
    CurrentPage: number;
    TotalPages: number;
    HasNext: boolean;
    HasPrevious: boolean;
};

export default function useSearch() {
    const meta = useMeta();
    const activeSortFilter = useSelector(getActiveSortFilter);
    const kundeId = useSelectedKundeId();
    const searchParams = useSelector(getSearchParams);
    const helpers = useSearchHelpers();
    const searchState = useSelector(getSearchState);
    const debouncedSearchParams = useDebounce(searchParams, 500);
    const {client} = useArealplanerApiClientContext();

    const searchArealplaner = async (page = 1): Promise<SearchResult> => {
        const res = await arealplanerGetArealplaner({
            client: client,
            path: {kundeId},
            query: {...debouncedSearchParams, ...activeSortFilter, page},
        });
        const pagination = JSON.parse(
            res.response.headers.get('X-Pagination') ?? ''
        ) as Pagination;
        return {
            data: res.data ?? ([] as ArealplanSearchResultDto[]),
            pagination,
            pageParam: page,
        };
    };

    const isValidSearchParams = searchParams?.saksnummer
        ? helpers.isvalidSaksnummer(searchParams?.saksnummer)
        : true;

    const query = useInfiniteQuery<SearchResult>(
        [
            'search',
            {kundeId, searchParams: debouncedSearchParams, activeSortFilter},
        ],
        ({pageParam = 1}) => searchArealplaner(pageParam),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage?.pagination?.HasNext) {
                    return lastPage.pageParam + 1;
                } else return undefined;
            },
            keepPreviousData: true,
            staleTime: 0,
            cacheTime: 1000 * 60 * 5, // 5m
            enabled: !!kundeId && meta.isFetched && isValidSearchParams,
        }
    );
    const mapResults = useMemo(() => {
        const data = [] as ArealplanSearchResultDto[];
        if (query?.data?.pages) {
            query?.data?.pages.map((page) => {
                for (const res in page.data) {
                    data.push(page.data[res]);
                }
            });
        }
        return data;
    }, [query.data]);

    return {
        ...query,
        searchState,
        results: mapResults,
        totalCount: query?.data?.pages[0]?.pagination?.TotalCount,
        activeSortFilter,
    };
}
