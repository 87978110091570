import {Badge} from '@mui/material';
import {styled, ThemeVars} from '@mui/material/styles';
import {ToiBadgeProps} from './ToiBadge';

export const StyledBadge: React.FC<ToiBadgeProps & {hide: boolean}> = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'hide',
})<ToiBadgeProps & {hide: boolean}>(({theme, color, variant, hide}) => {
  const isDot = variant === 'dot';
  const size = isDot ? 8 : 20;

  return {
    '.MuiBadge-badge': {
      display: hide ? 'none' : 'flex',
      minWidth: size,
      padding: isDot ? 0 : 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      width: isDot ? size : 'auto',
      height: size,
      '& > *': {
        color: theme.vars.toi.palette.text.default,
        verticalAlign: 'center',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: 12,
      },
      border: ['default', 'white', undefined].includes(color) && `1px solid ${theme.toi.palette.text.default}`,
      backgroundColor: isDot
        ? getDotColor(color, theme.vars.toi.palette)
        : getNumberColor(color, theme.vars.toi.palette),
    },
  };
});

const getDotColor = (color: ToiBadgeProps['color'] | undefined, palette: ThemeVars['toi']['palette']): string => {
  const colorMap: Record<NonNullable<ToiBadgeProps['color']>, string> = {
    default: palette.text.default,
    white: palette.backgrounds.component.fill,
    grey: palette.text.default,
    success: palette.signal.foreground.success,
    info: palette.signal.foreground.info,
    warning: palette.signal.foreground.warning,
    error: palette.signal.foreground.error,
    primary: palette.icons.primary,
    secondary: palette.icons.secondary,
  };

  // if (color === 'white') {
  //     console.error('Cannot combine variant=dot and color=white.');
  //     return colorMap.default;
  // }

  return color ? colorMap[color] : colorMap.default;
};

const getNumberColor = (color: ToiBadgeProps['color'] | undefined, palette: ThemeVars['toi']['palette']): string => {
  const backgroundColorMap: Record<NonNullable<ToiBadgeProps['color']>, string> = {
    default: palette.backgrounds.component.fill,
    white: palette.backgrounds.component.fill,
    grey: palette.signal.background.normal,
    success: palette.signal.background.success,
    info: palette.signal.background.info,
    warning: palette.signal.background.warning,
    error: palette.signal.background.error,
    primary: palette.icons.primary,
    secondary: palette.icons.secondary,
  };

  return color ? backgroundColorMap[color] : backgroundColorMap.default;
};
