import {useQuery} from 'react-query';
import {useNkAuth} from '@norkart/nk-auth';
import {
    arealplanerGetArealplanBehandlinger,
    BehandlingDto,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useBehandlinger() {
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();
    const {isAuthorized} = useNkAuth();
    const {client, authorizedClient} = useArealplanerApiClientContext();

    const getBehandlinger = async (
        arealplanId: number,
        isAuthorized: boolean
    ) => {
        const {data, error} = await arealplanerGetArealplanBehandlinger({
            path: {kundeId, arealplanId},
            query: {includeDokumenter: true},
            client: isAuthorized ? authorizedClient : client,
        });
        if (error) throw new Error(String(error.message));
        return data;
    };

    const query = useQuery<BehandlingDto[]>(
        ['behandlinger', {kundeId, arealplanId, isAuthorized}],
        () => getBehandlinger(arealplanId!, isAuthorized),
        {enabled: !!arealplanId}
    );

    return {...query, data: query?.data || []};
}
