import {ToiBox} from '@norkart/toi-components';
import HelpButton from '../../../../components/HelpButton/HelpButton';
import {helpObject} from '../../../../help/helpObject';
import useSearchHelpers from '../useSearchHelpers';
import CheckboxSectionAlt from './CheckboxSection';
import FilterLabel from './FilterLabel';
import {useFilter} from './useFilter';

export const PlanStatusFilter = () => {
    const helpers = useSearchHelpers();
    const filter = useFilter('planstatus');

    const getSelectedFilters = (): string[] => {
        return filter.get()?.split(',') || [];
    };

    const handleChange = (value: string) => {
        filter.set(value);
    };

    return (
        <ToiBox>
            <FilterLabel>
                Velg planstatus
                <HelpButton
                    helpText={helpObject.AvansertFilter.Planstatus}
                    type='PlanstatusFilter'
                />
            </FilterLabel>
            <CheckboxSectionAlt
                selectedOptions={getSelectedFilters()}
                options={helpers.planstatuserCheckboxList}
                handleChange={handleChange}
            />
        </ToiBox>
    );
};
