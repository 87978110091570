import {CssVarsThemeOptions} from '@mui/material';
import {toiFocus} from './toiFocus';

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    focus: CSSProperties;
    sideMenu?: {
      width: CSSProperties['width'];
    };
    drawer?: {
      temporary?: {
        width: CSSProperties['width'];
      };
      persistent?: {
        width: CSSProperties['width'];
      };
      permanent?: {
        width: CSSProperties['width'];
        collapsedWidth?: CSSProperties['width'];
      };
    };
  }
}

export const mixins: CssVarsThemeOptions['mixins'] = {
  toolbar: {
    minHeight: 60,
  },
  focus: toiFocus,
  sideMenu: {
    width: 270,
  },
  drawer: {
    temporary: {
      width: `min(350px, 80vw)`,
    },
    persistent: {
      width: `min(270px, 100vw)`,
    },
    permanent: {
      width: `min(518px, 100vw)`,
      collapsedWidth: '64px',
    },
  },
};
