import {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Chunk} from '../types';
import {chatHistoryItem} from '../cache/chatHistory';

export const FragmentParts = {
    chunkId: 'chunkId',
    chatItemIndex: 'chatItemIndex',
    answerIndex: 'answerIndex',
};

export const useOpenReference = ({
    chatItemIndex,
    chatHistory,
}: {
    chatItemIndex?: string;
    chatHistory: chatHistoryItem[];
}) => {
    const params = useParams();
    const location = useLocation();
    const [openReference, setOpenReference] = useState<Chunk | null>(null);

    useEffect(() => {
        if (
            !openReference &&
            params[FragmentParts.chunkId] &&
            params[FragmentParts.chatItemIndex] === chatItemIndex?.toString()
        ) {
            const chosenRef = params[FragmentParts.chunkId];
            const matchingChatItem =
                chatItemIndex && chatHistory[parseInt(chatItemIndex)];
            const matchedChunk =
                matchingChatItem &&
                matchingChatItem.answers
                    ?.find(
                        (answer, index) =>
                            index.toString() ===
                            params[FragmentParts.answerIndex]
                    )
                    ?.reference.chunks.find((chunk) => chunk.id === chosenRef);

            if (matchedChunk) {
                const content: string = matchedChunk?.content ?? '';
                setOpenReference({
                    content,
                    id: matchedChunk.id,
                    is_used: true,
                });
                return;
            }
        }
        setOpenReference(null);
    }, [location]);
    return openReference;
};
