import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
    ArrowDownIcon,
    ArrowUpIcon,
    TurnDirectionSortingIcon,
} from '@norkart/toi-icons';
import {ToiButton} from '@norkart/toi-components';
import {OrderBy, Order, SortFilter} from '../../../store/search/types';
import {ApplicationState} from '../../../store/index';
import {getActiveSortFilter} from '../../../store/selectors';

interface SortBtnProps {
    title: string;
    onClick: (order: Order, orderBy: OrderBy) => void;
    value: OrderBy;
}

interface StateProps {
    activeSortFilter: SortFilter | null;
}

const hoverStyle = {
    backgroundColor: 'transparent !important',
    '&:hover': {
        backgroundColor: 'transparent !important',
        color: 'black',
    },
    '&:focus': {
        backgroundColor: 'transparent !important',
        color: 'black',
    },
};

const SortButton = (props: SortBtnProps & StateProps) => {
    const [order, setOrder] = useState<Order | null>(null);

    useEffect(() => {
        if (
            props.activeSortFilter &&
            props.activeSortFilter.orderBy === props.value
        ) {
            setOrder(props.activeSortFilter.order);
        } else {
            setOrder(null);
        }
    }, [props.activeSortFilter]);

    const handleOnClick = () => {
        props.onClick(
            order === 'asc' ? 'desc' : order === 'desc' ? 'asc' : 'asc',
            props.value
        );
    };
    return (
        <React.Fragment key={props.value + 1}>
            <ToiButton
                aria-label={`Sorter på ${props.title}`}
                variant='transparent'
                onClick={handleOnClick}
                sx={{...hoverStyle}}
                disableRipple={true}
                endIcon={
                    order === 'asc' ? (
                        <ArrowUpIcon size='small' />
                    ) : order === 'desc' ? (
                        <ArrowDownIcon size='small' />
                    ) : (
                        <TurnDirectionSortingIcon size='small' />
                    )
                }
            >
                {props.title}
            </ToiButton>
        </React.Fragment>
    );
};

const SortTableBtn = connect((state: ApplicationState): StateProps => {
    return {
        activeSortFilter: getActiveSortFilter(state),
    };
})(SortButton);

export default SortTableBtn;
