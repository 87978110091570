import {ExpandMore} from '@mui/icons-material';
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableContainerProps,
    TableHead,
    TableProps,
    TableRow,
    TableRowProps,
    TableSortLabel,
} from '@mui/material';
import {
    ColumnDef,
    ColumnResizeMode,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    Header,
    Row,
    SortDirection,
    SortingState,
    useReactTable,
    Table,
    Cell,
} from '@tanstack/react-table';
import * as React from 'react';
import {ToiBox, ToiIconButton, ToiToolTip} from '@norkart/toi-components';
import useDebounce from '../../hooks/useDebounce';
import {ReactTableToolbar} from './ReactTableToolbar';

export interface ReactTableProps {
    columns: any;
    data: any[];
    toolbar?: ToolbarProps;
    cellProps?: TableCellProps;
    rowProps?: TableRowProps;
    tableProps?: TableProps;
    tableContainerProps?: TableContainerProps;
    customSort?: (
        property?: string | number,
        direction?: 'ASC' | 'DESC'
    ) => any;
    separateFirstColumn?: boolean;
    expandId?: string;
    onExpandChanged?: (id: string) => void;
    /** Enables expanding of rows to render the given subcomponent */
    renderSubComponent?: (props: {row: Row<any>}) => React.ReactElement;
    /** Enables expanding of rows to render subRows */
    expandEnabled?: boolean;
    textSize?: 'small' | 'default';
    noDataText?: string;
    enableColorIndicator?: boolean;
}

export interface ToolbarProps {
    clearSearchFilter?: () => void;
    onChange?: (event) => void;
    onKeyUp?: (event) => void;
    disabled?: boolean;
    value?: string | undefined;
    exportCsv?: ExportCsvProps;
    enableFilter?: boolean;
    dataInfo?: JSX.Element;
}

export type ExportCsvProps = {
    tooltipDisabled?: string;
    handleDownloadClick?: () => void;
    loadingRequest?: boolean;
};

type SortData = {
    table?: 'asc' | 'desc';
    custom?: 'ASC' | 'DESC';
    column?: string | number;
};

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const ReactTable = ({
    columns,
    data,
    toolbar,
    cellProps,
    rowProps,
    tableProps,
    customSort,
    separateFirstColumn,
    tableContainerProps,
    renderSubComponent,
    expandEnabled,
    expandId,
    onExpandChanged,
    textSize = 'default',
    noDataText = 'Ingenting å vise',
    enableColorIndicator = true,
}: ReactTableProps) => {
    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    const isExpandingEnabled = !!renderSubComponent || !!expandEnabled;
    const onlyExpandOneAtATime = !!onExpandChanged;

    const [searchText, setSearchText] = React.useState<string>('');
    const [filteredData, setFilteredData] = React.useState<any[] | undefined>(
        data
    );
    const debouncedFilteredData = useDebounce(filteredData, 500);

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [manualSorting, setManualSorting] = React.useState<
        SortData | undefined
    >();
    const [columnResizeMode, setColumnResizeMode] =
        React.useState<ColumnResizeMode>('onChange');

    const focusRow = (id: string) => {
        const rowId = table
            .getRowModel()
            .rows.find((row) => row.original.id == id)?.id;
        if (rowId) setExpanded({[rowId]: true});
    };

    const handleExpandId = (id: string | undefined) => {
        id ? focusRow(id) : setExpanded({});
    };

    React.useEffect(() => {
        if (isExpandingEnabled) handleExpandId(expandId);
    }, [data, expandId, isExpandingEnabled]);

    React.useEffect(() => {
        if (searchText === '') {
            filteredData && setFilteredData(data);
            return;
        }

        const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
        const filteredRows = data.filter((row: any) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(
                    row[field] ? row[field].toString() : ''
                );
            });
        });

        setFilteredData(filteredRows);
    }, [searchText, data]);

    const handleExpand = (row: Row<any>) => {
        if (row.getCanExpand()) {
            const id = row.original.id;
            if (onExpandChanged) {
                onExpandChanged(id);
                return;
            }

            row.getIsExpanded()
                ? row.toggleExpanded()
                : setExpanded({[row.id]: true});
        }
    };

    const handleRowClick = (e, row: Row<any>) => {
        e.stopPropagation();
        handleExpand(row);
    };

    const enhancedColumns = React.useMemo((): ColumnDef<any>[] => {
        return [
            ...(isExpandingEnabled
                ? [
                      {
                          id: 'expander',
                          header: ({table}) => {
                              if (onlyExpandOneAtATime) return null;
                              return (
                                  <ToiToolTip title={'Detaljer'}>
                                      <ToiIconButton
                                          aria-label='Detaljer'
                                          color='transparent'
                                          onClick={table.getToggleAllRowsExpandedHandler()}
                                      >
                                          <ExpandMore
                                              style={{
                                                  transform: `rotate(${
                                                      table.getIsAllRowsExpanded()
                                                          ? -180
                                                          : 0
                                                  }deg)`,
                                                  transition: 'transform 150ms',
                                              }}
                                          />
                                      </ToiIconButton>
                                  </ToiToolTip>
                              );
                          },
                          size: 52,
                          enableSorting: false,
                          cell: ({row}: {row: Row<any>}) => {
                              return row.getCanExpand() ? (
                                  <ToiToolTip title={'Detaljer'}>
                                      <ToiIconButton
                                          aria-label='Detaljer'
                                          color='transparent'
                                      >
                                          <ExpandMore
                                              style={{
                                                  transform: `rotate(${
                                                      !row.getCanExpand()
                                                          ? -90
                                                          : row.getIsExpanded()
                                                            ? -180
                                                            : 0
                                                  }deg)`,
                                                  transition: 'transform 150ms',
                                              }}
                                          />
                                      </ToiIconButton>
                                  </ToiToolTip>
                              ) : (
                                  ''
                              );
                          },
                      },
                  ]
                : []),
            ...columns,
        ];
    }, [columns, isExpandingEnabled]);

    const table = useReactTable({
        data: debouncedFilteredData,
        columns: enhancedColumns,
        state: {sorting, expanded},
        getSubRows: (row) => row.subRows,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        onExpandedChange: setExpanded,
        getRowCanExpand: renderSubComponent ? () => true : undefined,
        columnResizeMode,
        manualSorting: Boolean(customSort),
        //manualExpanding: Boolean(onExpandChanged)
    });

    const getColumnSize = (
        cell: Header<any, unknown> | Cell<any, unknown>
    ): number | string => {
        const size = cell.column.getSize();
        return size === 150 ? 'auto' : size;
    };

    const handleManualSorting = (header: Header<any, unknown>) => {
        if (!manualSorting || manualSorting.column !== header.column.id)
            setManualSorting({
                table: 'asc',
                custom: 'ASC',
                column: header.column.id,
            });
        else if (manualSorting.table === 'asc')
            setManualSorting({
                table: 'desc',
                custom: 'DESC',
                column: header.column.id,
            });
        else if (manualSorting.table === 'desc') setManualSorting(undefined);
    };

    const getBuiltInSortDirection = (direction: SortDirection | false) => {
        let sortDirectionResult: 'asc' | 'desc' | undefined;

        direction === 'asc'
            ? (sortDirectionResult = 'asc')
            : direction === 'desc'
              ? (sortDirectionResult = 'desc')
              : (sortDirectionResult = undefined);

        return sortDirectionResult;
    };

    React.useEffect(() => {
        if (customSort)
            customSort(manualSorting?.column, manualSorting?.custom);
    }, [manualSorting]);

    const renderTableHeader = (table: Table<any>) => (
        <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} {...rowProps}>
                    {headerGroup.headers.map((header) => (
                        <TableCell
                            {...{
                                onMouseDown: header.getResizeHandler(),
                                onTouchStart: header.getResizeHandler(),
                            }}
                            variant='head'
                            onClick={
                                header.column.getCanSort()
                                    ? !customSort
                                        ? header.column.getToggleSortingHandler()
                                        : () => handleManualSorting(header)
                                    : undefined
                            }
                            sx={{
                                width: getColumnSize(header),
                                padding: '4px 8px 4px 4px',
                                cursor: header.column.getCanSort()
                                    ? 'pointer'
                                    : 'auto',
                                ...(textSize === 'small' && {
                                    fontSize: '0.85rem',
                                }),
                            }}
                            key={header.id}
                            {...cellProps}
                        >
                            {header.column.getCanSort() ? (
                                <TableSortLabel
                                    sx={{
                                        width: '100%',
                                        ...(textSize === 'small' && {
                                            fontSize: '0.85rem',
                                        }),
                                    }}
                                    active={
                                        customSort
                                            ? manualSorting?.column ===
                                              header.column.id
                                            : sorting.find((s) => s)?.id ===
                                              header.column.id
                                    }
                                    direction={
                                        customSort
                                            ? manualSorting?.table
                                            : getBuiltInSortDirection(
                                                  header.column.getIsSorted()
                                              )
                                    }
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                          )}
                                </TableSortLabel>
                            ) : (
                                flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableHead>
    );

    const renderTableRow = (row: Row<any>) => (
        <TableRow
            sx={(theme) => ({
                padding: 'none',
                '&:last-child td:first-of-type': {
                    borderRight: separateFirstColumn ? '1px solid #e0e0e0' : 0,
                },
                ...(isExpandingEnabled &&
                    row.getCanExpand() && {
                        cursor: 'pointer',
                    }),
                ...(isExpandingEnabled &&
                    enableColorIndicator &&
                    row.getIsExpanded() && {
                        border: `2px solid ${theme.palette.primary.main}`,
                        borderBottom: 'none',
                    }),
            })}
            hover={row.getCanExpand()}
            onClick={
                onlyExpandOneAtATime
                    ? (e) => handleRowClick(e, row)
                    : row.getToggleExpandedHandler()
            }
        >
            {row.getVisibleCells().map((cell) => (
                <TableCell
                    key={cell.id}
                    sx={{
                        '&:first-of-type': {
                            borderRight: separateFirstColumn
                                ? '1px solid #e0e0e0'
                                : 0,
                        },
                        padding: '4px 8px 4px 4px',
                        width: getColumnSize(cell),
                        wordWrap: 'break-word',
                        ...(textSize === 'small' && {fontSize: '0.85rem'}),
                        ...(isExpandingEnabled &&
                            row.getIsExpanded() && {
                                borderBottom: 'none',
                            }),
                    }}
                >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
            ))}
        </TableRow>
    );

    const renderSubComponentRow = (row: Row<any>) => {
        if (row.getIsExpanded() && !!renderSubComponent) {
            return (
                <TableRow
                    sx={(theme) => ({
                        ...(isExpandingEnabled &&
                            row.getIsExpanded() && {
                                border: `2px solid ${theme.palette.primary.main}`,
                                borderTop: 'none',
                            }),
                    })}
                >
                    <TableCell colSpan={row.getVisibleCells().length}>
                        {renderSubComponent({row})}
                    </TableCell>
                </TableRow>
            );
        }
    };

    const renderNoDataRow = (table: Table<any>) => {
        return (
            <TableRow>
                <TableCell
                    colSpan={table.getAllColumns().length}
                    sx={{textAlign: 'center', padding: 10}}
                >
                    {noDataText}
                </TableCell>
            </TableRow>
        );
    };

    const renderRows = (table: Table<any>) => {
        return table.getRowModel().rows.map((row) => (
            <React.Fragment key={row.id}>
                {renderTableRow(row)}
                {renderSubComponentRow(row)}
            </React.Fragment>
        ));
    };

    return (
        <div>
            {toolbar && (
                <ToiBox sx={{marginBottom: '8px'}}>
                    <ReactTableToolbar
                        clearSearchFilter={() => setSearchText('')}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                        disabled={toolbar?.disabled}
                        exportCsv={toolbar?.exportCsv}
                        onKeyUp={(e) => {
                            if (e.key === 'Escape') setSearchText('');
                        }}
                        {...toolbar}
                    />
                </ToiBox>
            )}
            <TableContainer {...tableContainerProps}>
                <MuiTable
                    size='small'
                    padding='none'
                    sx={{
                        '& tbody tr:last-of-type td': {
                            border: 0,
                        },
                        columnSpan: 'all',
                    }}
                    {...tableProps}
                >
                    {renderTableHeader(table)}
                    <TableBody>
                        {debouncedFilteredData?.length
                            ? renderRows(table)
                            : renderNoDataRow(table)}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </div>
    );
};

export default ReactTable;
