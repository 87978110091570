import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const PictureImageIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M21.7152 2.83H2.28516C1.45673 2.83 0.785156 3.50157 0.785156 4.33V19.67C0.785156 20.4984 1.45673 21.17 2.28516 21.17H21.7152C22.5436 21.17 23.2152 20.4984 23.2152 19.67V4.33C23.2152 3.50157 22.5436 2.83 21.7152 2.83Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.08473 12.7008C8.37986 12.3946 8.87032 12.3948 9.16521 12.7012L10.3771 13.9607C10.6712 14.2665 11.1602 14.2675 11.4557 13.963L14.2671 11.066C14.58 10.7436 15.1043 10.7667 15.3875 11.1155L19.4821 16.1572C19.8802 16.6474 19.5314 17.38 18.8999 17.38H13.6852H8.1552H5.33961C4.67809 17.38 4.34053 16.5859 4.79958 16.1096L8.08473 12.7008Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.83051 8.89966C8.6616 8.89966 9.33533 8.22593 9.33533 7.39484C9.33533 6.56375 8.6616 5.89001 7.83051 5.89001C6.99942 5.89001 6.32568 6.56375 6.32568 7.39484C6.32568 8.22593 6.99942 8.89966 7.83051 8.89966Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default PictureImageIcon;
