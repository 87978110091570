import {sortBy} from 'lodash-es';
import {SakVerdiDto} from '@norkart/nkapi-arealplaner-api';
import SakItem from './SakItem';

export interface SakListProps {
    saker: Partial<SakVerdiDto>[];
}

function SakList({saker = []}: SakListProps) {
    return (
        <>
            {sortBy(saker, ['sakAar', 'sakSeknr']).map((sak, index) => (
                <span key={index}>
                    <SakItem sak={sak} />
                    {index < saker.length - 1 && ', '}
                </span>
            ))}
        </>
    );
}

export default SakList;
