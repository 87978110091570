import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const VisibleSightEyeIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M12 5C6.83241 5 3.53818 8.97381 2.27448 11.421C2.08613 11.7857 2.08613 12.2143 2.27448 12.579C3.53818 15.0262 6.83241 19 12 19C17.1676 19 20.4618 15.0262 21.7255 12.579C21.9139 12.2143 21.9139 11.7857 21.7255 11.421C20.4618 8.97381 17.1676 5 12 5Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
    />
    <circle cx='12' cy='12' r='4' stroke='var(--toi-palette-icons-secondary, #2E2D30)' strokeWidth='1.5' />
  </ToiIcon>
));

export default VisibleSightEyeIcon;
