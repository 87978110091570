import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import axios, {AxiosPromise} from 'axios';
import * as React from 'react';
import {useQueryClient} from 'react-query';
import {useDispatch} from 'react-redux';
import {ToiButton} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {BehandlingDto} from '@norkart/nkapi-arealplaner-api';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {openSnackbarMessage} from '../../../components/SnackbarMessage';
import useArealplanDokumenter from '../../../hooks/dokumenter/useArealplanDokumenter';
import useMeta from '../../../hooks/meta/useMeta';
import {moveDocumentFromPlan} from '../../../services/api';
import {hideDialog, showDialog} from '../../../store/dialog/actions';
import {
    SelectedDocument,
    removeDocument,
} from '../../../store/selectedDocumentsTable/reducer';
import {formatDate} from '../../../utils/formatDate';
import {DokumentUtvidet} from '../../plan/PlanDocumentList/PlanDocumentListDocuments';
import MoveDocumentFormItem, {MovableDocument} from './MoveDocumentFormItem';

export interface MoveDocumentDialogProps {
    open: boolean;
    behandling: BehandlingDto;
    plan: ArealplanDto;
    selectedDocuments: SelectedDocument[];
}

export interface SelectedDocumentExtended extends SelectedDocument {
    DokumenttypeId: number;
    VisIPlandokument: boolean;
    VisIGjeldendeBestemmelser: boolean;
    TilgangId: number;
}

function MoveDocumentDialog({
    open,
    selectedDocuments,
    behandling,
    plan,
}: MoveDocumentDialogProps) {
    const dispatch = useDispatch();
    const meta = useMeta();
    const queryClient = useQueryClient();
    const {data: dokumenter, refetch: refetchArealplanDokumenter} =
        useArealplanDokumenter();
    const [isMoving, setIsMoving] = React.useState(false);

    React.useEffect(() => {
        if (selectedDocuments.length === 0) handleClose();
    }, [selectedDocuments]);

    const initMovableDocuments = (): MovableDocument[] => {
        const res: MovableDocument[] = [];
        selectedDocuments.forEach((selDoc) => {
            const dok: DokumentUtvidet | undefined = dokumenter.find(
                (doc) => selDoc.dokumentId === doc.id
            );
            if (dok && selDoc.dokumentId && selDoc.filename) {
                const movableDok: MovableDocument = {
                    id: selDoc.dokumentId,
                    dokumentnavn: selDoc.filename,
                    dokumenttypeId: dok.dokumenttypeId,
                    dokumentdato: dok.dokumentdato,
                    visIGjeldendeBestemmelser: false,
                    visIPlandokumenter: false,
                    tilgangId: 1,
                };
                res.push(movableDok);
            }
        });
        return res;
    };

    function backToBehandlingDialog() {
        dispatch(
            showDialog({
                dialogType: 'SELECT_BEHANDLING',
                dialogProps: {
                    selectedDocuments,
                    plan,
                },
            })
        );
    }

    const [movableDocuments, setMovableDocuments] = React.useState<
        MovableDocument[]
    >(initMovableDocuments());

    function handleClose() {
        hideDialog(dispatch).then(() => {
            setIsMoving(false);
        });
    }

    function moveFileToBehandling(
        dokument: MovableDocument,
        behandling: BehandlingDto
    ) {
        if (behandling.id) return moveDocumentFromPlan(dokument, behandling.id);
    }

    // Uploads all files and expand details of behandling after completion
    function handleSubmit() {
        const reqs = [] as AxiosPromise[];

        movableDocuments &&
            movableDocuments.forEach((file) => {
                const req = moveFileToBehandling(file, behandling);
                if (req) reqs.push(req);
            });
        setIsMoving(true);
        axios
            .all(reqs)
            .then((res) => {
                res.forEach((response) => {
                    dispatch(removeDocument(response.data.id));
                });
            })
            .catch((err) =>
                openSnackbarMessage({
                    message:
                        'Noe gikk galt under flytting av filer. Vennligst prøv igjen.',
                    variant: 'error',
                })
            )
            .finally(() => {
                queryClient.invalidateQueries([
                    'behandlinger',
                    {arealplanId: plan.id},
                ]);
                queryClient.invalidateQueries(['arealplan', plan.id]);
                refetchArealplanDokumenter();
                handleClose();
            });
    }

    const onChange = (updatedDocument: MovableDocument) => {
        const updatedDocList: MovableDocument[] = movableDocuments.map(
            (doc) => {
                if (doc.id === updatedDocument.id) {
                    return updatedDocument;
                } else {
                    return doc;
                }
            }
        );
        setMovableDocuments(updatedDocList);
    };

    return (
        <Dialog
            open={open}
            aria-labelledby='import-document-title'
            maxWidth='xl'
            scroll='paper'
            PaperProps={{style: {overflowY: 'visible'}}}
        >
            <DialogTitle id='import-document-title'>
                Flytt til behandling: {behandling.behandlingstype}{' '}
                {formatDate(behandling.fraDato) +
                    (behandling.tilDato
                        ? ' - ' + formatDate(behandling.tilDato)
                        : '')}
            </DialogTitle>
            <DialogContent>
                {isMoving ? (
                    <>
                        <Typography>
                            Flytter valgte dokumenter til behandling
                        </Typography>
                        <LoadingSpinner />
                    </>
                ) : (
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell variant='head'>
                                        Dokumenttype
                                    </TableCell>
                                    <TableCell variant='head'>
                                        Dokumentnavn
                                    </TableCell>
                                    <TableCell variant='head'>Dato</TableCell>
                                    <TableCell
                                        variant='head'
                                        style={{whiteSpace: 'normal'}}
                                    >
                                        Vis i gjeldende bestemmelser
                                    </TableCell>
                                    <TableCell
                                        variant='head'
                                        style={{whiteSpace: 'normal'}}
                                    >
                                        Vis i andre dokumenter
                                    </TableCell>
                                    <TableCell
                                        variant='head'
                                        style={{whiteSpace: 'normal'}}
                                    >
                                        Dokumenttilgang
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedDocuments.map((document, index) => (
                                    <MoveDocumentFormItem
                                        key={`file-${index}`}
                                        data={movableDocuments[index]}
                                        dokumenttyper={meta.data.dokumenttyper}
                                        tilganger={meta.data.tilgangstyper}
                                        onChange={onChange}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                {!isMoving ? (
                    <>
                        <ToiButton
                            onClick={backToBehandlingDialog}
                            variant='secondary'
                        >
                            Tilbake
                        </ToiButton>
                        <ToiButton onClick={handleClose} variant='secondary'>
                            Avbryt
                        </ToiButton>
                        <ToiButton
                            onClick={handleSubmit}
                            disabled={!selectedDocuments}
                        >
                            Lagre
                        </ToiButton>
                    </>
                ) : null}
            </DialogActions>
        </Dialog>
    );
}

export default MoveDocumentDialog;
