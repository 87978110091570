import {styled, CSSObject} from '@mui/material';
import {CircularProgress as MuiCircularProgress} from '@mui/material';

export const StyledSpinner = styled(MuiCircularProgress)(({theme}): CSSObject => {
  return {
    borderRadius: '50%',
    backgroundColor: 'transparent',
    color: theme.vars.toi.palette.bars.active,
    position: 'absolute',
    left: 0,
    animationDuration: '1700ms',

    '& .MuiCircularProgress-circle': {
      strokeDasharray: '25px 200px', // Cover 25% of the circle
      strokeLinecap: 'round',
    },
  };
});

export const StyledSpinnerBackground = styled(MuiCircularProgress)(({theme}): CSSObject => {
  return {
    position: 'absolute',
    left: 0,
    color: theme.vars.toi.palette.bars.fill,
  };
});
