import * as React from 'react';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {
    ToiButton,
    ToiDialog,
    ToiDialogActions,
    ToiDialogContent,
    ToiDialogTitle,
} from '@norkart/toi-components';
import SpinnerButton from '../../../components/SpinnerButton';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog} from '../../../store/dialog/actions';
import PlanRelationshipForm from '../PlanRelationshipForm';

export interface PlanRelationshipFormDialogProps extends ConnectedReduxProps {
    plan: ArealplanDto;
    open: boolean;
}

const PlanRelationshipFormDialog = ({
    plan,
    open,
    dispatch,
}: PlanRelationshipFormDialogProps) => {
    const [submitting, setSubmitting] = React.useState(false);

    const onClose = async () => {
        return hideDialog(dispatch);
    };

    const handleSubmit = () => {
        document
            .getElementById('relationship-form')!
            .dispatchEvent(
                new Event('submit', {cancelable: true, bubbles: true})
            );
    };

    return (
        <ToiDialog
            open={open}
            aria-labelledby='form-dialog-title'
            maxWidth='lg'
            fullWidth={true}
            PaperProps={{style: {overflowY: 'visible'}}}
            disableAutoFocus={true}
        >
            <ToiDialogTitle id='form-dialog-title'>Planforhold</ToiDialogTitle>
            <ToiDialogContent style={{minHeight: '350px'}}>
                <PlanRelationshipForm
                    plan={plan}
                    formId='relationship-form'
                    setSubmitting={setSubmitting}
                    onClose={onClose}
                />
            </ToiDialogContent>
            <ToiDialogActions>
                <ToiButton variant='secondary' onClick={onClose}>
                    Avbryt
                </ToiButton>

                <SpinnerButton
                    label='Lagre'
                    loading={submitting}
                    disabled={submitting}
                    onClick={handleSubmit}
                />
            </ToiDialogActions>
        </ToiDialog>
    );
};

export default PlanRelationshipFormDialog;
