import {Select, SelectProps, styled} from '@mui/material';
import {forwardRef, useId} from 'react';
import {ChevronDownIcon} from '@norkart/toi-icons';
import {ToiFormControl} from '../pureMuiComponents';
import {ToiInputLabel} from '../ToiInputLabel/ToiInputLabel';
import {ToiBaseInput} from '../internal/BaseInput';
import {ToiFormHelperText} from '../ToiFormHelperText';

type InlineProps =
  | {
      inline: true;
      'aria-label': string;
    }
  | {
      inline?: false;
    };

type OwnProps = {
  label?: string;
  optional?: boolean;
  error?: boolean | string;
} & InlineProps;

export type ToiSelectProps = Omit<SelectProps, keyof OwnProps | 'variant'> & OwnProps;

const StyledSelect = styled(Select, {shouldForwardProp: (prop) => prop !== 'inline'})<ToiSelectProps>(
  ({theme, inline}) => ({
    // Specific styling for the select component. Other styles are applied on the BaseInput component

    background: inline ? 'transparent' : '',
    '&.MuiInputBase-root': inline && {
      outline: 'none',
      width: 'fit-content',
      padding: theme.toi.spacing['2xs'],
      paddingLeft: theme.toi.spacing['xs'],
      paddingRight: theme.toi.spacing['xs'],

      '&:hover': {
        background: theme.vars.toi.palette.backgrounds.component.hover,
      },

      '&.Mui-focused': {
        ...theme.toi.focus,
        background: theme.vars.toi.palette.backgrounds.component.selected,
      },

      '&:active': {
        background: theme.vars.toi.palette.backgrounds.component.selected,
      },
    },

    '& .MuiPaper-root   ': {
      marginTop: theme.toi.spacing['s'],
    },

    '& .MuiSelect-select': {
      minHeight: 0,
      display: 'block',
      alignItems: 'center',
    },

    '&.MuiInputBase-sizeSmall': {
      '& .MuiTypography-root': {
        ...theme.typography.body3,
      },
    },
  }),
);

export const ToiSelect = forwardRef<HTMLDivElement, ToiSelectProps>((props, forwardedRef) => {
  const {label, optional, error, fullWidth, ...restProps} = props;

  const labelId = useId();
  const menuId = useId(); // Mui handles this by default, but we need to specify it (and mock it) to make stable snapshots.

  return (
    <ToiFormControl
      variant='standard'
      error={Boolean(error)}
      fullWidth={fullWidth}
      sx={{width: fullWidth ? '100%' : '372px'}}
    >
      {label && (
        <ToiInputLabel optional={optional} id={labelId}>
          {label}
        </ToiInputLabel>
      )}

      <StyledSelect
        ref={forwardedRef}
        labelId={labelId}
        IconComponent={ChevronDownIcon}
        input={<ToiBaseInput sx={{cursor: 'default'}} />}
        SelectDisplayProps={{'aria-controls': menuId}}
        MenuProps={{MenuListProps: {id: menuId}, sx: {marginTop: (theme) => theme.toi.spacing['xs']}}}
        {...restProps}
      />
      {Boolean(error) && <ToiFormHelperText>{error}</ToiFormHelperText>}
    </ToiFormControl>
  );
});

export default ToiSelect;
