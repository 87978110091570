import {Accordion} from '@mui/material';
import qs from 'query-string';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {MapIcon, SearchIcon} from '@norkart/toi-icons';
import {ToiBox, ToiButton, ToiStack} from '@norkart/toi-components';
import LoadingSpinner from '../../../components/LoadingSpinner';
import DesktopContainer from '../../../containers/DesktopContainer';
import MobileContainer from '../../../containers/MobileContainer';
import useArealplan from '../../../hooks/arealplaner/useArealplan';
import useBehandlinger from '../../../hooks/behandlinger/useBehandlinger';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import useMeta from '../../../hooks/meta/useMeta';
import useMidlertidigForbud from '../../../hooks/midlertidigForbud/useMidlertidigForbud';
import {ConnectedReduxProps, getSearchState} from '../../../store';
import {showDialog} from '../../../store/dialog/actions';
import useCan from '../../../utils/auth/useCan';
import {toMoreMapFunctions} from '../../map/helpers/toMoreMapFunctions';
import PlanMap from '../../map/PlanMap';
import PlanNotifications from '../PlanNotifications';
import GjeldendeBestemmelser from './Components/GjeldendeBestemmelser';
import PlanInfoDetaljer from './Components/PlanInfoDetaljer';
import PlanInfoHeader from './Components/PlanInfoHeader';
import TopButtonsMenu from './Components/TopButtonsMenu';
import {shouldShowExternalMapButton} from './helpers';

export interface PlanInfoProps extends ConnectedReduxProps {
    resetMap: () => void;
    visDispIKart: boolean;
}

const PlanInfo: React.FC<PlanInfoProps> = ({
    visDispIKart,
    ...props
}: PlanInfoProps) => {
    const can = useCan();
    const meta = useMeta();
    const navigate = useNavigate();
    const kundeId = useSelectedKundeId();
    const {data: plan, isLoading} = useArealplan();
    const {data: behandlinger} = useBehandlinger();
    const midlertidigForbud = useMidlertidigForbud(plan.id);
    const searchState = useSelector(getSearchState);

    const handleEditClick = () => {
        props.dispatch(
            showDialog({dialogType: 'EDIT_PLAN', dialogProps: {plan}})
        );
    };
    const handleSearch = () => {
        props.resetMap();
        navigate({
            pathname: `/${kundeId}/arealplaner/search`,
            search: qs.stringify({...searchState.urlSearchParams}),
        });
    };

    const handleMapClick = () => {
        toMoreMapFunctions(plan, meta.visplanUrl);
    };

    const handleMapClickVisGI = () => {
        toMoreMapFunctions(plan, meta.visplanInternUrl);
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }
    return (
        <>
            <MobileContainer>
                <ToiStack gap={2} p={1}>
                    <ToiButton
                        variant='secondary'
                        onClick={handleSearch}
                        startIcon={<SearchIcon size={'small'} />}
                    >
                        Til plansøk
                    </ToiButton>
                    {shouldShowExternalMapButton(plan) &&
                        meta.visplanInternUrl &&
                        can.view && (
                            <ToiButton
                                variant='secondary'
                                onClick={handleMapClickVisGI}
                                startIcon={<MapIcon size={'small'} />}
                            >
                                {meta.visplanInternTekst || 'VisGI'}
                            </ToiButton>
                        )}
                    <PlanInfoHeader plan={plan} />
                    <GjeldendeBestemmelser />

                    <PlanNotifications
                        plan={plan}
                        behandlinger={behandlinger}
                        midlertidigForbud={midlertidigForbud}
                        variant='blocks'
                    />
                    <ToiBox
                        sx={{
                            height: '300px',
                        }}
                    >
                        <PlanMap showDispInMap={visDispIKart} />
                    </ToiBox>
                </ToiStack>
                <Accordion
                    expanded={false}
                    title={`Planinformasjon`}
                    style={{padding: 2}}
                >
                    <PlanInfoDetaljer plan={plan} />
                </Accordion>
            </MobileContainer>
            <DesktopContainer>
                <ToiStack gap={2} pt={4} pl={4} width={'100%'}>
                    <TopButtonsMenu
                        userCanView={can.view}
                        userCanEdit={can.edit}
                        handleSearch={handleSearch}
                        handleMapClickVisGI={
                            shouldShowExternalMapButton(plan)
                                ? handleMapClickVisGI
                                : undefined
                        }
                        handleMapClick={
                            shouldShowExternalMapButton(plan)
                                ? handleMapClick
                                : undefined
                        }
                        handleEditClick={handleEditClick}
                    />
                    <PlanInfoHeader plan={plan} />

                    <PlanInfoDetaljer plan={plan} />
                    <ToiStack gap={1}>
                        <GjeldendeBestemmelser />
                        <PlanNotifications
                            plan={plan}
                            behandlinger={behandlinger}
                            midlertidigForbud={midlertidigForbud}
                            variant='blocks'
                        />
                    </ToiStack>
                </ToiStack>
            </DesktopContainer>
        </>
    );
};

export default PlanInfo;
