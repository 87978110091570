import ToiButton from '../../ToiButton/ToiButton';
import {ToiDialogProps} from '../ToiDialog';
import {CommonDialogProps} from './types';

export type DefaultActionsProps = Pick<CommonDialogProps, 'onConfirm' | 'confirmText' | 'cancelText'> & {
  onClose?: ToiDialogProps['onClose'];
};

export function DefaultActions({
  onClose,
  onConfirm,
  confirmText = 'Bekreft',
  cancelText = 'Avbryt',
}: DefaultActionsProps) {
  function handleClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onClose && onClose(event, 'closeButton');
  }

  return (
    <>
      {onConfirm && (
        <ToiButton onClick={onConfirm} size='large'>
          {confirmText}
        </ToiButton>
      )}
      {onClose && (
        <ToiButton onClick={handleClose} size='large' variant='secondary'>
          {cancelText}
        </ToiButton>
      )}
    </>
  );
}
