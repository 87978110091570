import {Link, styled} from '@mui/material';
import DOMPurify from 'dompurify';
import {ToiLink} from '@norkart/toi-components';
import useMeta from '../../hooks/meta/useMeta';
import useCan from '../../utils/auth/useCan';
import './footer.css';

const Footer = () => {
    const meta = useMeta();
    const can = useCan();

    const StyledLink = styled(Link)({
        color: 'white',
        fontWeight: 300,
        textDecoration: 'underline',
    });

    const renderBottomRow = () => {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 10,
                    alignSelf: 'flex-end',
                    marginRight: '20px',
                    marginBottom: '10px',
                    alignItems: 'center',
                }}
            >
                {can.edit && (
                    <StyledLink
                        href='https://planoggeodata.zendesk.com/hc/no'
                        rel='noreferrer'
                        target='_blank'
                    >
                        Kundesenter
                    </StyledLink>
                )}
                <ToiLink
                    component='button'
                    sx={{
                        color: 'white',
                        fontWeight: 300,
                        textDecoration: 'underline',
                    }}
                    onClick={() => (window as any)?.CookieConsent?.renew()}
                >
                    Informasjonskapsler
                </ToiLink>
                <StyledLink
                    href={'/personvern'}
                    rel='noreferrer'
                    target='_blank'
                >
                    Personvernerklæring
                </StyledLink>
                {meta.tilgjengelighetUrl && (
                    <StyledLink
                        href={meta.tilgjengelighetUrl}
                        rel='noreferrer'
                        target='_blank'
                    >
                        Tilgjengelighetserklæring
                    </StyledLink>
                )}
            </div>
        );
    };

    const renderBrukerveiledning = () => {
        if (meta.brukerveiledningUrl)
            return (
                <div style={{maxWidth: '80vw', width: 400}}>
                    <p style={{color: 'white', fontWeight: 400}}>
                        Hjelp til å bruke arealplaner.no?
                    </p>
                    <p>
                        <StyledLink
                            rel='noreferrer'
                            href={meta.brukerveiledningUrl}
                            target='_blank'
                        >
                            Se brukerveiledning
                        </StyledLink>
                    </p>
                </div>
            );
    };

    const renderSporsmaal = () => {
        if (meta.sporsmaalInnhold && meta.sporsmaalInnhold !== '<p></p>\n')
            return (
                <div
                    className='sporsmaal-wrapper'
                    style={{maxWidth: '80vw', width: 400}}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(meta.sporsmaalInnhold),
                        }}
                    />
                </div>
            );
        if (meta.sporsmaalUrl)
            return (
                <div style={{maxWidth: '80vw', width: 400}}>
                    <p style={{color: 'white', fontWeight: 400}}>Spørsmål?</p>
                    <p style={{fontWeight: 300}}>
                        Har du spørsmål kan du kontakte{' '}
                        <StyledLink
                            rel='noreferrer'
                            href={meta.sporsmaalUrl}
                            target='_blank'
                        >
                            Plan- og bygningsavdelingens kundesenter
                        </StyledLink>
                    </p>
                </div>
            );
    };

    return (
        <div
            className='Footer'
            style={{
                height: 200,
                backgroundColor: '#4D4D4D',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                color: 'white',
            }}
        >
            {renderBrukerveiledning()}
            {renderSporsmaal()}
            {renderBottomRow()}
        </div>
    );
};
export default Footer;
