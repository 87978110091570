import axios from 'axios';
import {openSnackbarMessage} from '../../components/SnackbarMessage/SnackbarMessage';

export default function useErrorHandler() {
    const notify = (err: any) => {
        let errorMessage = 'Noe gikk galt';
        if (axios.isAxiosError(err)) errorMessage = err.response?.data?.message;
        if (err.message) errorMessage = err.message;
        if (typeof err == 'string') errorMessage = err;

        openSnackbarMessage({
            message: errorMessage,
            variant: 'error',
        });
    };

    return {notify};
}
