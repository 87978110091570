import {styled} from '@mui/material/styles';
import {ChevronDownIcon} from '@norkart/toi-icons';
import {ToiMenuTrigger, ToiMenuTriggerProps} from '../../../ToiBaseUIMenu/Trigger';

export type AppbarMenuTriggerProps = ToiMenuTriggerProps & {hideChevron?: boolean};
const StyledAppbarMenuTrigger = styled(ToiMenuTrigger)<ToiMenuTriggerProps>(({theme}) => {
  return {
    '--toi-palette-icons-secondary': theme.vars.toi.palette.text.default,
    '&.Mui-focusVisible': {
      ...theme.toi.focus,
      border: 'none',
      outlineOffset: '-2px',
    },
    ...theme.typography.body2,
    height: theme.mixins.toolbar.minHeight,
    paddingInline: theme.toi.spacing.s,
  };
});
export const AppbarMenuTrigger = (props: AppbarMenuTriggerProps) => {
  const {hideChevron, button, ...rest} = props;
  return (
    <StyledAppbarMenuTrigger
      {...rest}
      button={{variant: 'transparent', endIcon: !hideChevron && <ChevronDownIcon />, ...button}}
    />
  );
};
