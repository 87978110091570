import {useEffect, useState} from 'react';
import {
    ToiBox,
    ToiButton,
    ToiIconButton,
    ToiSpinner,
    ToiTextField,
} from '@norkart/toi-components';
import {CloseIcon, DownloadIcon, SearchIcon} from '@norkart/toi-icons';
import {fetchKonsistenssjekkReportCsv} from '../../../../services/api';
import {KonsistenssjekkSearchParams} from '../../../../hooks/konsistenssjekk/types';

type Props = {
    value: string;
    handleSearch: (term: string) => void;
    showRunningReport: boolean;
    searchParams: KonsistenssjekkSearchParams;
};

export default function Toolbar({
    value,
    handleSearch,
    showRunningReport,
    searchParams,
}: Props) {
    const [csv, setCsv] = useState<any | undefined>();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    useEffect(() => {
        if (csv) {
            const blob = new Blob(['\ufeff', csv], {
                type: 'data:text/csv; charset=utf-8',
            });

            const a = document.createElement('a');
            a.download = 'konsistenssjekk_report.csv';
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();

            setCsv(undefined);
            setIsDownloading(false);
        }
    }, [csv]);

    const handleDownloadClick = (e) => {
        setIsDownloading(true);
        fetchKonsistenssjekkReportCsv({
            term: searchParams.term,
            order: searchParams.order,
            orderBy: searchParams.orderBy,
        })
            .then((res) => res.data)
            .then((r) => setCsv(r))
            .catch((e) => {
                if (e.response.status === 404) return;
                else return e;
            });
    };

    return (
        <ToiBox
            display={'flex'}
            width={1}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <ToiTextField
                value={value}
                disabled={showRunningReport}
                onChange={(e) => handleSearch(e.target.value)}
                onKeyUp={(e) => {
                    if (e.key === 'Escape') handleSearch('');
                }}
                sx={{width: 400}}
                placeholder='Søk i tabell'
                endAdornment={
                    <ToiBox display={'flex'} alignItems={'center'} gap={1}>
                        <ToiIconButton
                            title='Clear'
                            aria-label='Clear'
                            color='transparent'
                            size='small'
                            style={{
                                visibility: value !== '' ? 'visible' : 'hidden',
                            }}
                            onClick={() => handleSearch('')}
                        >
                            <CloseIcon size='small' />
                        </ToiIconButton>
                        <SearchIcon fontSize='small' />
                    </ToiBox>
                }
            />
            <ToiButton
                onClick={(e) => handleDownloadClick(e)}
                disabled={showRunningReport || isDownloading}
                startIcon={
                    isDownloading ? (
                        <ToiSpinner />
                    ) : (
                        <DownloadIcon size='small' />
                    )
                }
            >
                Eksporter
            </ToiButton>
        </ToiBox>
    );
}
