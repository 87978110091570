import {CloseIcon} from '@norkart/toi-icons';
import {ToiIconButton, ToiIconButtonProps} from '../ToiIconButton';
import {ToiChipProps} from './ToiChip';

type OwnProps = Pick<ToiChipProps, 'onDelete'>;

export type ToiChipCloseButtonProps = Omit<ToiIconButtonProps, keyof OwnProps> & OwnProps;

export const ToiChipCloseButton = (props: ToiChipCloseButtonProps) => {
  return (
    <ToiIconButton
      className='ToiChipCloseButton-root'
      onClick={(e) => {
        e.stopPropagation();
        props.onDelete && props.onDelete(e);
      }}
      disableRipple
      aria-label={props['aria-label']}
    >
      <CloseIcon />
    </ToiIconButton>
  );
};
