import {CSSObject, styled} from '@mui/material/styles';

import {CircularProgress as MuiCircularProgress} from '@mui/material';

import {ToiCircularProgressProps} from './ToiCircularProgress';

export const StyledCircularProgress = styled(MuiCircularProgress, {
  shouldForwardProp: (prop) => !['halted', 'withLabel'].includes(prop.toString()),
})<ToiCircularProgressProps>(({theme, halted}) => {
  return {
    borderRadius: '50%',
    backgroundColor: 'transparent',
    color: halted ? theme.vars.toi.palette.bars.warning : theme.vars.toi.palette.bars.active,
    position: 'absolute',
    left: 0,
    animationDuration: '1700ms',

    '& .MuiCircularProgress-circle': {
      strokeDasharray: '25px 200px', // Cover 25% of the circle
      strokeLinecap: 'round',
    },
  };
});

export const StyledCircularProgressBackground = styled(MuiCircularProgress, {
  shouldForwardProp: (prop) => !['halted', 'withLabel'].includes(prop.toString()),
})(({theme}): CSSObject => {
  return {
    color: theme.vars.toi.palette.bars.fill,
  };
});
