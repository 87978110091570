import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {ToiCheckbox} from '@norkart/toi-components';
import {getJournalDocumentSelection} from '../../../store';
import {
    addJournalDocument,
    removeJournalDocument,
} from '../../../store/fileImport/actions';
import {SakArkivJournalDokumentDtoExtened} from '../../../hooks/journaler/types';
import ImportDocumentFromArchiveSelectFilesListItem from './ImportDocumentFromArchiveSelectFilesListItem';

type Props = {
    documents: SakArkivJournalDokumentDtoExtened[];
};

function ImportDocumentFromArchiveSelectFiles({documents}: Props) {
    const journalDocumentSelection = useSelector(getJournalDocumentSelection);
    const dispatch = useDispatch();

    const addFile = (data: SakArkivJournalDokumentDtoExtened) => {
        dispatch(addJournalDocument(data));
    };
    const removeFile = (data: SakArkivJournalDokumentDtoExtened) => {
        dispatch(removeJournalDocument(data));
    };

    const isSelected = (
        document: SakArkivJournalDokumentDtoExtened
    ): boolean => {
        return journalDocumentSelection.includes(document);
    };

    const handleToggleSingleDocument = (
        document: SakArkivJournalDokumentDtoExtened
    ) => {
        isSelected(document) ? removeFile(document) : addFile(document);
    };

    const handleToggleAllDocuments = () => {
        const allSelected = documents.every(isSelected);

        if (allSelected) {
            documents.forEach(removeFile);
        } else {
            documents
                .filter((document) => !isSelected(document))
                .forEach(addFile);
        }
    };

    const checkIfAllIsMarked = (): boolean => documents.every(isSelected);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell
                        variant='head'
                        sx={{textAlign: 'center', width: '52px'}}
                    >
                        <ToiCheckbox
                            name='selected'
                            checked={checkIfAllIsMarked()}
                            onClick={handleToggleAllDocuments}
                        />
                    </TableCell>
                    <TableCell variant='head'>Dokumenttittel</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {documents.map((document, i) => (
                    <ImportDocumentFromArchiveSelectFilesListItem
                        key={i}
                        document={document}
                        handleToggleSingleDocument={() =>
                            handleToggleSingleDocument(document)
                        }
                        isSelected={isSelected(document)}
                    />
                ))}
            </TableBody>
        </Table>
    );
}

export default ImportDocumentFromArchiveSelectFiles;
