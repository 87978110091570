import {CssVarsThemeOptions} from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    label: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    label?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    label: true;
    h5: false;
    h6: false;
  }
}

const fontFamilyPoppins = 'Poppins, ui-sans-serif, sans-serif';
const fontFamilyNotoSans = 'Noto Sans, Arial, sans-serif';

export const typographyStyle: CssVarsThemeOptions['typography'] = {
  allVariants: {
    color: 'var(--toi-palette-text-default)',
  },
  h1: {
    fontFamily: fontFamilyPoppins,
    fontSize: 'var(--toi-font-size-4xl)',
    lineHeight: 'var(--toi-font-line-height-4xl)',
    fontWeight: 500,
    marginBottom: 'var(--toi-spacing-m)',
    hyphens: 'auto',
  },
  h2: {
    fontFamily: fontFamilyPoppins,
    fontSize: 'var(--toi-font-size-3xl)',
    lineHeight: 'var(--toi-font-line-height-3xl)',
    fontWeight: 500,
    marginBottom: 'var(--toi-spacing-s)',
    hyphens: 'auto',
  },
  h3: {
    fontFamily: fontFamilyPoppins,
    fontSize: 'var(--toi-font-size-xxl)',
    lineHeight: 'var(--toi-font-line-height-xxl)',
    fontWeight: 500,
    marginBottom: 'var(--toi-spacing-s)',
    hyphens: 'auto',
  },
  h4: {
    fontFamily: fontFamilyPoppins,
    fontSize: 'var(--toi-font-size-xl)',
    lineHeight: 'var(--toi-font-line-height-xl)',
    fontWeight: 500,
    marginBottom: 'var(--toi-spacing-s)',
    hyphens: 'auto',
  },
  subtitle1: {
    fontFamily: fontFamilyNotoSans,
    fontSize: 'var(--toi-font-size-l)',
    lineHeight: 'var(--toi-font-line-height-l)',
    fontWeight: 700,
  },
  subtitle2: {
    fontFamily: fontFamilyNotoSans,
    fontSize: 'var(--toi-font-size-m)',
    lineHeight: 'var(--toi-font-line-height-m)',
    fontWeight: 600,
  },
  body1: {
    fontFamily: fontFamilyNotoSans,
    fontSize: 'var(--toi-font-size-xl)',
    lineHeight: 'var(--toi-font-line-height-xl)',
    fontWeight: 400,
  },
  body2: {
    fontFamily: fontFamilyNotoSans,
    fontSize: 'var(--toi-font-size-m)',
    lineHeight: 'var(--toi-font-line-height-m)',
    fontWeight: 400,
  },
  body3: {
    fontFamily: fontFamilyNotoSans,
    fontSize: 'var(--toi-font-size-s)',
    lineHeight: 'var(--toi-font-line-height-s)',
    fontWeight: 400,
    color: 'var(--toi-palette-text-default)', // Styling from allVariants not applied becuase not default Mui variant. Same for `body4` and `label`
  },
  body4: {
    fontFamily: fontFamilyNotoSans,
    fontSize: 'var(--toi-font-size-xs)',
    lineHeight: 'var(--toi-font-line-height-xs)',
    fontWeight: 400,
    color: 'var(--toi-palette-text-default)',
  },
  label: {
    fontFamily: fontFamilyNotoSans,
    fontSize: 'var(--toi-font-size-s)',
    lineHeight: 'var(--toi-font-line-height-m)',
    fontWeight: 600,
    color: 'var(--toi-palette-text-default)',
  },
};
