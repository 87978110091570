import {BoxProps, useTheme} from '@mui/material';
import {forwardRef} from 'react';
import {CopyIcon} from '@norkart/toi-icons';
import {ToiTypography, ToiTypographyProps} from '../ToiTypography';
import {ToiStack, ToiBox} from '../pureMuiComponents';
import {ToiIconButton} from '../ToiIconButton';

type ToiDataDisplayBaseProps = {
  direction?: 'row' | 'column';
  label: string;
  value: React.ReactNode;
  slotProps?: {
    labelProps?: ToiTypographyProps;
    valueProps?: ToiTypographyProps;
  };
  showCopyButtonOnHover?: boolean;
};

type OwnProps = ToiDataDisplayBaseProps &
  ({direction?: 'row'; icon?: never} | {direction?: 'column'; icon?: React.ReactNode});

export type ToiDataDisplayProps = Omit<BoxProps, keyof OwnProps | 'component'> & OwnProps;

export const ToiDataDisplay = forwardRef<HTMLElement, ToiDataDisplayProps>(
  ({direction = 'column', label, value, icon, slotProps, showCopyButtonOnHover, ...props}, forwardedRef) => {
    const theme = useTheme();

    return (
      <ToiBox width={1} ref={forwardedRef} {...props}>
        <ToiStack direction='row' alignItems='center' spacing={theme.toi.spacing.s}>
          {icon && <div>{icon}</div>}
          <ToiStack direction={direction} position='relative' spacing={direction === 'row' ? theme.toi.spacing.xs : 0}>
            <ToiTypography variant='label' {...slotProps?.labelProps}>
              {label}
            </ToiTypography>
            {['string', 'number'].includes(typeof value) ? (
              <ToiTypography {...slotProps?.valueProps}>{value}</ToiTypography>
            ) : (
              value
            )}
            {showCopyButtonOnHover && (
              <ToiIconButton aria-label='Copy'>
                <CopyIcon />
              </ToiIconButton>
            )}
          </ToiStack>
        </ToiStack>
      </ToiBox>
    );
  },
);

export default ToiDataDisplay;
