import {useTheme} from '@mui/material';
import {LogoProps} from './ToiLogo';

export function IconLogo({blackWhite, title, ...svgProps}: LogoProps) {
  const brandColors = useTheme().vars.toi.palette.brand;

  return (
    <svg
      width='128'
      height='134'
      viewBox='0 0 128 134'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMidYMid meet'
      {...svgProps}
    >
      <title>{title ?? 'Norkart Logo Icon'}</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.2911 68.9461L83.5026 67.205L67.7562 32.3647C66.3343 29.2118 61.6673 29.2118 60.2454 32.3647L50.5469 53.8264L84.2911 68.9506V68.9461ZM82.4846 74.0795L48.312 58.7658L44.4973 67.2052L30.3552 98.5002C28.7464 102.059 32.9302 105.456 36.3302 103.354L82.4846 74.0795ZM69.0729 89.3887L87.9276 76.9979V76.9934L97.6443 98.4957C99.2531 102.055 95.0693 105.451 91.6693 103.349L69.0729 89.3887Z'
        fill={blackWhite ? brandColors.wordmark : brandColors.logo}
      />
    </svg>
  );
}
