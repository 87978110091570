import {
  AccordionSummaryTypeMap as MuiAccordionSummaryTypeMap,
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from '@mui/material';
import {ElementType, ReactNode, forwardRef} from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {isString} from 'lodash-es';
import {ToiStack} from '../../../pureMuiComponents';
import {AccordionSize} from '../../ToiAccordion/ToiAccordion';
import {ToiAccordionStatusType, ToiAccordionStatus} from '../ToiAccordionStatus';
import {ToiAccordionBadge} from './ToiAccordionBadge';
import {ToiAccordionTitle} from './ToiAccordionTitle';
import {StyledAccordionSummary} from './StyledAccordionSummary';

type OwnProps = {
  title?: string;
  subtitle?: string;
  icon?: ReactNode;
  summaryContent?: ReactNode;
  badgeValue?: number;
  hasChildren?: boolean;
  component?: ElementType;
  status?: ToiAccordionStatusType | ReactNode;
  size?: AccordionSize;
};

type DefaultComponent = MuiAccordionSummaryTypeMap['defaultComponent'];

type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiAccordionSummaryProps = MuiAccordionSummaryProps<DefaultComponent, OwnProps>;

export const ToiAccordionSummary = forwardRef<RefType, ToiAccordionSummaryProps>((props, ref) => {
  const {icon, summaryContent, badgeValue, status} = props;

  return (
    <ToiStack direction='row'>
      {status && isString(status) ? <ToiAccordionStatus status={status as ToiAccordionStatusType} /> : status}
      <StyledAccordionSummary ref={ref} {...props}>
        {icon}
        <ToiAccordionTitle {...props} />
        <ToiAccordionBadge value={badgeValue} />
        {summaryContent}
      </StyledAccordionSummary>
    </ToiStack>
  );
}) as OverridableComponent<MuiAccordionSummaryTypeMap<OwnProps>>;

export default ToiAccordionSummary;
