import {useTheme} from '@mui/material';
import {ToiTypography} from '../../../ToiTypography/ToiTypography';
import {ToiBox} from '../../../pureMuiComponents';
import {ToiAccordionSummaryProps} from './ToiAccordionSummary';

export const ToiAccordionTitle = ({
  title,
  subtitle,
  status,
}: Pick<ToiAccordionSummaryProps, 'title' | 'subtitle' | 'status'>) => {
  const theme = useTheme();

  return title || subtitle ? (
    <ToiBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.toi.spacing['2xs'],
        textAlign: 'start',
      }}
    >
      {title && (
        <ToiTypography
          variant={status || subtitle ? 'h4' : 'subtitle2'}
          component={'p'}
          sx={{alignSelf: 'flex-start'}}
          noSpacing
        >
          {title}
        </ToiTypography>
      )}
      {subtitle && (
        <ToiTypography variant='body3' align='left' sx={{alignSelf: 'flex-start'}}>
          {subtitle}
        </ToiTypography>
      )}
    </ToiBox>
  ) : null;
};
