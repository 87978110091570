import {useEffect, useState} from 'react';
import {TokenDto, TokenType} from '@norkart/nkapi-arealplaner-api';
import {
    ToiBox,
    ToiButton,
    ToiDialogSimple,
    ToiTextField,
} from '@norkart/toi-components';
import {DeleteRecycleIcon} from '@norkart/toi-icons';
import SpinnerButton from '../../../components/SpinnerButton';
import useCreateToken from '../../../hooks/tokens/useCreateToken';
import useDeleteToken from '../../../hooks/tokens/useDeleteToken';
import useUpdateToken from '../../../hooks/tokens/useUpdateToken';
import {useNotification} from '../../../hooks/useNotification';

type FormData = Partial<TokenDto>;
type Props = {
    isOpen: boolean;
    handleClose: () => void;
    token?: TokenDto;
    tokenType: TokenType;
};

function TokenDialog({isOpen, handleClose, token, tokenType}: Props) {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const createToken = useCreateToken(tokenType);
    const updateToken = useUpdateToken(tokenType);
    const deleteToken = useDeleteToken(tokenType);
    const [formData, setFormData] = useState<FormData>(
        token || {description: ''}
    );
    const {notifySuccess} = useNotification();

    useEffect(() => {
        if (token) setFormData(token);
        else setFormData({description: ''});
    }, [token]);

    const handleSave = async () => {
        const res =
            token && token.id
                ? await updateToken.mutateAsync(
                      {
                          id: token.id,
                          description: formData.description ?? '',
                      },
                      {onSuccess: notifySuccess('Tilgang oppdatert')}
                  )
                : await createToken.mutateAsync(
                      {description: formData.description ?? ''},
                      {onSuccess: notifySuccess('Tilgang opprettet')}
                  );

        handleClose();
    };

    const handleConfirmDeleteOpen = () => {
        setConfirmDeleteOpen(true);
    };
    const handleConfirmDeleteClose = () => {
        setConfirmDeleteOpen(false);
    };
    const handleDelete = async () => {
        setConfirmDeleteOpen(false);
        if (token && token.id) {
            await deleteToken.mutateAsync(token.id, {
                onSuccess: notifySuccess('Tilgang slettet'),
            });
        }
        handleClose();
    };

    const isLoading =
        updateToken.isLoading || createToken.isLoading || deleteToken.isLoading;

    return (
        <>
            <ToiDialogSimple
                title={token ? 'Rediger tilgang' : 'Opprett ny tilgang'}
                open={isOpen}
                onClose={handleClose}
                onConfirm={handleConfirmDeleteOpen}
                maxWidth='sm'
                fullWidth={true}
                actions={
                    <ToiBox
                        display='flex'
                        justifyContent='space-between'
                        width='100%'
                    >
                        {token && (
                            <ToiButton
                                color='error'
                                onClick={handleConfirmDeleteOpen}
                                startIcon={<DeleteRecycleIcon />}
                            >
                                Slett tilgang
                            </ToiButton>
                        )}

                        <ToiBox display='flex' gap={2}>
                            <ToiButton
                                onClick={handleClose}
                                variant='secondary'
                            >
                                Avbryt
                            </ToiButton>
                            <SpinnerButton
                                onClick={handleSave}
                                label={token ? 'Lagre' : 'Opprett'}
                                disabled={isLoading}
                                loading={isLoading}
                            />
                        </ToiBox>
                    </ToiBox>
                }
            >
                <form>
                    <ToiTextField
                        name='description'
                        label='Beskrivelse'
                        value={formData.description}
                        autoComplete='false'
                        fullWidth
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                description: e.target.value,
                            })
                        }
                    />
                </form>
            </ToiDialogSimple>
            {token && (
                <ToiDialogSimple
                    title='Vil du virkelig slette tilgangen?'
                    open={confirmDeleteOpen}
                    onClose={handleConfirmDeleteClose}
                    onConfirm={handleDelete}
                    confirmText='Ja'
                    cancelText='Nei'
                />
            )}
        </>
    );
}

export default TokenDialog;
