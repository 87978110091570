import * as React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {connect} from 'react-redux';
import {uniqBy} from 'lodash-es';
import {Coords, MapboxLayer} from '@norkart/nkm-mapbox-map';
import {
    DispensasjonDto,
    DispensasjonForCreationDto,
    SakVerdiDto,
} from '@norkart/nkapi-arealplaner-api';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import * as actions from '../../../store/map/actions';
import {ApplicationState, ConnectedReduxProps} from '../../../store';
import {DispensasjonFormData} from '../../../hooks/dispensasjoner/types';
import {GFIAttrState, WmsLayersRequestState} from '../../../store/map/types';
import DispForm from '../DispForm';
import SelectMapPosition from '../../map/SelectMapPosition';
import {fetchGFI} from '../../map/helpers/gfiHelpers';
import {hideDialog} from '../../../store/dialog/actions';
import {showDialog} from '../../../store/dialog/actions';
import {toLatLng} from '../../behandling/BehandlingFormDialog/helpers';
import useCreateDispensasjon from '../../../hooks/dispensasjoner/useCreateDispensasjon';
import useMeta from '../../../hooks/meta/useMeta';
import useUpdateDispensasjon from '../../../hooks/dispensasjoner/useUpdateDispensasjon';

export interface DispFormDialogProps extends ConnectedReduxProps {
    disp: DispensasjonDto;
    plan: ArealplanDto;
    open: boolean;
    focusRow: (id: string) => void;
    onError: (errors) => void;
    gfiState: GFIAttrState;
    wmsLayerState: WmsLayersRequestState;
    referansesystemKode: string;
    planAreaLayer: MapboxLayer | undefined;
    knr: string;
}

const DispFormDialog = ({
    disp,
    plan,
    open,
    onError,
    focusRow,
    dispatch,
    gfiState,
    wmsLayerState,
    planAreaLayer,
}: DispFormDialogProps) => {
    const [displaySelectMapPosition, setDisplaySelectMapPosition] =
        React.useState(false);
    const [dispSubmitting, setDispSubmitting] = React.useState<boolean>(false);
    const [coords, setCoords] = React.useState<
        | {
              x: number;
              y: number;
          }
        | undefined
    >(undefined);

    const [vertikalnivaId, setVertikalnivaId] = React.useState<
        number | undefined
    >(1);
    const [dispFraTyper, setDispFraTyper] = React.useState<
        {Key: string; Value: string}[]
    >([]);

    const meta = useMeta();
    const createDisp = useCreateDispensasjon();
    const editDisp = useUpdateDispensasjon();

    React.useEffect(() => {
        if (disp.posisjon?.x && disp.posisjon?.y) {
            setCoords({
                x: disp.posisjon.x,
                y: disp.posisjon.y,
            });
        } else {
            setCoords(undefined);
        }
        setVertikalnivaId(getDefaultVertikalniva(disp, plan));
    }, [disp, plan]);

    React.useEffect(() => {
        if (gfiState.planAttributes && gfiState.planAttributes.length) {
            const attrList = gfiState.planAttributes[0].wmsLayers.map((l) =>
                l.attrList.map((a) => {
                    let type = a.FeatureType;
                    let attribute = a.AttributesTree.find(
                        (t) => t.Description === 'RPAREALFORMÅL'
                    );
                    if (!attribute) {
                        attribute = a.AttributesTree.find(
                            (t) => t.Description === 'RPJURLINJE'
                        );
                    }
                    if (attribute) {
                        type = attribute.Value;
                    }
                    return {Key: type, Value: type};
                })
            );
            const dispFra = uniqBy(attrList[0], 'Key');
            if (dispFra.length > 0) {
                dispFra.push({Key: 'Bestemmelser', Value: 'Bestemmelser'});
            }
            if (JSON.stringify(dispFraTyper) != JSON.stringify(dispFra)) {
                setDispFraTyper(dispFra);
            }
        }
    }, [gfiState]);

    React.useEffect(() => {
        if (coords && coords.x) {
            const latLngCoords: Coords = toLatLng(
                coords,
                meta.referansesystemKode
            )!;
            fetchNewGFI(latLngCoords);
        }
    }, [coords]);

    const getInitCoords = () => {
        return disp.posisjon?.x && disp.posisjon?.y
            ? {
                  x: disp.posisjon.x,
                  y: disp.posisjon.y,
              }
            : null;
    };

    const initCoords = getInitCoords();

    const getDefaultVertikalniva = (
        disp: DispensasjonDto,
        plan: ArealplanDto
    ) => {
        if (disp.vertikalnivaId) return disp.vertikalnivaId;
        if (plan.vertikalniva?.length) {
            return (
                plan.vertikalniva.find((vertniv) => vertniv.id == 2)?.id ||
                plan.vertikalniva[0].id
            );
        }
        return undefined;
    };
    const initVertikalnivaId = getDefaultVertikalniva(disp, plan);

    const onClose = () => {
        hideDialog(dispatch);
    };

    const onPositionSelected = (coords: {x: number; y: number} | undefined) => {
        setCoords(coords);
    };

    const onDeleteDisp = () => {
        dispatch(
            showDialog({
                dialogType: 'DELETE_DISP',
                dialogProps: {disp: disp, plan, focusRow: focusRow},
            })
        );
    };

    const onDeleteCoords = () => {
        setCoords(undefined);
    };

    const onVertikalNivaaChange = (id: number) => {
        if (vertikalnivaId != id) {
            setVertikalnivaId(id);
        }
    };

    const fetchNewGFI = (coords: Coords) => {
        fetchGFI(
            wmsLayerState,
            coords,
            undefined,
            (data) => dispatch(actions.fetchGfiSuccess(data)),
            meta.komnr
        );
    };

    const onSubmit = async (data: DispensasjonFormData): Promise<any> => {
        setDispSubmitting(true);
        if (!data.sak?.sakAar) {
            return;
        }
        if (!data.sak.sakSeknr) {
            return;
        }
        const sak = {
            sakAar: data.sak.sakAar,
            sakSeknr: data.sak.sakSeknr,
            arkivId: data.sak.arkivId,
        } as SakVerdiDto;

        const dispData: DispensasjonForCreationDto = {
            dispensasjonTypeId: data.dispensasjonTypeId,
            vedtaksdato: data.vedtaksdato?.toString(),
            beskrivelse: data.beskrivelse,
            vedtakId: data.vedtakId,
            sak: sak,
            vertikalnivaId: data.vertikalnivaId,
            dispFra: data.dispFra,
            journalpostnummer: data.journalpostnummer,
            journalaar: data.journalaar,
        };
        if (coords) {
            dispData.xkoord = coords.x;
            dispData.ykoord = coords.y;
            dispData.koordSys = meta.referansesystemKode;
        }

        if (disp.id) {
            return editDisp.mutate(
                {dispensasjonId: disp.id, dispensasjon: dispData},
                {
                    onSuccess: () => {
                        onClose();
                    },
                    onSettled: () => {
                        disp.id && focusRow(disp.id.toString());
                        setDispSubmitting(false);
                    },
                }
            );
        } else {
            return (
                plan.id &&
                createDisp.mutate(
                    {
                        dispensasjon: dispData,
                        arealplanId: plan.id,
                    },
                    {
                        onSuccess: (data: DispensasjonDto) => {
                            onClose();
                            data.id && focusRow(data.id.toString());
                            setDispSubmitting(false);
                        },
                    }
                )
            );
        }
    };

    return (
        <>
            {displaySelectMapPosition && (
                <SelectMapPosition
                    plan={plan}
                    initCoords={coords}
                    onPositionSelected={onPositionSelected}
                    setDisplaySelectMapPosition={setDisplaySelectMapPosition}
                    vertikalnivaId={vertikalnivaId}
                    title='Velg posisjon for dispensasjonen'
                />
            )}
            <Dialog
                open={open}
                aria-labelledby='form-dialog-title'
                maxWidth='sm'
                PaperProps={{style: {overflowY: 'visible'}}}
                disableAutoFocus={true}
                disableScrollLock={true}
            >
                <DialogTitle id='form-dialog-title'>
                    <Grid
                        container={true}
                        justifyContent='space-between'
                        spacing={8}
                        alignItems='center'
                    >
                        <Grid item={true}>
                            {disp.id ? 'Endre dispensasjon' : 'Ny dispensasjon'}
                        </Grid>
                        <Grid item={true}>
                            <IconButton onClick={onClose}>
                                <CloseIcon fontSize='small' />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DispForm
                        onDeleteCoords={onDeleteCoords}
                        onVertikalNivaaChange={onVertikalNivaaChange}
                        disp={disp}
                        plan={plan}
                        dispIsSubmitting={dispSubmitting}
                        onSubmit={onSubmit}
                        onCancel={onClose}
                        onDelete={onDeleteDisp}
                        focusRow={focusRow}
                        coords={coords}
                        setCoords={setCoords}
                        onPositionSelected={onPositionSelected}
                        setDisplaySelectMapPosition={
                            setDisplaySelectMapPosition
                        }
                        getDefaultVertikalniva={getDefaultVertikalniva}
                        coordsHasChanged={
                            initCoords !== coords ||
                            initVertikalnivaId !== vertikalnivaId
                        }
                        dispFraTyper={dispFraTyper}
                        planAreaLayer={planAreaLayer}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default connect((state: ApplicationState) => ({
    gfiState: state.map.gfiState,
    wmsLayerState: state.map.wmsLayerState,
    planAreaLayer: state.map.borderLayers?.combined,
}))(DispFormDialog);
