import {CSSObject, Theme} from '@mui/material';
import {ToiMenuSize} from './types';

/**
 * @deprecated Use ToiBaseUIMenu instead since it has better UU support
 */
export function toiMenuStyle(theme: Theme, width?: string, size?: ToiMenuSize): CSSObject {
  return {
    width: width ?? 248,
    backgroundColor: theme.vars.toi.palette.backgrounds.component.overlay,
    paddingBlock: theme.toi.spacing.xs,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.toi.spacing['2xs'],
    borderRadius: '4px',

    '& .MuiDivider-root': {
      marginBlock: '4px !important',
    },

    ...(size === 'small' && {
      '& .MuiTypography-root': theme.typography.body3,
    }),

    '& svg': {
      width: size === 'small' ? 20 : 24,
      height: size === 'small' ? 20 : 24,
    },
  };
}
