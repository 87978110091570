import {RadioProps as MuiRadioProps} from '@mui/material';
import {forwardRef} from 'react';
import {StyledRadio} from './StyledRadio';
import {CheckedRadio, UncheckedRadio} from './icons';

export type ToiRadioProps = MuiRadioProps;

export const ToiRadio = forwardRef<HTMLButtonElement, ToiRadioProps>((props, ref) => {
  const {...restProps} = props;

  return (
    <StyledRadio
      ref={ref}
      className='toiRadio'
      disableRipple
      icon={<UncheckedRadio />}
      checkedIcon={<CheckedRadio />}
      {...restProps}
    />
  );
});

export default ToiRadio;
