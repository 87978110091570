import {styled} from '@mui/material';
import React, {forwardRef, useEffect, useRef} from 'react';
import {ChevronLeft, ChevronRight, FirstPage, LastPage} from '@mui/icons-material';
import {ToiIconButton} from '../ToiIconButton';
import {ToiTypography} from '../ToiTypography';
import {ToiBaseInput} from '../internal/BaseInput';

interface ToiTablePaginationActionsProps {
  className?: string;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  page: number;
  count: number;
  rowsPerPage: number;
}

export const ToiTablePaginationActions = forwardRef<HTMLDivElement, ToiTablePaginationActionsProps>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {onPageChange, page, count, rowsPerPage} = props;

  const numberOfPages = Math.ceil(count / rowsPerPage);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newPage = parseInt(e.target.value, 10) - 1;
    if (newPage >= 0 && newPage < numberOfPages) {
      // Only change table state if input value is a valid page number
      onPageChange(null, newPage);
    }
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    // Reset input value if it's not a number or out of bounds
    if (!inputRef.current) return;

    const inputValue = e.target.value;
    if (!inputValue.match(/^\d+$/)) {
      inputRef.current.value = `${page + 1}`;
      return;
    }

    const newPage = parseInt(e.target.value, 10) - 1;
    if (newPage < 0 || newPage > numberOfPages) {
      inputRef.current.value = `${page + 1}`;
    }
  }

  useEffect(() => {
    // Update input value when page changes
    if (inputRef.current) {
      inputRef.current.value = `${page + 1}`;
    }
  }, [page]);

  return (
    <Container ref={ref} className={props.className}>
      <ToiIconButton
        color='transparent'
        aria-label='Første side'
        onClick={(e) => onPageChange(e, 0)}
        disabled={page === 0}
      >
        <FirstPage />
      </ToiIconButton>
      <ToiIconButton
        color='transparent'
        aria-label='Forrige side'
        onClick={(e) => onPageChange(e, page - 1)}
        disabled={page === 0}
      >
        <ChevronLeft />
      </ToiIconButton>
      <PageDisplayContainer>
        <ToiBaseInput
          inputRef={inputRef}
          defaultValue={page + 1}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{pattern: /^\d*/}}
        />
        <ToiTypography> av {numberOfPages}</ToiTypography>
      </PageDisplayContainer>
      <ToiIconButton
        color='transparent'
        aria-label='Neste side'
        onClick={(e) => onPageChange(e, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <ChevronRight />
      </ToiIconButton>
      <ToiIconButton
        color='transparent'
        aria-label='Siste side'
        onClick={(e) => onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPage />
      </ToiIconButton>
    </Container>
  );
});

const Container = styled('div')(({theme}) => ({
  '&.MuiTablePagination-actions': {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.toi.spacing['2xs'],
  },

  '& .MuiSvgIcon-root': {
    color: theme.vars.toi.palette.text.default,
  },

  '& .Mui-disabled > .MuiSvgIcon-root': {
    color: theme.vars.toi.palette.disabled.text,
  },
}));

const PageDisplayContainer = styled('div')(({theme}) => ({
  paddingInline: theme.toi.spacing.xs,
  display: 'flex',
  alignItems: 'center',
  gap: theme.toi.spacing['2xs'],
  margin: 0,

  '& .MuiInputBase-root': {
    width: 40,
    height: 38,
    boxSizing: 'border-box',
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
}));
