import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ToiBox} from '@norkart/toi-components';
import {MapLayersIcon} from '@norkart/toi-icons';
import {AvailableWmsLayer} from '../../../types';
import {addWmsLayer, removeWmsLayer} from '../../../../../store/map/actions';
import MenuButton from '../MenuButton';
import {getMapState} from '../../../../../store';
import {handleLayerClick, mapLayerMenuStyle} from './helper';
import KartlagMenuItem from './KartlagMenuItem';

type Props = {
    label?: string;
    defaultClosed?: boolean;
};

const PlanensKartlag = ({label, defaultClosed}: Props) => {
    const mapState = useSelector(getMapState);
    const dispatch = useDispatch();
    const [mapLayersMenuVisible, setMapLayersMenuVisible] = useState<boolean>(
        defaultClosed ? false : true
    );
    const [drawnWmsLayers, setDrawnWmsLayers] = useState<AvailableWmsLayer[]>(
        []
    );

    const availableWmsLayers = mapState.wmsLayerState.availableWmsLayers;

    useEffect(() => {
        if (mapState.wmsLayerState.availableWmsLayers) {
            setDrawnWmsLayers(
                mapState.wmsLayerState.availableWmsLayers.filter(
                    (lay) => lay.isVisible
                )
            );
        }
    }, [mapState.wmsLayerState.availableWmsLayers]);

    return (
        <ToiBox className='Kartlag section'>
            <MenuButton
                name={`${label ? label : 'Planens kartlag'} ${
                    mapState.wmsLayerState.availableWmsLayers?.length
                        ? '(' +
                          mapState.wmsLayerState.availableWmsLayers.length +
                          ')'
                        : ''
                } `}
                Icon={<MapLayersIcon />}
                onClick={() => setMapLayersMenuVisible(!mapLayersMenuVisible)}
                isOpen={mapLayersMenuVisible}
            />
            {mapLayersMenuVisible && (
                <ToiBox sx={(theme) => mapLayerMenuStyle(theme)}>
                    {!!mapState.wmsLayerState.availableWmsLayers?.length &&
                        mapState.wmsLayerState.availableWmsLayers.map(
                            (layer, index) => (
                                <KartlagMenuItem
                                    visible={!!drawnWmsLayers?.includes(layer)}
                                    text={layer.displayName}
                                    handleToggle={() =>
                                        handleLayerClick(
                                            layer,
                                            availableWmsLayers,
                                            (layer: AvailableWmsLayer) =>
                                                dispatch(addWmsLayer(layer)),
                                            (layer: AvailableWmsLayer) =>
                                                dispatch(removeWmsLayer(layer))
                                        )
                                    }
                                    key={index}
                                />
                            )
                        )}
                </ToiBox>
            )}
        </ToiBox>
    );
};
export default PlanensKartlag;
