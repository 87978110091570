import {
    Collapse,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import arrayMutators from 'final-form-arrays';
import * as React from 'react';
import {Form} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {useDispatch} from 'react-redux';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    CloseIcon,
    DeleteRecycleIcon,
    SearchIcon,
} from '@norkart/toi-icons';
import {
    ToiBox,
    ToiButton,
    ToiIconButton,
    ToiSpinner,
    ToiTextField,
    ToiTypography,
} from '@norkart/toi-components';
import {
    ArealplanDto,
    arealplanerGetArealplaner,
    ArealplanForUpdateDto,
    ArealplanSearchResultDto,
} from '@norkart/nkapi-arealplaner-api';
import LoadingSpinner from '../../../components/LoadingSpinner';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import TabbableTableLink from '../../../components/TabbableTableLink';
import useUpdateArealplan from '../../../hooks/arealplaner/useUpdateArealplan';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import useMeta from '../../../hooks/meta/useMeta';
import {useArealplanerApiClientContext} from '../../../services/arealplanerApi';
import {showDialog} from '../../../store/dialog/actions';

interface PlanRelationshipFormProps {
    plan: ArealplanDto;
    formId?: string;
    setSubmitting: (boolean) => void;
    onClose: () => any;
}

const SearchResultsWrapper = styled('div')(({theme}) => ({
    position: 'absolute',
    zIndex: 10,
    height: '200px',
    overflowY: 'auto',
    backgroundColor: 'white',
    maxWidth: '60%',
    boxShadow: '1px 1px grey',
    '& table': {tableLayout: 'fixed', width: '100%'},
    '& tr': {cursor: 'pointer'},
}));

const PlanRelationshipForm: React.FC<PlanRelationshipFormProps> = ({
    plan,
    formId,
    setSubmitting,
    onClose,
}: PlanRelationshipFormProps) => {
    const meta = useMeta();
    const {mutateAsync: updateArealplan} = useUpdateArealplan();

    const dispatch = useDispatch();

    const defaultPlanforholdTypeId = 5;
    const [expanded, setExpanded] = React.useState(true);
    const [searching, setSearching] = React.useState(false);
    const kundeId = useSelectedKundeId();
    const [term, setTerm] = React.useState('');
    const [selectedPlanforholdTypeId, setSelectedPlanforholdtypeId] =
        React.useState(defaultPlanforholdTypeId);
    const {client} = useArealplanerApiClientContext();

    const [searchResults, setSearchResults] = React.useState(
        [] as ArealplanSearchResultDto[]
    );

    const [pristine, setPristine] = React.useState(true);

    const handleSearch = async () => {
        setSearching(true);
        const res = await arealplanerGetArealplaner({
            path: {kundeId},
            query: {term},
            client,
        });

        setSearchResults(res.data ?? ([] as ArealplanSearchResultDto[]));
        setPristine(false);
        setSearching(false);
    };

    const handleClear = () => {
        setTerm('');
        clearSearchResult();
    };

    const clearSearchResult = () => {
        setSearchResults([]);
        const inputField = document.getElementById('plan-relationship-search');
        inputField && inputField.focus();
    };

    const getPlanforholdType = (type) => {
        const planforholdtype = meta.data.planforholdtyper.find(
            (p) => p.id === type
        );
        return planforholdtype ? planforholdtype.beskrivelseForloper : '';
    };

    const handleSubmit = async (values) => {
        setSubmitting(true);
        const data = {
            ...plan,
            planforholdForlopere: values.planforhold,
        } as ArealplanForUpdateDto;

        if (plan.id) {
            const res = await updateArealplan({
                arealplanForUpdate: data,
                arealplanId: plan.id,
            });
        }
        setSubmitting(false);

        const planforholdChangeTriggers = values.planforhold?.filter(
            (pf) => pf.source == 'Ny' && [2, 4].includes(pf.id)
        );

        onClose().then(() => {
            if (planforholdChangeTriggers?.length > 0) {
                dispatch(
                    showDialog({
                        dialogType: 'CONFIRM_PLAN_STATUS_CHANGE',
                        dialogProps: {
                            planforhold: planforholdChangeTriggers,
                            plan,
                        },
                    })
                );
            }
        });
    };

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <Form
            initialValues={{planforhold: plan.planforholdForlopere}}
            onSubmit={handleSubmit}
            mutators={{...arrayMutators}}
            render={({
                handleSubmit,
                pristine,
                invalid,
                values,
                form: {
                    mutators: {unshift},
                },
            }) => (
                <ToiBox
                    sx={{
                        '& td': {padding: '5px', wordBreak: 'keep-all'},
                        height: '100%',
                    }}
                >
                    <form onSubmit={handleSubmit} id={formId}>
                        <ToiButton
                            variant='transparent'
                            onClick={() => setExpanded((expanded) => !expanded)}
                            startIcon={
                                expanded ? (
                                    <ChevronUpIcon />
                                ) : (
                                    <ChevronDownIcon />
                                )
                            }
                        >
                            Nytt planforhold
                        </ToiButton>
                        <Collapse in={expanded} timeout='auto'>
                            <ToiBox
                                sx={(theme) => ({
                                    backgroundColor:
                                        theme.vars.toi.palette.backgrounds
                                            .generic.grey,
                                    marginBottom: '30px',
                                    padding: '10px',
                                })}
                            >
                                <div>
                                    <div style={{marginBottom: '10px'}}>
                                        <div
                                            style={{
                                                fontWeight: 500,
                                                marginBottom: '5px',
                                            }}
                                        >
                                            Type planforhold
                                        </div>
                                        <StyledSelect
                                            style={{
                                                minWidth: '360px',
                                            }}
                                            value={selectedPlanforholdTypeId}
                                            onChange={(e) => {
                                                setSelectedPlanforholdtypeId(
                                                    Number(e.target.value)
                                                );
                                            }}
                                            options={meta.data.planforholdtyper}
                                            keyCol='id'
                                            valueCol='beskrivelseForloper'
                                        />
                                    </div>
                                    <div style={{marginBottom: '10px'}}>
                                        <div
                                            style={{
                                                fontWeight: 500,
                                                marginBottom: '5px',
                                            }}
                                        >
                                            Planid
                                        </div>
                                        <ToiTextField
                                            variant='outlined'
                                            id='plan-relationship-search'
                                            type='text'
                                            onChange={(e) =>
                                                setTerm(e.target.value)
                                            }
                                            onKeyDown={(e) => {
                                                if (
                                                    e.code === 'Enter' ||
                                                    e.keyCode === 13
                                                ) {
                                                    e.preventDefault();
                                                    handleSearch();
                                                }
                                            }}
                                            value={term}
                                            placeholder='Søk på plannavn eller planid...'
                                            margin='none'
                                            endAdornment={
                                                <>
                                                    {term !== '' && (
                                                        <ToiIconButton
                                                            aria-label='Fjern søkeord'
                                                            color='transparent'
                                                            onClick={
                                                                handleClear
                                                            }
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.code ===
                                                                        'Enter' ||
                                                                    e.keyCode ===
                                                                        13
                                                                ) {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleClear();
                                                                }
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </ToiIconButton>
                                                    )}
                                                    {searching ? (
                                                        <ToiIconButton
                                                            aria-label='Searching'
                                                            color='transparent'
                                                        >
                                                            <ToiSpinner />
                                                        </ToiIconButton>
                                                    ) : (
                                                        <ToiIconButton
                                                            aria-label='Utfør søk'
                                                            color='transparent'
                                                            onClick={
                                                                handleSearch
                                                            }
                                                        >
                                                            <SearchIcon />
                                                        </ToiIconButton>
                                                    )}
                                                </>
                                            }
                                        />
                                    </div>
                                </div>
                                {searchResults.length > 0 && (
                                    <SearchResultsWrapper>
                                        <div className='tabbable-table-wrapper'>
                                            <Table padding='none'>
                                                <colgroup>
                                                    <col
                                                        style={{width: '200px'}}
                                                    />
                                                    <col
                                                        style={{width: 'auto'}}
                                                    />
                                                </colgroup>
                                                <TableBody>
                                                    {searchResults
                                                        .filter(
                                                            (
                                                                res: ArealplanDto
                                                            ) =>
                                                                res.id !=
                                                                plan.id
                                                        )
                                                        .filter(
                                                            (res) =>
                                                                !values.planforhold
                                                                    .filter(
                                                                        (pf) =>
                                                                            pf?.id ==
                                                                            selectedPlanforholdTypeId
                                                                    )
                                                                    .map(
                                                                        (pf) =>
                                                                            pf?.arealplanId
                                                                    )
                                                                    .includes(
                                                                        res?.id
                                                                    )
                                                        )
                                                        .map((searchResult) => (
                                                            <TableRow
                                                                onClick={() => {
                                                                    const {
                                                                        id: arealplanId,
                                                                        ...arealplan
                                                                    } =
                                                                        searchResult;
                                                                    unshift(
                                                                        'planforhold',
                                                                        {
                                                                            id: selectedPlanforholdTypeId,
                                                                            arealplanId,
                                                                            ...arealplan,
                                                                            source: 'Ny',
                                                                        }
                                                                    );
                                                                    clearSearchResult();
                                                                }}
                                                                key={
                                                                    searchResult.id
                                                                }
                                                            >
                                                                <TableCell>
                                                                    {
                                                                        searchResult.planId
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TabbableTableLink
                                                                        text={
                                                                            searchResult.planNavn ??
                                                                            ''
                                                                        }
                                                                        action={(
                                                                            e
                                                                        ) => {
                                                                            const {
                                                                                id: arealplanId,
                                                                                ...arealplan
                                                                            } =
                                                                                searchResult;
                                                                            unshift(
                                                                                'planforhold',
                                                                                {
                                                                                    id: selectedPlanforholdTypeId,
                                                                                    arealplanId,
                                                                                    ...arealplan,
                                                                                    source: 'Ny',
                                                                                }
                                                                            );
                                                                            clearSearchResult();
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </SearchResultsWrapper>
                                )}
                            </ToiBox>
                        </Collapse>
                        <div style={{paddingLeft: '10px'}}>
                            <ToiTypography variant='h3'>
                                Registrerte planforhold
                            </ToiTypography>
                            {values.planforhold?.length > 0 && (
                                <div>
                                    <Table padding='none'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>
                                                    Type forhold
                                                </TableCell>
                                                <TableCell>Planid</TableCell>
                                                <TableCell>Plannavn</TableCell>
                                                <TableCell>{}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {values.planforhold.map(
                                                (planforhold, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {planforhold.source ===
                                                            'Ny' ? (
                                                                <p
                                                                    style={{
                                                                        color: 'red',
                                                                    }}
                                                                >
                                                                    Ny
                                                                </p>
                                                            ) : null}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            <ToiTypography>
                                                                {getPlanforholdType(
                                                                    planforhold.id
                                                                )}
                                                            </ToiTypography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <ToiTypography>
                                                                {
                                                                    planforhold.planId
                                                                }
                                                            </ToiTypography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <ToiTypography>
                                                                {
                                                                    planforhold.planNavn
                                                                }
                                                            </ToiTypography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FieldArray name='planforhold'>
                                                                {({fields}) => {
                                                                    return fields.map(
                                                                        (
                                                                            name,
                                                                            fieldIndex
                                                                        ) => {
                                                                            if (
                                                                                index ===
                                                                                fieldIndex
                                                                            ) {
                                                                                //Looping through the fields to be able to remove correctly, but cannot display values from here because they are not up to date for som reason
                                                                                return (
                                                                                    <ToiIconButton
                                                                                        color='transparent'
                                                                                        aria-label='Slett'
                                                                                        key={
                                                                                            fieldIndex +
                                                                                            name
                                                                                        }
                                                                                        onClick={() =>
                                                                                            fields.remove(
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        size='small'
                                                                                    >
                                                                                        <DeleteRecycleIcon fontSize='small' />
                                                                                    </ToiIconButton>
                                                                                );
                                                                            }
                                                                        }
                                                                    );
                                                                }}
                                                            </FieldArray>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            )}
                            {values.planforhold?.length === 0 && (
                                <ToiTypography>Ingen planforhold</ToiTypography>
                            )}
                        </div>
                        {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre> */}
                    </form>
                </ToiBox>
            )}
        />
    );
};

export default PlanRelationshipForm;
