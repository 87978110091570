import {Avatar} from '@mui/material';
import {styled, Theme} from '@mui/material/styles';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {toiColors} from '@norkart/toi-colors';
import {ToiAvatarProps, ToiAvatarSize} from './ToiAvatar';

type ToiColor = keyof typeof toiColors;
type DeprecatedBaseColor = 'grey' | 'orange';
type BaseColor = Exclude<ToiColor, 'greyscale' | 'green'> | DeprecatedBaseColor;
type Variation = 'light' | 'dark';

export type ToiAvatarColor = BaseColor | `${Exclude<BaseColor, 'yellow' | 'grey' | 'orange'>}-${Variation}`;

const getColorCode = (str: string) =>
  str.split('').reduce((hash, char) => (hash << 5) + hash + char.charCodeAt(0), 5381) >>> 16;

function colorIndex(charCode: number, min: number, max: number) {
  const r = max - min;
  return min + ((((charCode - min) % r) + r) % r);
}

const availableAvatarColors: ToiAvatarColor[] = Object.keys(toiColors)
  .filter((c) => !['greyscale', 'yellow', 'green'].includes(c))
  .flatMap((c) => ['-light', '', '-dark'].map((i) => c + i))
  .concat(['greyscale', 'yellow']) as any;

function getColorForString(colors: ToiAvatarColor[], initials?: string, fullName?: string) {
  const str = fullName ? fullName : initials;
  return str
    ? {
        ...getColorOverride(colors[colorIndex(getColorCode(str), 0, colors.length)] as any),
      }
    : {};
}

function mapOldColorToNew(color: ToiAvatarColor): ToiColor {
  switch (color) {
    case 'orange':
      return 'yellow';
    case 'grey':
      return 'greyscale';
    default:
      return color as ToiColor;
  }
}

function getColorOverride(colorOverride?: ToiAvatarColor) {
  if (!colorOverride) return {};

  let textColorIntensity, backgroundColorIntensity;
  const baseColor = colorOverride.split('-')[0] as ToiAvatarColor;

  if (['yellow', 'orange'].includes(colorOverride)) {
    textColorIntensity = 80;
    backgroundColorIntensity = 50;
  } else if (colorOverride === 'grey') {
    textColorIntensity = 5;
    backgroundColorIntensity = 80;
  } else if (colorOverride.endsWith('-light')) {
    textColorIntensity = 90;
    backgroundColorIntensity = 20;
  } else if (colorOverride.endsWith('-dark')) {
    textColorIntensity = 5;
    backgroundColorIntensity = 90;
  } else {
    textColorIntensity = 5;
    backgroundColorIntensity = 70;
  }

  const textColor = ['yellow', 'orange'].includes(colorOverride)
    ? toiColors.greyscale[80]
    : textColorIntensity === 20 || textColorIntensity === 90
      ? (toiColors as any)[mapOldColorToNew(baseColor)][textColorIntensity]
      : toiColors.greyscale[5];

  return {
    color: textColor,
    backgroundColor: (toiColors[mapOldColorToNew(baseColor)] as any)[backgroundColorIntensity],
  };
}

const getAvatarSize = (theme: Theme, avatarSize: ToiAvatarSize = 'medium') => {
  const {size, typography} = {
    small: {
      size: 24,
      typography: theme.typography.body4,
    },
    medium: {
      size: 32,
      typography: theme.typography.body3,
    },
    large: {
      size: 48,
      typography: theme.typography.h4,
    },
    xl: {
      size: 64,
      typography: theme.typography.h3,
    },
    xxl: {
      size: 96,
      typography: theme.typography.h1,
    },
  }[avatarSize];

  return {
    width: size,
    height: size,
    ...typography,
    marginBottom: 0,
  };
};

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => !['colorOverride'].includes(prop.toString()),
})<ToiAvatarProps>(({theme, size, initials, fullname, colorOverride}) => {
  const colors = availableAvatarColors;

  return {
    ...getAvatarSize(theme, size),
    ...getColorForString(colors, initials, fullname),
    ...(colorOverride && getColorOverride(colorOverride)),
  };
});
