import {orderBy} from 'lodash-es';
import {useQuery} from 'react-query';
import {useNkAuth} from '@norkart/nk-auth';
import {
    arealplanerGetArealplanDokumenter,
    DokumentDto,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useArealplanDokumenter() {
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();
    const {isAuthorized} = useNkAuth();
    const {client, authorizedClient} = useArealplanerApiClientContext();

    const getDokumenter = async (
        arealplanId: number,
        isAuthorized: boolean
    ) => {
        const {data, error} = await arealplanerGetArealplanDokumenter({
            path: {kundeId, arealplanId},
            client: isAuthorized ? authorizedClient : client,
        });
        if (error) throw new Error(String(error.message));
        return data;
    };

    const queryKey = [
        'dokumenter',
        {kundeId, arealplanId, isAuthorized},
    ] as any;
    const query = useQuery<DokumentDto[]>(
        queryKey,
        () => getDokumenter(arealplanId!, isAuthorized),
        {
            enabled: !!arealplanId,
        }
    );

    const data =
        orderBy(query?.data, ['rekkefolge', 'dokumentType'], ['asc', 'asc']) ||
        [];

    const count = data.length;

    const gjeldendeBestemmelser = data.filter(
        (d) => d.visIGjeldendeBestemmelser
    );
    const plandokumenter = data.filter(
        (d) => !(d.behandling || d.dispensasjon || d.planforhold)
    );
    const andreDokumenter = data.filter((d) => !d.visIGjeldendeBestemmelser);

    return {
        ...query,
        data,
        count,
        gjeldendeBestemmelser,
        plandokumenter,
        andreDokumenter,
    };
}
