import {styled, Tabs as MuiTabs, TabsProps as MuiTabsProps, TabsTypeMap} from '@mui/material';
import clsx from 'clsx';

type OwnProps = {
  alternative?: boolean; // The variant prop is already used by MuiTabs
};

type DefaultComponent = TabsTypeMap['defaultComponent'];
export type ToiTabsProps = MuiTabsProps<DefaultComponent, OwnProps>;

const StyledTabs = styled(MuiTabs, {
  shouldForwardProp: (prop) => !['alternative'].includes(prop.toString()),
})<ToiTabsProps>(({theme, alternative}) => {
  return {
    display: 'flex',
    gap: theme.toi.spacing.xs,

    '& .MuiTabs-indicator': {
      zIndex: 2,
      backgroundColor: alternative ? 'unset' : theme.vars.toi.palette.signal.foreground.success,
    },

    '& .MuiTabs-flexContainer': {
      // gap: theme.toi.spacing.s,
      gap: theme.toi.spacing.xs, // TODO: Finn bra defaults
    },

    '& .MuiTab-root': {
      margin: 0,
    },

    // Handles all the variant specific styles for ToiTabs, see StyledTab the non-variant specific styles
    '&.ToiTabs-alternative .MuiTab-root': {
      backgroundColor: theme.vars.toi.palette.backgrounds.generic.panelOnGrey,
      borderRadius: '4px 4px 0 0',
      border: '1px solid transparent', // Avoid a jumping active tab
      borderBottom: `1px solid ${theme.vars.toi.palette.border.component}`,

      '&.Mui-selected': {
        border: `1px solid ${theme.vars.toi.palette.border.component}`,
        borderBottom: '1px solid transparent', // Avoid a jumping active tab
      },

      '&.Mui-focusVisible': {
        border: `1px dashed ${theme.vars.toi.palette.focus.border}`,
      },
    },

    '&.ToiTabs-alternative .MuiTabs-indicator': {
      display: 'none',
    },
  };
});

export const ToiTabs = ({children, alternative, ...restProps}: ToiTabsProps) => {
  return (
    <StyledTabs className={clsx(alternative && 'ToiTabs-alternative')} {...restProps}>
      {children}
    </StyledTabs>
  );
};

export default ToiTabs;
