import {forwardRef, ReactNode, useId} from 'react';
import {FormHelperTextProps, InputBaseProps} from '@mui/material';
import {ToiBaseInput} from '../internal/BaseInput';
import {ToiFormControl} from '../pureMuiComponents';
import {ToiFormHelperText} from '../ToiFormHelperText';
import {ToiInputLabel, ToiInputLabelProps} from '../ToiInputLabel/ToiInputLabel';
import {ToiTagProps} from '../ToiTag';

/** @deprecated */
export type LabelVariant = 'default' | 'light' | 'semibold';

type OwnProps = {
  label?: ReactNode;
  TagProps?: ToiTagProps;
  optional?: boolean;
  error?: boolean | string;
  helperText?: string | React.ReactNode;
  FormHelperTextProps?: FormHelperTextProps;
  variant?: 'map' | 'outlined';
  /** @deprecated These props can be added at root instead */
  InputProps?: InputBaseProps;
  /** @deprecated This prop has no effect */
  labelVariant?: LabelVariant;
  InputLabelProps?: ToiInputLabelProps;
};

declare module '@mui/material' {
  interface StandardTextFieldProps extends OwnProps {}
  interface OutlinedTextFieldProps extends OwnProps {}
  interface FilledTextFieldProps extends OwnProps {}
}

export type ToiTextFieldProps = Omit<InputBaseProps, keyof OwnProps | 'ref'> & OwnProps;

export const ToiTextField = forwardRef<HTMLDivElement, ToiTextFieldProps>((props, forwardedRef) => {
  const {
    TagProps,
    optional,
    label,
    error,
    helperText,
    sx,
    fullWidth,
    id,
    'aria-describedby': ariaDescribedby,
    FormHelperTextProps,
    InputProps,
    InputLabelProps,
    ...restProps
  } = props;

  const randomId = useId();
  const inputId = id ?? InputProps?.id ?? `input-${randomId}`;
  const helperTextId = FormHelperTextProps?.id ?? `helper-text-${randomId}`;

  return (
    <ToiFormControl variant='standard' error={Boolean(error)} sx={sx} fullWidth={fullWidth}>
      {label && (
        <ToiInputLabel TagProps={TagProps} optional={optional} htmlFor={inputId} {...InputLabelProps}>
          {label}
        </ToiInputLabel>
      )}
      <ToiBaseInput
        ref={forwardedRef}
        aria-describedby={ariaDescribedby ? `${ariaDescribedby} ${helperTextId}` : helperTextId}
        {...InputProps}
        {...restProps}
        id={inputId}
      />
      <ToiFormHelperText {...FormHelperTextProps} id={helperTextId}>
        {helperText}
      </ToiFormHelperText>
    </ToiFormControl>
  );
});

export default ToiTextField;
