import {ToiBox, ToiDialogSimple} from '@norkart/toi-components';
import {toiColors} from '@norkart/toi-colors';
import {Chunk} from '../types';
import {NoraMarkdownContent} from './NoraMardown';

type ReferenceContentDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    chunk: Chunk | null;
};

export const ReferenceContentDialog = ({
    isOpen,
    onClose,
    chunk,
}: ReferenceContentDialogProps) => {
    return (
        <ToiDialogSimple
            sx={{
                '& .MuiDialog-paper': {
                    width: '65vw', // Override default width
                    p: 4,
                },
            }}
            title={'Referanse'}
            open={isOpen}
            onClose={onClose}
            cancelText='Lukk'
        >
            <ToiBox
                sx={{
                    border: '1px solid ' + toiColors.greyscale[30],
                    backgroundColor: toiColors.greyscale[10],
                    borderRadius: '2px',
                    padding: 4,
                }}
            >
                <NoraMarkdownContent markdown={chunk?.content || ''} />
            </ToiBox>
        </ToiDialogSimple>
    );
};
