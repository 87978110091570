import {CommonEndSessionRequest} from '@azure/msal-common';
import {useLogoutPopup, useLogoutRedirect} from '@norkart/nk-auth';
import {ToiButton, ToiButtonProps} from '@norkart/toi-components';
import {LogOutIcon} from '@norkart/toi-icons';
import {LoginOrOutInteractionProcess} from './types';

export type LogOutButtonProps = ToiButtonProps & {
    logOutInteractionProcess?: LoginOrOutInteractionProcess;
    logOutRequest?: Omit<CommonEndSessionRequest, 'correlationId'>;
};

export const useGetLogOut = (
    loginType = LoginOrOutInteractionProcess.REDIRECT,
    logOutRequest?: Omit<CommonEndSessionRequest, 'correlationId'>
) => {
    const logOutPopup = useLogoutPopup(logOutRequest);
    const logOutRedirect = useLogoutRedirect(logOutRequest);

    return loginType === LoginOrOutInteractionProcess.POPUP
        ? logOutPopup
        : logOutRedirect;
};

/**
 * ToiButton that logs the user out. Must be used within a NkAzureADAuthProvider or NkAzureADAuthProvider.
 */
export function LogOutButton({
    logOutInteractionProcess: logOutMethod,
    logOutRequest,
    ...props
}: Omit<LogOutButtonProps, 'onClick'>) {
    const logOut = useGetLogOut(logOutMethod, logOutRequest);

    return (
        <ToiButton
            variant='secondary'
            startIcon={<LogOutIcon />}
            {...props}
            onClick={async () => {
                await logOut();
            }}
        >
            {props.children ?? 'Logg ut'}
        </ToiButton>
    );
}
