import {SliderProps as MuiSliderProps} from '@mui/material';
import {forwardRef, useId} from 'react';
import {ToiStack} from '../pureMuiComponents';
import {ToiTypography} from '../ToiTypography';
import ToiInputLabel from '../ToiInputLabel/ToiInputLabel';
import {StyledSlider} from './StyledSlider';

type OwnProps = {
  minMaxLabel?: {min: string; max: string};
  optional?: boolean;
  label?: string;
};

export type ToiSliderProps = Omit<MuiSliderProps, keyof OwnProps | 'component'> & OwnProps;

export const ToiSlider = forwardRef<HTMLDivElement, ToiSliderProps>((props, ref) => {
  const {minMaxLabel, 'aria-label': ariaLabel, ...restProps} = props;
  const id = useId();

  return (
    <>
      {props.label && (
        <ToiInputLabel htmlFor={id} optional={props.optional}>
          {props.label}
        </ToiInputLabel>
      )}
      <ToiStack flexDirection={'row'} alignItems={'center'} gap={2}>
        {minMaxLabel && <ToiTypography aria-hidden={true}> {minMaxLabel.min}</ToiTypography>}
        <StyledSlider id={id} ref={ref} {...restProps} />
        {minMaxLabel && <ToiTypography aria-hidden={true}> {minMaxLabel.max}</ToiTypography>}
      </ToiStack>
    </>
  );
});

export default ToiSlider;
