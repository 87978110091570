import {useParams} from 'react-router-dom';
import {AiAnswer} from '../types';
import {NoraMarkdownContent} from './NoraMardown';

type ChatAnswerProps = {
    aiAnswer: AiAnswer;
    chatElementIndex: string;
    answerIndex: number;
};

export const AiChatAnswer = ({
    aiAnswer,
    chatElementIndex,
    answerIndex,
}: ChatAnswerProps) => {
    const {mnr} = useParams();
    // If there's no reference, just render the content as-is
    if (!aiAnswer.reference) {
        return <NoraMarkdownContent markdown={aiAnswer.answer_content} />;
    } else {
        // splits on "[<number>]" and captures the specific number, so every other index in array contains captured text
        // ex: "Hello [5] World [9] More text "-> ["Hello ", 5, " World ", 9, " More text"]
        let parsedAnswer = '';
        // Split text for each citation, noted with square brackets and an id, i.e [23]
        const parts = aiAnswer.answer_content.split(/\[([\d]+)\]/); //every other elemnt is a text and a reference
        parts.forEach((part, index) => {
            const is_text = index % 2 === 0;
            if (is_text) {
                //is text
                parsedAnswer += part;
            } else {
                //is citation
                const cited_chunk = aiAnswer.reference.chunks.find(
                    (chunk) => chunk.id === part
                );
                if (cited_chunk) {
                    parsedAnswer += `[[${part}]](${mnr}/${part}/${chatElementIndex}/${answerIndex})`;
                } else {
                    parsedAnswer += `[${part}]`;
                }
            }
        });

        return <NoraMarkdownContent markdown={parsedAnswer} />;
    }
};
