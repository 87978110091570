import {CheckboxProps as MuiCheckboxProps} from '@mui/material';
import {forwardRef} from 'react';
import {CheckedCheckbox, IndeterminedCheckbox, UncheckedCheckbox} from './icons';
import {StyledCheckbox} from './StyledCheckbox';

export interface ToiCheckboxProps extends MuiCheckboxProps {
  error?: boolean;
}

export const ToiCheckbox = forwardRef<HTMLButtonElement, ToiCheckboxProps>(({error, color, ...props}, ref) => (
  <StyledCheckbox
    disableRipple
    ref={ref}
    icon={<UncheckedCheckbox />}
    checkedIcon={<CheckedCheckbox />}
    indeterminateIcon={<IndeterminedCheckbox />}
    color={error ? 'error' : color}
    {...props}
  />
));

export default ToiCheckbox;
