import DOMPurify from 'dompurify';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ArrowLeftIcon, EditIcon} from '@norkart/toi-icons';
import {ToiBox, ToiButton, ToiStack} from '@norkart/toi-components';
import {openSnackbarMessage} from '../../components/SnackbarMessage';
import SpinnerButton from '../../components/SpinnerButton';
import Editor from '../../components/TextEditor/Editor';
import useMeta from '../../hooks/meta/useMeta';
import useUpdateInnstillinger from '../../hooks/meta/useUpdateInnstillinger';
import useCan from '../../utils/auth/useCan';
import '../faq/faq.css';
import Footer from '../footer';

const RelevantLinks = () => {
    const can = useCan();
    const meta = useMeta();
    const {mutateAsync: updateInnstillinger, isLoading} =
        useUpdateInnstillinger();

    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [initialHTML, setInitialHTML] = useState<string>('');
    const [editorHTML, setEditorHTML] = useState<string>('');

    const handleEditorState = (newEditorHTML: string) => {
        setEditorHTML(newEditorHTML);
    };

    useEffect(() => {
        if (meta.relevanteLinker) {
            setInitialHTML(meta.relevanteLinker);
        }
    }, [meta.relevanteLinker]);

    const onCancel = () => {
        setIsEditMode(false);
    };

    const save = () => {
        updateInnstillinger({
            Innstilling: 'relevante_linker',
            Verdi: editorHTML,
        }).then((response: any) => {
            if (response.response && response.response.status !== 200) {
                openSnackbarMessage({
                    message: 'Noe gikk galt, prøv igjen senere',
                    variant: 'error',
                });
            } else {
                setIsEditMode(false);
                setInitialHTML(editorHTML);
            }
        });
    };

    return (
        <>
            <ToiBox pt={3} pl={3}>
                <ToiStack gap={1} direction={'row'}>
                    <ToiButton
                        onClick={() => navigate(-1)}
                        startIcon={<ArrowLeftIcon size={'small'} />}
                    >
                        Tilbake
                    </ToiButton>
                    {!isEditMode && can.edit && (
                        <ToiButton
                            variant='secondary'
                            onClick={() => setIsEditMode(true)}
                            startIcon={<EditIcon fontSize='small' />}
                        >
                            Endre
                        </ToiButton>
                    )}
                </ToiStack>
                <ToiBox
                    sx={{
                        margin: 'auto',
                        marginTop: '50px',
                        marginBottom: '50px',
                        maxWidth: '1000px',
                    }}
                >
                    {isEditMode ? (
                        <>
                            <Editor
                                initialEditorContent={
                                    meta.relevanteLinker
                                        ? meta.relevanteLinker
                                        : ''
                                }
                                toolbarOptions={[
                                    'bold',
                                    'italic',
                                    'list',
                                    'undo',
                                    'redo',
                                    'link',
                                ]}
                                currentEditorState={handleEditorState}
                            />
                            <ToiButton
                                variant='secondary'
                                onClick={onCancel}
                                disabled={isLoading}
                            >
                                Avbryt
                            </ToiButton>
                            <SpinnerButton
                                type='submit'
                                label='Lagre'
                                style={{marginLeft: '20px'}}
                                onClick={() => save()}
                                disabled={editorHTML === meta.relevanteLinker}
                                loading={isLoading}
                            />
                        </>
                    ) : (
                        <ToiBox
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(initialHTML),
                            }}
                        />
                    )}
                </ToiBox>
            </ToiBox>
            <Footer />
        </>
    );
};
export default RelevantLinks;
