import {SxProps} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {Link} from 'react-router-dom';
import {ToiLink, ToiTypography} from '@norkart/toi-components';

//Custom markdown component to get relative links to work from markdown with react-router-dom,
// and to display correct sized headers in reference views
export const NoraMarkdownContent = ({
    markdown,
    sx,
}: {
    markdown: string;
    sx?: SxProps;
}) => {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
                h1({children}) {
                    return (
                        <ToiTypography variant='h3' component={'h2'}>
                            {children}
                        </ToiTypography>
                    );
                },
                h2({children}) {
                    return (
                        <ToiTypography variant='h4' component={'h3'}>
                            {children}
                        </ToiTypography>
                    );
                },
                h3({children}) {
                    return (
                        <ToiTypography variant='h4'>{children}</ToiTypography>
                    );
                },
                p: ({children}) => {
                    return (
                        <ToiTypography
                            variant='body2'
                            sx={{
                                ...sx,
                                display: 'block',
                                mb: 1,
                            }}
                        >
                            {children}
                        </ToiTypography>
                    );
                },
                a: ({href, children}) => {
                    return (
                        <ToiLink
                            sx={{fontSize: 16, display: 'inline'}}
                            to={href as string}
                            component={Link}
                        >
                            {children}
                        </ToiLink>
                    );
                },
                ul: ({children}) => {
                    return (
                        <ToiTypography
                            variant='body2'
                            sx={{
                                display: 'block',
                                mb: 1,
                                listStyleType: 'disc',
                                listStylePosition: 'outside',
                                ml: '19px',
                            }}
                            component={'ul'}
                        >
                            {children}
                        </ToiTypography>
                    );
                },
                li: ({children}) => {
                    return (
                        <ToiTypography
                            sx={{
                                p: '4px 0',
                                '> p': {mt: 0, lineHeight: '100%'},
                            }}
                            component={'li'}
                        >
                            {children}
                        </ToiTypography>
                    );
                },
                sup: ({children}) => {
                    return (
                        <sup
                            style={{
                                top: 0,
                                left: 0,
                                fontSize: '80%',
                                verticalAlign: 'none',
                            }}
                        >
                            {children}
                        </sup>
                    );
                },
            }}
        >
            {markdown}
        </ReactMarkdown>
    );
};
