import {forwardRef} from 'react';
import {ToiFormControlLabel, ToiFormControlLabelProps} from '../ToiFormControlLabel';
import {InputDescription, InputDescriptionProps} from '../internal/InputDescription';
import ToiSwitch, {ToiSwitchProps} from './ToiSwitch';

interface OwnProps extends InputDescriptionProps {
  labelPlacement?: ToiFormControlLabelProps['labelPlacement'];
}

export type ToiSwitchOptionProps = ToiSwitchProps & OwnProps;

export const ToiSwitchOption = forwardRef<HTMLButtonElement, ToiSwitchOptionProps>((props, ref) => {
  const {label, helpText, labelPlacement = 'start', ...switchProps} = props;
  const descriptionProps = {label, helpText};

  return (
    <ToiFormControlLabel
      labelPlacement={labelPlacement}
      label={<InputDescription {...descriptionProps} variant='label' />}
      control={<ToiSwitch ref={ref} {...switchProps} />}
    />
  );
});
