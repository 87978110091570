import {useDispatch} from 'react-redux';
import {ToiBox, ToiButton} from '@norkart/toi-components';
import KartBakgrunn from '../../../assets/images/kart.png';
import DesktopContainer from '../../../containers/DesktopContainer';
import useTrackArealplanerEvent from '../../../hooks/appinsights/useTrackArealplanerEvent';
import {setUseAltFrontpage} from '../../../store/session/actions';

export default function ChangeToMapCard() {
    const dispatch = useDispatch();
    const trackHome = useTrackArealplanerEvent('forside', 'button');

    const handlechangeToMap = (comp: string) => {
        trackHome(comp);
        dispatch(setUseAltFrontpage(true));
    };
    return (
        <DesktopContainer>
            <ToiBox
                onClick={() => handlechangeToMap('byttTilKart_fraSøk')}
                sx={{
                    position: 'relative',
                    border: '1px solid grey',
                    overflow: 'hidden',
                    marginX: '10px',
                }}
            >
                <img alt='kart' src={KartBakgrunn} />
                <ToiButton
                    onClick={() => handlechangeToMap('byttTilKart_fraForside')}
                    sx={{
                        position: 'absolute',
                        right: '15px',
                        bottom: '15px',
                    }}
                >
                    Gå til kart
                </ToiButton>
            </ToiBox>
        </DesktopContainer>
    );
}
