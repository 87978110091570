import {useQuery} from 'react-query';
import {useNkAuth} from '@norkart/nk-auth';
import {
    arealplanerGetArealplanDispensasjoner,
    DispensasjonDto,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedArealplanId from '../arealplaner/useSelectedArealplanId';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useDispensasjoner() {
    const arealplanId = useSelectedArealplanId();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const {isAuthorized} = useNkAuth();
    const {client, authorizedClient} = useArealplanerApiClientContext();

    const getDispensasjoner = async (
        arealplanId: number,
        isAuthorized: boolean
    ) => {
        const {data, error} = await arealplanerGetArealplanDispensasjoner({
            path: {kundeId, arealplanId},
            client: isAuthorized ? authorizedClient : client,
        });
        if (error) throw new Error(String(error.message));
        return data;
    };

    const query = useQuery<DispensasjonDto[]>(
        ['dispensasjoner', {kundeId, arealplanId, isAuthorized}],
        () => getDispensasjoner(arealplanId!, isAuthorized),
        {
            onError: notify,
            enabled: !!arealplanId,
        }
    );

    return {
        ...query,
        data: query.data || [],
    };
}
