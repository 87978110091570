import {Grid} from '@mui/material';
import {AxiosError} from 'axios';
import * as React from 'react';
import {useState} from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {DeleteRecycleIcon, EditIcon} from '@norkart/toi-icons';
import {
    ToiBox,
    ToiButton,
    ToiFormControl,
    ToiFormLabel,
    ToiIconButton,
    ToiRadioGroup,
    ToiRadioOption,
    ToiStack,
    ToiTag,
    ToiTypography,
} from '@norkart/toi-components';
import {MapboxLayer} from '@norkart/nkm-mapbox-map';
import {ArealplanDto, DispensasjonDto} from '@norkart/nkapi-arealplaner-api';
import DatePicker from '../../../components/DatePicker';
import FormError from '../../../components/FormError';
import Error from '../../../components/FormError/Error';
import FormInputField from '../../../components/FormInputField';
import FormSelectInput from '../../../components/FormSelectInput';
import LoadingSpinner from '../../../components/LoadingSpinner';
import PositionOptions from '../../../components/PositionOptions';
import SpinnerButton from '../../../components/SpinnerButton';
import {DispensasjonFormData} from '../../../hooks/dispensasjoner/types';
import useMeta from '../../../hooks/meta/useMeta';
import {parseDate} from '../../../utils/parseDate';
import './dispform.css';

export interface DispFormProps {
    disp: DispensasjonDto;
    plan: ArealplanDto;
    dispIsSubmitting: boolean;
    onSubmit: (data: DispensasjonFormData) => Promise<DispensasjonDto>;
    onCancel: () => void;
    onDelete: () => void;
    onVertikalNivaaChange: (data: number) => void;
    focusRow: (id: string) => void;
    setDisplaySelectMapPosition: (value: boolean) => void;
    coords: {x: number; y: number} | undefined;
    onPositionSelected: (coords: {x: number; y: number} | undefined) => void;
    setCoords: React.Dispatch<
        React.SetStateAction<
            | {
                  x: number;
                  y: number;
              }
            | undefined
        >
    >;
    onDeleteCoords: () => void;
    coordsHasChanged: boolean;
    dispFraTyper: {Key: string; Value: string}[];
    planAreaLayer: MapboxLayer | undefined;
    getDefaultVertikalniva: (
        disp: DispensasjonDto,
        plan: ArealplanDto
    ) => number | undefined;
}

type error = object;

export interface DispFormState {
    errors?: AxiosError;
    editSakVerdiId: boolean;
    values: DispensasjonFormData;
}

function DispForm({plan, disp, ...props}: DispFormProps) {
    const meta = useMeta();
    const valuesRef = React.useRef<{values: string} | any>();
    const [editSakVerdiId, setEditSakVerdiId] = useState(false);
    const [formValues, setFormValues] = useState({});

    const validationSchema = (values): error => {
        const errors = {} as any;
        if (!values.dispensasjonTypeId)
            errors.dispensasjonTypeId = 'Dispensasjonstype er påkrevd';
        if (!values.sak)
            errors.sak = {
                sakAar: 'Saksår er påkrevd',
                sakSeknr: 'Sekvensnummer er påkrevd',
            };
        if (!values.sak && values.journalpostnummer)
            errors.journalpostnummer = 'Journalpostnummer krever saksår';
        if (values.sak && !values.sak.sakAar)
            errors.sak = {...errors.sak, sakAar: 'Saksår er påkrevd'};
        if (values.sak && !values.sak.sakSeknr)
            errors.sak = {...errors.sak, sakSeknr: 'Sekvensnummer er påkrevd'};
        if (!values.vedtaksdato) errors.vedtaksdato = 'Vedtaksdato er påkrevd';
        if (values.beskrivelse && values.beskrivelse.length > 1000)
            errors.beskrivelse = 'Beskrivelsen overskrider 1000 tegn.';
        return errors;
    };

    React.useEffect(() => {
        if (props.coords) {
            if (valuesRef.current) {
                valuesRef.current.xkoord = props.coords.x;
                valuesRef.current.ykoord = props.coords.y;
            }
        } else {
            if (valuesRef.current) {
                valuesRef.current.xkoord = '';
                valuesRef.current.ykoord = '';
            }
        }
    }, [props.coords, formValues]);

    React.useEffect(() => {
        const initialArkivId = meta.aktivtArkiv;

        const initialValues: Partial<DispensasjonFormData> = disp.id
            ? disp
            : {
                  sak: {
                      arkivId: initialArkivId,
                  },
                  vertikalnivaId: props.getDefaultVertikalniva(disp, plan),
              };

        if (Object.keys(formValues).length === 0) {
            setFormValues(initialValues);
        }
    }, []);

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <Form
            onSubmit={props.onSubmit}
            initialValues={formValues}
            validate={validationSchema}
            render={({handleSubmit, pristine, submitting, invalid, values}) => (
                <ToiBox>
                    <form onSubmit={handleSubmit} autoComplete='off'>
                        <FormSpy
                            subscription={{values: true}}
                            onChange={({values}) => {
                                valuesRef.current = values;
                            }}
                        />
                        {values.fraFiks && (
                            <Grid item={true}>
                                <ToiTag value='Fiks Plan' color='info' mb={2} />
                            </Grid>
                        )}
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={6}>
                                <FormInputField
                                    name='sak.sakAar'
                                    label='Saksår'
                                    inputProps={{maxLength: 4}}
                                    placeholder='saksår'
                                    optional={false}
                                    fieldProps={{
                                        parse: (value) => {
                                            if (value === '') return value;
                                            return Number(
                                                value.replace(/[^\d]/g, '')
                                            );
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <FormInputField
                                    name='sak.sakSeknr'
                                    label='Sakssekvensnummer'
                                    placeholder='sekvensnr'
                                    optional={false}
                                    fieldProps={{
                                        parse: (value) => {
                                            if (value === '') return value;
                                            return Number(
                                                value.replace(/[^\d]/g, '')
                                            );
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <FormInputField
                                    label='Journalår'
                                    name='journalaar'
                                    optional={true}
                                    inputProps={{maxLength: 4}}
                                    fieldProps={{
                                        parse: (value) => {
                                            if (value === '') return undefined;
                                            return Number(
                                                value.replace(/[^\d]/g, '')
                                            );
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <FormInputField
                                    name='journalpostnummer'
                                    label='Journalpostnummer'
                                    disabled={values.sak?.sakAar ? false : true}
                                    optional={true}
                                    fieldProps={{
                                        parse: (value) => {
                                            if (value === '') return undefined;
                                            return Number(
                                                value.replace(/[^\d]/g, '')
                                            );
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <ToiStack direction={'row'}>
                                    <ToiBox flexGrow={1}>
                                        <FormSelectInput
                                            title='Arkiv'
                                            width='100%'
                                            hideMinWidth={true}
                                            name='sak.arkivId'
                                            options={meta.data.arkiver}
                                            keyCol='id'
                                            valueCol='beskrivelse'
                                            displayId={false}
                                            optional={false}
                                            disabled={!editSakVerdiId}
                                            onBlur={() =>
                                                setEditSakVerdiId(false)
                                            }
                                            nullOption={false}
                                            parse={(v) => Number(v)}
                                        />
                                    </ToiBox>
                                    <ToiBox
                                        display={'flex'}
                                        alignItems={'center'}
                                        pt='22px'
                                    >
                                        <ToiIconButton
                                            aria-label='Slå på redigering av arkiv'
                                            color='transparent'
                                            onClick={() =>
                                                setEditSakVerdiId(
                                                    !editSakVerdiId
                                                )
                                            }
                                            size='small'
                                        >
                                            <EditIcon />
                                        </ToiIconButton>
                                    </ToiBox>
                                </ToiStack>
                            </Grid>
                            <Grid xs={12} item={true}>
                                <ToiTypography
                                    variant='label'
                                    className='importantFormLabel'
                                >
                                    Vedtaksdato
                                </ToiTypography>
                                <div>
                                    <Field
                                        name='vedtaksdato'
                                        component={DatePicker}
                                        parse={parseDate}
                                    />
                                    <Field
                                        name='vedtaksdato'
                                        subscription={{
                                            error: true,
                                            submitError: true,
                                            touched: true,
                                            dirtySinceLastSubmit: true,
                                        }}
                                        render={({meta}) =>
                                            meta.touched &&
                                            meta.error &&
                                            !meta.dirtySinceLastSubmit ? (
                                                <Error error={meta.error} />
                                            ) : null
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid xs={12} item={true}>
                                <FormSelectInput
                                    name='dispensasjonTypeId'
                                    title='Dispensasjonstype'
                                    options={meta.data.dispensasjonstyper}
                                    keyCol='id'
                                    valueCol='beskrivelse'
                                    displayId={true}
                                    optional={false}
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <ToiTypography variant='label'>
                                    Beskrivelse av dispensasjon
                                </ToiTypography>
                                <Field
                                    name='beskrivelse'
                                    component='textarea'
                                    className='formInput textarea'
                                    rows={3}
                                />
                                <FormError name={'beskrivelse'} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                {props.coords &&
                                props.coords.x &&
                                props.dispFraTyper.length ? (
                                    <FormSelectInput
                                        name='dispFra'
                                        title='Dispensasjon fra'
                                        options={props.dispFraTyper}
                                        keyCol='Key'
                                        valueCol='Value'
                                        displayId={false}
                                        optional={false}
                                    />
                                ) : null}
                            </Grid>
                            <Grid item={true} xs={12}>
                                <FormSelectInput
                                    name='vedtakId'
                                    title='Vedtakstyper'
                                    options={meta.data.vedtakstyper}
                                    keyCol='id'
                                    valueCol='beskrivelse'
                                    displayId={false}
                                    optional={true}
                                    parse={(v) => Number(v)}
                                />
                            </Grid>
                            {plan.vertikalniva && plan.vertikalniva.length ? (
                                <Grid item={true} xs={12}>
                                    <ToiFormControl>
                                        <ToiFormLabel>
                                            Vertikalnivå
                                        </ToiFormLabel>
                                        <ToiRadioGroup
                                            defaultValue={
                                                plan.vertikalniva[0]?.id
                                            }
                                            name='vertikalnivaId'
                                        >
                                            {plan.vertikalniva.map((v) => (
                                                <Field
                                                    key={v.id}
                                                    name='vertikalnivaId'
                                                    type='radio'
                                                    parse={(v) => {
                                                        props.onVertikalNivaaChange(
                                                            Number(v)
                                                        );
                                                        return Number(v);
                                                    }}
                                                    value={v.id}
                                                >
                                                    {({input}) => (
                                                        <ToiRadioOption
                                                            label={
                                                                v.beskrivelse
                                                            }
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                            ))}
                                        </ToiRadioGroup>
                                    </ToiFormControl>
                                </Grid>
                            ) : null}
                            <Grid item={true} xs={12}>
                                <ToiTypography variant='label'>
                                    Valgt posisjon
                                </ToiTypography>
                                <PositionOptions
                                    planAreaLayer={props.planAreaLayer}
                                    coords={props.coords}
                                    setCoords={props.setCoords}
                                    setDisplaySelectMapPosition={
                                        props.setDisplaySelectMapPosition
                                    }
                                    allFormData={valuesRef}
                                />
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                style={{marginTop: '20px'}}
                            >
                                <Grid
                                    container={true}
                                    justifyContent='space-between'
                                    alignItems='baseline'
                                >
                                    <Grid item={true}>
                                        {Boolean(disp?.id) && (
                                            <ToiButton
                                                variant='secondary'
                                                color='error'
                                                startIcon={
                                                    <DeleteRecycleIcon />
                                                }
                                                onClick={props.onDelete}
                                            >
                                                Slett dispensasjon
                                            </ToiButton>
                                        )}
                                    </Grid>
                                    <Grid item={true}>
                                        <ToiButton
                                            variant='secondary'
                                            onClick={props.onCancel}
                                            disabled={
                                                submitting ||
                                                props.dispIsSubmitting
                                            }
                                        >
                                            Avbryt
                                        </ToiButton>
                                        <SpinnerButton
                                            type='submit'
                                            label='Lagre'
                                            style={{marginLeft: '20px'}}
                                            disabled={
                                                submitting ||
                                                invalid ||
                                                (pristine &&
                                                    !props.coordsHasChanged) ||
                                                props.dispIsSubmitting
                                            }
                                            loading={
                                                submitting ||
                                                props.dispIsSubmitting
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                        <pre>
                            subErrors: {JSON.stringify(submitErrors, null, 2)}
                        </pre>
                        <pre>Values: {JSON.stringify(values, null, 2)}</pre> */}
                    </form>
                </ToiBox>
            )}
        />
    );
}

export default DispForm;
