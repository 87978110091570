import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const VideoPlayIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M16.3071 12.0001L9.84671 15.73L9.84671 8.27014L16.3071 12.0001Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinejoin='round'
    />
    <circle cx='12' cy='12' r='11' stroke='var(--toi-palette-icons-secondary, #2E2D30)' strokeWidth='1.5' />
  </ToiIcon>
));

export default VideoPlayIcon;
