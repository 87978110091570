import React from 'react';
import {ToiBox} from '@norkart/toi-components';

export default function InfoIconWrapper({
    children,
}: {
    children?: React.ReactNode;
}) {
    return (
        <ToiBox
            height={'60px'}
            width={'60px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            {children ?? null}
        </ToiBox>
    );
}
