import {LinkTypeMap} from '@mui/material';
import {forwardRef, ReactNode, useEffect, useRef} from 'react';
import {OverridableComponent} from '@mui/types';
import clsx from 'clsx';
import {ToiBox, ToiCardActionArea, ToiStack} from '../../pureMuiComponents';
import {ToiCardMedia} from '../../pureMuiComponents';
import {ToiTypography} from '../../ToiTypography';
import {ToiLinkProps} from '../../ToiLink';
import {ToiTagProps} from '../../ToiTag';
import {StyledNavCardContent} from './StyledNavCardContent';
import {StyledNavCard} from './StyledNavCard';

export type ToiNavCardSize = 'small' | 'medium' | 'large';

interface OwnProps {
  title: string;
  img: ReactNode;
  size?: ToiNavCardSize;
  description?: string;
  tag?: ToiTagProps & {ref?: React.Ref<HTMLDivElement>};
}

type NavigationCardTypeMap = LinkTypeMap<OwnProps>;

export type ToiNavigationCardProps = Omit<ToiLinkProps, keyof OwnProps> & OwnProps;

export const ToiNavigationCard = forwardRef<HTMLAnchorElement, ToiNavigationCardProps>((props, ref) => {
  const {title, img, size = 'medium', description, children, tag, sx, ...restProps} = props;

  // Disable actions on children elements
  const childrenRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (childrenRef.current) {
      const subElements = childrenRef.current.querySelectorAll('*') || [];
      subElements.forEach((el) => {
        const htmlElement = el as HTMLElement;
        htmlElement.setAttribute('tabIndex', '-1');
        htmlElement.style.pointerEvents = 'none';
      });
    }
  }, [children]);

  const className = clsx(size && `ToiNavigationCard-${size}`);

  const ariaLabel = [title, tag?.value && ` (${tag.value})`, description && `: ${description}`]
    .filter(Boolean)
    .join('');

  return (
    <StyledNavCard className={className} tag={tag} sx={sx}>
      <ToiCardActionArea className={className} component='a' aria-label={ariaLabel} ref={ref} {...restProps}>
        <ToiCardMedia className={className} aria-hidden={true}>
          {img}
        </ToiCardMedia>
        <StyledNavCardContent className={className} component={ToiStack}>
          <ToiStack gap={(theme) => theme.toi.spacing['2xs']}>
            <ToiTypography variant={size === 'small' ? 'subtitle2' : 'subtitle1'}>{title}</ToiTypography>
            <ToiTypography>{description}</ToiTypography>
          </ToiStack>
          {children && <ToiBox>{children}</ToiBox>}
        </StyledNavCardContent>
      </ToiCardActionArea>
    </StyledNavCard>
  );
}) as OverridableComponent<NavigationCardTypeMap>;
