import {useParams} from 'react-router-dom';
import {dokumenterGetDokumentById} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

export default function useDokuments() {
    const {notify} = useErrorHandler();
    const kundeId = useSelectedKundeId();
    const params = useParams();
    const {client} = useArealplanerApiClientContext();

    const getDokument = async (dokumentId: number) => {
        const {data, error} = await dokumenterGetDokumentById({
            path: {kundeId: kundeId ?? params.customerId, dokumentId},
            client,
        });
        if (error) throw new Error(String(error.message));
        return data;
    };

    const openDokument = async (
        dokumentId?: number | null,
        target: 'tab' | 'replace' = 'tab'
    ) => {
        if (!dokumentId) return;
        try {
            if (target == 'tab') {
                const windowRef = window.open();
                const res = await getDokument(dokumentId);
                if (windowRef && res?.direkteUrl)
                    windowRef.location = res.direkteUrl;
            }
            if (target == 'replace') {
                const res = await getDokument(dokumentId);
                res?.direkteUrl && window.location.replace(res.direkteUrl);
            }
        } catch (error) {
            notify(error);
        }
    };

    const getDokumentUrl = async (
        dokumentId?: number | null
    ): Promise<string | null | undefined> => {
        if (!dokumentId) return;
        try {
            const res = await getDokument(dokumentId);
            return res?.direkteUrl;
        } catch (error) {
            notify(error);
        }
    };

    return {
        openDokument,
        getDokumentUrl,
    };
}
