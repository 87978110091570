import {ToiBox, ToiDataDisplay, ToiLink} from '@norkart/toi-components';
import DesktopContainer from '../../../../../../containers/DesktopContainer';
import MobileContainer from '../../../../../../containers/MobileContainer';
import {
    FeatureAttribute,
    gfiAttId,
} from '../../../../../../services/apiTjenestekatalogen';

type Props = {
    attributes: FeatureAttribute[];
    gfiAttId: gfiAttId;
    visibleGfiAtt?: gfiAttId;
    zoomToDrawnLayer;
    setVisibleInMap: (id: gfiAttId, value: boolean) => void;
};

const AttributeDetails = ({
    attributes,
    gfiAttId,
    visibleGfiAtt,
    setVisibleInMap,
    zoomToDrawnLayer,
}: Props) => {
    const gfiAttIdIsVisibleInMap = visibleGfiAtt && visibleGfiAtt === gfiAttId;
    const filteredAttributes = attributes.filter(
        (att) => att.Name !== 'Planidentifikasjon'
    );

    const getAttValue = (att) => {
        if (att.Value.split('(utgått)').length === 2) {
            return att.Value.split('(utgått)')[0];
        } else {
            return att.Value;
        }
    };

    return (
        <ToiBox
            sx={{
                padding: '10px',
                marginBottom: '10px',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                border: '1px solid grey',
            }}
        >
            {filteredAttributes.map((att, index) => {
                return (
                    <ToiDataDisplay
                        key={index + 'attDetail'}
                        direction='row'
                        label={att.Name}
                        value={getAttValue(att)}
                    />
                );
            })}
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                }}
            >
                <MobileContainer>
                    <ToiLink
                        component={'button'}
                        tabIndex={0}
                        onClick={() =>
                            setVisibleInMap(gfiAttId, !gfiAttIdIsVisibleInMap)
                        }
                    >
                        {gfiAttIdIsVisibleInMap
                            ? 'Fjern markering'
                            : 'Se markering'}
                    </ToiLink>
                </MobileContainer>
                <ToiLink
                    component='button'
                    disabled={!gfiAttIdIsVisibleInMap}
                    tabIndex={0}
                    onClick={() => {
                        gfiAttIdIsVisibleInMap && zoomToDrawnLayer();
                    }}
                >
                    Zoom til markering
                </ToiLink>
                <DesktopContainer>
                    <ToiLink
                        component={'button'}
                        tabIndex={0}
                        onClick={() =>
                            setVisibleInMap(gfiAttId, !gfiAttIdIsVisibleInMap)
                        }
                    >
                        {gfiAttIdIsVisibleInMap
                            ? 'Fjern markering i kart'
                            : 'Se markering i kartet'}
                    </ToiLink>
                </DesktopContainer>
            </div>
        </ToiBox>
    );
};
export default AttributeDetails;
