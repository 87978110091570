import * as React from 'react';
import TableHead from '@mui/material/TableHead';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {DokumentDto} from '@norkart/nkapi-arealplaner-api';
import {EditIcon, InfoIcon, ShareIcon} from '@norkart/toi-icons';
import {ToiStack} from '@norkart/toi-components';
import TabbableTableLink from '../../../components/TabbableTableLink';
import StyledCheckbox from '../../../components/Checkbox/StyledCheckbox';
import TableHeaderCheckbox from '../../../components/Checkbox/TableHeaderCheckbox';
import FileIcon from '../../../components/FileIcon';
import GenericTable from '../../../components/GenericTable';
import {GTableAction} from '../../../components/GenericTable/GenericTable';
import useDokuments from '../../../hooks/dokumenter/useDokuments';
import {showDialog} from '../../../store/dialog/actions';
import {ApplicationState} from '../../../store/index';
import {
    addDocuments,
    convertFromDokumentToSelected,
    convertListFromDokumentToSelected,
    emptyDocumentSelection,
    removeDocument,
    removeDocuments,
    SelectedDocument,
    setAllGjeldendeDocsSelected,
    setAllOtherDocumentsSelected,
} from '../../../store/selectedDocumentsTable/reducer';
import useCan from '../../../utils/auth/useCan';
import {formatDate} from '../../../utils/formatDate';
import {getFileExtension} from '../../../utils/getFileExtension';
import ShareTextDialog from '../../../components/ShareTextDialog';
import {getDokumentKilde} from './helpers';
import {DocumentTableType} from './PlanDocumentList';

export interface PlanDocumentListDocumentsProps
    extends DispatchProps,
        StateProps {
    tableType: DocumentTableType;
    dokumenter: DokumentUtvidet[];
    selectedDocuments: SelectedDocument[];
    allGjeldendeBestDocsSelected: boolean;
    allOtherDocsSelected: boolean;
    setAllDocumentsSelected: (
        selected: boolean,
        tableType: DocumentTableType
    ) => void;
    removeDocuments: (docs: DokumentUtvidet[]) => void;
    removeDocument: (docId: number) => void;
    emptyDocumentSelection: () => void;
}

export interface DokumentUtvidet extends DokumentDto {
    index?: number;
}

const cellPadding = '4px 16px';

export function PlanDocumentListDocumentsRender({
    dokumenter = [] as DokumentDto[],
    dispatch,
    selectedDocuments,
    setAllDocumentsSelected,
    addDocuments,
    tableType,
    allGjeldendeBestDocsSelected,
    allOtherDocsSelected,
    removeDocuments,
    emptyDocumentSelection,
    removeDocument,
}) {
    const can = useCan();
    const [isOpen, setIsOpen] = React.useState(false);
    const [textToShare, setTextToShare] = React.useState('');
    const {openDokument} = useDokuments();

    const handleShare = (event, dokument) => {
        if (dokument.url) {
            setTextToShare(dokument.url);
            setIsOpen(true);
        }
    };

    React.useEffect(() => {
        emptyDocumentSelection();
        return () => {
            emptyDocumentSelection();
        };
    }, []);

    const refAllDocs = React.useRef(dokumenter);
    refAllDocs.current = dokumenter;

    const refSelectedDocs = React.useRef(selectedDocuments);
    refSelectedDocs.current = selectedDocuments;

    const refAllGjeldendeBestDocumentsSelected = React.useRef(
        allGjeldendeBestDocsSelected
    );
    refAllGjeldendeBestDocumentsSelected.current = allGjeldendeBestDocsSelected;

    const refAllOtherDocumentsSelected = React.useRef(allOtherDocsSelected);
    refAllOtherDocumentsSelected.current = allOtherDocsSelected;

    const selectDocumentAction = (
        documentList: SelectedDocument[],
        document: DokumentUtvidet
    ) => {
        const selected = documentList.find(
            (doc) => doc.dokumentId === document.id
        );
        if (!selected) {
            addDocuments([convertFromDokumentToSelected(document)]);
        } else {
            removeDocument(document.id);
            if (
                refAllGjeldendeBestDocumentsSelected ||
                refAllOtherDocumentsSelected
            ) {
                setAllDocumentsSelected(false, tableType);
            }
        }
    };
    const allDocumentsAction = (tableType: DocumentTableType) => {
        switch (tableType) {
            case 'GjeldendeBest':
                if (!refAllGjeldendeBestDocumentsSelected.current) {
                    addDocuments(
                        convertListFromDokumentToSelected(refAllDocs.current)
                    );
                } else {
                    removeDocuments(refAllDocs.current);
                }
                setAllDocumentsSelected(
                    !refAllGjeldendeBestDocumentsSelected.current,
                    tableType
                );
                break;
            case 'Other':
                if (!refAllOtherDocumentsSelected.current) {
                    addDocuments(
                        convertListFromDokumentToSelected(refAllDocs.current)
                    );
                } else {
                    removeDocuments(refAllDocs.current);
                }
                setAllDocumentsSelected(
                    !refAllOtherDocumentsSelected.current,
                    tableType
                );
                return;
            default:
                console.error('Table type is not supporten ' + tableType);
                break;
        }
    };

    const isSelectedDocument = (document: DokumentUtvidet): boolean => {
        return refSelectedDocs.current.find(
            (doc) => doc.dokumentId === document.id
        )
            ? true
            : false;
    };

    const RenderDocName = (document: DokumentDto) => {
        return (
            <ToiStack direction='row' gap={1}>
                <FileIcon
                    extension={getFileExtension(document.dokumentnavn ?? '')}
                />
                <TabbableTableLink
                    underline={false}
                    text={document.dokumentnavn ?? ''}
                    action={() => openDokument(document.id)}
                />
            </ToiStack>
        );
    };

    const columns = [
        {
            title: (
                <TableHeaderCheckbox
                    name={'Velg alle'}
                    tableType={tableType}
                    onChange={() => allDocumentsAction(tableType)}
                />
            ),
            tooltip: 'Velg alle',
            render: (dokument) => (
                <StyledCheckbox
                    name='velg'
                    onChange={(e) => {
                        //Do nothing, row click event handles change
                    }}
                    checked={isSelectedDocument(dokument)}
                />
            ),
        },
        {
            title: 'Dokumenttype',
            field: 'dokumenttype',
            cellStyle: {
                padding: cellPadding,
                whiteSpace: 'nowrap',
            } as React.CSSProperties,
            headerStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
        },
        {
            title: 'Dokumentnavn',
            field: 'dokumentnavn',
            cellStyle: {
                padding: cellPadding,
                wordBreak: 'break-word',
            } as React.CSSProperties,
            headerStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
            render: RenderDocName,
        },
        {
            title: 'Beskrivelse',
            field: 'beskrivelse',
            cellStyle: {
                padding: cellPadding,
                wordBreak: 'break-word',
            } as React.CSSProperties,
            headerStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
        },
        {
            title: 'Dato',
            field: 'dokumentdato',
            cellStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
            headerStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
            render: (dokument) => formatDate(dokument.dokumentdato),
        },
    ];
    const actions = [
        {
            icon: () => <InfoIcon />,
            tooltip: 'Vis dokumentinfo',
            iconProps: {
                style: {color: 'rgba(0, 0, 0, 0.54)'},
                fontSize: 'small',
            },
            onClick: (event, doc) => {
                dispatch(
                    showDialog({
                        dialogType: 'DOKUMENT_DETAIL',
                        dialogProps: {
                            document: doc,
                        },
                    })
                );
            },
            hidden: (doc) => getDokumentKilde(doc) == 'Plan',
        },
    ] as GTableAction[];
    actions.push({
        icon: () => <ShareIcon />,
        tooltip: 'Del',
        iconProps: {
            style: {color: 'rgba(0, 0, 0, 0.54)'},
            fontSize: 'small',
        },
        onClick: (event, dokument) => {
            handleShare(event, dokument);
        },
    } as GTableAction);

    if (can.edit) {
        actions.push({
            icon: () => <EditIcon />,
            tooltip: 'Endre dokument',
            iconProps: {
                style: {color: 'rgba(0, 0, 0, 0.54)'},
                fontSize: 'small',
            },
            onClick: (event, doc) => {
                dispatch(
                    showDialog({
                        dialogType: 'EDIT_DOKUMENT',
                        dialogProps: {
                            dokument: doc,
                            plandok: getDokumentKilde(doc) === 'Plan',
                            dokumenter: dokumenter,
                        },
                    })
                );
            },
            hidden: (row) => row.planforhold,
        } as GTableAction);
    }

    return (
        <div className='tabbable-table-wrapper'>
            <GenericTable
                title='Andre dokumenter'
                data={dokumenter}
                columns={columns}
                idColumn='id'
                actions={actions}
                onRowClick={(event, rowData) =>
                    selectDocumentAction(selectedDocuments, rowData)
                }
                style={{width: '100%'}}
                components={{
                    Header: (props) => (
                        <>
                            <colgroup>
                                <col style={{width: '56px'}} />
                                <col style={{width: '200px'}} />
                                <col style={{width: 'auto'}} />
                                <col style={{width: 'auto'}} />
                                <col style={{width: '120px'}} />
                                <col
                                    style={{
                                        width: can.edit ? '150px' : '100px',
                                    }}
                                />
                            </colgroup>
                            <TableHead {...props} />
                        </>
                    ),
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Ingen dokumenter',
                    },
                }}
            />
            <ShareTextDialog
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
                title={'Del link til dokumentet'}
                textToShare={textToShare}
            />
        </div>
    );
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addDocuments: (docs: SelectedDocument[]) => {
            dispatch(addDocuments(docs));
        },
        setAllDocumentsSelected: (
            allSelected: boolean,
            tableType: DocumentTableType
        ) => {
            if (tableType === 'GjeldendeBest') {
                dispatch(setAllGjeldendeDocsSelected(allSelected) as any);
            } else {
                dispatch(setAllOtherDocumentsSelected(allSelected));
            }
        },
        emptyDocumentSelection: () => {
            dispatch(emptyDocumentSelection());
        },
        removeDocuments: (docs: DokumentUtvidet[]) => {
            dispatch(removeDocuments(docs));
        },
        removeDocument: (docId: number) => {
            dispatch(removeDocument(docId));
        },
        dispatch,
    };
};
type StateProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: ApplicationState) => ({
    selectedDocuments: state.tableSelection.selectedDocuments,
    allGjeldendeBestDocsSelected:
        state.tableSelection.allGjeldendeBestDocsSelected,
    allOtherDocsSelected: state.tableSelection.allOtherDocsSelected,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlanDocumentListDocumentsRender);
