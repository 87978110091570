import {StackProps as ToiStackProps, styled} from '@mui/material';
import {ToiStack} from '../pureMuiComponents';

const StyledComponent = styled(ToiStack)(({theme}) => ({
  gap: theme.toi.spacing.xs,
}));

export type ToiLinkListGroupProps = ToiStackProps;

export const ToiLinkListGroup = (props: ToiLinkListGroupProps) => (
  <StyledComponent {...props}>{props.children}</StyledComponent>
);
