import {useReactTable, TableOptions, RowData, getCoreRowModel, ColumnDef} from '@tanstack/react-table';
import {useMemo} from 'react';
import {ToiTablePaginationProps} from '../ToiTablePagination';
import {ToiFilterComponentProps} from './ToiTableFilter';
import {selectFilterFn, SelectFilterOption} from './ToiTableFilter/SelectFilter';
import {getExpandRowColumn, getSelectRowColumn} from './columns';

declare module '@tanstack/react-table' {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select';
    FilterComponent?: React.ComponentType<ToiFilterComponentProps<TData, TValue>>;
    filterOptions?: {
      min?: number;
      max?: number;
      options?: (SelectFilterOption | string)[];
    };
    disableRowClick?: boolean;
  }
  interface TableMeta<TData extends RowData> {
    small?: boolean;
    hideExpandAll?: boolean;
    showFooter?: boolean;
    rowsPerPageOptions?: ToiTablePaginationProps['rowsPerPageOptions'];
  }
}

export type ToiTableOptions<TData extends RowData> = Omit<TableOptions<TData>, 'getCoreRowModel'>;
export function useToiTable<TData extends RowData>(options: ToiTableOptions<TData>) {
  const resolvedOptions = useResolvedOptions(options);
  return useReactTable<TData>({
    getCoreRowModel: getCoreRowModel(),
    ...resolvedOptions,
    columns: useResolvedColumns<TData>(resolvedOptions) as ColumnDef<TData, any>[],
    meta: useResolvedMeta(resolvedOptions),
  });
}

function useResolvedOptions<TData extends RowData>(options: ToiTableOptions<TData>) {
  return useMemo(() => {
    const enableExpanding = Boolean(options.getExpandedRowModel);
    return {
      enableRowSelection: options.enableRowSelection ?? Boolean(options.state?.rowSelection),
      enableFilters: Boolean(options.getFilteredRowModel),
      enableExpanding,
      enableSorting: Boolean(options.getSortedRowModel),
      getRowCanExpand: enableExpanding ? () => true : undefined,
      ...options,
    };
  }, [options]);
}

function useResolvedColumns<TData extends RowData>(options: ToiTableOptions<TData>) {
  return useMemo(() => {
    const columns = options.columns.map((column) => {
      if (column.meta?.filterVariant === 'select') {
        return {
          filterFn: selectFilterFn,
          ...column,
        };
      }
      return column;
    });
    if (options.enableRowSelection) {
      columns.unshift(getSelectRowColumn());
    }
    if (options.enableExpanding) {
      columns.unshift(getExpandRowColumn(options));
    }
    return columns;
  }, [options]);
}

function useResolvedMeta<TData extends RowData>(options: ToiTableOptions<TData>) {
  return useMemo(() => {
    return options.columns.some((column) => column.footer) ? {showFooter: true, ...options.meta} : options.meta;
  }, [options]);
}
