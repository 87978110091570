import {ToiMenuItem, ToiSelect, ToiSelectProps} from '@norkart/toi-components';

type Props = {
    nullOption?: boolean;
    nullOptionDisplayText?: string;
    displayId?: boolean;
    error?: boolean;
    options;
    keyCol;
    valueCol;
} & ToiSelectProps;

export function StyledSelect({
    keyCol,
    valueCol,
    options,
    displayId,
    nullOption,
    nullOptionDisplayText,
    error,
    disabled,
    ref,
    ...selectProps
}: Props) {
    return (
        <ToiSelect
            disabled={disabled}
            error={error}
            displayEmpty
            fullWidth
            {...selectProps}
        >
            {nullOption && nullOptionDisplayText ? (
                <ToiMenuItem value=''>{nullOptionDisplayText}</ToiMenuItem>
            ) : (
                <ToiMenuItem value='' sx={{height: '38px'}} />
            )}
            {options.map((option) => {
                const key = option[keyCol];
                const value = option[valueCol];
                return (
                    <ToiMenuItem key={key} value={key}>
                        {displayId ? `${key} - ${value}` : value}
                    </ToiMenuItem>
                );
            })}
        </ToiSelect>
    );
}
export default StyledSelect;
