import {LinearProgress} from '@mui/material';
import {styled} from '@mui/material/styles';

import {ToiLinearProgressProps} from './ToiLinearProgress';

export const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => !['halted', 'withLabel'].includes(prop.toString()),
})<ToiLinearProgressProps>(({theme, halted}) => {
  return {
    borderRadius: 5,
    height: 8,
    backgroundColor: theme.vars.toi.palette.bars.fill,

    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
      backgroundColor: halted ? theme.vars.toi.palette.bars.warning : theme.vars.toi.palette.bars.active,
    },
  };
});
