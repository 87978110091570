import {Checkbox, styled} from '@mui/material';
import {ToiCheckboxProps} from './ToiCheckbox';

export const StyledCheckbox = styled(Checkbox)<Partial<ToiCheckboxProps>>(({theme}) => {
  return {
    margin: 0,
    marginBottom: 'auto',
    padding: 0,
    borderRadius: 3,
    height: 24,
    width: 24,

    '& svg': {
      stroke: `${theme.vars.toi.palette.border.input}`,
      fill: theme.vars.toi.palette.backgrounds.component.fill,
      '#symbol': {
        fill: 'white',
      },
    },

    '&:hover': {
      strokeWidth: 2,
      stroke: theme.vars.toi.palette.border.inputHover,
    },

    '&.Mui-focusVisible': {
      outline: `1px dashed ${theme.vars.toi.palette.border.inputHover}`,
    },

    '&.MuiCheckbox-colorError': {
      '& svg': {
        stroke: theme.vars.toi.palette.border.error,
      },
      '&:hover svg': {
        stroke: theme.vars.toi.palette.border.errorHover,
      },
    },

    '&.Mui-checked,&.MuiCheckbox-indeterminate': {
      '& svg': {
        stroke: 'none',
        fill: theme.vars.toi.palette.backgrounds.component.active,
      },
      '&:hover svg': {
        fill: theme.vars.toi.palette.backgrounds.component.activeHover,
        stroke: 'none',
      },
    },

    '&.Mui-disabled': {
      '& svg': {
        stroke: theme.vars.toi.palette.disabled.fill,
      },
      '&.Mui-checked,&.MuiCheckbox-indeterminate': {
        '& svg': {
          stroke: 'none',
          fill: theme.vars.toi.palette.disabled.fill,
          '#symbol': {
            fill: theme.vars.toi.palette.disabled.text,
          },
        },
      },
    },
  };
});
