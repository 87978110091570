import {useState, useEffect} from 'react';
import {ToiBox, ToiButton, ToiLinearProgress} from '@norkart/toi-components';

interface KonsistenssjekkIsRunningProps {
    currentPage: number | undefined;
    totalPages: number | undefined;
    showRunningReport: boolean;
    setShowRunningReport: React.Dispatch<React.SetStateAction<boolean>>;
}

const calculateProgress = (current, total) => {
    const calculatedProgress = (current / total) * 100;
    return calculatedProgress;
};

export default function KonsistenssjekkIsRunning({
    currentPage,
    totalPages,
    showRunningReport,
    setShowRunningReport,
}: KonsistenssjekkIsRunningProps) {
    const [reportProgress, setReportProgress] = useState(0);

    useEffect(() => {
        if (!currentPage && !totalPages) return;
        else setReportProgress(calculateProgress(currentPage, totalPages));
    }, [currentPage]);

    return (
        <ToiBox display={'flex'} flexDirection={'column'} width={'50%'} gap={2}>
            <ToiLinearProgress
                variant='determinate'
                value={reportProgress}
                withLabel
            />
            <ToiBox>
                {!showRunningReport ? (
                    <ToiButton
                        variant='secondary'
                        onClick={() => setShowRunningReport(!showRunningReport)}
                    >
                        Se midlertidig rapport
                    </ToiButton>
                ) : (
                    <ToiButton
                        variant='secondary'
                        onClick={() => setShowRunningReport(!showRunningReport)}
                    >
                        Se siste ferdige rapport
                    </ToiButton>
                )}
            </ToiBox>
        </ToiBox>
    );
}
