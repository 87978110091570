import {bbox as turfBbox} from '@turf/bbox';
import {AllGeoJSON} from '@turf/helpers';
import qs from 'qs';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
    ToiBox,
    ToiButton,
    ToiCheckboxOption,
    ToiStack,
} from '@norkart/toi-components';
import {FitBounds} from '@norkart/nkm-mapbox-map';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {ChevronLeftIcon} from '@norkart/toi-icons';
import useArealplan from '../../hooks/arealplaner/useArealplan';
import useSelectedArealplanId from '../../hooks/arealplaner/useSelectedArealplanId';
import useSelectedKundeId from '../../hooks/kunder/useSelectedKundeId';
import useSearch from '../../hooks/search/useSearch';
import {useNavigateToArealplan} from '../../hooks/useNavigateToArealplan';
import {gfiAttId, WmsFeature} from '../../services/apiTjenestekatalogen';
import {getMapState} from '../../store';
import {
    addFitToBounds,
    resetGFIState,
    resetMapState,
} from '../../store/map/actions';
import {GFIAttrState} from '../../store/map/types';
import PunktinfoRelatertePlaner from '../map/Components/Menus/PunktInfo/PunktinfoRelatertePlaner';
import {getMapboxLayer} from '../map/helpers/getMapboxLayer';
import PlanSummary from './AlternativeHomeRelated/PlanSummary';

type Props = {setDisplaySurroundingPlanWms; displaySurroundingPlanWms};

export default function SidebarPlan({
    setDisplaySurroundingPlanWms,
    displaySurroundingPlanWms,
}: Props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mapState = useSelector(getMapState);
    const kundeId = useSelectedKundeId();
    const search = useSearch();
    const selectedArealplanId = useSelectedArealplanId();
    const plan = useArealplan(selectedArealplanId);
    const navigateArealplan = useNavigateToArealplan();

    const [visibleGfiFeature, setVisibleGfiFeature] = useState<
        WmsFeature | undefined
    >(getVisibleGfiFeature(mapState.drawnAttInMap, mapState.gfiState));

    useEffect(() => {
        const newVisibleGfiFeature = getVisibleGfiFeature(
            mapState.drawnAttInMap,
            mapState.gfiState
        );
        if (newVisibleGfiFeature !== visibleGfiFeature) {
            setVisibleGfiFeature(newVisibleGfiFeature);
        }
    }, [mapState.gfiState, mapState.drawnAttInMap]);

    const handleSearch = () => {
        dispatch(resetMapState());
        navigate(
            {
                pathname: `/${kundeId}/arealplaner/search`,
                search: qs.stringify({...search.searchState.urlSearchParams}),
            },
            {replace: true}
        );
    };

    function getVisibleGfiFeature(
        drawnAttInMap: gfiAttId | undefined,
        gfiState: GFIAttrState | undefined
    ): WmsFeature | undefined {
        let allAttsLists: WmsFeature[] = [];
        gfiState &&
            gfiState.planAttributes &&
            gfiState.planAttributes.forEach((plan) => {
                plan.wmsLayers.forEach((layer) => {
                    allAttsLists = allAttsLists.concat(layer.attrList);
                });
            });
        const gfiFeature = allAttsLists.filter(
            (att) => att.id === drawnAttInMap
        );
        return gfiFeature.length ? gfiFeature[0] : undefined;
    }

    function zoomToDrawnLayer() {
        if (visibleGfiFeature) {
            const layerToZoomTo: mapboxgl.GeoJSONSourceRaw = getMapboxLayer(
                visibleGfiFeature.Geometry,
                visibleGfiFeature.Interiors,
                visibleGfiFeature.id
            ).source! as mapboxgl.GeoJSONSourceRaw;

            const bbox = turfBbox((layerToZoomTo.data as AllGeoJSON)!) as [
                number,
                number,
                number,
                number,
            ];
            const bounds: FitBounds = {
                bounds: bbox,
                options: {padding: 110, linear: true, maxZoom: 20},
            };
            dispatch(addFitToBounds(bounds));
        }
    }

    function getSurroundingFeatures() {
        const surroundingFeatures = mapState.gfiState.planAttributes; //?.filter(
        //  feature => feature.planId !== selectedPlan?.planId
        //)

        if (surroundingFeatures) {
            return surroundingFeatures.map((feature) => feature.planId);
        } else {
            return [];
        }
    }

    const handlePlanClick = (plan: ArealplanDto) => {
        dispatch(resetGFIState());
        navigateArealplan.toArealplan(plan.id);
    };

    const surroundingPlanFeatures = useMemo(
        () => getSurroundingFeatures(),
        [mapState.gfiState.planAttributes]
    );
    return (
        <ToiBox
            sx={(theme) => ({
                boxSizing: 'border-box',
                borderRight: '1px solid lightgrey',
                overflow: 'auto',
                height: 'calc(100% - 64px)',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('md')]: {
                    width: '30%',
                    minWidth: '400px',
                },
            })}
        >
            <ToiStack gap={1} p={2}>
                <ToiButton
                    variant='secondary'
                    onClick={handleSearch}
                    startIcon={<ChevronLeftIcon />}
                >
                    Tilbake til søk
                </ToiButton>
                <ToiCheckboxOption
                    label='Vis naboplaner'
                    checked={displaySurroundingPlanWms}
                    onClick={() =>
                        setDisplaySurroundingPlanWms((current) => !current)
                    }
                />
                <PlanSummary plan={plan.data} />
                <ToiButton
                    sx={{marginTop: '20px'}}
                    onClick={() => handlePlanClick(plan.data)}
                >
                    Gå til plandetaljer
                </ToiButton>
                <ToiBox
                    sx={{borderTop: '1px solid lightgray', marginY: '40px'}}
                />
                <PunktinfoRelatertePlaner
                    planIds={surroundingPlanFeatures}
                    displaySurroundingPlanWms={displaySurroundingPlanWms}
                    zoomToDrawnLayer={zoomToDrawnLayer}
                />
            </ToiStack>
        </ToiBox>
    );
}
