import {useMutation, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {arealplanerDeleteArealplan} from '@norkart/nkapi-arealplaner-api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useDeleteArealplan() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const navigate = useNavigate();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useMutation(
        async (arealplanId: number) => {
            const {data, error} = await arealplanerDeleteArealplan({
                client: authorizedClient,
                path: {kundeId, arealplanId},
            });
            if (error)
                throw new Error(
                    String(error.message) || 'Error deleting arealplan'
                );
            return data;
        },
        {
            onError: (error) => notify(error),
            onSuccess: (data, arealplanId) => {
                navigate(`/${kundeId}/`);
                queryClient.removeQueries([
                    'arealplan',
                    {kundeId, arealplanId},
                ]);
                queryClient.removeQueries([
                    'dokumenter',
                    {kundeId, arealplanId},
                ]);
                queryClient.removeQueries([
                    'behandlinger',
                    {kundeId, arealplanId},
                ]);
                queryClient.removeQueries([
                    'dispensasjoner',
                    {kundeId, arealplanId},
                ]);
                queryClient.removeQueries([
                    'midlertidigforbud',
                    {kundeId, arealplanId},
                ]);
            },
        }
    );
}
