import {LinearProgressProps as MuiLinearProgressProps} from '@mui/material';
import {forwardRef} from 'react';
import {ToiTypography} from '../ToiTypography';
import {ToiBox} from '../pureMuiComponents';
import {StyledLinearProgress} from './StyledLinearProgress';

type OwnProps = {
  halted?: boolean;
  withLabel?: boolean;
};

export type ToiLinearProgressProps = Omit<MuiLinearProgressProps, keyof OwnProps | 'component'> & OwnProps;

export const ToiLinearProgress = forwardRef<HTMLElement, ToiLinearProgressProps>((props, forwardedRef) => {
  return (
    <ToiBox display={'flex'} alignItems={'center'}>
      <ToiBox width={'100%'} mr={1}>
        <StyledLinearProgress ref={forwardedRef} {...props} />
      </ToiBox>
      {props.withLabel && props.value && <ToiTypography>{Math.round(props.value)}%</ToiTypography>}
    </ToiBox>
  );
});

export default ToiLinearProgress;
