import {ToiTypography} from '../../ToiTypography';
import {ToiDialogTitle} from '../ToiDialogTitle';
import {CommonDialogProps} from './types';

export type DialogHeaderProps = Pick<CommonDialogProps, 'title' | 'description'>;

export function DialogHeader({title, description}: DialogHeaderProps) {
  return (
    <>
      <ToiDialogTitle>{title}</ToiDialogTitle>
      {description && <ToiTypography className='ToiDialog-description'>{description}</ToiTypography>}
    </>
  );
}
