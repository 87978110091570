import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const WarningIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <circle cx='12' cy='12' r='11' stroke='var(--toi-palette-icons-secondary, #2E2D30)' strokeWidth='1.5' />
    <path
      d='M12 7V13M12 16V16.5'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </ToiIcon>
));

export default WarningIcon;
