import * as React from 'react';
import {MarkdownContent} from '../MarkdownContent';

type Props = {
    helpText: string;
    style?: React.CSSProperties;
};

const HelpText = ({helpText, style}: Props) => {
    return (
        <div style={style}>
            <div style={{maxWidth: '45vw'}}>
                <MarkdownContent markdown={helpText} />
            </div>
        </div>
    );
};
export default HelpText;
