import * as React from 'react';
import {ToiTypography} from '@norkart/toi-components';

export interface ErrorProps {
    error: any;
}

const Error: React.FC<ErrorProps> = ({error}: ErrorProps) => {
    return (
        <ToiTypography
            component={'span'}
            sx={(theme) => ({
                color: theme.palette.error.main,
            })}
        >
            {error}
        </ToiTypography>
    );
};

export default Error;
