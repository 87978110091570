import {memo, useMemo, ChangeEvent} from 'react';
import {debounce} from 'lodash-es';
import {ToiBox, ToiFormControl} from '../../pureMuiComponents';
import {ToiFormLabel} from '../../ToiFormLabel/ToiFormLabel';
import {ToiTextField} from '../../ToiTextField/ToiTextField';
import {ToiFilterComponentProps} from './types';

export const TextFilter = memo(
  ({column}: ToiFilterComponentProps) => {
    const handleChange = useMemo(
      () =>
        debounce((e: ChangeEvent<HTMLInputElement>) => {
          column.setFilterValue(e.target.value);
        }, 100),
      [column],
    );

    return (
      <ToiBox>
        <ToiFormControl component='fieldset'>
          <ToiFormLabel component='legend'>Søk</ToiFormLabel>
          <ToiTextField defaultValue={column.getFilterValue()} placeholder='Søk...' onChange={handleChange} />
        </ToiFormControl>
      </ToiBox>
    );
  },
  () => true,
);
