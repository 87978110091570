import {Grid, Typography} from '@mui/material';
import {sortBy} from 'lodash-es';
import {Field, Form} from 'react-final-form';
import {
    ToiBox,
    ToiButton,
    ToiStack,
    ToiTextField,
} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {BehandlingDto} from '@norkart/nkapi-arealplaner-api';
import {DispensasjonDto} from '@norkart/nkapi-arealplaner-api';
import {DokumentDto} from '@norkart/nkapi-arealplaner-api';
import {DeleteRecycleIcon} from '@norkart/toi-icons';
import FormInputField from '../../../components/FormInputField';
import FormSelectInput from '../../../components/FormSelectInput';
import LoadingSpinner from '../../../components/LoadingSpinner';
import OnChange from '../../../components/OnChange';
import SpinnerButton from '../../../components/SpinnerButton';
import useArealplanDokumenter from '../../../hooks/dokumenter/useArealplanDokumenter';
import useMeta from '../../../hooks/meta/useMeta';
import {getFileExtension} from '../../../utils/getFileExtension';
import {getDokumentKilde} from '../../plan/PlanDocumentList/helpers';
import {DokumentUtvidet} from '../../plan/PlanDocumentList/PlanDocumentListDocuments';
import useDocumentHelpers from '../useDocumentHelpers';
import FormDateInputField from '../../../components/FormDateInputField';
import FormSwitchField from '../../../components/FormSwitchField';

export interface DocumentFormProps {
    dokument: DokumentUtvidet;
    plandok?: boolean;
    onSubmit: (data: DokumentDto) => void;
    onDelete: (id) => void;
    onClose: () => void;
    plan?: ArealplanDto;
    behandling?: BehandlingDto;
    dispensasjon?: DispensasjonDto;
}

const WhenFieldChanges = ({field, predictor, set, to}: any) => (
    <Field name={set} subscription={{}}>
        {({input: {onChange}}) => (
            <OnChange name={field}>
                {(value) => {
                    if (predictor(value)) {
                        onChange(to);
                    }
                }}
            </OnChange>
        )}
    </Field>
);

function DocumentForm({
    dokument,
    plandok = false,
    onDelete,
    onClose,
    ...props
}: DocumentFormProps) {
    const meta = useMeta();
    const kilde = getDokumentKilde(dokument);
    const helpers = useDocumentHelpers();
    const {plandokumenter} = useArealplanDokumenter();
    const fileExtention = '.' + getFileExtension(dokument.dokumentnavn ?? '');

    const isNameUnique = (
        id?: number,
        dokumentnavn?: string | null
    ): boolean => {
        let filteredDocs = [] as DokumentDto[] | undefined;

        if (props.behandling)
            filteredDocs = props.behandling.dokumenter?.filter(
                (doc) => doc.id != id
            );

        if (props.dispensasjon)
            filteredDocs = props.dispensasjon.dokumenter?.filter(
                (doc) => doc.id != id
            );

        if (plandok && !filteredDocs?.length)
            filteredDocs = plandokumenter.filter((doc) => doc.id != id);

        if (
            filteredDocs &&
            filteredDocs.map((doc) => doc.dokumentnavn).includes(dokumentnavn)
        ) {
            return false;
        }

        return true;
    };

    async function onSubmit(data: DokumentDto) {
        if (!isNameUnique(data.id, data.dokumentnavn))
            return Promise.resolve({dokumentnavn: 'Dokumentet finnes fra før'});
        await props.onSubmit(data);
        onClose();
    }

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={dokument}
            validate={(values) => {
                const errors = {} as any;
                if (!values.dokumentnavn)
                    errors.dokumentnavn = 'Dokumentet må ha et navn';
                if (
                    (values.tilgangId ?? 0) <
                    helpers.getDefaultTilgangId(props.behandling)
                )
                    errors.tilgangId =
                        'Dokumentet må ha en av tilgangstypene i listen';
                return errors;
            }}
            render={({
                handleSubmit,
                values,
                submitting,
                pristine,
                errors,
                submitErrors,
            }) => (
                <ToiBox
                    sx={(theme) => ({
                        form: {
                            '& .importantFormLabel': {fontWeight: 600},
                            '& .formInput': {
                                width: '100%',
                                padding: '5px',
                                fontFamily: theme.typography.fontFamily,
                            },
                            '& .formInput:disabled': {
                                backgroundColor: '#f0f0f0',
                            },
                            '& .formInputError': {border: '1px solid red'},
                            '& .textarea': {fontSize: 'inherit'},
                        },
                    })}
                >
                    <form onSubmit={handleSubmit}>
                        <WhenFieldChanges
                            field='visIGjeldendeBestemmelser'
                            predictor={(value) => value}
                            set='visIPlandokumenter'
                            to={false}
                        />
                        <WhenFieldChanges
                            field='dokumenttypeId'
                            predictor={(value) => true}
                            set='visIGjeldendeBestemmelser'
                            to={false}
                        />
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={6}>
                                <FormSelectInput
                                    name='dokumenttypeId'
                                    title='Dokumenttype'
                                    options={sortBy(
                                        meta.data.dokumenttyper.filter(
                                            (dokumenttype) =>
                                                dokumenttype.id != 22
                                        ),
                                        (dokumenttype) =>
                                            dokumenttype.beskrivelse
                                    )}
                                    keyCol='id'
                                    valueCol='beskrivelse'
                                    displayId={false}
                                    autoFocus={true}
                                    nullOption={false}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Grid
                                    container={true}
                                    justifyContent='flex-start'
                                    spacing={2}
                                >
                                    {dokument.dokumentnummer && (
                                        <Grid item={true}>
                                            <Typography>
                                                Dokumentnummer
                                            </Typography>
                                            <Typography>
                                                {dokument.dokumentnummer}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item={true}>
                                        <FormDateInputField
                                            name='dokumentdato'
                                            label='Dato'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <ToiStack
                                    direction={'row'}
                                    spacing={1}
                                    width={'100%'}
                                >
                                    <FormInputField
                                        label='Dokumentnavn'
                                        name='dokumentnavn'
                                        fieldProps={{
                                            format: (value: string) => {
                                                const ix =
                                                    value.lastIndexOf(
                                                        fileExtention
                                                    );
                                                return value
                                                    ? value.substring(0, ix)
                                                    : '';
                                            },
                                            parse: (value) => {
                                                return (
                                                    value &&
                                                    value + fileExtention
                                                );
                                            },
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            mb: '18px!important',
                                        }}
                                    >
                                        {fileExtention}
                                    </Typography>
                                </ToiStack>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Field name='beskrivelse'>
                                    {({input}) => (
                                        <ToiTextField
                                            label='Beskrivelse'
                                            {...input}
                                            multiline
                                            rows={3}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <FormSelectInput
                                    name='tilgangId'
                                    title='Tilgang'
                                    options={helpers.getAvailableTilgangstyper(
                                        props.behandling
                                    )}
                                    keyCol='id'
                                    valueCol='navn'
                                    displayId={false}
                                    nullOption={false}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <FormSwitchField
                                    name={'visIGjeldendeBestemmelser'}
                                    label={
                                        'Skal vises i Gjeldende bestemmelser'
                                    }
                                    disabled={
                                        !helpers.shouldBestemmelserBeActive(
                                            values.dokumenttypeId
                                        )
                                    }
                                />
                            </Grid>
                            {!plandok && (
                                <Grid item={true} xs={6}>
                                    <FormSwitchField
                                        name={'visIPlandokumenter'}
                                        label={'Skal vises i plandokumenter'}
                                        disabled={
                                            Number(
                                                values.visIGjeldendeBestemmelser
                                            ) == 1
                                        }
                                    />
                                </Grid>
                            )}
                            {helpers.shouldHoringsdokumentBeActive(
                                props.behandling
                            ) && (
                                <Grid item={true} xs={6}>
                                    <FormSwitchField
                                        name={'horingsdokument'}
                                        label={'Høringsdokument'}
                                        disabled={
                                            Number(
                                                values.visIGjeldendeBestemmelser
                                            ) == 1
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid
                                item={true}
                                xs={12}
                                style={{marginTop: '20px'}}
                            >
                                <Grid
                                    container={true}
                                    justifyContent='space-between'
                                    alignItems='baseline'
                                >
                                    <Grid item={true}>
                                        {dokument && dokument.id && (
                                            <ToiButton
                                                variant='secondary'
                                                color='error'
                                                onClick={onDelete}
                                                startIcon={
                                                    <DeleteRecycleIcon />
                                                }
                                                disabled={
                                                    kilde === 'Plan'
                                                        ? false
                                                        : true
                                                }
                                            >
                                                {kilde === 'Plan'
                                                    ? 'Slett dokument'
                                                    : 'Gå til behandling for å slette'}
                                            </ToiButton>
                                        )}
                                    </Grid>
                                    <Grid item={true}>
                                        <ToiButton
                                            variant='secondary'
                                            onClick={onClose}
                                            disabled={submitting}
                                        >
                                            Avbryt
                                        </ToiButton>

                                        <SpinnerButton
                                            type='submit'
                                            disabled={submitting || pristine}
                                            label='Lagre'
                                            loading={submitting}
                                            style={{marginLeft: '20px'}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                        <pre>Pristine: {JSON.stringify(pristine, null, 2)}</pre>
                        <pre>
                            Submitting: {JSON.stringify(submitting, null, 2)}
                        </pre>
                        <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                        <pre>
                            SubmitErrors:{' '}
                            {JSON.stringify(submitErrors, null, 2)}
                        </pre> */}
                    </form>
                </ToiBox>
            )}
        />
    );
}

export default DocumentForm;
