import * as React from 'react';
import {Grid} from '@mui/material';
import {connect} from 'react-redux';
import {Routes, Route} from 'react-router-dom';
import DialogRoot from '../components/DialogRoot';
import IEError from '../components/Error/IEError/IEError';
import ServerError from '../components/Error/ServerError';
import SnackbarMessage from '../components/SnackbarMessage';
import StatusMelding from '../components/StatusMelding/StatusMelding';
import CustomerSelector from '../features/kunde/MunicipalitySelector';
import Nyheter from '../features/NyhetsTjeneste/Nyheter';
import {
    ApplicationState,
    ConnectedReduxProps,
    getGeneralErrors,
    searchIsActive,
} from '../store';
import CustomerRoutes from './CustomerRoutes';
import './main.css';
import About from '../features/about/About';
import Topbar from '../components/Topbar/Topbar';
import PersonvernPage from '../features/personvern/PersonvernPage';
import {browserIsIE} from './utils';

export interface Props extends ConnectedReduxProps {
    errors?: string;
    hideTopbar: boolean;
}

const AppRoutes = ({errors, hideTopbar, dispatch, ...props}: Props) => {
    const [showIEError, setShowIEError] = React.useState<boolean | undefined>(
        undefined
    );

    if (showIEError === undefined) {
        setShowIEError(browserIsIE());
    }

    if (errors) return <ServerError />;
    if (showIEError) return <IEError />;
    return (
        <>
            <Topbar />

            <Grid container={true} direction='column' wrap='nowrap'>
                <Routes>
                    <Route path='/*' element={<SnackbarMessage />} />
                </Routes>
                <Grid item={true}>
                    <Routes>
                        <Route path='/*' element={<StatusMelding />} />
                    </Routes>
                </Grid>
                <Grid
                    item={true}
                    sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            maxWidth: '100vw !important',
                            //marginBottom: 40
                        },
                        flexGrow: 1,
                    })}
                >
                    <Routes>
                        <Route
                            path='/personvern'
                            element={<PersonvernPage />}
                        />
                        <Route path='/nyheter' element={<Nyheter />} />
                        <Route path='/om-arealplaner' element={<About />} />
                        <Route
                            path='/:customerId/*'
                            element={<CustomerRoutes />}
                        />
                        <Route path='/' element={<CustomerSelector />} />
                    </Routes>
                    <DialogRoot />
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    errors: getGeneralErrors(state),
    hideTopbar: searchIsActive(state),
});

export default connect(mapStateToProps)(AppRoutes);
