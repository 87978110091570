import * as React from 'react';
import {ToiTypography} from '@norkart/toi-components';
import {BehandlingDto} from '@norkart/nkapi-arealplaner-api';
import {DispensasjonDto} from '@norkart/nkapi-arealplaner-api';
import theme from '../../../theme';
import useCan from '../../../utils/auth/useCan';

export interface JournalpostItemProps {
    journalpostData: Partial<BehandlingDto> | Partial<DispensasjonDto>;
}

const JournalpostItem: React.FC<JournalpostItemProps> = ({
    journalpostData,
}: JournalpostItemProps) => {
    const can = useCan();
    const url = can.view
        ? journalpostData.journalpostUrlIntern
        : journalpostData.journalpostUrl;
    if (url)
        return (
            <a
                href={`${url}`}
                target='_blank'
                rel='noreferrer'
                style={{color: theme.palette.secondary.dark}}
            >
                {journalpostData.journalpostnummer}
            </a>
        );
    return (
        <ToiTypography component='span'>
            {journalpostData.journalpostnummer}
        </ToiTypography>
    );
};

export default JournalpostItem;
