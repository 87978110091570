import {Link} from 'react-router-dom';
import {useIsLoggedIn} from '@norkart/nk-auth';
import {
    AuthenticationMethod,
    LoginButton,
    LoginOrOutInteractionProcess,
    UserMenu,
} from '@norkart/nk-auth-components';
import {
    ToiAppBar,
    ToiAppBarGroup,
    ToiAppBarTitle,
} from '@norkart/toi-components';
import TopbarActions from './components/TopbarActions';
import TopbarKommuneHeader from './components/TopbarKommuneHeader';

const Login = () => {
    const isLoggedIn = useIsLoggedIn();

    return isLoggedIn ? (
        <UserMenu idp={AuthenticationMethod.ENTRAID} />
    ) : (
        <LoginButton
            size='medium'
            authenticationMethod={AuthenticationMethod.ENTRAID}
            loginInteractionProcess={LoginOrOutInteractionProcess.POPUP}
        />
    );
};

export const Topbar = () => {
    return (
        <ToiAppBar
            title={
                <ToiAppBarTitle title='Arealplaner' component={Link} to='/' />
            }
            endComponent={<Login />}
        >
            <TopbarKommuneHeader />
            <ToiAppBarGroup grow />
            <TopbarActions />
        </ToiAppBar>
    );
};

export default Topbar;
