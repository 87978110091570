import {orderBy} from 'lodash-es';
import {BehandlingDto} from '@norkart/nkapi-arealplaner-api';

const planforslagAvvistId = 24; //behandlingId That Sould Be Sorted First
export function sortBehandlingList(behandlinger: BehandlingDto[]) {
    const orderedList = orderBy(
        behandlinger,
        ['fraDato', compareFunctionPlanforslag, 'id'],
        ['desc', 'desc', 'desc']
    );
    return orderedList;
}
function compareFunctionPlanforslag(item: BehandlingDto) {
    if (item.behandlingstypeId === planforslagAvvistId) {
        return 1;
    }
    return 0;
}

const ShouldHaveHiddenMsgBehandlingId = [19];
export function showChipWithHiddenMsg(behandling: BehandlingDto) {
    if (!behandling.behandlingstypeId) return false;
    return (
        ShouldHaveHiddenMsgBehandlingId.includes(
            behandling.behandlingstypeId
        ) && behandling.tilgang !== 'Alle'
    );
}
