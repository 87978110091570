import {useQuery} from 'react-query';
import useArealplan from '../arealplaner/useArealplan';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {getProjectsBySearch} from '../../services/apiMittInnspill';
import useKunde from '../kunder/useKunde';
import {ListResponseOfProjectResponse} from './types';

export function useMittInnspillProjects() {
    const kundeId = useSelectedKundeId();
    const arealplan = useArealplan();
    const kunde = useKunde();

    const query = useQuery<ListResponseOfProjectResponse>(
        ['mittinnspill', {kundeId, arealplanId: arealplan.data.id}],
        async () => {
            if (!arealplan.data.planId) return null;
            const {data} = await getProjectsBySearch({
                planid: arealplan.data.planId,
                komnr: arealplan.data?.komnr?.toString(),
                tenantId: kunde.data?.norkartKundeId,
            });
            return data;
        },
        {
            enabled: !!arealplan.data.id && !!kundeId,
        }
    );
    return {
        ...query,
        data: query?.data || ({} as ListResponseOfProjectResponse),
    };
}
