import {action} from 'typesafe-actions';
import {SakArkivJournalDokumentDtoExtened} from '../../hooks/journaler/types';
import {FileImportActionTypes, FileData, FileImportErrors} from './types';

export const addFile = (data: FileData) =>
    action(FileImportActionTypes.ADD_FILE, data);
export const removeFile = (data: FileData) =>
    action(FileImportActionTypes.REMOVE_FILE, data);
export const updateFile = (data: FileData) =>
    action(FileImportActionTypes.UPDATE_FILE, data);
export const reset = () => action(FileImportActionTypes.RESET_FILE_IMPORT);

export const updateErrors = (errors: FileImportErrors) =>
    action(FileImportActionTypes.UPDATE_ERRORS, errors);

export const resetErrorForField = (data: FileData, field: string) =>
    action(FileImportActionTypes.RESET_ERROR_FOR_FIELD, {data, field});

export const resetErrorForFile = (data: FileData) =>
    action(FileImportActionTypes.RESET_ERROR_FOR_FILE, data);

export const addJournalDocument = (data: SakArkivJournalDokumentDtoExtened) =>
    action(FileImportActionTypes.ADD_JOURNAL_DOCUMENT, data);
export const removeJournalDocument = (
    data: SakArkivJournalDokumentDtoExtened
) => action(FileImportActionTypes.REMOVE_JOURNAL_DOCUMENT, data);
