export const formatDate = (date?: Date | string | null) => {
    if (!date) return '';

    let dateToFormat: Date;
    typeof date == 'string'
        ? (dateToFormat = new Date(date))
        : (dateToFormat = date);

    try {
        return formatDateToJSdate(dateToFormat);
    } catch (e) {
        return dateToFormat.toISOString();
    }
};
const formatDateToJSdate = (date: Date) => {
    const d = new Date(date);
    const day = ('0' + d.getDate()).slice(-2);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();

    return `${day}.${month}.${year}`;
};
