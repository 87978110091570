import {forwardRef, PropsWithChildren, ReactNode} from 'react';
import {ToiButtonProps} from '../../../ToiButton';
import {ToiBaseUIMenu as Menu} from '../../../ToiBaseUIMenu';
import {ToiAccordion} from '../../../ToiAccordion';
import {ToiList} from '../../../ToiList';
import {useToiAppBarChildrenEnvironment} from '../..';
import {ToiMenuPopupProps} from '../../../ToiBaseUIMenu/Popup';
import {AppbarMenuTrigger} from './AppbarMenuTrigger';

export type ToiAppBarMenuButtonProps = PropsWithChildren<{
  title?: ReactNode;
  hideChevron?: boolean;
  slotProps?: {menuPopup?: Partial<ToiMenuPopupProps>; button?: Partial<Omit<ToiButtonProps, 'endIcon'>>};
}>;

export const ToiAppBarMenuButton = forwardRef<HTMLElement, ToiAppBarMenuButtonProps>(
  ({title, children, hideChevron, slotProps}, ref) => {
    const env = useToiAppBarChildrenEnvironment();

    return env === 'appbar' ? (
      <Menu.Root>
        <AppbarMenuTrigger hideChevron={hideChevron} button={slotProps?.button}>
          {title}
        </AppbarMenuTrigger>
        <Menu.Popup {...slotProps?.menuPopup}>{children}</Menu.Popup>
      </Menu.Root>
    ) : (
      <ToiAccordion ref={ref as any} title={title as string} icon={slotProps?.button?.startIcon}>
        <ToiList>{children}</ToiList>
      </ToiAccordion>
    );
  },
);
