import * as React from 'react';
import {ToiStack, ToiTypography} from '@norkart/toi-components';

export type ServerErrorProps = object;

export type ServerErrorState = object;

class ServerError extends React.PureComponent<
    ServerErrorProps,
    ServerErrorState
> {
    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    padding: '60px',
                }}
            >
                <ToiStack maxWidth={'520px'}>
                    <ToiTypography
                        variant='h1'
                        sx={(theme) => ({color: theme.toi.palette.text.error})}
                    >
                        En feil har oppstått
                    </ToiTypography>
                    <ToiTypography variant='h2'>
                        Prøv igjen om kort tid.
                    </ToiTypography>
                    <ToiTypography>
                        Vi jobber med å finne en løsning på problemet og
                        beklager eventuell ulempe dette medfører.
                    </ToiTypography>
                </ToiStack>
            </div>
        );
    }
}

export default ServerError;
