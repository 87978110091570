import {styled} from '@mui/material';
import {ToiCardContent, ToiCardContentProps} from '../../pureMuiComponents';

export const StyledNavCardContent = styled(ToiCardContent)<ToiCardContentProps>(({theme}) => {
  const spacing = theme.toi.spacing;
  return {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'flex-end',
    paddingInline: spacing.m,
    paddingBlock: 0,
    gap: theme.toi.spacing.xs,

    '&.ToiNavigationCard-small': {
      gap: spacing.xs,
      minWidth: '175px',
    },

    '&.ToiNavigationCard-medium': {
      gap: spacing.s,
      minWidth: '250px',
    },

    '&.ToiNavigationCard-large': {
      minWidth: '300px',
    },
  };
});
