import {isEmpty} from 'lodash-es';
import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    ToiAppBarGroup,
    ToiAppBarTextButton,
    ToiLogo,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import {
    municipalitiesWithHeradEnding,
    northSamiMunicipalities,
    southSamiMunicipalities,
} from '../../../config/const';
import useKommuneInfo from '../../../hooks/kommuneinfo/useKommuneInfo';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import useIsDemoVersion from '../../../hooks/useIsDemoVersion';

function TopbarKommuneHeader() {
    const kommune = useKommuneInfo();
    const navigate = useNavigate();
    const kundeId = useSelectedKundeId();
    const isDemoVersion = useIsDemoVersion();

    const shouldDisplayNK =
        isDemoVersion || Object.keys(kommune.data).length === 0;

    const kommuneTitle = useMemo(
        () =>
            municipalitiesWithHeradEnding.includes(
                kommune?.data?.KommuneName?.nor
            )
                ? kommune?.data?.KommuneName?.nor + ' herad'
                : kommune?.data?.KommuneName?.nor + ' kommune',
        [municipalitiesWithHeradEnding, kommune?.data?.KommuneName?.nor]
    );

    const samiMunicipalityTitle = useMemo(() => {
        if (southSamiMunicipalities.includes(kommune?.data?.KommuneName?.nor)) {
            return kommune?.data?.KommuneName?.sma + ' tjïelte';
        }

        if (northSamiMunicipalities.includes(kommune?.data?.KommuneName?.nor)) {
            return kommune?.data?.KommuneName?.sma + ' suohkan';
        }
        return null;
    }, [
        southSamiMunicipalities,
        northSamiMunicipalities,
        kommune?.data?.KommuneName?.nor,
        kommune?.data?.KommuneName?.sma,
    ]);

    if (isEmpty(kommune.data)) return null;

    const handleMunicipalityClick = () => {
        navigate(`${kundeId}/arealplaner/search`);
    };

    return (
        <ToiAppBarGroup grow noSpacing>
            <ToiAppBarTextButton
                startIcon={
                    shouldDisplayNK ? (
                        <ToiLogo iconOnly width={'32px'} />
                    ) : (
                        <img
                            src={kommune.data.KommuneCrest}
                            alt='Kommunelogo'
                            style={{height: '28px'}}
                        />
                    )
                }
                sx={{
                    fontWeight: 'light',
                    fontSize: '16px',
                    gap: '10px',
                    ':hover': {backgroundColor: 'white'},
                }}
                onClick={handleMunicipalityClick}
            >
                {shouldDisplayNK
                    ? 'Norkart AS'
                    : !samiMunicipalityTitle && kommuneTitle}

                {samiMunicipalityTitle && (
                    <ToiStack
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}
                    >
                        <ToiTypography component={'span'}>
                            {kommuneTitle}
                        </ToiTypography>
                        <ToiTypography component={'span'}>
                            {samiMunicipalityTitle}
                        </ToiTypography>
                    </ToiStack>
                )}
            </ToiAppBarTextButton>
        </ToiAppBarGroup>
    );
}

export default TopbarKommuneHeader;
