export interface FormatNasjonalArealplanIdProps {
    komnr?: string | null;
    planId?: string | null;
}

export const formatNasjonalArealplanId = ({
    komnr,
    planId,
}: FormatNasjonalArealplanIdProps) => {
    const _komnr = String(komnr);
    return komnr && planId ? `${_komnr}_${planId}` : 'NA';
};
