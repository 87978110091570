import {memo, useMemo} from 'react';
import {debounce} from 'lodash-es';
import {ToiFormControl} from '../../pureMuiComponents';
import {ToiSlider} from '../../ToiSlider/ToiSlider';
import {ToiFormLabel} from '../../ToiFormLabel/ToiFormLabel';
import {ToiFilterComponentProps} from './types';

export const RangeFilter = memo(
  ({column}: ToiFilterComponentProps) => {
    const [min, max] = useMinMax(column);

    const defaultValue = (column.getFilterValue() as [number, number]) ?? [min, max];

    const handleChange = useMemo(
      () =>
        debounce((event: Event, value: number | number[]) => {
          column.setFilterValue(value);
        }, 100),
      [column],
    );

    return (
      <ToiFormControl component='fieldset'>
        <ToiFormLabel component='legend'>Filter</ToiFormLabel>
        <ToiSlider
          min={min}
          max={max}
          valueLabelDisplay='auto'
          minMaxLabel={{min: min.toString(), max: max.toString()}}
          defaultValue={defaultValue}
          getAriaLabel={() => 'slider with range'}
          onChange={handleChange}
        />
      </ToiFormControl>
    );
  },
  () => true,
);

function useMinMax(column: ToiFilterComponentProps['column']) {
  const {min: staticMin, max: staticMax} = column.columnDef.meta?.filterOptions ?? {};

  if (staticMin !== undefined && staticMax !== undefined) {
    return [staticMin, staticMax];
  }
  if (column._getFacetedRowModel === undefined || column._getFacetedMinMaxValues === undefined) {
    throw new Error(
      `Could not calculate min and max values for column ${column.id}.
        Remember to either set "min" and "max" in the "meta.filterOptions" property of the column
        or add the "getFacetedRowModel" and "getFacetedMinMaxValues" to the useToiTable hook to automatically calculate min and max values.`,
    );
  }
  const calculatedMinMax = column.getFacetedMinMaxValues();
  if (calculatedMinMax === undefined) {
    throw new Error(
      'Could not calculate min and max values for column. This should not happen. Contact the monorepo maintainers.',
    );
  }
  const [calculatedMin, calculatedMax] = calculatedMinMax;
  return [staticMin ?? calculatedMin, staticMax ?? calculatedMax];
}
