import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import arrayMutators from 'final-form-arrays';
import {Field, Form} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {DeleteRecycleIcon, PlusIcon} from '@norkart/toi-icons';
import {
    ToiButton,
    ToiDialog,
    ToiDialogActions,
    ToiDialogContent,
    ToiDialogTitle,
    ToiIconButton,
    ToiTextField,
} from '@norkart/toi-components';
import {ArkivVerdiDto, SakVerdiDto} from '@norkart/nkapi-arealplaner-api';
import StyledSelect from '../../../../components/StyledSelect/StyledSelect';

export interface EditSakDialogProps {
    saker: Partial<SakVerdiDto>[];
    arkiver: ArkivVerdiDto[];
    open: boolean;
    defaultValue: number;
    onClose: () => void;
    onOk: (values) => void;
}

function EditSakDialog({
    open,
    saker,
    arkiver,
    onClose,
    onOk,
    defaultValue,
}: EditSakDialogProps) {
    function handleOk() {
        document
            .getElementById('sak-form')!
            .dispatchEvent(
                new Event('submit', {cancelable: true, bubbles: true})
            );
    }

    function handleSubmit(values) {
        onOk(values);
    }

    function sakForm() {
        return (
            <Form
                initialValues={{saker: saker}}
                onSubmit={handleSubmit}
                mutators={{...arrayMutators}}
                render={({
                    handleSubmit,
                    pristine,
                    invalid,
                    values,
                    form: {
                        mutators: {push},
                    },
                }) => (
                    <form onSubmit={handleSubmit} id='sak-form'>
                        <ToiButton
                            startIcon={<PlusIcon size='small' />}
                            variant='secondary'
                            onClick={() =>
                                push('saker', {arkivId: defaultValue})
                            }
                        >
                            Nytt saksnummer
                        </ToiButton>
                        <FieldArray name='saker'>
                            {({fields}) => (
                                <Table padding='checkbox'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Saksår</TableCell>
                                            <TableCell>Sekvensnummer</TableCell>
                                            <TableCell>Arkiv</TableCell>
                                            <TableCell>{}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fields.map((name, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Field
                                                        name={`${name}.sakAar`}
                                                        parse={(
                                                            value,
                                                            name
                                                        ) => {
                                                            if (value == '')
                                                                return value;
                                                            return Number(
                                                                value.replace(
                                                                    /[^\d]/g,
                                                                    ''
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        {({input}) => (
                                                            <ToiTextField
                                                                inputProps={{
                                                                    maxLength: 4,
                                                                }}
                                                                {...input}
                                                            />
                                                        )}
                                                    </Field>
                                                </TableCell>
                                                <TableCell>
                                                    <Field
                                                        name={`${name}.sakSeknr`}
                                                        parse={(
                                                            value,
                                                            name
                                                        ) => {
                                                            if (value == '')
                                                                return value;
                                                            return Number(
                                                                value.replace(
                                                                    /[^\d]/g,
                                                                    ''
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        {({input}) => (
                                                            <ToiTextField
                                                                {...input}
                                                            />
                                                        )}
                                                    </Field>
                                                </TableCell>
                                                <TableCell>
                                                    <Field
                                                        name={`${name}.arkivId`}
                                                        parse={(value) =>
                                                            Number(value)
                                                        }
                                                        render={({input}) => (
                                                            <StyledSelect
                                                                {...input}
                                                                options={
                                                                    arkiver
                                                                }
                                                                valueCol='beskrivelse'
                                                                keyCol='id'
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <ToiIconButton
                                                        aria-label='Slett rad'
                                                        color='transparent'
                                                        onClick={() =>
                                                            fields.remove(index)
                                                        }
                                                        size='small'
                                                    >
                                                        <DeleteRecycleIcon size='small' />
                                                    </ToiIconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </FieldArray>
                        {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre> */}
                    </form>
                )}
            />
        );
    }

    return (
        <ToiDialog
            open={open}
            aria-labelledby='sak-form-dialog-title'
            maxWidth='lg'
            onClose={onClose}
        >
            <ToiDialogTitle id='sak-form-dialog-title'>
                Redigere saksnummer
            </ToiDialogTitle>
            <ToiDialogContent sx={{minWidth: '500px'}}>
                {sakForm()}
            </ToiDialogContent>
            <ToiDialogActions>
                <ToiButton onClick={onClose} variant='secondary'>
                    Avbryt
                </ToiButton>
                <ToiButton onClick={handleOk}>Ok</ToiButton>
            </ToiDialogActions>
        </ToiDialog>
    );
}

export default EditSakDialog;
