import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const RoadHorizontalIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path d='M23 5L1 5' stroke='var(--toi-palette-icons-secondary, #2E2D30)' strokeWidth='1.5' strokeLinecap='round' />
    <path
      d='M23 19L1 19'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M23 12L1 12'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='3 4'
    />
  </ToiIcon>
));

export default RoadHorizontalIcon;
