import {ToiButton} from '@norkart/toi-components';
import {ChevronDownIcon, ChevronRightIcon} from '@norkart/toi-icons';

type Props = {
    name: string;
    onClick;
    isOpen: boolean;
    Icon: JSX.Element;
};

/**Knappen for å åpne/lukke meny */
const MenuButton = ({name, onClick, isOpen, Icon}: Props) => {
    return (
        <ToiButton
            variant='secondary'
            onClick={onClick}
            startIcon={Icon}
            endIcon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
        >
            {name}
        </ToiButton>
    );
};
export default MenuButton;
