import * as React from 'react';
import {orderBy} from 'lodash-es';
import {ToiTypography} from '@norkart/toi-components';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useFrontendSettings from '../../../hooks/settings/useFrontendSettings';
import useCan from '../../../utils/auth/useCan';
import {FrontendSettingsTable} from './FrontendSettingsTable';

export default function FrontendSettings() {
    const can = useCan();
    const innstillinger = useFrontendSettings();
    const isLoading = innstillinger.isLoading;
    if (isLoading)
        return <LoadingSpinner circularProgressProps={{color: 'secondary'}} />;
    if (can.edit)
        return (
            <div>
                <ToiTypography variant='h1'>Innstillinger</ToiTypography>
                <ToiTypography variant='h4'>
                    Her kan du endre innstillinger som påvirker din kommunes
                    side hos arealplaner.no.
                </ToiTypography>
                <FrontendSettingsTable
                    innstillinger={orderBy(
                        innstillinger.kundeInstillinger,
                        'beskrivelse'
                    )}
                />
            </div>
        );
    return (
        <ToiTypography>
            Du har ikke rettigheter til å se denne siden.
        </ToiTypography>
    );
}
