import {Autocomplete} from '@mui/material';
import * as React from 'react';
import {PlanforholdtypeVerdiDto} from '@norkart/nkapi-arealplaner-api';
import {ToiTextField} from '@norkart/toi-components';
import DateRangePickerMui, {
    StartOrEndDate,
} from '../../../../components/DatePicker/DateRangePickerMui/DateRangePickerMui';
import FilterWrapper from '../FilterWrapper';
import {useFilterQL} from '../ReportTools/FilterQL';

type FilterPlanforholdProps = {
    setFilterValues: React.Dispatch<any>;
    planforholdtyper?: PlanforholdtypeVerdiDto[];
    clearFilter: boolean;
};

type SelectedFiltersProps = {
    planforhold?: PlanforholdtypeVerdiDto[];
    ikraftforloperFra?: Date;
    ikraftforloperTil?: Date;
};

export function PlanforholdFilter({
    setFilterValues,
    planforholdtyper,
    clearFilter,
}: FilterPlanforholdProps) {
    const filterQl = useFilterQL();
    const [selectedFilters, setSelectedFilters] = React.useState<
        SelectedFiltersProps | undefined
    >();

    React.useEffect(() => {
        if (planforholdtyper)
            setSelectedFilters({
                planforhold: planforholdtyper.filter(
                    (f) => f.id === 4 || f.id === 5
                ),
            });
    }, []);

    const handleDatoChange = (dates: StartOrEndDate | undefined) => {
        setSelectedFilters({
            ...selectedFilters,
            ikraftforloperTil: dates?.end,
            ikraftforloperFra: dates?.start,
        });
    };

    //Lager array av objekter som skal brukes som filtere
    const handleFiltering = React.useCallback(
        (filters: SelectedFiltersProps | undefined) => {
            const newFilter: any[] = [];
            filters?.ikraftforloperFra &&
                newFilter.push({
                    planIdTilNavigation: {
                        iKraft: {
                            gte: filters.ikraftforloperFra.toJSON(),
                        },
                    },
                });

            filters?.ikraftforloperTil &&
                newFilter.push({
                    planIdTilNavigation: {
                        iKraft: {
                            lte: filters.ikraftforloperTil.toJSON(),
                        },
                    },
                });

            newFilter.push({
                or: filters?.planforhold?.map((pf) => ({
                    planforholdTypeId: {
                        eq: pf.id,
                    },
                })),
            });
            if (newFilter.length === 0) filterQl.updateFilter(undefined);
            else filterQl.updateFilter(newFilter);
        },
        [selectedFilters]
    );

    React.useMemo(() => {
        handleFiltering(selectedFilters);
    }, [
        selectedFilters?.ikraftforloperFra,
        selectedFilters?.ikraftforloperTil,
        selectedFilters?.planforhold,
    ]);

    //Setter oppdaterte filterverdier så det kan brukes som variabler i graphql request
    React.useEffect(() => {
        setFilterValues(filterQl.filterReturn);
    }, [filterQl.filterReturn]);

    React.useEffect(() => {
        if (clearFilter)
            setSelectedFilters({
                planforhold: planforholdtyper?.filter(
                    (pf) => pf.id === 4 || pf.id === 5
                ),
            });
    }, [clearFilter]);

    return (
        <FilterWrapper>
            <Autocomplete
                size='small'
                defaultChecked={true}
                value={
                    selectedFilters?.planforhold
                        ? selectedFilters.planforhold
                        : []
                }
                options={planforholdtyper ? planforholdtyper : []}
                limitTags={1}
                multiple={true}
                getOptionLabel={(option) =>
                    `${option.id} - ${option?.beskrivelseEtterkommer}`
                }
                onChange={(event, newValue) =>
                    setSelectedFilters({
                        ...selectedFilters,
                        planforhold: newValue,
                    })
                }
                renderInput={(params) => (
                    <ToiTextField
                        {...params}
                        label='Planforhold type'
                        variant='outlined'
                    />
                )}
            />

            <DateRangePickerMui
                textFieldProps={{sx: {width: 400}}}
                onChange={(d) => {
                    handleDatoChange(d);
                }}
                label='Ikraft etterkommer'
                startDate={selectedFilters?.ikraftforloperFra}
                endDate={selectedFilters?.ikraftforloperTil}
            />
        </FilterWrapper>
    );
}
