import {FormLabel, FormLabelProps, styled} from '@mui/material';
import {ToiTag, ToiTagProps} from '../ToiTag';

const StyledFormLabel = styled(FormLabel, {
  shouldForwardProp: (prop) => !['optional'].includes(prop.toString()),
})(({theme}) => ({
  ...theme.typography.label,
  display: 'flex',
  gap: theme.toi.spacing.xs,
  alignItems: 'center',

  '&.Mui-focused:not(.Mui-error)': {
    color: theme.toi.palette.text.default,
  },
}));

type OwnProps = {
  optional?: boolean;
  TagProps?: Omit<ToiTagProps, 'ref'>;
};

export type ToiFormLabelProps = FormLabelProps & OwnProps;

export const ToiFormLabel = ({children, optional, TagProps, ...restProps}: ToiFormLabelProps) => (
  <StyledFormLabel {...restProps}>
    {children}
    {optional ? <ToiTag value='Valgfri' color='normal' /> : TagProps && <ToiTag {...TagProps} />}
  </StyledFormLabel>
);

export default ToiFormLabel;
