import {Menu} from '@base-ui-components/react/menu';

const {Root, Group} = Menu;
export {Root, Group};
export {
  ToiMenuItem as Item,
  ToiMenuRadioItem as RadioItem,
  ToiMenuRadioGroup as RadioGroup,
  ToiMenuCheckboxItem as CheckboxItem,
  ToiMenuLabel as Label,
  ToiMenuGroupLabel as GroupLabel,
  ToiMenuExternalLinkItem as ExternalLinkItem,
  ToiMenuReactRouterLinkItem as ReactRouterLinkItem,
} from './Item';
export type {
  ToiMenuItemProps as ItemProps,
  ToiMenuRadioGroupProps as RadioGroupProps,
  ToiMenuRadioItemProps as RadioItemProps,
  ToiMenuCheckboxItemProps as CheckboxItemProps,
} from './Item';
export {ToiMenuTrigger as Trigger, ToiSubMenuTrigger as SubmenuTrigger} from './Trigger';
export {ToiMenuPopup as Popup} from './Popup';
export type {ToiMenuPopupProps as PopupProps} from './Popup';
export type {ToiMenuTriggerProps as TriggerProps, ToiSubMenuTriggerProps as SubmenuTriggerProps} from './Trigger';
