import {SelectChangeEvent} from '@mui/material';
import {useMemo} from 'react';
import {ToiBox, ToiMenuItem, ToiSelect} from '@norkart/toi-components';
import useMeta from '../../../../hooks/meta/useMeta';
import FilterLabel from './FilterLabel';
import {useFilter} from './useFilter';

export const OpprinneligAdministrativEnhetFilter = () => {
    const meta = useMeta();
    const filter = useFilter('opprinneligAdministrativEnhet');

    const getSelectedValue = useMemo<string>(() => {
        return filter.get() || '';
    }, [filter]);

    const handleChange = (e: SelectChangeEvent<unknown>) => {
        e.target.value ? filter.set(String(e.target.value)) : filter.clear();
    };
    return (
        <ToiBox>
            <FilterLabel>Opprinneling administrativ enhet</FilterLabel>
            <ToiSelect
                onChange={handleChange}
                value={getSelectedValue}
                MenuProps={{disableScrollLock: true}}
            >
                <ToiMenuItem
                    aria-label='Ingen'
                    value=''
                    sx={{minHeight: '30px!important'}}
                />
                {meta.data.kommuner.map((k) => {
                    return (
                        <ToiMenuItem
                            key={k.kommuneNummer ?? ''}
                            value={k.kommuneNummer ?? ''}
                        >
                            {`${k.kommuneNummer} - ${k.kommuneNavn}`}
                        </ToiMenuItem>
                    );
                })}
            </ToiSelect>
        </ToiBox>
    );
};
