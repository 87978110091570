import {ToiTypography} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {formatDate} from '../../utils/formatDate';

interface IkraftDatoDisplayProps {
    plan: ArealplanDto;
}

const IkraftDatoDisplay = (props: IkraftDatoDisplayProps) => {
    return (
        <ToiTypography
            sx={{
                ...(_shouldBeDisabled(props.plan.planStatusId) && {
                    color: 'grey',
                    fontStyle: 'italic',
                }),
            }}
        >
            {formatDate(props.plan.iKraft)}
        </ToiTypography>
    );
};

const _shouldBeDisabled = (planstatusId?: number | null) => {
    if (!planstatusId) return false;
    // Tydelig visning av at ikraftdato er utgått på planer med status 4 og 5
    return [4, 5].includes(planstatusId);
};

export default IkraftDatoDisplay;
