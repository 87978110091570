import * as React from 'react';
import {SakVerdiDto} from '@norkart/nkapi-arealplaner-api';
import theme from '../../../theme';
import useCan from '../../../utils/auth/useCan';
import {formatSaksnr} from '../../../utils/formatSaksnr';

export interface SakItemProps {
    sak: Partial<SakVerdiDto>;
}

const SakItem: React.FC<SakItemProps> = ({sak}: SakItemProps) => {
    const can = useCan();
    const url = can.view ? sak.urlIntern : sak.url;
    if (url)
        return (
            <a
                href={`${url}`}
                target='_blank'
                rel='noreferrer'
                style={{color: theme.palette.secondary.dark}}
            >
                {formatSaksnr(sak.sakAar, sak.sakSeknr)}
            </a>
        );
    return <span>{formatSaksnr(sak.sakAar, sak.sakSeknr)}</span>;
};

export default SakItem;
