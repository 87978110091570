import {Header, RowData} from '@tanstack/react-table';
import {TableSortLabel} from '@mui/material';
import {flexRender} from '@tanstack/react-table';
import {ArrowDownIcon} from '@norkart/toi-icons';
import {ToiTableFilter} from './ToiTableFilter';

interface ToiHeaderContentProps<TData extends RowData> {
  header: Header<TData, unknown>;
}

export function ToiHeaderContent<TData extends RowData>({header}: ToiHeaderContentProps<TData>) {
  if (header.isPlaceholder) return null;

  if (header.column.getCanSort()) {
    return (
      <>
        <TableSortLabel
          active={Boolean(header.column.getIsSorted())}
          direction={header.column.getIsSorted() || undefined}
          onClick={header.column.getToggleSortingHandler()}
          hideSortIcon={!header.column.getCanSort()}
          title={
            header.column.getNextSortingOrder() === 'asc'
              ? 'Sorter stigende'
              : header.column.getNextSortingOrder() === 'desc'
                ? 'Sorter synkende'
                : 'Fjern sortering'
          }
          IconComponent={ArrowDownIcon}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
        </TableSortLabel>
        {header.column.getCanFilter() && <ToiTableFilter column={header.column} noMargin />}
      </>
    );
  } else {
    return (
      <>
        {flexRender(header.column.columnDef.header, header.getContext())}
        {header.column.getCanFilter() && <ToiTableFilter column={header.column} />}
      </>
    );
  }
}
