import {ButtonTypeMap, styled} from '@mui/material';
import {forwardRef} from 'react';

import {OverridableComponent} from '@mui/material/OverridableComponent';
import {ToiButton} from '../ToiButton';
import {ToiButtonProps} from '../ToiButton/ToiButton';
import {useToiAppBarCollapse} from './ToiAppBar';

export type ToiAppBarTextButtonProps = ToiButtonProps;

const StyledButton = styled(ToiButton)(({theme}) => {
  const {isCollapsed} = useToiAppBarCollapse();

  return {
    backgroundColor: 'transparent',

    ...(isCollapsed && {
      width: '100%',
      justifyContent: 'flex-start',
      paddingBlock: 0,

      '& .MuiButton-endIcon': {
        marginInline: 'auto 4px',
      },
    }),

    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.vars.toi.palette.backgrounds.component.highlighted,
    },

    '&.Mui-focusVisible': {
      ...theme.toi.focus,
      border: 'none',
      outlineOffset: '-2px',
    },

    height: theme.mixins.toolbar.minHeight,
    borderRadius: 0,
    paddingInline: theme.toi.spacing.s,
    paddingBlock: theme.toi.spacing.xs,
    '--toi-palette-icons-secondary': theme.vars.toi.palette.text.default,
    ...theme.typography.body2,
  };
});

type DefaultComponent = ButtonTypeMap['defaultComponent'];
type RefType = HTMLElementTagNameMap[DefaultComponent];

export const ToiAppBarTextButton = forwardRef<RefType, ToiButtonProps>((props, forwardedRef) => {
  return (
    <StyledButton ref={forwardedRef} {...props}>
      {props.children}
    </StyledButton>
  );
}) as OverridableComponent<ButtonTypeMap>;

export default ToiAppBarTextButton;
