import {forwardRef} from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {LinkTypeMap} from '@mui/material';
import {ToiTypography} from '../ToiTypography';
import {ToiLink, ToiLinkProps, ToiLinkOwnProps} from '../ToiLink';
import {ToiStack} from '../pureMuiComponents';

type OwnProps = {
  icon?: React.ReactNode;
  title?: string;
};

export type ToiAppBarTitleProps = Omit<ToiLinkProps, keyof OwnProps> & OwnProps;

export const ToiAppBarTitle = forwardRef<HTMLAnchorElement, ToiAppBarTitleProps>(
  ({icon, title, children, sx, ...restProps}: ToiAppBarTitleProps, ref) => {
    return (
      <ToiLink underline='none' sx={{marginLeft: '0 !important', ...sx}} {...restProps} ref={ref}>
        {children ? (
          children
        ) : (
          <ToiStack direction='row' alignItems='center' gap={(theme) => theme.toi.spacing.xs}>
            {icon}
            {title && (
              <ToiTypography noSpacing variant='h4' component='span'>
                {title}
              </ToiTypography>
            )}
          </ToiStack>
        )}
      </ToiLink>
    );
  },
) as OverridableComponent<LinkTypeMap<ToiLinkOwnProps & OwnProps>>;
