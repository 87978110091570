import * as React from 'react';
import {ToiTypography} from '@norkart/toi-components';

export interface WarningProps {
    message;
}

const Warning: React.FC<WarningProps> = ({message}: WarningProps) => {
    return (
        <ToiTypography
            component={'span'}
            sx={(theme) => ({color: theme.palette.warning.main})}
        >
            {message}
        </ToiTypography>
    );
};

export default Warning;
