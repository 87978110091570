import {Typography} from '@mui/material';
import * as React from 'react';
import {ToiBox, ToiButton} from '@norkart/toi-components';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import {useKodelisterBehandlingstyper} from '../../../../hooks/kodelister/useKodelisterBehandlingstyper';
import useMeta from '../../../../hooks/meta/useMeta';
import {setTitle} from '../../../../utils/setTitle';
import ReportPage from '../ReportPage';
import {SaksbehandlingstidFilter} from './SaksbehandlingstidFilter';
import SaksbehandlingstidReport from './SaksbehandlingstidReport';

export type SaksbehandlingstidQueryVariables = {
    filter?: {and: any[]} | undefined;
    inputParams?: any;
};

const calculateYearMonthDayAverage = (days: number) => {
    const totalYears = days / 365;
    const remainingDays = days % 365;
    const totalMonths = remainingDays / 30;
    const totalDays = remainingDays % 30;

    return {
        years: Math.floor(totalYears),
        months: Math.floor(totalMonths),
        days: Math.round(totalDays),
    };
};

export default function Saksbehandlingstid() {
    setTitle('Rapporter > Saksbehandlingstid');
    const initialVariables: SaksbehandlingstidQueryVariables = {};
    const meta = useMeta();
    const behandlingstyper = useKodelisterBehandlingstyper();
    const [filterValues, setFilterValues] = React.useState<any | undefined>(
        undefined
    );
    const [inputParamsValues, setInputParamsValues] = React.useState<
        any | undefined
    >(undefined);
    const [vars, setVars] =
        React.useState<SaksbehandlingstidQueryVariables>(initialVariables);
    const [averageDays, setAverageDays] = React.useState<number | undefined>(
        undefined
    );
    const yearMonthDayAverage = averageDays
        ? calculateYearMonthDayAverage(averageDays)
        : undefined;

    //Oppdaterer variabler til graphql request når "filterValues" oppdateres i "FilterBehandlinger" komponent
    React.useMemo(() => {
        const newVars: SaksbehandlingstidQueryVariables = {
            ...vars,
            filter: filterValues,
            inputParams: inputParamsValues,
        };
        setVars((prevValue) => {
            if (newVars === initialVariables) return prevValue;
            else if (!newVars.filter && !newVars.inputParams) {
                return initialVariables;
            } else {
                return newVars;
            }
        });
    }, [filterValues, inputParamsValues]);

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <ReportPage title='Saksbehandlingstid'>
            <SaksbehandlingstidFilter
                setInputParamsValues={setInputParamsValues}
                setFilterValues={setFilterValues}
                clearFilter={filterValues ? false : true}
                behandlingstyperFra={
                    behandlingstyper.data ? behandlingstyper.data : []
                }
                behandlingstyperTil={
                    behandlingstyper.data ? behandlingstyper.data : []
                }
                plantyper={meta.data.plantyper}
            />

            <ToiButton
                variant='secondary'
                onClick={() => setFilterValues(undefined)}
                sx={{
                    display: filterValues ? 'flex' : 'none',
                    marginTop: '10px',
                }}
            >
                Nullstill filter
            </ToiButton>

            <ToiBox
                visibility={averageDays ? 'visible' : 'hidden'}
                boxSizing={'border-box'}
                width={'fit-content'}
                p={'26px'}
                display={'flex'}
                flexDirection='column'
                mt={'10px'}
                sx={{
                    border: '1px solid #DCDCDC',
                    height: 'fit-content',
                    backgroundColor: '#F3F5F0',
                }}
            >
                <Typography
                    fontWeight={600}
                    color='#005600'
                    lineHeight='120%'
                    fontSize={'18px'}
                    textTransform='uppercase'
                >
                    Gjennomsnitt antall dager
                </Typography>
                <Typography
                    fontWeight={400}
                    color='#005600'
                    lineHeight='140%'
                    fontSize={'16px'}
                    textTransform='lowercase'
                >
                    Gjennomsnitt av antall dager mellom behandlingene
                </Typography>
                <Typography
                    pt='16px'
                    fontWeight={500}
                    color='#005600'
                    lineHeight='38px'
                    fontSize={'32px'}
                    textTransform='lowercase'
                >
                    {averageDays && Math.round(averageDays)} dager
                </Typography>
                {yearMonthDayAverage && (
                    <Typography
                        fontWeight={400}
                        color='#005600'
                        lineHeight='140%'
                        fontSize={'16px'}
                        textTransform='lowercase'
                    >
                        {yearMonthDayAverage.years} år,{' '}
                        {yearMonthDayAverage.months} måneder,{' '}
                        {yearMonthDayAverage.days} dager
                    </Typography>
                )}
            </ToiBox>
            <SaksbehandlingstidReport
                setAverageDays={setAverageDays}
                kundeId={meta.kundeId}
                kommunenummer={meta.komnr}
                variables={vars}
            />
        </ReportPage>
    );
}
