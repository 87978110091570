import type {} from '@mui/material/themeCssVarsAugmentation';

import {useTheme} from '@mui/material';

export function useSpacing() {
  return useTheme().toi.spacing;
}

export function usePalette() {
  return useTheme().vars.toi.palette;
}
