import {MenuItemTypeMap, MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps, styled} from '@mui/material';
import {forwardRef} from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {ToiListItemText} from '../ToiList';

type OwnProps = {
  nonInteractive?: boolean;
};

/**
 * @deprecated Use ToiBaseUIMenu instead since it has better UU support
 */
export type ToiMenuItemProps = Omit<MuiMenuItemProps, keyof OwnProps> & OwnProps;

export const StyledMenuItem = styled(MuiMenuItem)(({theme}) => {
  return {
    paddingBlock: theme.toi.spacing.xs,
    paddingInline: theme.toi.spacing.s,

    '&[role="listitem"]': {
      pointerEvents: 'none',
    },

    '&.Mui-focusVisible': {
      backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,
    },
    '&:hover': {
      backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,
    },

    '&.Mui-selected:': {
      backgroundColor: theme.vars.toi.palette.backgrounds.component.selected,
    },
    '&.Mui-disabled': {
      color: theme.vars.toi.palette.disabled.textOnWhite,
    },

    '& .MuiListItemText-root > .MuiListItemText-primary': {
      fontWeight: 400,
      '&:has(~ .MuiListItemText-secondary)': {
        fontWeight: 500,
      },
    },

    '& .MuiFormControlLabel-root .MuiTypography-root': theme.typography.body2,
  };
});

/**
 * @deprecated Use ToiBaseUIMenu instead since it has better UU support
 */
export const ToiMenuItem = forwardRef<HTMLLIElement, ToiMenuItemProps>(({children, nonInteractive, ...props}, ref) => {
  return (
    <StyledMenuItem ref={ref} role={nonInteractive ? 'listitem' : 'menuitem'} {...props}>
      {typeof children === 'string' ? <ToiListItemText primary={children} /> : children}
    </StyledMenuItem>
  );
}) as OverridableComponent<MenuItemTypeMap<OwnProps>>;
