import * as React from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Link,
    Typography,
} from '@mui/material';
import MarkDown from 'react-markdown';
import {ConnectedReduxProps} from '../../../../store';
import SpinnerButton from '../../../../components/SpinnerButton';
import StyledCheckbox from '../../../../components/Checkbox/StyledCheckbox';
import {Vertikalniva} from '../../../../store/shared/types';
import {helpObject} from '../../../../help/helpObject';
import {hideDialog} from '../../../../store/dialog/actions';
import useTrackArealplanerEvent from '../../../../hooks/appinsights/useTrackArealplanerEvent';
import Vertikalnivå from '../../../../assets/images/vertniv.png';

export interface MapInformationDialogProps extends ConnectedReduxProps {
    vertikalLevels: Vertikalniva[];
    open: boolean;
}

export const AlwaysHideKey = 'HideMapInfoDialogAlways';
const MapInformationDialog: React.FC<MapInformationDialogProps> = ({
    vertikalLevels,
    open,
    dispatch,
    ...props
}: MapInformationDialogProps) => {
    const [alwaysHideMsg, setHideAlways] = React.useState<boolean>(false);
    const trackMapHideInfo = useTrackArealplanerEvent(
        'promptAlwaysHideMapInformation',
        'event'
    );
    function handleClose() {
        hideDialog(dispatch);
    }

    function hideDialogAlways() {
        setHideAlways(!alwaysHideMsg);
    }

    function handleOkClick() {
        if (alwaysHideMsg) {
            localStorage.setItem(AlwaysHideKey, 'true');
            trackMapHideInfo('AlltidSkjulInfoDialogKart');
        }
        handleClose();
    }

    return (
        <Dialog open={open} maxWidth='md'>
            <DialogContent>
                <h2>Valgt plan har kartlag (vertikalnivå):</h2>
                {vertikalLevels.map((vert) => (
                    <Typography key={vert.id}>
                        {'(' + vert.id + ') ' + vert.beskrivelse}
                    </Typography>
                ))}
                <Box
                    sx={{
                        '& img': {
                            maxWidth: 400,
                            textAlign: 'center',
                            margin: 'auto',
                        },
                    }}
                >
                    <MarkDown>{helpObject.MapInformationDialog1}</MarkDown>
                    <img alt='vertikalnivå' src={Vertikalnivå} />
                    <MarkDown>{helpObject.MapInformationDialog2}</MarkDown>
                </Box>
            </DialogContent>
            <DialogActions>
                <Link
                    component='button'
                    onClick={hideDialogAlways}
                    style={{marginRight: '30px'}}
                >
                    <StyledCheckbox checked={alwaysHideMsg} /> Aldri vis denne
                    meldingen igjen
                </Link>
                <SpinnerButton onClick={handleOkClick} label='Ok' />
            </DialogActions>
        </Dialog>
    );
};
export default MapInformationDialog;
