import {forwardRef} from 'react';
import {ToiFormControlLabel} from '../ToiFormControlLabel';

import {ToiCheckbox, ToiCheckboxProps} from '../ToiCheckbox';
import {InputDescription, InputDescriptionProps} from '../internal/InputDescription';

interface OwnProps extends InputDescriptionProps {
  error?: boolean;
}

export type ToiCheckboxOptionProps = ToiCheckboxProps & OwnProps;

export const ToiCheckboxOption = forwardRef<HTMLButtonElement, ToiCheckboxOptionProps>((props, ref) => {
  const {label, optional, helpText, errorText, error, ...restProps} = props;

  const descriptionProps = {label, optional, helpText, errorText};
  return (
    <ToiFormControlLabel
      control={<ToiCheckbox ref={ref} error={error || Boolean(errorText)} {...restProps} />}
      label={<InputDescription {...descriptionProps} />}
    />
  );
});
