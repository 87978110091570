import {useState} from 'react';
import {ToiBox, ToiChip, ToiTypography} from '@norkart/toi-components';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useMeta from '../../../hooks/meta/useMeta';
import {JournalposterSettings} from './JournalposterSettings';

type ArkivSelectorProps = {
    selectedArchive: number;
    onClick: (archiveId: number) => void;
};

function ArkivSelector({selectedArchive, onClick}: ArkivSelectorProps) {
    const meta = useMeta();
    const arkiver = meta.data.arkiver;

    return (
        <ToiBox>
            <ToiTypography variant='caption'>Velg arkiv: </ToiTypography>
            {arkiver.map((arkiv) => {
                return (
                    <ToiChip
                        key={arkiv.id}
                        label={arkiv.beskrivelse}
                        color={
                            selectedArchive === arkiv.id ? 'success' : 'grey'
                        }
                        sx={{
                            marginRight: '5px',
                        }}
                        clickable={true}
                        onClick={(e) => arkiv.id && onClick(arkiv.id)}
                    />
                );
            })}
        </ToiBox>
    );
}

export const ArchiveIntegrationSettings = () => {
    const meta = useMeta();
    const arkiver = meta.data.arkiver;
    const [selectedArchive, setSelectedArchive] = useState(meta.aktivtArkiv);

    if (!meta.isFetched) return <LoadingSpinner />;
    if (!meta.hasArkivIntegrasjon())
        return (
            <ToiBox>
                <ToiTypography>
                    Arkivintegrasjon er ikke aktivert.
                </ToiTypography>
            </ToiBox>
        );

    return (
        <>
            <ToiTypography variant='h1'>Arkivintegrasjon</ToiTypography>
            <ToiTypography sx={{paddingBottom: '20px'}}>
                Her kan du sette opp hvilke egenskaper som gjelder for
                integrasjon mot arkiv.
            </ToiTypography>
            {arkiver.length && arkiver.length > 1 && (
                <ToiBox paddingBottom={'10px'}>
                    <ArkivSelector
                        selectedArchive={selectedArchive}
                        onClick={setSelectedArchive}
                    />
                </ToiBox>
            )}
            <JournalposterSettings arkivId={selectedArchive} />
        </>
    );
};
