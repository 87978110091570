import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {orderBy} from 'lodash-es';
import {BehandlingDto, SakVerdiDto} from '@norkart/nkapi-arealplaner-api';
import useArealplan from '../../../hooks/arealplaner/useArealplan';
import {showDialog} from '../../../store/dialog/actions';
import {setExpandBehandlingId} from '../../../store/session/actions';
import {ImportDocumentDialogProps} from '../../dokumenter/ImportDocumentDialog/ImportDocumentDialog';

export const useBehandlingActions = () => {
    const dispatch = useDispatch();
    const {data: plan} = useArealplan();

    const focusRow = useCallback((id: string) => {
        dispatch(setExpandBehandlingId(id));
    }, []);

    const create = () => {
        const behandling = {} as BehandlingDto;
        if ((plan.saker?.length ?? 0) > 0) {
            let highestSaksYear = 0;
            plan.saker?.forEach((sak) => {
                highestSaksYear =
                    (sak?.sakAar ?? 0 > highestSaksYear)
                        ? (sak.sakAar ?? 0)
                        : highestSaksYear;
            });
            const sakerWithHighestYear: SakVerdiDto[] =
                plan?.saker?.filter((sak) => sak.sakAar === highestSaksYear) ??
                [];
            let highestSaksnummer = 0;
            sakerWithHighestYear.filter(
                (sak) =>
                    (highestSaksnummer =
                        (sak.sakSeknr ?? 0 > highestSaksnummer)
                            ? (sak.sakSeknr ?? 0)
                            : highestSaksnummer)
            );
            const sakToDisplay = sakerWithHighestYear.find(
                (sak) => sak.sakSeknr
            );
            if (sakToDisplay) behandling.sak = sakToDisplay;
        }
        dispatch(
            showDialog({
                dialogType: 'EDIT_BEHANDLING',
                dialogProps: {behandling, plan, focusRow},
            })
        );
    };

    const edit = (behandling: BehandlingDto) => {
        dispatch(
            showDialog({
                dialogType: 'EDIT_BEHANDLING',
                dialogProps: {behandling, plan, focusRow},
            })
        );
    };

    const importFilesFromDisk = (event, behandling: BehandlingDto) => {
        const files = Array.from(event.target.files) as File[];
        dispatch(
            showDialog({
                dialogType: 'IMPORT_DOCUMENTS',
                dialogProps: {
                    files,
                    behandling: behandling,
                    plan,
                    showRowDetails: focusRow,
                } as ImportDocumentDialogProps,
            })
        );

        // @ts-expect-error Trick input to fire onChange every time. Does not work on IE.
        // eslint-disable-next-line no-constant-binary-expression
        const isIE = /*@cc_on!@*/ false || !!document.documentMode;
        if (!isIE) event.target.value = '';
    };

    const importFilesFromArchive = (behandling: BehandlingDto) => {
        dispatch(
            showDialog({
                dialogType: 'IMPORT_DOCUMENTS_FROM_ARCHIVE',
                dialogProps: {
                    behandling: behandling,
                    plan,
                    showRowDetails: focusRow,
                } as ImportDocumentDialogProps,
            })
        );
    };

    const rearrangeDocuments = (behandling: BehandlingDto) => {
        dispatch(
            showDialog({
                dialogType: 'REARRANGE_DOCUMENTS',
                dialogProps: {
                    plan,
                    behandling: behandling,
                    documents: orderBy(
                        behandling.dokumenter,
                        ['rekkefolgeBehandling', 'dokumentType'],
                        ['asc', 'asc']
                    ),
                },
            })
        );
    };

    return {
        create,
        edit,
        importFilesFromArchive,
        importFilesFromDisk,
        rearrangeDocuments,
    };
};
