import React from 'react';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from '@hello-pangea/dnd';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DragHandle from '@mui/icons-material/DragHandle';
import {ToiStack} from '@norkart/toi-components';
import {DokumentUtvidet} from '../../plan/PlanDocumentList/PlanDocumentListDocuments';
import {formatDate} from '../../../utils/formatDate';
import FileIcon from '../../../components/FileIcon';
import {getFileExtension} from '../../../utils/getFileExtension';

type Props = {
    documents: DokumentUtvidet[];
    setDocuments: (documents: DokumentUtvidet[]) => void;
};

function RearrangeDocumentsTable({documents, setDocuments, ...props}: Props) {
    const onDragEnd = (result: DropResult) => {
        const {destination, source} = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const document = documents[source.index];
        const newDocuments = documents;
        newDocuments.splice(source.index, 1);
        newDocuments.splice(destination.index, 0, document);
        setDocuments(newDocuments);
    };
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <MuiTable>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell variant='head'>Dokumenttype</TableCell>
                        <TableCell variant='head'>Dokumentnavn</TableCell>
                        <TableCell variant='head'>Dato</TableCell>
                    </TableRow>
                </TableHead>
                <Droppable droppableId='dokumenter'>
                    {(provided) => (
                        <TableBody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {documents.map((document, index) => (
                                <Row
                                    key={document.id}
                                    document={document}
                                    index={index}
                                />
                            ))}
                            {provided.placeholder}
                        </TableBody>
                    )}
                </Droppable>
            </MuiTable>
        </DragDropContext>
    );
}

export default RearrangeDocumentsTable;

function Row({document, index}: {document: DokumentUtvidet; index: number}) {
    if (!document.id) return null;
    return (
        <Draggable draggableId={document.id.toString()} index={index}>
            {(provided) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <TableCell>
                        <DragHandle />
                    </TableCell>
                    <TableCell>{document.dokumenttype}</TableCell>
                    <TableCell>
                        <ToiStack direction='row' gap={1}>
                            <FileIcon
                                extension={getFileExtension(
                                    document.dokumentnavn ?? ''
                                )}
                            />
                            {document.dokumentnavn}
                        </ToiStack>
                    </TableCell>
                    <TableCell>{formatDate(document.dokumentdato)}</TableCell>
                </TableRow>
            )}
        </Draggable>
    );
}
