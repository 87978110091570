import * as React from 'react';
import {ColumnDef} from '@tanstack/react-table';
import {ToiLink} from '@norkart/toi-components';
import {LogLines} from '../../../../hooks/konsistenssjekk/types';

export default function KonsistenssjekkColumns(
    kundeId: string,
    kommunenummer: string
) {
    const baseUrl = window.location.origin;

    const columns = React.useMemo<ColumnDef<LogLines>[]>(
        () => [
            {
                id: 'planId',
                accessorKey: 'planId',
                header: () => 'PlanId',
                cell: (r) => (
                    <ToiLink
                        key={r.row.id + 'link'}
                        href={`${baseUrl}/${kundeId}/gi?funksjon=VisPlan&planidentifikasjon=${r.row.original?.planId}&kommunenummer=${kommunenummer}`}
                        target='_blank'
                        underline='none'
                    >
                        {r.row.original?.planId}
                    </ToiLink>
                ),
                minSize: 150,
            },
            {
                accessorKey: 'kategoriFeil',
                header: () => 'Feil',
                minSize: 190,
            },
            {
                accessorKey: 'qmsOppgavenavn',
                header: () => 'Oppgavenavn QMS',
                minSize: 220,
            },
            {
                accessorKey: 'verdiArealplaner',
                header: () => 'Verdi arealplaner',
                minSize: 400,
            },
            {
                accessorKey: 'verdiQms',
                header: () => 'Verdi QMS',
                minSize: 400,
            },
        ],
        []
    );
    return columns;
}
