import {SVGProps} from 'react';
import {IconLogo} from './IconLogo';
import {IconLogoWithoutSpacing} from './IconLogoWithoutSpacing';
import {Logo} from './Logo';
import {LogoWithoutSpacing} from './LogoWithoutSpacing';

export interface ToiLogoProps extends SVGProps<SVGSVGElement> {
  iconOnly?: boolean;
  blackWhite?: boolean;
  noSpacing?: boolean;
  title?: string;
}

export type LogoProps = Exclude<ToiLogoProps, 'iconOnly' | 'noSpacing' | 'title'>;

export const ToiLogo = ({iconOnly, noSpacing, ...svgProps}: ToiLogoProps) => {
  if (noSpacing) {
    return iconOnly ? <IconLogoWithoutSpacing {...svgProps} /> : <LogoWithoutSpacing {...svgProps} />;
  }
  return iconOnly ? <IconLogo {...svgProps} /> : <Logo {...svgProps} />;
};

export default ToiLogo;
