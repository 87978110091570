import moment from 'moment-timezone';
import * as parser from 'cron-parser';
import {ToiBox, ToiTypography} from '@norkart/toi-components';
import useKonsistenssjekk from '../../../../../hooks/konsistenssjekk/useKonsistenssjekk';
import useMeta from '../../../../../hooks/meta/useMeta';

const newDateFormat = (dateObject: Date | string) => {
    return new Date(dateObject).toLocaleDateString('nb-NO', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

//Used for calculating how long the last report took to run
const timeDiffMinutes = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const numbers = new Date(endDate.getTime() - startDate.getTime());
    if (isNaN(numbers.getSeconds())) return false;
    const result =
        numbers.getMinutes() +
        'm ' +
        (numbers.getSeconds() < 10
            ? '0' + numbers.getSeconds()
            : numbers.getSeconds() + 's');

    return result !== '0:00' ? result : false;
};

const parseCronExpression = (cronString: string) => {
    if (cronString === '') return;
    const whitespaceCount = cronString.trim().split(' ').length - 1;

    let formattedCronExpression = cronString;
    if (whitespaceCount > 5) {
        formattedCronExpression = cronString.substring(
            0,
            cronString.lastIndexOf(' ')
        );
    } else if (whitespaceCount < 5) {
        formattedCronExpression = '0 ' + cronString;
    }

    try {
        const konsistenssjekkScheduledCronParse = parser
            .parseExpression(formattedCronExpression)
            .next()
            .toDate();
        const formatDateTime = newDateFormat(konsistenssjekkScheduledCronParse);

        return formatDateTime;
    } catch {
        return;
    }
};

export default function KonsistenssjekkRunInfo() {
    const konsistenssjekk = useKonsistenssjekk({
        term: '',
        order: '',
        orderBy: '',
    });
    const meta = useMeta();

    const nextScheduledKonsistenssjekk = parseCronExpression(
        meta.konsistenssjekkScheduler
    );

    const lastRun =
        konsistenssjekk.data?.dato &&
        newDateFormat(
            moment
                .utc(konsistenssjekk.data.dato)
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                .utc()
                .format()
        );

    const lastRunTime = timeDiffMinutes(
        konsistenssjekk.data?.startDato ? konsistenssjekk.data?.startDato : '',
        konsistenssjekk.data?.dato ? konsistenssjekk.data?.dato : ''
    );

    return (
        <ToiBox>
            <ToiTypography>
                Siste kjørt: {lastRun ? lastRun : 'Aldri kjørt'}
            </ToiTypography>
            <ToiTypography>
                Kjøretid forrige kjøring:{' '}
                {lastRunTime ? lastRunTime : !lastRun ? 'Aldri kjørt' : ''}
            </ToiTypography>
            <ToiTypography>
                Neste planlagte kjøring:{' '}
                {nextScheduledKonsistenssjekk
                    ? nextScheduledKonsistenssjekk
                    : 'Ingen planlagt kjøring'}
            </ToiTypography>
        </ToiBox>
    );
}
