import {useMemo, useState} from 'react';
import {
    ColumnDef,
    getSortedRowModel,
    SortingState,
} from '@tanstack/react-table';
import {KontaktpersonDto} from '@norkart/nkapi-arealplaner-api';
import {
    ToiBox,
    ToiIconButton,
    ToiStack,
    ToiTable,
    ToiTooltip,
    ToiTypography,
    useToiTable,
} from '@norkart/toi-components';
import {DeleteRecycleIcon, EditIcon, PlusIcon} from '@norkart/toi-icons';
import {useKontaktpersoner} from '../../../hooks/kontaktpersoner/useKontaktpersoner';
import useMeta from '../../../hooks/meta/useMeta';
import ActionButton from '../../../components/ActionButtons/ActionButton';
import KontaktpersonDialog from './KontaktpersonDialog';
import ConfirmDeleteKontaktpersonDialog from './ConfirmDeleteKontaktpersonDialog';

type KontaktpersonMedBeskrivelse = KontaktpersonDto & {beskrivelse: string};

function Kontaktpersoner() {
    const {data: kontaktpersoner} = useKontaktpersoner();
    const {data: metaData} = useMeta();

    const [kontaktpersonDialog, setKontaktpersonDialog] = useState(false);
    const [kontaktpersonDeleteDialog, setKontaktpersonDeleteDialog] =
        useState(false);

    const [kontaktperson, setKontaktperson] = useState<
        KontaktpersonMedBeskrivelse | undefined
    >(undefined);

    const handleAddButton = () => {
        setKontaktperson(undefined);
        setKontaktpersonDialog(true);
    };

    const handleEdit = (kontaktperson: KontaktpersonMedBeskrivelse) => {
        setKontaktperson(kontaktperson);
        setKontaktpersonDialog(true);
    };

    const handleDelete = (kontaktperson: KontaktpersonMedBeskrivelse) => {
        setKontaktperson(kontaktperson);
        setKontaktpersonDialog(false);
        setKontaktpersonDeleteDialog(true);
    };

    const handleDialogClose = () => {
        setKontaktpersonDialog(false);
        setKontaktpersonDeleteDialog(false);
    };

    const fullKontaktpersonList = useMemo(
        () =>
            kontaktpersoner?.map((kp) => ({
                ...kp,
                beskrivelse:
                    metaData?.rolletyper.find((rt) => rt.id === kp.rolleId)
                        ?.beskrivelse || '',
            })) || [],
        [kontaktpersoner, metaData]
    );

    const columns = useMemo<ColumnDef<KontaktpersonMedBeskrivelse>[]>(
        () => [
            {
                accessorKey: 'navn',
                header: 'Navn',
            },
            {
                header: 'Telefon',
                accessorKey: 'telefon',
                enableSorting: false,
            },
            {
                accessorKey: 'epost',
                header: 'E-post',
                enableSorting: false,
            },
            {
                accessorKey: 'organisasjon',
                header: 'Organisasjon',
                enableSorting: false,
            },
            {
                accessorKey: 'beskrivelse',
                header: 'Rolle',
                enableSorting: false,
            },
            {
                id: 'actions',
                header: '',

                cell: ({row}) => (
                    <ToiStack
                        direction={'row'}
                        gap={(theme) => theme.toi.spacing.xs}
                    >
                        <ToiIconButton
                            color='transparent'
                            aria-label='Endre'
                            onClick={() => handleEdit(row.original)}
                        >
                            <ToiTooltip title='Endre'>
                                <EditIcon />
                            </ToiTooltip>
                        </ToiIconButton>
                        <ToiIconButton
                            color='transparent'
                            aria-label='Slett'
                            onClick={() => handleDelete(row.original)}
                        >
                            <ToiTooltip title='Slett'>
                                <DeleteRecycleIcon />
                            </ToiTooltip>
                        </ToiIconButton>
                    </ToiStack>
                ),
            },
        ],
        []
    );

    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useToiTable<KontaktpersonMedBeskrivelse>({
        data: fullKontaktpersonList,
        columns,
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: {
            sorting,
        },
    });

    return (
        <>
            <ToiTypography variant='h1'>Kontaktpersoner</ToiTypography>
            <ToiBox sx={{paddingBottom: '20px'}}>
                <ActionButton
                    title={
                        <>
                            <PlusIcon /> Legg til kontakperson
                        </>
                    }
                    label={'Ny kontaktperson'}
                    onClick={handleAddButton}
                />
            </ToiBox>

            <ToiTable table={table} ariaLabel='Kontaktperson table' />

            <KontaktpersonDialog
                kontaktperson={kontaktperson}
                isOpen={kontaktpersonDialog}
                handleClose={handleDialogClose}
            />
            <ConfirmDeleteKontaktpersonDialog
                id={kontaktperson?.id ? kontaktperson.id : 0}
                name={kontaktperson?.navn ? kontaktperson.navn : ''}
                isOpen={kontaktpersonDeleteDialog}
                handleClose={handleDialogClose}
            />
        </>
    );
}

export default Kontaktpersoner;
