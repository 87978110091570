import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const ThumbsDownIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M23 14.5C23 15.3284 22.3284 16 21.5 16H19V4H21.5C22.3284 4 23 4.67157 23 5.5V14.5Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.45792 23C9.80198 23 10.1289 22.8497 10.3528 22.5884L16 16V4H5.0395C4.41423 4 3.85455 4.38786 3.63501 4.97331L1.09551 11.7453C1.03235 11.9137 1 12.0921 1 12.272V14.5C1 15.3284 1.67157 16 2.5 16H7.64452C8.59949 16 9.31132 16.8805 9.11122 17.8143L8.30548 21.5744C8.14826 22.3081 8.70757 23 9.45792 23V23Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default ThumbsDownIcon;
