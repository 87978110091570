import {
    getGFI,
    WmsFeature,
    gfiAttId,
} from '../../../services/apiTjenestekatalogen';
import {Coords} from '../types';
import {
    GFIAttrState,
    GFIPlanFeature,
    GFIAttribute,
    WmsLayersRequestState,
} from '../../../store/map/types';
import {mapConstants} from './constants';

export const fetchGFI = async (
    wmsState: WmsLayersRequestState,
    coords: Coords,
    activePlanId: string | null | undefined,
    setState: (newState: GFIAttrState) => void,
    knr: string
) => {
    setState({loading: true, notFound: false});

    const resp:
        | {res?: WmsFeature[]; error?: any; isCancellingToken?: boolean}
        | undefined = await getGFI(wmsState, coords, knr);

    if (resp && resp.res) {
        const respWithIds = addIds(resp.res);
        const planAttributes = sortAttributesIntoPlanAndLayer(
            respWithIds,
            activePlanId
        );

        setState({
            loading: false,
            notFound: false,
            planAttributes: planAttributes,
        });
    } else if (resp && resp.isCancellingToken) {
        //Do nothing, starting new request and should keep its state
    } else {
        setState({loading: false, notFound: true});
    }
};

const sortAttributesIntoPlanAndLayer = (
    features: WmsFeature[],
    activePlanId
): GFIPlanFeature[] => {
    const existingPlanIds = getAllExistingPlanidsForFeatures(features);
    const featuresWithUnkownPlanid = getFeaturesWithUnknownPlanid(features);

    // Only mft layer
    const mftFeature = featuresWithUnkownPlanid.filter(
        (feature) => feature.WMSLayer === mapConstants.MFT_layer_name
    );
    //other layers missing plan id
    const featuresWithUnknownPlanidToAdd = featuresWithUnkownPlanid.filter(
        (feature) => !mftFeature.includes(feature)
    );

    const planFeatures: GFIPlanFeature[] = [];
    existingPlanIds.forEach((planId) => {
        let featuresInPlan: WmsFeature[] = features.filter(
            (feature) => getAssociatedPlanid(feature) === planId
        );
        if (planId === activePlanId)
            featuresInPlan = featuresInPlan.concat(mftFeature);
        const planFeature: GFIPlanFeature = {
            planId: planId,
            wmsLayers: getInfoPerWmsLayer(featuresInPlan),
        };
        planFeatures.push(planFeature);
    });

    //If no other feature for active plan, add feature with features that are missing id but exist in plan
    if (!existingPlanIds.filter((planId) => planId === activePlanId).length) {
        const planFeature: GFIPlanFeature = {
            planId: activePlanId,
            wmsLayers: getInfoPerWmsLayer(mftFeature),
        };
        planFeatures.push(planFeature);
    }

    if (featuresWithUnknownPlanidToAdd.length) {
        planFeatures.push({
            planId: 'Ukjent planid',
            wmsLayers: getInfoPerWmsLayer(featuresWithUnknownPlanidToAdd),
        });
    }
    return planFeatures;
};

const getAllExistingPlanidsForFeatures = (features: WmsFeature[]) => {
    const existingPlanIds: string[] = [];
    features.forEach((feature) => {
        const currentFeaturesPlanId = getAssociatedPlanid(feature);
        if (
            currentFeaturesPlanId &&
            !existingPlanIds.includes(currentFeaturesPlanId)
        )
            existingPlanIds.push(currentFeaturesPlanId);
    });
    return existingPlanIds;
};

const getFeaturesWithUnknownPlanid = (features: WmsFeature[]): WmsFeature[] => {
    const unknwonPlanFeatures: WmsFeature[] = [];
    features.forEach((feature) => {
        const currentFeaturesPlanId = getAssociatedPlanid(feature);
        if (!currentFeaturesPlanId) unknwonPlanFeatures.push(feature);
    });
    return unknwonPlanFeatures;
};

const getAssociatedPlanid = (feature: WmsFeature): string | undefined => {
    let id;
    feature.AttributesTree.forEach((att) => {
        if (att.Description === 'NASJONALAREALPLANID' && att.AttributesTree) {
            id = att.AttributesTree[0].Value;
        }
    });
    return id;
};

const addIds = (features: WmsFeature[]) => {
    const featureWithIds: WmsFeature[] = features.map((feature, index) => {
        return {
            ...feature,
            id: ('att' + index) as gfiAttId,
            visibleInMap: false,
        };
    });
    return featureWithIds;
};

const getInfoPerWmsLayer = (attrList: WmsFeature[]): GFIAttribute[] => {
    const uniqueWmsLayers: string[] = [];
    attrList.forEach(
        (att) =>
            !uniqueWmsLayers.includes(att.WMSLayer) &&
            uniqueWmsLayers.push(att.WMSLayer)
    );
    const infoPerWmsLayer: {
        wmsLayerName: string;
        attrList: WmsFeature[];
    }[] = uniqueWmsLayers.map((layer) => {
        return {
            wmsLayerName: layer,
            attrList: attrList.filter((attr) => attr.WMSLayer === layer),
        };
    });
    return infoPerWmsLayer;
};
