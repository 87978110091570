import {ToiTypography, ToiTypographyProps} from '../ToiTypography';
import {ToiTag, ToiTagProps} from '../ToiTag';

export interface InputDescriptionProps {
  label: string | React.ReactNode | null;
  optional?: boolean;
  helpText?: string | React.ReactNode;
  errorText?: string;
  variant?: ToiTypographyProps['variant'];
  TagProps?: Omit<ToiTagProps, 'ref'>;
}

export function InputDescription({
  label,
  optional,
  TagProps,
  helpText,
  errorText,
  variant = 'body2',
}: InputDescriptionProps) {
  return (
    <>
      <ToiTypography component='span' variant={variant}>
        {label}
      </ToiTypography>
      {helpText && (
        <ToiTypography className='ToiFormControlLabel-helpText' variant='body3' component='span'>
          {helpText}
        </ToiTypography>
      )}
      {optional ? <ToiTag value='Valgfri' color='normal' /> : TagProps && <ToiTag {...TagProps} />}
      {errorText && (
        <ToiTypography variant='body3' color='error' component='span'>
          {errorText}
        </ToiTypography>
      )}
    </>
  );
}
