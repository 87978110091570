import {Accordion, AccordionDetails} from '@mui/material';
import {useState} from 'react';
import {ToiBox, ToiButton, ToiStack} from '@norkart/toi-components';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    DeleteRecycleIcon,
} from '@norkart/toi-icons';
import HelpButton from '../../../components/HelpButton/HelpButton';
import {helpObject} from '../../../help/helpObject';
import useMeta from '../../../hooks/meta/useMeta';
import {
    BehandlingstypeFilter,
    OpprinneligAdministrativEnhetFilter,
    PlanStatusFilter,
    PlantypeFilter,
    SaksnummerFilter,
} from './filters';
import {DateFilter} from './filters/DateFilter';
import FilterLabel from './filters/FilterLabel';
import {useFilters} from './filters/useFilters';
import {usePropertySearch} from './filters/PropertySearchFilter/usePropertySearch';

export type Option = {
    label: string;
    value: string;
};

const AdvancedFilters = () => {
    const meta = useMeta();
    const filters = useFilters();
    const propertySearch = usePropertySearch();

    const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

    const disableBehandlingTypeFilter =
        filters.get('fromDate') || filters.get('toDate') ? false : true;

    const shouldShowClearButton =
        Boolean(filters.activeFiltersFromUrl.length) ||
        Boolean(propertySearch.get());

    return (
        <Accordion
            sx={{
                marginBottom: 1,
                boxShadow: 'none',
                backgroundColor: 'inherit',
            }}
            expanded={filtersOpen}
            square={true}
            disableGutters={true}
        >
            <ToiBox
                sx={{display: 'flex', justifyContent: 'space-between'}}
                id='advancedsearch'
            >
                <ToiButton
                    variant='secondary'
                    size='medium'
                    sx={(theme) => ({mt: theme.toi.spacing.xs})}
                    onClick={() => {
                        setFiltersOpen(!filtersOpen);
                    }}
                >
                    {filtersOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    Filter
                </ToiButton>
                {shouldShowClearButton && (
                    <ToiButton
                        variant='secondary'
                        size='medium'
                        sx={(theme) => ({mt: theme.toi.spacing.xs})}
                        onClick={() => filters.clearAll()}
                        startIcon={<DeleteRecycleIcon />}
                    >
                        Fjern filtre
                    </ToiButton>
                )}
            </ToiBox>
            <AccordionDetails>
                <ToiStack gap={2}>
                    <PlantypeFilter />
                    <PlanStatusFilter />
                    <SaksnummerFilter />

                    <ToiBox>
                        <FilterLabel>
                            Velg dato/periode
                            <HelpButton
                                helpText={helpObject.AvansertFilter.Dato}
                                type='Dato/PeriodeFilter'
                            />
                        </FilterLabel>
                        <DateFilter />
                    </ToiBox>

                    {!disableBehandlingTypeFilter && <BehandlingstypeFilter />}

                    {meta.data.kommuner && (
                        <OpprinneligAdministrativEnhetFilter />
                    )}
                </ToiStack>
            </AccordionDetails>
        </Accordion>
    );
};

export default AdvancedFilters;
