import {Fade} from '@mui/material';
import {useEffect, useState} from 'react';
import {TokenDto, TokenType} from '@norkart/nkapi-arealplaner-api';
import {
    ToiBox,
    ToiDialogSimple,
    ToiIconButton,
    ToiStack,
    ToiTextField,
    ToiToolTip,
} from '@norkart/toi-components';
import {CopyIcon} from '@norkart/toi-icons';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useMeta from '../../../hooks/meta/useMeta';
import useToken from '../../../hooks/tokens/useToken';

type Props = {
    tokenType: TokenType;
    token: TokenDto;
    isOpen: boolean;
    handleClose: () => void;
};

function TokenShareDialog({tokenType, isOpen, ...props}: Props) {
    const {komnr} = useMeta();
    const token = useToken(props.token.id);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };
    const getShareString = (): string => {
        return tokenType == 'API'
            ? (token.data.tokenString ?? '')
            : `https://waapi.webatlas.no/wms-dispensasjoner/?api_key=${token.data.tokenString}&CQL_FILTER=KommuneNummer=${komnr};KommuneNummer=${komnr}`;
    };
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (tooltipOpen) {
            timer = setTimeout(handleTooltipClose, 1500);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [tooltipOpen]);

    const copyToClipboard = () => {
        handleTooltipOpen();
        navigator.clipboard.writeText(getShareString());
    };

    const handleClose = () => {
        handleTooltipClose();
        props.handleClose();
    };

    return (
        <ToiDialogSimple
            open={isOpen}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth={true}
            title={tokenType === 'WMS' ? 'Del WMS URL' : 'Del API nøkkel'}
            cancelText='Lukk'
        >
            {token.isLoading ? (
                <LoadingSpinner />
            ) : (
                <ToiStack
                    direction='row'
                    gap={(theme) => theme.toi.spacing.s}
                    alignItems={'center'}
                >
                    <ToiTextField
                        label={tokenType === 'WMS' ? 'URL' : 'Nøkkel'}
                        readOnly={true}
                        value={getShareString()}
                        fullWidth
                    />
                    <ToiToolTip
                        open={tooltipOpen}
                        onClose={handleTooltipClose}
                        title='Kopiert'
                        placement='top'
                        arrow
                        TransitionComponent={Fade}
                        disableFocusListener={true}
                        disableHoverListener={true}
                        disableTouchListener={true}
                    >
                        <ToiBox marginTop={2}>
                            <ToiToolTip title='Kopier'>
                                <ToiIconButton
                                    aria-label='Kopier'
                                    onClick={copyToClipboard}
                                    color='transparent'
                                >
                                    <CopyIcon />
                                </ToiIconButton>
                            </ToiToolTip>
                        </ToiBox>
                    </ToiToolTip>
                </ToiStack>
            )}
        </ToiDialogSimple>
    );
}

export default TokenShareDialog;
