import {ButtonBase, DrawerProps, styled} from '@mui/material';
import {ReactNode} from 'react';
import clsx from 'clsx';
import {ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon} from '@norkart/toi-icons';
import {ToiDrawerProps} from './ToiDrawer';

const chevronMap: Record<
  NonNullable<DrawerProps['anchor']>,
  {
    open: ReactNode;
    closed: ReactNode;
  }
> = {
  right: {
    open: <ChevronRightIcon />,
    closed: <ChevronLeftIcon />,
  },
  left: {
    open: <ChevronLeftIcon />,
    closed: <ChevronRightIcon />,
  },
  top: {
    open: <ChevronUpIcon />,
    closed: <ChevronDownIcon />,
  },
  bottom: {
    open: <ChevronDownIcon />,
    closed: <ChevronUpIcon />,
  },
};

function getScrollBarWidth() {
  const el = document.createElement('div');
  el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
  document.body.appendChild(el);
  const width = el.offsetWidth - el.clientWidth;
  el.remove();
  return width;
}

const StyledButton = styled(ButtonBase)<PersistentDrawerToggleButtonProps>(({theme, open}) => {
  const palette = theme.vars.toi.palette;
  const spacing = theme.toi.spacing;

  return {
    border: 'none',
    cursor: 'pointer',
    backgroundColor: palette.backgrounds.component.fill,
    boxShadow: theme.shadows[4],
    fontSize: 0,

    position: 'absolute',
    visibility: 'visible',

    '&:focus': theme.toi.focus,

    '&.right, &.left': {
      paddingBlock: spacing.s,
      paddingInline: spacing['2xs'],
    },

    '&.top, &.bottom': {
      paddingBlock: spacing['2xs'],
      paddingInline: spacing.s,
    },

    '&.right': {
      top: '50%',
      transform: 'translateY(-50%)',
      // TODO: Do this only when scroller is visible
      // left: `calc(-32px - ${open ? 0 : getScrollBarWidth()}px)`,
      left: `-32px`,
      transition: 'left 0.1s ease',
      borderRadius: '6px 0 0 6px',
      clipPath: 'inset(-6px 0px -6px -6px)',
    },

    '&.left': {
      top: '50%',
      transform: 'translateY(-50%)',
      right: '-32px',
      borderRadius: '0 6px 6px 0',
      clipPath: 'inset(-6px -6px -6px 0px)',
    },

    '&.top': {
      bottom: '-32px',
      left: '50%',
      transform: 'translateX(-50%)',
      borderRadius: '0 0 6px 6px',
      clipPath: 'inset(0px -6px -6px -6px)',
    },

    '&.bottom': {
      top: '-32px',
      borderRadius: '6px 6px 0 0',
      left: '50%',
      transform: 'translateX(-50%)',
      clipPath: 'inset(-6px -6px 0px -6px)',
    },
  };
});

type PersistentDrawerToggleButtonProps = Pick<ToiDrawerProps, 'onToggle' | 'open' | 'onToggle' | 'anchor'>;

export const PersistentDrawerToggleButton = (props: PersistentDrawerToggleButtonProps) => {
  return (
    <StyledButton
      aria-label='toggle-drawer'
      className={clsx('drawer-toggle-visible-btn', props.anchor)}
      onClick={props.onToggle}
    >
      {chevronMap[props.anchor || 'right'][props.open ? 'open' : 'closed']}
    </StyledButton>
  );
};
