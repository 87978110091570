import {Theme, CSSObject} from '@mui/material';

export const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.mixins.drawer?.permanent?.width,
  transition: theme.transitions.create(['width', 'paddingInline'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  overflowX: 'hidden',
  border: 'none',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['width', 'paddingInline'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  width: theme.mixins.drawer?.permanent?.collapsedWidth ?? '64px',
  paddingInline: 0,
  overflowX: 'hidden',
  border: 'none',
});
