import {AppBar, styled} from '@mui/material';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {usePalette, useSpacing} from '@norkart/toi-theme';
import {ToiAppBarProps} from './ToiAppBar';

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !['collapsePoint'].includes(prop.toString()),
})<ToiAppBarProps>(({theme, collapsePoint}) => {
  const spacing = useSpacing();
  const palette = usePalette();

  return {
    borderBottom: palette.backgrounds.component.fill,
    borderRadius: 0,
    background: palette.backgrounds.generic.panelOnGrey,
    zIndex: 1200,
    position: 'sticky',

    '& .MuiToolbar-root': {
      padding: 0,
      ...theme.mixins.toolbar,

      '& .ToiToolbar-flexContainer': {
        flex: 1,
        gap: spacing.m,
        marginInline: spacing.l,
        alignItems: 'center',

        [theme.breakpoints.down(collapsePoint ?? 'laptop')]: {
          gap: spacing.xs,
          marginInline: spacing.s,
        },

        '& > :last-child': {
          // marginLeft: 'auto',
          flexDirection: 'row',
        },
      },

      '& .MuiTabs-flexContainer': {
        gap: '0 !important',
        ...theme.mixins.toolbar,
      },
    },

    '& .MuiPaper-root, & .MuiBackdrop-root': {
      top: '60px',
    },
  };
});
