import {styled, TableContainer, TableContainerTypeMap} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';

interface OwnProps {
  small?: boolean;
}

export const ToiTableContainer = styled(TableContainer, {
  shouldForwardProp: (prop) => prop !== 'small',
})<OwnProps>(({theme, small}) => {
  return {
    borderRadius: theme.toi.radius.table,
    boxShadow: 'unset',
    border: `1px solid ${theme.vars.toi.palette.border.component}`,

    '& .MuiTableCell-root': {
      color: theme.vars.toi.palette.text.default,
      padding: small ? theme.toi.spacing.xs : theme.toi.spacing.s,

      '&.MuiTableCell-body': {
        ...(small ? theme.typography.body3 : theme.typography.body2),
      },
      '&.MuiTableCell-head': {
        ...theme.typography.label,
        whiteSpace: 'nowrap',
      },
    },

    '& .MuiTableRow-footer:not(:has( > td > .MuiTablePagination-root))': {
      '& .MuiTableCell-footer': {
        ...theme.typography.body2,
        fontWeight: 600,
      },
      backgroundColor: theme.vars.toi.palette.backgrounds.generic.orange,
    },

    '& .ToiTableRow-selected': {
      backgroundColor: theme.vars.toi.palette.backgrounds.component.highlighted,
    },

    '& .ToiTableRow-canExpand': {
      '&  > td': {
        borderBottom: 'unset',
      },
      '& + tr > td > .MuiCollapse-root > .MuiCollapse-wrapper': {
        paddingBlock: theme.toi.spacing.xs,
        paddingInline: theme.toi.spacing.m,
      },
    },

    '& .ToiTableRow-clickable:hover': {
      backgroundColor: theme.vars.toi.palette.backgrounds.component.hover,
    },
    '& .ToiTableCell-clickable': {
      cursor: 'pointer',
    },

    '& .MuiTableSortLabel-root > svg ': {
      marginRight: 0,
    },
  };
}) as OverridableComponent<TableContainerTypeMap<OwnProps>>;
