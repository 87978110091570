import {forwardRef} from 'react';
import {InputAdornment} from '@mui/material';
import {SearchIcon} from '@norkart/toi-icons';
import {ToiTextField, ToiTextFieldProps} from '../ToiTextField';

export type ToiSearchBarProps = ToiTextFieldProps;

export const ToiSearchBar = forwardRef<HTMLInputElement, ToiSearchBarProps>(({placeholder = 'Søk', ...props}, ref) => {
  return (
    <ToiTextField
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      }
      ref={ref}
      {...props}
    />
  );
});
