import {Collapse, TableCell, TableRow} from '@mui/material';
import {PropsWithChildren} from 'react';

interface Props extends PropsWithChildren {
  colSpan: number;
  isExpanded: boolean;
}

export function ToiTableSummary({children, colSpan, isExpanded}: Props) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} style={{padding: 0}}>
        <Collapse in={isExpanded} unmountOnExit>
          {children}
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
