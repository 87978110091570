import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const CopyIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M17 21V21.5C17 22.3284 16.3284 23 15.5 23H4.5C3.67157 23 3 22.3284 3 21.5V6.5C3 5.67157 3.67157 5 4.5 5H5'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7 17.5V2.5C7 1.67157 7.67157 1 8.5 1H15.8787C16.2765 1 16.658 1.15803 16.9393 1.43934L20.5607 5.06066C20.842 5.34196 21 5.7235 21 6.12132V17.5C21 18.3284 20.3284 19 19.5 19H8.5C7.67157 19 7 18.3284 7 17.5Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 7V13M17 10H11'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default CopyIcon;
