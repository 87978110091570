import {ListItemButtonProps} from '@mui/material';
import {CSSObject} from '@mui/material/styles';
import {useToiAppBarChildrenEnvironment} from '../..';
import {ToiListItemButton} from '../../../ToiList';
import {ToiMenuItem, ToiMenuItemProps} from '../../../ToiBaseUIMenu/Item';

export type ToiAppbarMenuItemProps = {
  menuItemProps?: Omit<ToiMenuItemProps, 'children' | 'onClick' | 'component'>;
  listItemProps?: Omit<ListItemButtonProps, 'children' | 'onClick' | 'component'>;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
  component?: React.ElementType;
};

/* Conditionally render a Menu.Item or ListItem based on context*/
export const ToiAppbarMenuItem = ({
  menuItemProps,
  listItemProps,
  children,
  style,
  onClick,
  component,
}: ToiAppbarMenuItemProps) => {
  const env = useToiAppBarChildrenEnvironment();
  return env === 'appbar' ? (
    <ToiMenuItem onClick={onClick} {...menuItemProps} style={{...style, ...menuItemProps?.style}}>
      {children}
    </ToiMenuItem>
  ) : (
    <ToiListItemButton
      component={component || 'button'}
      onClick={onClick}
      {...listItemProps}
      sx={(theme) => ({
        width: '100%',
        gap: theme.toi.spacing.s,
        ...theme.typography.body2,
        ...style,
        ...((listItemProps?.sx as CSSObject) || {}),
      })}
    >
      {children}
    </ToiListItemButton>
  );
};
