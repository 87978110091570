import {Grid, Typography} from '@mui/material';
import {PickersDay, StaticDatePicker} from '@mui/x-date-pickers';
import * as React from 'react';
import {StartOrEndDate} from './DateRangePickerMui';

type DateRangePickerCalendarProps = {
    onChange: (date: StartOrEndDate) => void;
    calendarValues: StartOrEndDate | undefined;
};

export function DateRangePickerCalendar({
    calendarValues,
    onChange,
}: DateRangePickerCalendarProps) {
    const calendars = ['start', 'end'];

    return (
        <Grid
            sx={{
                position: 'absolute',
                display: 'flex',
                backgroundColor: '#f9f9f9',
            }}
            justifyContent={'space-between'}
            item={true}
        >
            {calendars.map((value, index) => {
                return (
                    <div key={'calendar-' + index}>
                        <Typography
                            fontSize={12}
                            color='InactiveCaptionText'
                            sx={{overfliw: 'clip', paddingLeft: '12px'}}
                            key={'label-' + index}
                        >
                            {value === 'start' ? 'Fra' : 'Til'}
                        </Typography>
                        <StaticDatePicker
                            className='dateRange'
                            key={index}
                            disabled={!calendarValues?.start && value === 'end'}
                            value={
                                value === 'start'
                                    ? calendarValues?.start
                                    : value === 'end'
                                      ? calendarValues?.end
                                      : null
                            }
                            disableHighlightToday={true}
                            displayStaticWrapperAs='desktop'
                            onChange={(e) =>
                                onChange({...calendarValues, [value]: e})
                            }
                            slots={{
                                day: PickersDay,
                                rightArrowIcon: undefined,
                            }}
                            slotProps={{
                                day: {
                                    disableRipple: true,
                                    sx: {
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: '#005600',
                                            color: 'white',
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#1E7400',
                                            opacity:
                                                !calendarValues?.start &&
                                                value === 'end'
                                                    ? 0.5
                                                    : 1,
                                        },
                                        '&.Mui-selected:hover': {
                                            backgroundColor: '#1E7400',
                                        },
                                        '&.Mui-selected:focus': {
                                            backgroundColor: '#1E7400',
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                );
            })}
        </Grid>
    );
}
