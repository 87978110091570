import React from 'react';
import {Autocomplete, AutocompleteProps} from '@mui/material';
import {useState} from 'react';
import {findIndex} from 'lodash-es';
import {ToiTextField} from '@norkart/toi-components';
import {ChevronDownIcon} from '@norkart/toi-icons';

export type FilterableDropdownProps<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
> = Pick<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    | 'getOptionLabel'
    | 'options'
    | 'onBlur'
    | 'onFocus'
    | 'className'
    | 'disabled'
    | 'id'
> & {
    keyCol: string;
    valueCol: string;
    value: string;
    onChange: (value: string) => void;
    error?: boolean;
    nullOption?: boolean;
    displayId?: boolean;
};

export function FilterableDropdown<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>({
    options,
    keyCol,
    valueCol,
    getOptionLabel,
    value,
    onChange,
    nullOption,
    displayId,
    ...props
}: FilterableDropdownProps<T, Multiple, DisableClearable, FreeSolo>) {
    const indexOfSelectedOption = findIndex(
        options,
        (o) => o[keyCol] === value
    );
    const [selectedOption, setSectedOption] = useState<any>(
        options[indexOfSelectedOption]
    );

    const getOptionLabelCustom = (option) => {
        if (getOptionLabel) return getOptionLabel(option);
        if (displayId) return `${option[keyCol]} - ${option[valueCol]}`;
        return `${option[valueCol]}`;
    };
    return (
        <Autocomplete
            selectOnFocus={true}
            clearOnBlur={true}
            disableClearable={!nullOption}
            options={options}
            openText={'Åpne'}
            popupIcon={<ChevronDownIcon />}
            closeText={'Lukke'}
            getOptionLabel={getOptionLabelCustom}
            onChange={(e, newOption) => {
                const newId = (newOption && newOption[keyCol]) || undefined;
                setSectedOption(newOption);
                onChange(newId);
            }}
            value={selectedOption ? selectedOption : null}
            isOptionEqualToValue={(option, value) =>
                option[keyCol] == value[keyCol]
            }
            noOptionsText='Ingen alternativer'
            fullWidth={true}
            renderInput={(params) => <ToiTextField {...params} />}
            {...props}
        />
    );
}

export default FilterableDropdown;
