import {Grid, Typography} from '@mui/material';
import * as React from 'react';
import {useDispatch} from 'react-redux';
import {ToiBox, ToiStack} from '@norkart/toi-components';
import ExpansionPanel from '../../../components/ExpansionPanel';
import LoadingSpinner from '../../../components/LoadingSpinner';
import DesktopContainer from '../../../containers/DesktopContainer';
import MobileContainer from '../../../containers/MobileContainer';
import useArealplan from '../../../hooks/arealplaner/useArealplan';
import useBehandlinger from '../../../hooks/behandlinger/useBehandlinger';
import useDispensasjoner from '../../../hooks/dispensasjoner/useDispensasjoner';
import useArealplanDokumenter from '../../../hooks/dokumenter/useArealplanDokumenter';
import {
    setExpandBehandlingId,
    setExpandDispensasjonId,
} from '../../../store/session/actions';
import useCan from '../../../utils/auth/useCan';
import BehandlingList from '../../behandling/BehandlingList';
import {DispensasjonList} from '../../dispensasjon/DispensasjonList';
import Footer from '../../footer';
import PlanMap from '../../map/PlanMap';
import PlanRelationships from '../../planforhold/PlanRelationships';
import JournalList from '../../sak/JournalList';
import PlanDescription from '../PlanDescription';
import PlanDocumentList from '../PlanDocumentList';
import PlanFeedback from '../PlanFeedback/index';
import PlanInfo from '../PlanInfo/index';
import PlanProcess from '../PlanProcess';
import PlanTabs from './components/PlanTabs/index';
import usePlanViewHelpers from './usePlanViewHelpers';

function PlanViewContent() {
    const can = useCan();
    const {data: plan, isFetched} = useArealplan();
    const plandokumenter = useArealplanDokumenter();
    const behandlinger = useBehandlinger();
    const dispensasjoner = useDispensasjoner();
    const dokumenter = useArealplanDokumenter();
    const helpers = usePlanViewHelpers();

    const [activePage, setActivePage] = React.useState(
        plan.planStatusId == 3 ? 'plandokumenter' : 'planbehandlinger'
    );

    const [visDispIKart, setVisDispIKart] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (activePage == 'dispensasjoner' && !visDispIKart) {
            setVisDispIKart(true);
        } else if (visDispIKart) {
            setVisDispIKart(false);
        }
    }, [activePage]);

    const Tabs = () => {
        if (
            !behandlinger.isFetched ||
            !dokumenter.isFetched ||
            !dispensasjoner.isFetched
        ) {
            return <LoadingSpinner />;
        }

        return (
            <Grid container={true} style={{height: '100%'}}>
                <PlanTabs
                    plan={plan}
                    onChange={setActivePage}
                    activePage={activePage}
                />
                <Grid
                    item={true}
                    sx={(theme) => ({
                        marginLeft: '30px',
                        marginRight: '30px',
                        marginTop: '30px',
                        padding: 1,
                        flexGrow: 1,
                    })}
                >
                    {activePage == 'beskrivelse' && <PlanDescription />}
                    {activePage == 'plandokumenter' && <PlanDocumentList />}
                    {activePage == 'planbehandlinger' && <BehandlingList />}
                    {activePage == 'mindre endringer' && (
                        <Typography variant='h2'>Endringer</Typography>
                    )}
                    {activePage == 'dispensasjoner' && <DispensasjonList />}
                    {activePage == 'planforhold' && <PlanRelationships />}
                    {activePage == 'saksdokumenter' &&
                        plan.saker &&
                        helpers.shouldShowSaksdokumenter(
                            plan.planStatusId,
                            plan.saker[0]
                        ) && <JournalList />}
                    {activePage == 'tidsakse' &&
                        plan.saker &&
                        helpers.shouldShowTidsakse(
                            plan.planStatusId,
                            plan.saker[0]
                        ) && <PlanProcess />}
                    {activePage == 'si din mening' &&
                        helpers.shouldShowTilbakemelding(plan.planStatusId) && (
                            <PlanFeedback />
                        )}
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <MobileContainer>
                <PlanInfo visDispIKart={false} />
                {(can.edit ||
                    (plan.planBeskrivelse &&
                        plan.planBeskrivelse.length > 0)) && (
                    <PlanDescription />
                )}
                {(can.edit || dokumenter.data.length > 0) && (
                    <PlanDocumentList />
                )}
                {(can.edit || behandlinger.data.length > 0) && (
                    <BehandlingList />
                )}
                {(can.editDisp || dispensasjoner.data.length > 0) && (
                    <DispensasjonList />
                )}
                {(can.edit ||
                    (plan.planforholdForlopere?.length ?? 0) > 0 ||
                    (plan.planforholdEtterkommere?.length ?? 0) > 0) && (
                    <PlanRelationships />
                )}
                {helpers.shouldShowSaksdokumenter(
                    plan.planStatusId,
                    plan.saker ? plan.saker[0] : undefined
                ) && (
                    <ExpansionPanel title='Saksdokumenter'>
                        <JournalList />
                    </ExpansionPanel>
                )}
                {helpers.shouldShowTidsakse(
                    plan.planStatusId,
                    plan.saker ? plan.saker[0] : undefined
                ) && <PlanProcess />}
                {helpers.shouldShowTilbakemelding(plan.planStatusId) && (
                    <PlanFeedback />
                )}
                <Footer />
            </MobileContainer>
            <DesktopContainer>
                <ToiStack>
                    <ToiStack
                        direction={'row'}
                        justifyContent={'space-between'}
                        sx={{
                            minHeight: '400px',
                            width: '100%',
                        }}
                    >
                        <PlanInfo visDispIKart={visDispIKart} />
                        <ToiBox
                            sx={{
                                width: '100%',
                                flexGrow: 0,
                            }}
                        >
                            {isFetched && plandokumenter.isFetched && (
                                <PlanMap showDispInMap={visDispIKart} />
                            )}
                        </ToiBox>
                    </ToiStack>
                    <Tabs />
                    <Footer />
                </ToiStack>
            </DesktopContainer>
        </>
    );
}

function PlanView() {
    const {isLoading, isError, isIdle} = useArealplan();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setExpandDispensasjonId(undefined));
        dispatch(setExpandBehandlingId(undefined));
    }, []);

    if (isLoading || isIdle) return <LoadingSpinner />;

    if (isError) {
        return null;
    }

    return <PlanViewContent />;
}
export default PlanView;
