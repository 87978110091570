import {ChatItem} from '@norkart/nora-components';

export const chatHistoryKey = 'Planprat.Arealplaner.ChatHistory';

export type chatHistoryItem = ChatItem & {searchIndices: string[]};

export const getCachedChatHistory = (): chatHistoryItem[] => {
    const chatHistory = sessionStorage.getItem(chatHistoryKey);
    const conversation: chatHistoryItem[] = chatHistory
        ? (JSON.parse(chatHistory) as chatHistoryItem[])
        : [];

    return conversation;
};

export const cacheNewChatHistoryItem = (newChat: chatHistoryItem) => {
    const cachedConversation: chatHistoryItem[] = getCachedChatHistory();
    cachedConversation.push(newChat);
    sessionStorage.setItem(chatHistoryKey, JSON.stringify(cachedConversation));
};

export const clearCachedChatHistory = () =>
    sessionStorage.removeItem(chatHistoryKey);
