import orderBy from 'lodash-es/orderBy';
import {Reducer} from '@reduxjs/toolkit';
import {FitBounds, WmsLayer} from '@norkart/nkm-mapbox-map';
import {AvailableWmsLayer} from '../../features/map/types';
import {getWmsLayer} from '../../features/map/helpers/wmsHelpers';
import {mapConstants} from '../../features/map/helpers/constants';
import {ensureUniqueId} from '../../utils/ensureUniqueId';
import * as actions from './actions';
import {MapActionTypes, MapState, WmsLayersRequestState} from './types';

export const initialFullscreenState: MapState = {
    gfiState: {loading: false, notFound: false},
    wmsLayerState: {
        loading: false,
        wmsNotFound: false,
    },
    showDispInMap: false,
};

export type MapAction =
    | ReturnType<typeof actions.setVisibleInMap>
    | ReturnType<typeof actions.refreshWmsLayers>
    | ReturnType<typeof actions.addWmsLayer>
    | ReturnType<typeof actions.removeWmsLayer>
    | ReturnType<typeof actions.fetchWms>
    | ReturnType<typeof actions.fetchWmsSuccess>
    | ReturnType<typeof actions.fetchWmsError>
    | ReturnType<typeof actions.fetchGfi>
    | ReturnType<typeof actions.fetchGfiSuccess>
    | ReturnType<typeof actions.fetchGfiError>
    | ReturnType<typeof actions.setMapMode>
    | ReturnType<typeof actions.setDifferenceLayer>
    | ReturnType<typeof actions.setBorderLayers>
    | ReturnType<typeof actions.addFitToBounds>
    | ReturnType<typeof actions.resetMapState>
    | ReturnType<typeof actions.resetGFIState>;

const reducer: Reducer<MapState, MapAction> = (
    state = initialFullscreenState,
    action
) => {
    switch (action.type) {
        case MapActionTypes.FETCH_WMS_REQUEST:
            return {
                ...state,
                //gfiState: { loading: false, notFound: false }, //Reset GFI
                wmsLayerState: {
                    loading: true,
                    wmsNotFound: false,
                },
            };
        case MapActionTypes.FETCH_WMS_SUCCESS:
            return {
                ...state,
                wmsLayerState: action.payload,
            };
        case MapActionTypes.FETCH_WMS_ERROR:
            return {
                ...state,
                wmsLayerState: {
                    loading: false,
                    wmsNotFound: true,
                },
            };
        case MapActionTypes.REFRESH_WMS_LAYERS:
            return {
                ...state,
                wmsLayerState: refreshwmslayers(
                    state.wmsLayerState,
                    action.payload
                ),
            };
        case MapActionTypes.ADD_WMS_LAYER:
            return {
                ...state,
                wmsLayerState: updateWmsLayer(
                    action.payload,
                    state.wmsLayerState,
                    'add'
                ),
            };
        case MapActionTypes.REMOVE_WMS_LAYER:
            return {
                ...state,
                wmsLayerState: updateWmsLayer(
                    action.payload,
                    state.wmsLayerState,
                    'remove'
                ),
            };
        case MapActionTypes.FETCH_gfi_REQUEST:
            return {
                ...state,
                gfiState: {
                    loading: true,
                    notFound: false,
                },
            };
        case MapActionTypes.FETCH_gfi_SUCCESS:
            return {
                ...state,
                gfiState: action.payload,
            };
        case MapActionTypes.FETCH_gfi_ERROR:
            return {
                ...state,
                gfiState: {
                    loading: false,
                    notFound: true,
                },
            };
        case MapActionTypes.SET_VISIBLE_IN_MAP: {
            return {
                ...state,
                drawnAttInMap: action.payload.value
                    ? action.payload.id
                    : undefined,
            };
        }
        case MapActionTypes.SET_MAP_MODE:
            return {
                ...state,
                showDispInMap: action.payload.showDispInMap,
            };
        case MapActionTypes.SET_DIFFERENCE_LAYER:
            return {
                ...state,
                differenceLayer: action.payload,
            };
        case MapActionTypes.SET_BORDER_LAYERS:
            return {
                ...state,
                borderLayers: action.payload,
            };
        case MapActionTypes.ADD_FITTOBOUNDS:
            return {
                ...state,
                fitBounds: {...action.payload} as FitBounds, //recreate object to ensure updates even when object is equal to previous object
            };
        case MapActionTypes.RESET_GFI:
            return {
                ...state,
                gfiState: {
                    loading: false,
                    notFound: false,
                },
            };
        case MapActionTypes.RESET_STATE:
            return initialFullscreenState;
        default:
            return state;
    }
};

export {reducer as mapReducer};

const refreshwmslayers = (
    wmsState: WmsLayersRequestState,
    displaySurroundingLayers
) => {
    const planLayers = wmsState.availableWmsLayers;
    const otherLayers = wmsState.otherWmsLayers;

    const visiblePlanLayers = planLayers?.filter((layer) => layer.isVisible);
    const visibleOtherLayers = otherLayers?.filter((layer) => layer.isVisible);

    planLayers?.forEach((layer) => {
        wmsState = updateWmsLayer(layer, wmsState, 'remove');
    });

    otherLayers?.forEach(
        (layer) => (wmsState = updateWmsLayer(layer, wmsState, 'remove'))
    );

    visiblePlanLayers?.forEach(
        (layer) => (wmsState = updateWmsLayer(layer, wmsState, 'add'))
    );

    if (displaySurroundingLayers) {
        visibleOtherLayers?.forEach(
            (layer) => (wmsState = updateWmsLayer(layer, wmsState, 'add'))
        );
    }

    return wmsState;
};

const updateWmsLayer = (
    wmsLayerToToggle: AvailableWmsLayer,
    wmsState: WmsLayersRequestState,
    operation: 'add' | 'remove'
): WmsLayersRequestState => {
    const updateLayerVisibility = (layers: AvailableWmsLayer[]) => {
        return layers.map((lay) => ({
            ...lay,
            isVisible:
                lay.displayName === wmsLayerToToggle.displayName
                    ? operation === 'add'
                    : lay.isVisible,
        }));
    };

    // check if more than one layer references the same wmsLayer and is visible
    const isDuplicateLayer = () => {
        const availableWmsLayers = wmsState.availableWmsLayers;
        const otherWmsLayers = wmsState.otherWmsLayers;

        let availableCount = 0;
        let otherCount = 0;

        if (availableWmsLayers) {
            availableCount = availableWmsLayers.filter(
                (layer) =>
                    layer.Name === wmsLayerToToggle.Name && layer.isVisible
            ).length;
        }
        if (otherWmsLayers) {
            otherCount = otherWmsLayers.filter(
                (layer) =>
                    layer.Name === wmsLayerToToggle.Name && layer.isVisible
            ).length;
        }

        return availableCount + otherCount > 1;
    };

    const updatedDrawnLayers = wmsState.availableWmsLayers?.some(
        (layer) => layer.displayName === wmsLayerToToggle.displayName
    )
        ? updateLayerVisibility(wmsState.availableWmsLayers)
        : wmsState.availableWmsLayers || [];

    const updatedDrawnOtherLayers = wmsState.otherWmsLayers?.some(
        (layer) => layer.displayName === wmsLayerToToggle.displayName
    )
        ? updateLayerVisibility(wmsState.otherWmsLayers)
        : wmsState.otherWmsLayers || [];

    const orderedUpdatedLayers = orderBy(
        [...updatedDrawnLayers, ...updatedDrawnOtherLayers],
        ['drawOrder']
    );

    let updatedWmsLayerList: WmsLayer[] = [];

    if (operation === 'add') {
        const visibleLayers = orderedUpdatedLayers.filter(
            (layer) => layer.isVisible
        );
        const wmsServerUrl = wmsState.wmsServerUrl;
        if (wmsServerUrl) {
            updatedWmsLayerList = visibleLayers.map((layer) =>
                getWmsLayer(layer, wmsServerUrl, orderedUpdatedLayers)
            );
            updatedWmsLayerList = ensureUniqueId(updatedWmsLayerList);
        }
    } else {
        if (isDuplicateLayer()) {
            updatedWmsLayerList = wmsState.wmsLayers || [];
        } else {
            updatedWmsLayerList =
                wmsState.wmsLayers?.filter(
                    (existingWmsLayer) =>
                        existingWmsLayer.id !==
                        mapConstants.layerIdPrefix + wmsLayerToToggle.Name
                ) || [];
        }
    }

    return {
        ...wmsState,
        availableWmsLayers: orderBy(updatedDrawnLayers, ['displayOrder']),
        otherWmsLayers: orderBy(updatedDrawnOtherLayers, ['displayOrder']),
        wmsLayers: updatedWmsLayerList,
    };
};
