import {ToiBox, ToiTypography} from '@norkart/toi-components';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import useMeta from '../../../../hooks/meta/useMeta';
import {setTitle} from '../../../../utils/setTitle';
import ReportPage from '../ReportPage';
import TilstandLovreferanseReport from './TilstandLovreferanseReport';
import TilstandPlantypeReport from './TilstandPlantypeReport';

export default function Tilstand() {
    setTitle('Rapporter > Tilstand');
    const meta = useMeta();

    if (meta.isLoading) return <LoadingSpinner />;
    return (
        <ReportPage title='Tilstandsrapport'>
            <ToiBox mb={(theme) => theme.toi.spacing.l}>
                <ToiTypography variant='h2'>
                    Antall planobjekt per planstatus og lovreferanse
                </ToiTypography>
                <TilstandLovreferanseReport />
            </ToiBox>
            <ToiBox>
                <ToiTypography variant='h2'>
                    Antall planobjekt per planstatus og plantype
                </ToiTypography>
                <TilstandPlantypeReport />
            </ToiBox>
        </ReportPage>
    );
}
