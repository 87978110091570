import React from 'react';
import {useDispatch} from 'react-redux';
import {ToiLink} from '@norkart/toi-components';
import {useFilters} from '../../search/Search/filters';
import {usePropertySearch} from '../../search/Search/filters/PropertySearchFilter/usePropertySearch';
import DesktopContainer from '../../../containers/DesktopContainer';
import {setUseAltFrontpage} from '../../../store/session/actions';
import useTrackArealplanerEvent from '../../../hooks/appinsights/useTrackArealplanerEvent';

export default function ChangeToMapButton() {
    const filters = useFilters();
    const propertyFilter = usePropertySearch();
    const dispatch = useDispatch();
    const trackHome = useTrackArealplanerEvent('forside', 'button');

    const handlechangeToMap = (comp: string) => {
        trackHome(comp);
        dispatch(setUseAltFrontpage(true));
    };

    if (!filters.activeFiltersFromUrl.length && !propertyFilter.get())
        return null;
    return (
        <DesktopContainer>
            <ToiLink
                component={'button'}
                color={'secondary'}
                sx={{
                    position: 'absolute',
                    cursor: 'pointer',
                    width: '200px',
                    marginY: '15px',
                    marginX: '20px',
                }}
                onClick={() => handlechangeToMap('byttTilKart_fraSøk')}
            >
                Bytt til Kart
            </ToiLink>
        </DesktopContainer>
    );
}
