import {Box, styled} from '@mui/material';
import {CheckSuccessIcon, CrossCloseIcon, InfoIcon, WarningIcon} from '@norkart/toi-icons';
import {ToiToolTip} from '../../ToiTooltip';

export type ToiAccordionStatusType = 'success' | 'warning' | 'error' | 'info';

const getIcon = (status: ToiAccordionStatusType) => {
  return {
    success: <CheckSuccessIcon />,
    error: <CrossCloseIcon />,
    warning: <WarningIcon />,
    info: <InfoIcon />,
  }[status];
};

interface Props {
  status: ToiAccordionStatusType;
  tooltip?: string;
}
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status',
})<Props>(({theme, status}) => {
  return {
    backgroundColor: theme.vars.toi.palette.signal.background[status],
    paddingInline: theme.toi.spacing['2xs'],
    minHeight: '60px',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

export const ToiAccordionStatus = ({status, tooltip}: Props) => {
  const Icon = getIcon(status);

  if (!tooltip) {
    return <StyledBox status={status}>{Icon}</StyledBox>;
  }

  return (
    <ToiToolTip title={tooltip}>
      <StyledBox status={status}>{Icon}</StyledBox>
    </ToiToolTip>
  );
};
