import React, {useEffect, useState} from 'react';
import {SelectChangeEvent} from '@mui/material';
import {isEmpty} from 'lodash-es';
import {
    KontakpersonForCreationDto,
    KontaktpersonDto,
} from '@norkart/nkapi-arealplaner-api';
import {
    ToiDialogSimple,
    ToiMenuItem,
    ToiSelect,
    ToiTextField,
} from '@norkart/toi-components';
import useMeta from '../../../hooks/meta/useMeta';
import useCreateKontaktperson from '../../../hooks/kontaktpersoner/useCreateKontaktperson';
import useUpdateKontaktperson from '../../../hooks/kontaktpersoner/useUpdateKontaktpersoner';

type Props = {
    kontaktperson?: KontaktpersonDto;
    isOpen: boolean;
    handleClose: () => void;
};
const KontaktpersonDialog = ({kontaktperson, isOpen, handleClose}: Props) => {
    const {mutateAsync: addKontaktperson} = useCreateKontaktperson();
    const {mutateAsync: updateKontaktperson} = useUpdateKontaktperson();
    const {data: metaData} = useMeta();

    const initialFormData: KontakpersonForCreationDto = {
        navn: '',
        telefon: '',
        epost: '',
        organisasjon: '',
        rolle: 0,
    };

    const [formData, setFormData] =
        useState<KontakpersonForCreationDto>(initialFormData);

    const [formValidationErrors, setFormValidationErrors] = useState<
        Record<string, string>
    >({});

    const validateForm = (values) => {
        const errors: Record<string, string> = {};
        if (!values.navn) errors.navn = ' Må fylle inn navn';
        if (!values.telefon) errors.telefon = ' Må fylle inn telefon';
        else if (values.telefon.length <= 7)
            errors.telefon = ' Skriv inn et gyldig telefonnummer';
        if (!values.epost) errors.epost = ' Må fylle inn e-post';
        else if (!values.epost.includes('@'))
            errors.epost = ' Skriv inn en gyldig e-post';
        if (!values.organisasjon)
            errors.organisasjon = ' Må fylle inn organisasjon';
        setFormValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    useEffect(() => {
        setFormValidationErrors({});

        if (kontaktperson) {
            setFormData({
                navn: kontaktperson.navn,
                telefon: kontaktperson.telefon || 'Ingen telefon',
                epost: kontaktperson.epost || 'Ingen E-post',
                organisasjon:
                    kontaktperson.organisasjon || 'Ingen organisasjon',
                rolle: kontaktperson.rolleId || 0,
            });
        } else {
            setFormData(initialFormData);
        }
    }, [kontaktperson]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (!isEmpty(formValidationErrors)) {
            setFormValidationErrors((prevData) => {
                const newData = prevData;
                delete newData[name];
                return newData;
            });
        }
    };

    const handlePrimRolleChange = (event: SelectChangeEvent<unknown>) => {
        const selectedValue = Number(event.target.value);
        setFormData((prevData) => ({
            ...prevData,
            rolle: isNaN(selectedValue) ? 0 : selectedValue,
        }));
    };

    const handleButton = () => {
        const isFormDataValid = validateForm(formData);
        if (isFormDataValid) {
            if (kontaktperson && kontaktperson.id) {
                updateKontaktperson({
                    kontaktperson: formData,
                    id: kontaktperson.id,
                });
            } else addKontaktperson(formData);
            handleClose();
            setFormData(initialFormData);
        }
    };

    return (
        <ToiDialogSimple
            title={
                kontaktperson
                    ? 'Endre kontaktperson'
                    : 'Legg til ny kontaktperson'
            }
            open={isOpen}
            onClose={handleClose}
            maxWidth='sm'
            confirmText={kontaktperson ? 'Endre' : 'Legg til'}
            onConfirm={handleButton}
        >
            <form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '10px 20px',
                }}
            >
                <ToiTextField
                    name='navn'
                    label='Navn'
                    value={formData.navn}
                    onChange={handleChange}
                    error={formValidationErrors.navn}
                    helperText={formValidationErrors.navn}
                />
                <ToiTextField
                    name='telefon'
                    label='Telefon'
                    value={formData.telefon}
                    onChange={handleChange}
                    error={formValidationErrors.telefon}
                    helperText={formValidationErrors.telefon}
                />
                <ToiTextField
                    name='epost'
                    label='Epost'
                    value={formData.epost}
                    onChange={handleChange}
                    error={formValidationErrors.epost}
                    helperText={formValidationErrors.epost}
                />

                <ToiTextField
                    name='organisasjon'
                    label='Organisasjon'
                    value={formData.organisasjon}
                    onChange={handleChange}
                    error={formValidationErrors.organisasjon}
                    helperText={formValidationErrors.organisasjon}
                />
                <ToiSelect
                    label={'Primærrolle'}
                    value={formData.rolle}
                    onChange={handlePrimRolleChange}
                >
                    <ToiMenuItem
                        aria-label='Ikke valgt'
                        value={0}
                        sx={{minHeight: '30px!important'}}
                    />
                    {metaData.rolletyper.map((rt) => (
                        <ToiMenuItem key={rt.id} value={rt.id}>
                            {rt.beskrivelse}
                        </ToiMenuItem>
                    ))}
                </ToiSelect>
            </form>
        </ToiDialogSimple>
    );
};

export default KontaktpersonDialog;
