import {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {toiColors} from '@norkart/toi-colors';
import {ToiMarkdown, ToiStack, ToiTypography} from '@norkart/toi-components';
import {chatHistoryItem} from '../cache/chatHistory';
import ChatStarsGraphic from '../assets/images/chat_stars_graphic.svg';
import {Chunk, ConversationType, Feedback} from '../types';
import {useOpenReference} from '../hooks/useOpenReference';
import {ReferenceChatItem} from './ReferenceChatItem';
import {ReferenceContentDialog} from './ReferenceContentDialog';
import FeedbackComponent from './FeedbackComponent';

export const ChatWindow = (props: {
    botName: string;
    chatHistory: chatHistoryItem[];
    conversationConfig: ConversationType[];
    displaySearchedIndicesText?: boolean;
    displayFeedbackButtons?: boolean;
    onSubmitFeedback?: (feedback: Feedback) => void;
}) => {
    const {
        botName,
        chatHistory,
        conversationConfig,
        displaySearchedIndicesText = true,
        onSubmitFeedback,
    } = props;

    const navigate = useNavigate();
    const location = useLocation();
    const [currentSelectedChunk, setCurrentSelectedChunk] =
        useState<Chunk | null>(null);

    const {mnr, chunkId, chatItemIndex, answerIndex} = useParams();
    const openReferenceFromLink = useOpenReference({
        chatItemIndex,
        chatHistory,
    });

    const isOpenChunk = Boolean(currentSelectedChunk);

    useEffect(() => {
        if (chunkId && chatItemIndex && answerIndex) {
            const item = chatHistory[parseInt(chatItemIndex)];
            const correspondingAnswer =
                item.answers && item.answers[parseInt(answerIndex)];
            const correspondingChunk =
                correspondingAnswer &&
                correspondingAnswer.reference.chunks.find(
                    (chunk) => chunk.id === chunkId
                );
            correspondingChunk && setCurrentSelectedChunk(correspondingChunk);
        }
    }, [openReferenceFromLink, chunkId, chatItemIndex, answerIndex]);

    const closeReference = () => {
        if (chunkId && chatItemIndex && answerIndex) {
            // Split the path into segments
            const pathSegments = location.pathname.split('/');
            // Remove the last three segments:   /chunkId/answerIndex/chatElementIndex
            const newPathSegments = pathSegments.slice(0, -3);
            // Join the segments back into a path
            const newPath = newPathSegments.join('/');
            navigate(`${newPath}`);
        }
        setCurrentSelectedChunk(null);
    };

    const getSearchIndexName = (id: string) => {
        const searchIndex = conversationConfig
            .map((c) => c.searchIndices)
            .flat()
            .find((si) => si.id === id);
        return searchIndex?.name ? searchIndex?.name?.toLowerCase() : id;
    };

    const getSearchText = (
        searchIndices: string[],
        hideLongText = true,
        maxLength = 60
    ) => {
        const fullText = searchIndices
            .map((searchIndex) => getSearchIndexName(searchIndex))
            .join(', ');

        return fullText.length > maxLength && hideLongText
            ? fullText.substring(0, maxLength) + '...'
            : fullText;
    };

    return (
        <ToiStack gap={4}>
            {chatHistory.map((chatElement, chatElementIndex) => {
                const isAi = chatElement.type === 'ai';
                return (
                    <ToiStack
                        p={1.5}
                        key={chatElementIndex}
                        sx={isAi ? {} : {backgroundColor: toiColors.purple[20]}}
                        alignSelf={'start'}
                    >
                        <ToiStack
                            direction='row'
                            alignItems={'center'}
                            gap={1.5}
                        >
                            {isAi && (
                                <img
                                    alt='Nora-logo'
                                    src={ChatStarsGraphic}
                                    style={{
                                        height: 35,
                                        marginLeft: '-50px',
                                    }}
                                />
                            )}

                            <ToiTypography
                                color='primary'
                                variant='body2'
                                sx={{fontWeight: 600}}
                            >
                                {isAi ? botName : 'Deg'}
                            </ToiTypography>
                            {isAi &&
                                displaySearchedIndicesText &&
                                chatElement.searchIndices.length > 0 && (
                                    <ToiTypography
                                        color='primary'
                                        variant='body3'
                                        sx={{fontWeight: 600}}
                                        title={getSearchText(
                                            chatElement.searchIndices,
                                            false
                                        )}
                                    >
                                        {`– Søkte i ${getSearchText(
                                            chatElement.searchIndices
                                        )}
                                        og fant:`}
                                    </ToiTypography>
                                )}
                            {isAi &&
                                !displaySearchedIndicesText &&
                                chatElement.additionalAiMessageHeadline && (
                                    <ToiTypography
                                        color='primary'
                                        variant='body2'
                                        sx={{fontWeight: 600}}
                                    >
                                        {
                                            chatElement.additionalAiMessageHeadline
                                        }
                                    </ToiTypography>
                                )}
                        </ToiStack>
                        {chatElement.answers ? (
                            chatElement.answers.map((answer, i) => (
                                <ReferenceChatItem
                                    key={i}
                                    answerIndex={i}
                                    answer={answer}
                                    setCurrentSelectedChunk={
                                        setCurrentSelectedChunk
                                    }
                                    chatElementIndex={chatElementIndex.toString()}
                                    displayFeedbackButtons={
                                        props.displayFeedbackButtons
                                    }
                                    onSubmitFeedback={onSubmitFeedback}
                                />
                            ))
                        ) : isAi ? (
                            <ToiMarkdown>{chatElement.content}</ToiMarkdown>
                        ) : (
                            <ToiTypography fontWeight='semibold'>
                                {chatElement.content}
                            </ToiTypography>
                        )}
                        {isAi &&
                            props.displayFeedbackButtons &&
                            onSubmitFeedback && (
                                <FeedbackComponent
                                    onSubmitFeedback={onSubmitFeedback}
                                />
                            )}
                    </ToiStack>
                );
            })}
            <ReferenceContentDialog
                isOpen={isOpenChunk}
                onClose={closeReference}
                chunk={currentSelectedChunk}
            />
        </ToiStack>
    );
};
