import * as React from 'react';
import {useDispatch} from 'react-redux';
import {
    BehandlingDto,
    DispensasjonDto,
    DokumentDto,
} from '@norkart/nkapi-arealplaner-api';
import {EditIcon, ShareIcon} from '@norkart/toi-icons';
import {ToiStack} from '@norkart/toi-components';
import useDokuments from '../../hooks/dokumenter/useDokuments';
import {showDialog} from '../../store/dialog/actions';
import useCan from '../../utils/auth/useCan';
import {formatDate} from '../../utils/formatDate';
import {getFileExtension} from '../../utils/getFileExtension';
import FileIcon from '../FileIcon';
import GenericTable from '../GenericTable';
import {GTableAction, GenericTableProps} from '../GenericTable/GenericTable';
import ShareTextDialog from '../ShareTextDialog';
import TabbableTableLink from '../TabbableTableLink';

export interface DocumentTableProps extends Partial<GenericTableProps> {
    dokumenter: DokumentDto[];
    behandling?: BehandlingDto;
    dispensasjon?: DispensasjonDto;
}

const cellPadding = '4px 16px';

function DocumentTable({dokumenter, ...props}: DocumentTableProps) {
    const {openDokument} = useDokuments();
    const can = useCan();
    const [isOpen, setIsOpen] = React.useState(false);
    const [textToShare, setTextToShare] = React.useState('');
    const dispatch = useDispatch();

    function RenderDocName(document: DokumentDto) {
        return (
            <ToiStack direction={'row'} gap={1}>
                <FileIcon
                    extension={getFileExtension(document.dokumentnavn ?? '')}
                />
                <TabbableTableLink
                    underline={false}
                    text={document.dokumentnavn ?? ''}
                    action={() => document.id && openDokument(document.id)}
                />
            </ToiStack>
        );
    }

    const columns = [
        {
            title: 'Dokumenttype',
            field: 'dokumenttype',
            cellStyle: {
                padding: cellPadding,
                whiteSpace: 'nowrap',
            } as React.CSSProperties,
            headerStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
        },
        {
            title: 'Dokumentnavn',
            field: 'dokumentnavn',
            cellStyle: {
                padding: cellPadding,
                wordBreak: 'break-word',
            } as React.CSSProperties,
            headerStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
            render: RenderDocName,
        },
        {
            title: 'Beskrivelse',
            field: 'beskrivelse',
            cellStyle: {
                padding: cellPadding,
                wordBreak: 'break-word',
            } as React.CSSProperties,
            headerStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
        },
        {
            title: 'Dato',
            field: 'dokumentdato',
            cellStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
            headerStyle: {
                padding: cellPadding,
            } as React.CSSProperties,
            render: (dokument) => formatDate(dokument.dokumentdato),
        },
    ];

    const allowEdit = props.dispensasjon ? can.editDisp : can.edit;

    function handleEdit(event, dokument) {
        dispatch(
            showDialog({
                dialogType: 'EDIT_DOKUMENT',
                dialogProps: {
                    dokument,
                    behandling: props.behandling,
                    dispensasjon: props.dispensasjon,
                },
            })
        );
    }
    const handleShare = (event, dokument) => {
        if (dokument.url) {
            setTextToShare(dokument.url);
            setIsOpen(true);
        }
    };

    const actions = [] as GTableAction[];
    actions.push({
        icon: () => <ShareIcon />,
        tooltip: 'Del',
        onClick: (event, dokument) => {
            handleShare(event, dokument);
        },
    } as GTableAction);

    if (allowEdit) {
        actions.push({
            icon: () => <EditIcon />,
            tooltip: 'Endre dokument',
            onClick: (event, dokument) => {
                handleEdit(event, dokument);
            },
        } as GTableAction);
    }
    return (
        <div className='tabbable-table-wrapper'>
            <GenericTable
                title='Dokumenter'
                data={dokumenter}
                idColumn='id'
                columns={columns}
                actions={actions}
                onRowClick={(event, rowData, togglePanel) => {
                    openDokument(rowData.id);
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Ingen dokumenter',
                    },
                }}
                {...props}
            />
            <ShareTextDialog
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
                title={'Del link til dokumentet'}
                textToShare={textToShare}
            />
        </div>
    );
}

export default DocumentTable;
