import {useState} from 'react';
import {
    ArealplanDto,
    ArealplanForUpdateDto,
} from '@norkart/nkapi-arealplaner-api';
import {
    ToiButton,
    ToiDialog,
    ToiDialogContent,
    ToiDialogTitle,
    ToiStack,
} from '@norkart/toi-components';
import Editor from '../../../components/TextEditor/Editor';
import useUpdateArealplan from '../../../hooks/arealplaner/useUpdateArealplan';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog} from '../../../store/dialog/actions';

export interface PlanDescriptionDialogProps extends ConnectedReduxProps {
    plan: ArealplanDto;
    open: boolean;
}

const PlanDescriptionDialog = ({
    plan,
    open,
    dispatch,
}: PlanDescriptionDialogProps) => {
    const [description, setDescription] = useState<string>(
        plan.planBeskrivelse ?? ''
    );
    const {mutateAsync: updateArealplan} = useUpdateArealplan();

    const handleEditorState = (newEditorHTML: string) => {
        if (newEditorHTML === '<p><br></p>') {
            setDescription('');
        } else {
            setDescription(newEditorHTML);
        }
    };

    const onCancel = () => {
        hideDialog(dispatch);
        setDescription(plan.planBeskrivelse ?? '');
    };

    const onSave = async () => {
        hideDialog(dispatch);
        const data = {
            ...plan,
            planBeskrivelse: description,
        } as ArealplanForUpdateDto;
        const res =
            plan.id &&
            (await updateArealplan({
                arealplanForUpdate: data,
                arealplanId: plan.id,
            }));
    };

    return (
        <ToiDialog
            onClose={onCancel}
            open={open}
            disableScrollLock
            maxWidth={'md'}
        >
            <ToiDialogTitle id='form-dialog-title'>
                Beskrivelse av planen
            </ToiDialogTitle>
            <ToiDialogContent>
                <ToiStack gap={(theme) => theme.toi.spacing.s}>
                    <Editor
                        initialEditorContent={description ? description : ''}
                        toolbarOptions={[
                            'bold',
                            'italic',
                            'list',
                            'undo',
                            'redo',
                            'link',
                        ]}
                        currentEditorState={handleEditorState}
                    />
                    <ToiStack
                        direction='row'
                        gap={(theme) => theme.toi.spacing.s}
                        justifyContent={'flex-end'}
                    >
                        <ToiButton variant='secondary' onClick={onCancel}>
                            Avbryt
                        </ToiButton>
                        <ToiButton onClick={onSave}>Lagre</ToiButton>
                    </ToiStack>
                </ToiStack>
            </ToiDialogContent>
        </ToiDialog>
    );
};

export default PlanDescriptionDialog;
