import {FormControlLabel, FormControlLabelProps, styled} from '@mui/material';

export type ToiFormControlLabelProps = FormControlLabelProps & {
  displayHelpTextInline?: boolean;
};

export const ToiFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'displayHelpTextInline',
})<ToiFormControlLabelProps>(({theme, displayHelpTextInline}) => ({
  margin: 0,
  alignItems: 'flex-start',
  gap: theme.toi.spacing.xs,

  '&.MuiFormControlLabel-labelPlacementStart': {
    width: '100%',
    maxWidth: '400px',
    justifyContent: 'space-between',
    gap: theme.toi.spacing.s,
  },

  '& .MuiFormControlLabel-label': {
    gap: theme.toi.spacing.xs,
    display: 'flex',
    flexDirection: displayHelpTextInline ? 'row' : 'column',
    textJustify: 'center',
    '&.Mui-disabled': {
      color: theme.vars.toi.palette.disabled.textOnWhite,
    },
  },

  '& .ToiFormControlLabel-helpText': {
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root': theme.typography.body3,
  },
}));

export default ToiFormControlLabel;
