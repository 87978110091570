import {
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import {AxiosError} from 'axios';
import arrayMutators from 'final-form-arrays';
import {useEffect, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    DeleteRecycleIcon,
} from '@norkart/toi-icons';
import {
    ToiBox,
    ToiButton,
    ToiIconButton,
    ToiStack,
} from '@norkart/toi-components';
import {
    ArealplanDto,
    arealplanerGetNewPlanId,
    SakVerdiDto,
} from '@norkart/nkapi-arealplaner-api';
import FormDateInputField from '../../../components/FormDateInputField';
import FormInputField from '../../../components/FormInputField';
import FormSelectInput from '../../../components/FormSelectInput/FormSelectInput';
import FormSwitchField from '../../../components/FormSwitchField';
import LoadingSpinner from '../../../components/LoadingSpinner';
import OnChange from '../../../components/OnChange';
import {openSnackbarMessage} from '../../../components/SnackbarMessage/SnackbarMessage';
import useMeta from '../../../hooks/meta/useMeta';
import useCreateOrganisasjonsPerson from '../../../hooks/organisasjonspersoner/useCreateOrganisasjonsperson';
import useDeleteOrganisasjonsPerson from '../../../hooks/organisasjonspersoner/useDeleteOrganisasjonsPerson';
import {useOrganisasjonsPersoner} from '../../../hooks/organisasjonspersoner/useOrganisasjonsPersoner';
import {firstLower} from '../../../utils/firstLower';
import {useArealplanerApiClientContext} from '../../../services/arealplanerApi';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import EditSak from './components/EditSak';
import Ikraft from './components/Ikraft';
import Lovreferanse from './components/Lovreferanse';
import NasjonalArealplanId from './components/NasjonalArealplanId';
import Vertniv from './components/Vertniv';
import Warning from './components/Warning';
import EditOrganisasjonspersonDialog from './EditOrganisasjonspersonDialog';
import {
    isForslagsstillerAllowed,
    isIKraftEnabled,
    isLovRefDefault,
    isPlanBestemmelserSuggested,
    isUbehandletEnabled,
    isVertNivaEnabled,
    shouldDisplayHoringsfrist,
    shouldDisplayKlagefrist,
} from './helpers';
import OrganisasjonspersonPlanForm, {
    Option,
} from './OrganisasjonspersonPlanForm';
import validate from './validate';

export interface PlanFormProps {
    plan: ArealplanDto;
    errors?: any;
    onSubmit: (values: any) => any;
    onCancel: () => void;
    onDelete: () => void;
    onClose: () => void;
}

export interface PlanFormState {
    initialValues: ArealplanFormValues;
    expanded: boolean; // Controls if extra properties pane is open
    planidOpen: boolean; // Controls if Edit planid is open
    confirmSubmitOpen: boolean; // Controls confirm dialog
    confirmSubmitConfirmed: boolean; // Set if ok if clicked in confirm dialog
    editSakOpen: boolean;
    internalErrors?: AxiosError;
    updateQMS: boolean;
}

export interface ArealplanFormValues {
    komnr: string;
    planId: string;
    navn?: string;
    planTypeId?: number;
    planStatusId?: number;
    iKraft?: Date;
    planBestemmelseId?: number;
    vertikalniva?: number[];
    lovreferanseId?: number;
    forslagsstillerId?: number;
    formatId?: number;
    opprinneligAdministrativEnhet?: number;
    opprinneligPlanId?: string;
    overstyrtPlanid?: boolean;
    plandokumentasjonOppdatert?: boolean;
    ubehandletKlage?: boolean;
    ubehandletInnsigelse?: boolean;
    klagerFerdigbehandlet?: boolean;
    klagefrist?: Date;
    horingsStart?: string;
    horingsfrist?: string;
    meldingKlagefrist?: boolean;
    saker: Partial<SakVerdiDto>[];
    updateQMS?: boolean;
}

const WhenFieldChanges = ({field, predictor, set, to}: any) => (
    <Field name={set} subscription={{}}>
        {({input: {onChange}}) => (
            <OnChange name={field}>
                {(value) => {
                    if (predictor(value)) {
                        onChange(to);
                    }
                }}
            </OnChange>
        )}
    </Field>
);

function PlanForm({plan, ...props}: PlanFormProps) {
    const [initialValues, setInitialValues] = useState<ArealplanFormValues>(
        {} as ArealplanFormValues
    );
    const [expanded, setExpanded] = useState(false);
    const kundeId = useSelectedKundeId();

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
    const [confirmSubmitConfirmed, setConfirmSubmitConfirmed] = useState(false);
    const [editOrganisasjonsPersonOpen, setEditOrganisasjonsPersonOpen] =
        useState(false);
    const [internalErrors, setInternalErrors] = useState();

    const meta = useMeta();
    const {authorizedClient} = useArealplanerApiClientContext();
    const {data: organisasjonspersoner} = useOrganisasjonsPersoner(plan.id);

    const {mutateAsync: addOrganisasjonsperson} =
        useCreateOrganisasjonsPerson();

    const {mutateAsync: deleteOrganisasjonsperson} =
        useDeleteOrganisasjonsPerson();

    const [
        selectedValuesOrganisasjonsPerson,
        setSelectedValuesOrganisasjonsPerson,
    ] = useState<Option[]>([]);

    const addAndDeleteOrganisasjonsPerson = (arealplanId: number) => {
        const organisasjonsPersonCreationList =
            selectedValuesOrganisasjonsPerson.map(({id, rolleId}) => ({
                arealplanId: arealplanId,
                kontaktpersonId: id,
                rolleId,
            }));

        if (organisasjonspersoner && plan.id) {
            const addList = organisasjonsPersonCreationList.filter(
                (newItem) =>
                    !organisasjonspersoner.some(
                        (oldItem) =>
                            oldItem.arealplan?.id === newItem.arealplanId &&
                            oldItem.kontaktperson?.id ===
                                newItem.kontaktpersonId &&
                            oldItem.rolle?.id === newItem.rolleId
                    )
            );

            const deleteList = organisasjonspersoner.filter(
                (oldItem) =>
                    !organisasjonsPersonCreationList.some(
                        (newItem) =>
                            newItem.arealplanId === oldItem.arealplan?.id &&
                            newItem.kontaktpersonId ===
                                oldItem.kontaktperson?.id &&
                            newItem.rolleId === oldItem.rolle?.id
                    )
            );

            addList.forEach((organisasjonsPerson) => {
                addOrganisasjonsperson(organisasjonsPerson);
            });
            deleteList.forEach((organisasjonsPerson) => {
                deleteOrganisasjonsperson(organisasjonsPerson);
            });
        } else
            organisasjonsPersonCreationList.forEach((organisasjonsPerson) => {
                addOrganisasjonsperson(organisasjonsPerson);
            });
    };

    const handleOrganisasjonsPersonClose = () => {
        setEditOrganisasjonsPersonOpen(false);
    };

    const handleOrganisasjonsPersonOk = (values) => {
        const existCheck = values.some(
            (orgPerson) =>
                !selectedValuesOrganisasjonsPerson.some(
                    (selectedOrgPerson) =>
                        orgPerson.kontaktperson === selectedOrgPerson.id &&
                        orgPerson.rolletype === selectedOrgPerson.rolleId
                )
        );

        if (existCheck) {
            const newValues = values.map(({kontaktperson, rolletype}) => {
                return {
                    id: kontaktperson,
                    rolleId: rolletype,
                };
            });

            setSelectedValuesOrganisasjonsPerson((prevValues) => [
                ...prevValues,
                ...newValues,
            ]);
        }

        handleOrganisasjonsPersonClose();
    };

    useEffect(() => {
        plan.planId
            ? setInitialState(plan)
            : arealplanerGetNewPlanId({
                  path: {kundeId},
                  client: authorizedClient,
              }).then((res) =>
                  setInitialState({
                      planId: res.data as string,
                      komnr: meta.komnr || '',
                      vertikalniva: [],
                      saker: [],
                  } as ArealplanFormValues)
              );
    }, []);

    const setInitialState = (initialState) => {
        const {dokumenter, behandlinger, gjenstaendeKlage, ...filteredState} =
            initialState;
        const newState = {
            initialValues: {
                ...filteredState,
                klagerFerdigbehandlet: !gjenstaendeKlage,
                vertikalniva: filteredState.vertikalniva.map((v) => v.id),
                lovreferanseId:
                    !filteredState.lovreferanseId &&
                    isLovRefDefault(filteredState.planTypeId)
                        ? 6
                        : filteredState.lovreferanseId,
            } as ArealplanFormValues,
        };
        setInitialValues(newState.initialValues);
    };

    const handleErrors = (response) => {
        const errors = response.errors;
        const submitErrors = {};
        Object.keys(errors).map((key) => {
            const fieldId = firstLower(key);
            submitErrors[fieldId] = errors[key][0];
        });

        return submitErrors;
    };

    // Maps vertikalnivå from array to array of objects
    // Determines if PlanId is modified
    // Converts PlandokumentasjonOppdatert from boolean to smallint
    const mapDataOut = (values) => {
        let vertikalNivaMap = [];
        if (values.vertikalniva && values.vertikalniva.length > 0) {
            vertikalNivaMap = values.vertikalniva.map((vn) => ({id: vn}));
        }

        const {klagerFerdigbehandlet, ...filteredValues} = values;

        const data = {
            ...filteredValues,
            iKraft: isIKraftEnabled(values.planStatusId) ? values.iKraft : null,
            overstyrtPlanid:
                initialValues.overstyrtPlanid ||
                values.planId != initialValues.planId,
            vertikalniva: isVertNivaEnabled(
                values.planTypeId,
                values.planStatusId
            )
                ? vertikalNivaMap
                : null,
            forslagsstillerId: isForslagsstillerAllowed(values.planTypeId)
                ? values.forslagsstillerId
                : null,
            gjenstaendeKlage: !values.klagerFerdigbehandlet,
        };
        return data;
    };

    const hasNasjonalPlanIdChanged = ({komnr, planId}) => {
        const planIdChanged = planId != initialValues.planId;
        const komNrChanged = komnr != initialValues.komnr;

        return planIdChanged || komNrChanged;
    };

    // Async in order to properly return submit errors
    const onSubmit = async (values: ArealplanFormValues) => {
        // Show confirm dialog if Nasjonal arealplan-id changed from initialValues
        if (hasNasjonalPlanIdChanged(values) && !confirmSubmitConfirmed) {
            handleConfirmOpen();
        } else {
            // Modify form data before submit
            const data = mapDataOut(values);

            // Actual submission of form data
            try {
                const response = await props.onSubmit(data);
                if (plan.id) {
                    addAndDeleteOrganisasjonsPerson(plan.id);
                } else if (response.id) {
                    addAndDeleteOrganisasjonsPerson(response.id);
                }

                // Handle form validation errors
                if (response?.errors) {
                    openSnackbarMessage({
                        variant: 'error',
                        message: 'Noe er galt i skjema',
                    });
                    return handleErrors(response);
                } else {
                    props.onClose();
                }
            } catch (err) {
                // TODO: Handle other errors
                // setInternalErrors(err)
            }
        }
    };

    // Handles submit confirm dialog
    const handleConfirmOpen = () => {
        setConfirmSubmitOpen(true);
    };
    const handleSubmitClose = () => {
        setConfirmSubmitOpen(false);
    };

    useEffect(() => {
        if (confirmSubmitConfirmed)
            document
                .getElementById('plan-form')!
                .dispatchEvent(
                    new Event('submit', {cancelable: true, bubbles: true})
                );
    }, [confirmSubmitConfirmed]);

    const handleSubmitOk = () => {
        setConfirmSubmitOpen(false);
        setConfirmSubmitConfirmed(true);
    };

    const confirmSubmitDialog = () => (
        <Dialog open={confirmSubmitOpen} aria-labelledby='confirm-dialog-title'>
            <DialogTitle id='confirm-dialog-title'>
                Lagre med endring på nasjonal arealplanid?
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Nasjonal arealplanid er endret. Dette kan påvirke koblingen
                    til andre systemer. Ønsker du å lagre dette?
                </Typography>
            </DialogContent>
            <DialogActions>
                <ToiButton onClick={handleSubmitClose} variant='secondary'>
                    Nei
                </ToiButton>
                <ToiButton type='submit' onClick={handleSubmitOk}>
                    Ja
                </ToiButton>
            </DialogActions>
        </Dialog>
    );

    const submitForm = () => {
        document
            .getElementById('plan-form')!
            .dispatchEvent(
                new Event('submit', {cancelable: true, bubbles: true})
            );
    };

    const handleSaveMenuSubmit = (values) => {
        values.updateQMS = false;
        submitForm();
    };

    const handleSaveMenuSubmitQMS = (values) => {
        values.updateQMS = true;
        submitForm();
    };

    if (meta.isLoading || !initialValues.planId) return <LoadingSpinner />;
    if (meta.isError || internalErrors) return null;

    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                mutators={{
                    ...arrayMutators,
                }}
                validate={validate}
                render={({
                    handleSubmit,
                    pristine,
                    submitting,
                    submitErrors,
                    values,
                    form,
                    errors,
                }) => (
                    <ToiBox
                        sx={(theme) => ({
                            '& .importantFormLabel': {fontWeight: 'bold'},
                            '& .formInput': {width: '100%', padding: '5px'},
                            '& .formInput:disabled': {
                                backgroundColor: '#f0f0f0',
                            },
                            '& .formInputError': {border: '1px solid red'},
                            marginBottom: '10px',
                        })}
                    >
                        <form
                            id='plan-form'
                            onSubmit={handleSubmit}
                            autoComplete='off'
                        >
                            {/* Change LovreferanseId to 6 for certain PlanTypeId values based on predictor being true */}
                            <WhenFieldChanges
                                field='planTypeId'
                                predictor={isLovRefDefault}
                                set='lovreferanseId'
                                to={'6'}
                            />
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={12} md={6}>
                                    <NasjonalArealplanId values={values} />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <EditSak
                                        values={values}
                                        initialValues={initialValues}
                                        setInitialValues={setInitialValues}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={12}>
                                    <FormInputField
                                        name='planNavn'
                                        label='Plannavn'
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <ToiStack gap={1}>
                                        <FormSelectInput
                                            name='planTypeId'
                                            title='Plantype'
                                            options={meta.data.plantyper}
                                            keyCol='id'
                                            valueCol='beskrivelse'
                                        />
                                        <Lovreferanse
                                            values={values}
                                            plan={plan}
                                        />
                                        <FormSelectInput
                                            name='forslagsstillerId'
                                            title='Forslagsstillertype'
                                            options={
                                                meta.data.forslagsstillertyper
                                            }
                                            optional={true}
                                            keyCol='id'
                                            valueCol='beskrivelse'
                                            disabled={
                                                values.planTypeId
                                                    ? !isForslagsstillerAllowed(
                                                          values.planTypeId
                                                      )
                                                    : true
                                            }
                                        />
                                        {values.forslagsstillerId &&
                                            !isForslagsstillerAllowed(
                                                values.planTypeId
                                            ) && (
                                                <Warning message='Forslagsstiller vil bli slettet' />
                                            )}
                                        <FormSelectInput
                                            name='planBestemmelseId'
                                            title='Planbestemmelser'
                                            options={meta.data.planbestemmelser}
                                            keyCol='id'
                                            valueCol='beskrivelse'
                                            optional={
                                                !isPlanBestemmelserSuggested(
                                                    values.planStatusId
                                                )
                                            }
                                        />
                                        {values.planBestemmelseId == null &&
                                            isPlanBestemmelserSuggested(
                                                values.planStatusId
                                            ) && (
                                                <Warning message='Planbestemmelser er påkrevd, men kan lagres uten' />
                                            )}
                                    </ToiStack>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Vertniv values={values} />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <FormSelectInput
                                        name='planStatusId'
                                        title='Planstatus'
                                        options={meta.data.planstatuser}
                                        keyCol='id'
                                        valueCol='beskrivelse'
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Ikraft values={values} />
                                </Grid>
                                {shouldDisplayKlagefrist(values) && (
                                    <>
                                        <Grid item={true} xs={12} md={6}>
                                            <FormSwitchField
                                                name={'meldingKlagefrist'}
                                                label={
                                                    "Vis melding 'Klagefrist ikke utløpt' før Kunngjøring"
                                                }
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <FormDateInputField
                                                name='klagefrist'
                                                label='Klagefrist'
                                            />
                                        </Grid>
                                    </>
                                )}
                                {values.planStatusId == 6 && (
                                    <>
                                        <Grid item={true} xs={12} md={6}>
                                            <FormSwitchField
                                                name={'ubehandletKlage'}
                                                label='Oppsettende(utsatt) virkning av klage'
                                                disabled={
                                                    !isUbehandletEnabled(
                                                        values.planStatusId
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <FormSwitchField
                                                name={'ubehandletInnsigelse'}
                                                label='Ubehandlet innsigelse'
                                                disabled={
                                                    !isUbehandletEnabled(
                                                        values.planStatusId
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </>
                                )}
                                {/* {shouldDisplayKlagefrist(values) && ( */}
                                <Grid item={true} xs={12}>
                                    <FormSwitchField
                                        name={'klagerFerdigbehandlet'}
                                        label='Ingen klager under behandling'
                                    />
                                </Grid>
                                {/* )} */}
                                {shouldDisplayHoringsfrist(values) && (
                                    <>
                                        <Grid item={true} xs={12} md={6}>
                                            <FormDateInputField
                                                name='horingsStart'
                                                label='Høringsstart'
                                                optional
                                                maxDate={values.horingsfrist}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <FormDateInputField
                                                name='horingsfrist'
                                                label='Høringsfrist'
                                                optional
                                                minDate={values.horingsStart}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item={true} xs={12} md={9}>
                                    <FormSwitchField
                                        name={'plandokumentasjonOppdatert'}
                                        label='Plandokumentasjonen er oppdatert i henhold til vedtak'
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={12}>
                                    <ToiButton
                                        variant='transparent'
                                        startIcon={
                                            expanded ? (
                                                <ChevronUpIcon />
                                            ) : (
                                                <ChevronDownIcon />
                                            )
                                        }
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setExpanded(!expanded);
                                        }}
                                    >
                                        {expanded
                                            ? 'Vis færre egenskaper'
                                            : 'Vis flere egenskaper'}
                                    </ToiButton>
                                    <Collapse in={expanded} timeout='auto'>
                                        <Grid
                                            pt={1}
                                            container={true}
                                            spacing={2}
                                            justifyContent={'space-between'}
                                        >
                                            <Grid item={true} xs={12} md={6}>
                                                <FormSelectInput
                                                    name='opprinneligAdministrativEnhet'
                                                    title='Opprinnelig administrativ enhet'
                                                    options={meta.data.kommuner}
                                                    keyCol='kommuneNummer'
                                                    valueCol='kommuneNavn'
                                                    optional={true}
                                                />
                                            </Grid>
                                            <Grid item={true} xs={12} md={6}>
                                                <FormInputField
                                                    name='opprinneligPlanId'
                                                    label='Opprinnelig planid'
                                                    optional={true}
                                                />
                                            </Grid>

                                            <Grid item={true} xs={12} md={6}>
                                                <FormSelectInput
                                                    name='formatId'
                                                    title='Format'
                                                    options={meta.data.formater}
                                                    keyCol='id'
                                                    valueCol='beskrivelse'
                                                    optional={true}
                                                />
                                            </Grid>
                                            <Grid item={true} xs={12} md={6}>
                                                {meta.hasKontaktPerson() && (
                                                    <OrganisasjonspersonPlanForm
                                                        plan={plan}
                                                        openEditOrganisasjonspersonDialog={() =>
                                                            setEditOrganisasjonsPersonOpen(
                                                                true
                                                            )
                                                        }
                                                        setSelectedValuesOrganisasjonsPerson={
                                                            setSelectedValuesOrganisasjonsPerson
                                                        }
                                                        selectedValuesOrganisasjonsPerson={
                                                            selectedValuesOrganisasjonsPerson
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Grid>
                                <Grid item={true} style={{flexGrow: 1}}>
                                    <div />
                                </Grid>
                                <Grid
                                    container={true}
                                    justifyContent='space-between'
                                    alignItems='baseline'
                                >
                                    <Grid item={true}>
                                        {plan && plan.id ? (
                                            <ToiIconButton
                                                aria-label='Slett arealplan'
                                                color='transparent'
                                                onClick={props.onDelete}
                                                sx={{
                                                    marginLeft: '25px',
                                                }}
                                            >
                                                <DeleteRecycleIcon />
                                            </ToiIconButton>
                                        ) : null}
                                    </Grid>
                                    <Grid item={true}>
                                        <div
                                            style={{
                                                marginTop: '20px',
                                                display: 'flex',
                                            }}
                                        >
                                            {meta.hasFeature(
                                                'vis_oppdater_qms'
                                            ) && (
                                                <ToiButton
                                                    type='submit'
                                                    disabled={submitting}
                                                    onClick={() =>
                                                        handleSaveMenuSubmitQMS(
                                                            values
                                                        )
                                                    }
                                                >
                                                    Lagre og oppdater QMS
                                                </ToiButton>
                                            )}
                                            <ToiButton
                                                style={{marginLeft: 10}}
                                                type='submit'
                                                disabled={submitting}
                                                onClick={() =>
                                                    handleSaveMenuSubmit(values)
                                                }
                                            >
                                                Lagre
                                            </ToiButton>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                            <pre>
                                Pristine: {JSON.stringify(pristine, null, 2)}
                            </pre>
                            <pre>{JSON.stringify(submitting, null, 2)}</pre>
                            <pre>{JSON.stringify(errors, null, 2)}</pre>
                            <pre>{JSON.stringify(submitErrors, null, 2)}</pre> */}
                        </form>
                    </ToiBox>
                )}
            />
            {confirmSubmitDialog()}
            <EditOrganisasjonspersonDialog
                isOpen={editOrganisasjonsPersonOpen}
                onClose={handleOrganisasjonsPersonClose}
                onOk={handleOrganisasjonsPersonOk}
            />
        </>
    );
}

export default PlanForm;
