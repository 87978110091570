import {useQuery} from 'react-query';
import {TokenType, TokenDto} from '@norkart/nkapi-arealplaner-api';
import {fetchTokens} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

const getTokens = async (tokenType: TokenType) => {
    const {data} = await fetchTokens(tokenType);
    return data;
};

export default function useTokens(tokenType: TokenType) {
    const {notify} = useErrorHandler();
    const kundeId = useSelectedKundeId();

    const query = useQuery<TokenDto[]>(
        ['tokens', {kundeId, tokenType}],
        () => getTokens(tokenType),
        {
            onError: notify,
            enabled: !!kundeId,
            staleTime: undefined,
            cacheTime: undefined,
        }
    );

    return {
        ...query,
        data: query.data || [],
    };
}
