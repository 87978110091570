import * as React from 'react';
import {DokumentDto} from '@norkart/nkapi-arealplaner-api';
import DocumentListItem from './DocumentListItem';

export interface DocumentListProps {
    documents?: DokumentDto[] | null;
    bgColor?: string;
}

export function DocumentList({documents, bgColor}: DocumentListProps) {
    if (!documents) return null;
    return (
        <>
            {documents.map((document) => {
                return (
                    <DocumentListItem
                        key={document.id}
                        document={document}
                        bgColor={bgColor || 'default'}
                    />
                );
            })}
        </>
    );
}

export default DocumentList;
