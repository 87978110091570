import {TablePaginationProps as MuiTablePaginationProps, styled, TablePagination} from '@mui/material';
import {forwardRef} from 'react';
import {ChevronDownIcon} from '@norkart/toi-icons';
import ToiFormLabel from '../ToiFormLabel/ToiFormLabel';
import {ToiTablePaginationActions} from './ToiTablePaginationActions';

export type ToiTablePaginationProps = Omit<MuiTablePaginationProps, 'slots' | 'slotProps' | 'labelDisplayedRows'>;

export const ToiTablePagination = forwardRef<HTMLDivElement, ToiTablePaginationProps>((props, forwardedRef) => {
  return (
    <StyledTablePagination
      ref={forwardedRef}
      labelRowsPerPage={props.labelRowsPerPage ?? <ToiFormLabel>Rader per side</ToiFormLabel>}
      ActionsComponent={ToiTablePaginationActions}
      {...props}
      slotProps={{
        select: {
          IconComponent: ChevronDownIcon,
          MenuProps: {
            sx: [
              (theme) => ({
                '& .MuiTablePagination-menuItem': theme.typography.body3,
              }),
            ],
          },
        },
      }}
    />
  );
}) as typeof TablePagination;

export default ToiTablePagination;

const StyledTablePagination = styled(TablePagination)(({theme}) => ({
  display: 'flex',

  '& .MuiTablePagination-menuItem': theme.typography.body3,

  '& .MuiTablePagination-displayedRows': {
    display: 'none',
  },

  '& .MuiTablePagination-input': {
    outline: '1px solid',
    outlineColor: theme.vars.toi.palette.border.input,
    background: theme.vars.toi.palette.backgrounds.component.fill,
    borderRadius: '4px',
    height: 38,
    minWidth: 75,
    alignItems: 'center',
    marginLeft: theme.toi.spacing.s,
    marginRight: theme.toi.spacing.l,

    '&> .MuiTablePagination-select': {
      textAlignLast: 'left',
    },

    '&> svg': {
      top: 'auto',
      bottom: 'auto',
      right: theme.toi.spacing.xs,
    },

    '&:hover': {
      outline: '2px solid',
      outlineColor: theme.toi.palette.border.inputHover,
    },

    '&.Mui-focused': {
      outline: '2px solid',
      outlineColor: theme.toi.palette.border.active,
    },

    '& .MuiTablePagination-select:focus': {
      background: 'none',
    },
  },
}));
