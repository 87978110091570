import {styled} from '@mui/material';
import ToiCard, {ToiCardProps} from '../ToiCard';

export const StyledNavCard = styled(ToiCard)<ToiCardProps>(({theme}) => {
  const palette = theme.vars.toi.palette;
  const spacing = theme.toi.spacing;

  return {
    boxShadow: 'none',
    maxWidth: theme.toi.textMaxWidth,
    backgroundColor: palette.backgrounds.component.fill,

    '& .MuiCardActionArea-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',

      '&.ToiNavigationCard-small': {
        paddingBlock: spacing.m,
      },

      '&.ToiNavigationCard-medium': {
        paddingBlock: spacing.l,
      },

      '&.ToiNavigationCard-large': {
        paddingBottom: spacing.l,
        justifyContent: 'flex-start',
      },

      '&:hover, &.MuiCardActionArea-focusHighlight': {
        backgroundColor: palette.backgrounds.component.fill,
      },
    },

    '& .MuiCardMedia-root': {
      margin: 0,
      marginBottom: spacing.s,
    },

    '& .MuiCardMedia-root.ToiNavigationCard-small': {
      marginBottom: spacing.xs,
    },

    '&:hover': {
      outline: `2px solid ${palette.border.active}`,
    },

    '&:focus-within': theme.mixins.focus,

    '&.Mui-focusVisible': {
      boxShadow: `inset 0px 0px 0px 2px ${palette.border.active}`,
    },
  };
});
