import React, {Component} from 'react';
import {ArrowLeftIcon, RoadHorizontalIcon} from '@norkart/toi-icons';

class HitElement extends Component<any, any> {
    private myRef = React.createRef<any>();
    state = {
        allowScroll: true,
    };

    render() {
        let className = 'list-group-item';
        if (this.props.hover && !this.props.selected) {
            className += ' hover';
            if (this.myRef.current) {
                if (this.state.allowScroll) {
                    this.myRef.current.parentNode.scrollTop =
                        this.myRef.current.offsetTop -
                        this.myRef.current.parentNode.offsetTop;
                }
            }
        }
        if (this.props.selected) {
            className += ' active';
        }
        if (this.props.disabled) {
            className += ' disabled';
        }
        return (
            <button
                type='button'
                value={this.props.index}
                tabIndex={-1}
                ref={this.myRef}
                className={className}
                onMouseEnter={(e) => {
                    this.setState({allowScroll: false});
                    this.props.onMouseEnter(this.props.index);
                }}
                onMouseLeave={() => {
                    this.setState({allowScroll: true});
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.hitSelected(this.props.index);
                }}
            >
                <div className='wrapper'>
                    {this.props.enableBackButton && (
                        <div className='backButtonIcon'>
                            <ArrowLeftIcon />
                        </div>
                    )}
                    <p>{this.props.text}</p>
                    {this.props.enableStreetButton && (
                        <div className='streetButtonIcon'>
                            <RoadHorizontalIcon />
                        </div>
                    )}
                </div>
            </button>
        );
    }
}
export default HitElement;
