import {ListItemNode, insertList, removeList} from '@lexical/list';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
    $getSelection,
    $isRangeSelection,
    FORMAT_TEXT_COMMAND,
    LexicalNode,
    REDO_COMMAND,
    RangeSelection,
    UNDO_COMMAND,
} from 'lexical';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import AddLinkIcon from '@mui/icons-material/AddLink';
import React, {useState} from 'react';
import {LinkNode} from '@lexical/link';
import {ToiBox, ToiIconButton} from '@norkart/toi-components';
import {LinkPopper} from './LinkPopper';

const icons: Record<string, JSX.Element> = {
    bold: <FormatBoldIcon />,
    italic: <FormatItalicIcon />,
    underline: <FormatUnderlinedIcon />,
    list: <FormatListBulletedIcon />,
    link: <AddLinkIcon />,
    undo: <UndoIcon />,
    redo: <RedoIcon />,
};

type EditorToolbarProps = {
    toolbarOptions: string[];
};

export default function EditorToolbar({toolbarOptions}: EditorToolbarProps) {
    const [editor] = useLexicalComposerContext();
    const [linkPopperState, setLinkPopperState] = useState<boolean>(false);
    const [popperAnchorEl, setPopperAnchorEl] =
        useState<HTMLButtonElement | null>(null);
    const [urlTitle, setUrlTitle] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [editorSelection, setEditorSelection] = useState<RangeSelection>();

    const handleUrlTextChange = (newUrl: string, newUrlTitle: string) => {
        setUrlTitle(newUrlTitle);
    };

    const handleUrlPopperClose = () => {
        setLinkPopperState(false);
    };

    function isNodeOrParentOfType<T extends LexicalNode>(
        node: LexicalNode,
        type: new (...args: any[]) => T
    ): boolean {
        let currentNode: LexicalNode | null = node;
        while (currentNode !== null) {
            if (currentNode instanceof type) {
                return true;
            }
            currentNode = currentNode.getParent();
        }
        return false;
    }

    const onClick = (
        option: string,
        event?: React.MouseEvent<HTMLButtonElement>
    ) => {
        editor.update(() => {
            const action = toolbarActions[option];
            if (action) {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    action(selection, event);
                }
            }
        });
    };

    const toolbarActions: Record<
        string,
        (
            selection: RangeSelection,
            event?: React.MouseEvent<HTMLButtonElement>
        ) => void
    > = {
        bold: (selection) =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold'),
        italic: (selection) =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic'),
        undo: (selection) => editor.dispatchCommand(UNDO_COMMAND, undefined),
        redo: (selection) => editor.dispatchCommand(REDO_COMMAND, undefined),
        list: (selection) => {
            const hasListItemNode = selection
                .getNodes()
                .some((node) => isNodeOrParentOfType(node, ListItemNode));
            if (hasListItemNode) {
                removeList(editor);
            } else {
                insertList(editor, 'bullet');
            }
        },
        link: (selection, event) => {
            if (event) {
                if (linkPopperState) {
                    setLinkPopperState(false);
                } else {
                    setLinkPopperState(true);
                    const node = selection?.getNodes()[0].getParent();
                    if (node instanceof LinkNode) {
                        setUrl(node?.__url);
                        setUrlTitle(
                            node?.getParent()?.getTextContent()
                                ? selection?.getNodes()[0].getTextContent()
                                : ''
                        );
                    } else {
                        setUrl('');
                        setUrlTitle(selection.getTextContent());
                    }
                    setEditorSelection(selection);
                    setPopperAnchorEl(event.currentTarget);
                }
            }
        },
    };

    return (
        <ToiBox
            sx={{
                display: 'flex',
                gap: 1,
                px: 1,
                background: '#f5f5f5',
                border: '1px solid #e0e0e0',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                borderRadius: '4px 4px 0 0',
            }}
        >
            {toolbarOptions.map((option) => (
                <ToiIconButton
                    color='transparent'
                    aria-label={option}
                    key={option}
                    onClick={(event) => onClick(option, event)}
                >
                    {icons[option]}
                </ToiIconButton>
            ))}
            <LinkPopper
                anchorEl={popperAnchorEl}
                open={linkPopperState}
                initialUrlTitle={urlTitle}
                initialUrl={url}
                onPopperTextChange={handleUrlTextChange}
                onUrlPopperClose={handleUrlPopperClose}
                editorSelection={editorSelection ? editorSelection : undefined}
            />
        </ToiBox>
    );
}
