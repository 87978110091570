import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {PlanpratMetadata, PlanpratPlan} from '../components/Chat/types';

export const formatArealplan = (
    plan: ArealplanDto,
    metadata: PlanpratMetadata
) => {
    const selectedEgenskaper = metadata.egenskaperFraDelareal.filter(
        (e) => e.selected
    );
    const selectedEgenskaperFromDelareal = selectedEgenskaper.map(
        (e) => e.egenskaper
    );
    const metadataString = JSON.stringify(selectedEgenskaperFromDelareal);
    const newPlan = {
        plan_id: plan.planId,
        metadata: metadataString,
        knr: plan.komnr?.toString(),
        plantype: plan.planType,
    };
    return newPlan as PlanpratPlan;
};

export const formatMultiplePlans = (
    plans: ArealplanDto[],
    metadatas: PlanpratMetadata[]
) => {
    const newPlans: PlanpratPlan[] = [];
    plans.forEach((p) => {
        const correspondingMetadata = metadatas.find(
            (m) => m.planId === p.planId
        );
        if (correspondingMetadata?.selected) {
            const newPlan = formatArealplan(p, correspondingMetadata);
            newPlans.push(newPlan);
        }
    });

    return newPlans;
};
