import {Radio} from '@mui/material';
import {styled} from '@mui/material/styles';

export const StyledRadio = styled(Radio)(({theme}) => {
  const palette = theme.vars.toi.palette;

  return {
    height: theme.toi.iconSize,
    width: theme.toi.iconSize,
    padding: 0,

    '& svg': {
      stroke: palette.border.input,
      fill: palette.backgrounds.component.fill,
    },

    '&:hover': {
      color: palette.border.inputHover,
      stroke: palette.border.inputHover,
      strokeWidth: 2,
    },

    '&.Mui-checked': {
      '& svg': {
        stroke: palette.backgrounds.component.active,
      },

      '& path:last-child': {
        fill: palette.backgrounds.component.active,
      },

      '&:hover svg': {
        stroke: palette.backgrounds.component.activeHover,
        strokeWidth: 1,
        '& path:last-child': {
          fill: palette.backgrounds.component.activeHover,
        },
      },
    },

    '&.Mui-focusVisible': {
      outline: '1px',
      outlineStyle: 'dashed',
      outlineColor: palette.focus.border,
    },

    '&.Mui-disabled': {
      '& svg': {
        stroke: palette.disabled.fill,
      },

      '&.Mui-checked': {
        '& path:last-child': {
          fill: palette.disabled.fill,
        },
      },
    },
  };
});
