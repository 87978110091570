import {
    ArealplanDto,
    PlanstatusVerdiDto,
    VertikalnivaVerdiDto,
} from '@norkart/nkapi-arealplaner-api';
import useMeta from '../../../hooks/meta/useMeta';

export default function useBehandlingFormHelpers() {
    const {
        data: {behandlingstyper},
    } = useMeta();

    const getUnntak = (PlanProsessId): number[] => {
        for (let i = 0; i < behandlingstyper.length; i++) {
            const behandlingstype = behandlingstyper[i];
            if (behandlingstype.planProsessId == parseInt(PlanProsessId)) {
                return behandlingstype.unntak || [];
            }
        }

        return [];
    };

    const getBehandlingstypeId = (PlanProsessId): number => {
        for (let i = 0; i < behandlingstyper.length; i++) {
            const behandlingstype = behandlingstyper[i];
            if (behandlingstype.planProsessId == parseInt(PlanProsessId))
                return behandlingstype.behandlingstypeId ?? 0;
        }
        return 0;
    };

    const getBehandlingstype = (PlanProsessId): string => {
        for (let i = 0; i < behandlingstyper.length; i++) {
            const behandlingstype = behandlingstyper[i];
            if (behandlingstype.planProsessId == parseInt(PlanProsessId))
                return behandlingstype.beskrivelse ?? '';
        }

        return '';
    };

    const getVertikalniva = (
        VertikalnivaId,
        vertikalniva: VertikalnivaVerdiDto[]
    ): string => {
        for (let i = 0; i < vertikalniva.length; i++) {
            const vertniv = vertikalniva[i];
            if (vertniv.id == parseInt(VertikalnivaId))
                return vertniv.beskrivelse ?? '';
        }
        return '';
    };

    const getPlanstatus = (
        PlanStatusId,
        planstatuser: PlanstatusVerdiDto[]
    ): string => {
        for (let i = 0; i < planstatuser.length; i++) {
            const planstatus = planstatuser[i];
            if (planstatus.id == parseInt(PlanStatusId))
                return planstatus.beskrivelse ?? '';
        }
        return '';
    };

    const shouldHaveTwoDateInputs = (PlanProsessId): boolean => {
        return getUnntak(PlanProsessId).includes(1);
    };

    const kommuneplanIds = [20, 21, 22];
    const shouldHaveKlagefrist = (PlanProsessId, plan: ArealplanDto) => {
        return PlanProsessId == null
            ? false
            : getUnntak(PlanProsessId).includes(2) &&
                  !kommuneplanIds.includes(plan.planTypeId ?? 0);
    };

    const shouldHaveEndringstype = (PlanProsessId) =>
        PlanProsessId == null ? false : getUnntak(PlanProsessId).includes(3);

    const shouldHaveDifferentMyndighet = (PlanProsessId) =>
        PlanProsessId == null ? false : getUnntak(PlanProsessId).includes(4);

    const shouldHaveTilgang = (PlanProsessId) =>
        PlanProsessId == null ? false : getUnntak(PlanProsessId).includes(5);

    const shouldHaveCheckboxForKlage = (PlanProsessId) =>
        PlanProsessId == null ? false : getUnntak(PlanProsessId).includes(6);

    const shouldHaveMapPositionOption = (PlanProsessId) =>
        PlanProsessId == null ? false : getUnntak(PlanProsessId).includes(7);

    const shouldSetKlage = (PlanProsessId) =>
        getBehandlingstypeId(PlanProsessId) === 37;

    const shouldDisplayStatusChangeMessage = (PlanProsessId) =>
        [38, 20].includes(getBehandlingstypeId(PlanProsessId));

    const shouldUpdatePlanstatusTo6 = (PlanProsessId) =>
        getBehandlingstypeId(PlanProsessId) === 35;

    const shouldUpdatePlanstatusTo3 = (PlanProsessId) =>
        [4, 36].includes(getBehandlingstypeId(PlanProsessId));

    const shouldSetUbehandletInnsigelse = (PlanProsessId) =>
        getBehandlingstypeId(PlanProsessId) === 35;

    const shouldClearUbehandletInnsigelse = (PlanProsessId) =>
        [4, 20, 36].includes(getBehandlingstypeId(PlanProsessId));

    const shouldUpdateVertniv = (PlanProsessId) =>
        [4, 36].includes(getBehandlingstypeId(PlanProsessId));

    const shouldUpdateIkraft = (PlanProsessId) =>
        [4, 36].includes(getBehandlingstypeId(PlanProsessId));

    const shouldUncheckVismeldingOmKlagefrist = (
        PlanProsessId,
        plan: ArealplanDto
    ) =>
        [9].includes(getBehandlingstypeId(PlanProsessId)) &&
        !kommuneplanIds.includes(plan.planTypeId ?? 0);

    return {
        getUnntak,
        getBehandlingstypeId,
        getBehandlingstype,
        getVertikalniva,
        getPlanstatus,
        shouldHaveTwoDateInputs,
        shouldHaveKlagefrist,
        shouldHaveEndringstype,
        shouldHaveDifferentMyndighet,
        shouldHaveTilgang,
        shouldHaveCheckboxForKlage,
        shouldSetKlage,
        shouldDisplayStatusChangeMessage,
        shouldUpdatePlanstatusTo6,
        shouldUpdatePlanstatusTo3,
        shouldSetUbehandletInnsigelse,
        shouldClearUbehandletInnsigelse,
        shouldUpdateVertniv,
        shouldUpdateIkraft,
        shouldUncheckVismeldingOmKlagefrist,
        shouldHaveMapPositionOption,
    };
}
