import {ToiChip} from '@norkart/toi-components';
import {
    CheckSuccessIcon,
    CrossCloseIcon,
    HelpQuestionIcon,
    RotateIcon,
} from '@norkart/toi-icons';

export interface PlanStatusChipProps {
    planStatusId?: number | null;
    planStatus?: string | null;
}

const statusIcons = {
    success: CheckSuccessIcon,
    warning: RotateIcon,
    error: CrossCloseIcon,
    default: HelpQuestionIcon,
};

function PlanStatusChip({planStatusId, planStatus}: PlanStatusChipProps) {
    if (!planStatusId || !planStatus) return null;
    let iconStyle = 'default' as
        | 'default'
        | 'grey'
        | 'success'
        | 'warning'
        | 'error'
        | 'info'
        | undefined;
    if ([3].includes(planStatusId)) iconStyle = 'success';
    if ([0, 1, 2, 6].includes(planStatusId)) iconStyle = 'warning';
    if ([4, 5, 8, 9, 10].includes(planStatusId)) iconStyle = 'error';

    const Icon = statusIcons[iconStyle ?? 'default'];
    return (
        <ToiChip
            icon={<Icon size='small' />}
            label={planStatus}
            color={iconStyle}
            disableDot
        />
    );
}

export default PlanStatusChip;
