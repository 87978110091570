import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import {ToiButton} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import {DispensasjonDto} from '@norkart/nkapi-arealplaner-api';
import useDeleteDispensasjon from '../../../hooks/dispensasjoner/useDeleteDispensasjon';
import {ConnectedReduxProps} from '../../../store';
import {hideDialog, showDialog} from '../../../store/dialog/actions';

export interface DeleteDispDialogProps extends ConnectedReduxProps {
    disp: DispensasjonDto;
    plan: ArealplanDto;
    open: boolean;
    focusRow: (id: string) => void;
}

const DeleteDispDialog = ({
    disp,
    plan,
    focusRow,
    open,
    dispatch,
}: DeleteDispDialogProps) => {
    const {mutate: deleteDisp} = useDeleteDispensasjon();

    const handleClose = () => {
        dispatch(
            showDialog({
                dialogType: 'EDIT_DISP',
                dialogProps: {plan, disp, focusRow: focusRow},
            })
        );
    };

    const handleOk = () => {
        disp.id && deleteDisp(disp.id);
        hideDialog(dispatch);
    };
    return (
        <Dialog open={open} aria-labelledby='confirm-dialog-title'>
            <DialogTitle id='confirm-dialog-title'>
                Vil du virkelig slette dispensasjonen?
            </DialogTitle>
            <DialogActions>
                <ToiButton onClick={handleClose}>Nei</ToiButton>
                <ToiButton onClick={handleOk}>Ja</ToiButton>
            </DialogActions>
        </Dialog>
    );
};
export default DeleteDispDialog;
