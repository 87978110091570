import React, {useState} from 'react';
import {
    ToiBox,
    ToiIconButton,
    ToiTooltip,
    ToiTypography,
    ToiDialog,
    ToiDialogTitle,
    ToiDialogContent,
    ToiDialogActions,
    ToiButton,
    ToiTextField,
} from '@norkart/toi-components';
import {ThumbsDownIcon, ThumbsUpIcon} from '@norkart/toi-icons';
import {HandshakePictogram} from '@norkart/toi-pictograms';
import {useApplicationInsights} from '@norkart/core-applicationinsights';
import doubleThumb from '../assets/images/two-thumbs.svg';
import {Feedback} from '../types';

type FeedbackComponentProps = {
    onSubmitFeedback: (feedback: Feedback) => void;
};

const FeedbackComponent: React.FC<FeedbackComponentProps> = ({
    onSubmitFeedback,
}: FeedbackComponentProps) => {
    const [feedback, setFeedback] = useState<'bad' | 'good' | 'very good'>();
    const [openDialog, setOpenDialog] = useState(false);
    const [writtenFeedback, setWrittenFeedback] = useState('');
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const applicationinsights = useApplicationInsights();

    const handleFeedback = (value: 'bad' | 'good' | 'very good') => {
        setFeedback(value);
        setOpenDialog(true);
        applicationinsights?.trackEvent({
            name: `Feedback ${value} - first click`,
        });
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setWrittenFeedback('');
    };

    const handleSubmitFeedback = () => {
        feedback &&
            onSubmitFeedback({feedbackCategory: feedback, writtenFeedback});
        handleDialogClose();
        setConfirmationDialogOpen(true);
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const Thumbs = () => {
        const activeThumb = feedback;
        return (
            <ToiBox display={'flex'} alignItems={'center'} gap={1}>
                <ToiTooltip title='Dårlig'>
                    <ToiIconButton
                        color={
                            activeThumb === 'bad' ? 'default' : 'transparent'
                        }
                        aria-label='bad'
                        onClick={() => handleFeedback('bad')}
                        size='small'
                    >
                        <ThumbsDownIcon size={'small'} />
                    </ToiIconButton>
                </ToiTooltip>
                <ToiTooltip title='Bra'>
                    <ToiIconButton
                        color={
                            activeThumb === 'good' ? 'default' : 'transparent'
                        }
                        size='small'
                        aria-label='good'
                        onClick={() => handleFeedback('good')}
                    >
                        <ThumbsUpIcon invertedColor='white' size={'small'} />
                    </ToiIconButton>
                </ToiTooltip>
                <ToiTooltip title='Veldig bra'>
                    <ToiIconButton
                        color={
                            activeThumb === 'very good'
                                ? 'default'
                                : 'transparent'
                        }
                        size='medium'
                        aria-label='very good'
                        onClick={() => handleFeedback('very good')}
                    >
                        <img
                            alt={'To tomler'}
                            src={doubleThumb}
                            style={{width: 'inherit'}}
                        />
                    </ToiIconButton>
                </ToiTooltip>
            </ToiBox>
        );
    };

    return (
        <ToiBox display={'flex'} gap={1} alignItems={'center'}>
            <ToiTypography variant='body3'>
                Hva synes du om svaret?
            </ToiTypography>
            <Thumbs />
            <ToiDialog open={openDialog} onClose={handleDialogClose}>
                <ToiDialogTitle>Gi oss tilbakemelding</ToiDialogTitle>
                <ToiDialogContent>
                    <ToiTypography sx={{mt: 3}} variant='body2'>
                        Hva synes du om svarene?
                    </ToiTypography>
                    <Thumbs />
                    <ToiTextField
                        sx={{marginTop: 3}}
                        label='Skriv din tilbakemelding'
                        placeholder='Valgfritt felt for å beskrive hva du synes om svarene'
                        value={writtenFeedback}
                        onChange={(e) => setWrittenFeedback(e.target.value)}
                        fullWidth
                        multiline
                        rows={6}
                    />
                </ToiDialogContent>
                <ToiDialogActions>
                    <ToiButton variant='secondary' onClick={handleDialogClose}>
                        Avbryt
                    </ToiButton>
                    <ToiButton onClick={handleSubmitFeedback}>Send</ToiButton>
                </ToiDialogActions>
            </ToiDialog>

            <ToiDialog
                open={confirmationDialogOpen}
                onClose={handleConfirmationDialogClose}
            >
                <ToiBox mb={2} display={'flex'} justifyContent={'center'}>
                    <HandshakePictogram />
                </ToiBox>
                <ToiDialogTitle>Takk for tilbakemeldingen!</ToiDialogTitle>
                <ToiDialogContent>
                    <ToiTypography variant='body2'>
                        Nå kan planprat bli enda smartere!
                    </ToiTypography>
                </ToiDialogContent>
                <ToiDialogActions>
                    <ToiButton
                        sx={{mt: 4}}
                        variant='secondary'
                        onClick={handleConfirmationDialogClose}
                    >
                        Lukk
                    </ToiButton>
                </ToiDialogActions>
            </ToiDialog>
        </ToiBox>
    );
};

export default FeedbackComponent;
