import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const PolylineIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M5 18.5C5 19.3284 4.32843 20 3.5 20C2.67157 20 2 19.3284 2 18.5C2 17.6716 2.67157 17 3.5 17C3.86937 17 4.20755 17.1335 4.46893 17.3549M5 18.5C5 18.0409 4.79378 17.63 4.46893 17.3549M5 18.5H19M19 18.5C19 19.3284 19.6716 20 20.5 20C21.3284 20 22 19.3284 22 18.5C22 17.6716 21.3284 17 20.5 17C19.6716 17 19 17.6716 19 18.5ZM13.5311 6.6451C13.7924 6.86649 14.1306 7 14.5 7C15.3284 7 16 6.32843 16 5.5C16 4.67157 15.3284 4 14.5 4C13.6716 4 13 4.67157 13 5.5C13 5.95906 13.2062 6.36995 13.5311 6.6451ZM13.5311 6.6451L4.46893 17.3549'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
    />
  </ToiIcon>
));

export default PolylineIcon;
