import React from 'react';
import {Field} from 'react-final-form';
import {
    ToiCheckboxOption,
    ToiFormControl,
    ToiFormGroup,
    ToiFormLabel,
    ToiStack,
} from '@norkart/toi-components';
import useMeta from '../../../../hooks/meta/useMeta';
import {isVertNivaRequired, isVertNivaEnabled} from '../helpers';
import {ArealplanFormValues} from '../PlanForm';
import Error from '../../../../components/FormError/Error';
import Warning from './Warning';

export default function Vertniv({values}: {values: ArealplanFormValues}) {
    const meta = useMeta();
    const disabled = !isVertNivaEnabled(values.planTypeId, values.planStatusId);
    const optional = !isVertNivaRequired(
        values.planTypeId,
        values.planStatusId
    );
    return (
        <Field name='vertikalniva'>
            {({input, meta: {error, submitError, touched, ...rest}}) => (
                <ToiStack>
                    <ToiFormControl component='fieldset'>
                        <ToiFormLabel
                            component='legend'
                            optional={!disabled && optional}
                            disabled={disabled}
                        >
                            Vertikalnivå
                        </ToiFormLabel>
                        <ToiFormGroup>
                            {meta.data.vertikalniva.map((v) => (
                                <Field
                                    key={v.id}
                                    name={input.name}
                                    type='checkbox'
                                    value={v.id}
                                >
                                    {({input}) => (
                                        <ToiCheckboxOption
                                            key={v.id}
                                            label={v.beskrivelse}
                                            disabled={disabled}
                                            {...input}
                                        />
                                    )}
                                </Field>
                            ))}
                        </ToiFormGroup>
                    </ToiFormControl>
                    {values.vertikalniva &&
                        values.vertikalniva.length > 0 &&
                        disabled && (
                            <Warning message='Vertikalnivå vil bli slettet' />
                        )}

                    {(error ||
                        ((!values.vertikalniva ||
                            values.vertikalniva.length == 0) &&
                            !optional &&
                            submitError)) && (
                        <Error error={error || submitError} />
                    )}
                </ToiStack>
            )}
        </Field>
    );
}
