import ToiBadge from '../../../ToiBadge/ToiBadge';

export const ToiAccordionBadge = ({value}: {value: number | undefined}) => {
  return (
    <ToiBadge
      sx={{
        '& .MuiBadge-badge': {transform: 'unset', position: 'relative'},
      }}
      color='info'
      badgeContent={value}
    />
  );
};
