import React, {useState} from 'react';
import {Dialog, DialogTitle, DialogActions, Typography} from '@mui/material';
import {ToiButton} from '@norkart/toi-components';

type props = {
    /** Destination url */
    url: string;
    /** Text to display instead of url */
    linkText?: string;
};

function ExternalLink({url, linkText}: props) {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        window.open(url);
    };

    const getLinkText = () => {
        if (!url) {
            return 'Noe er galt! Url ikke definert';
        } else if (linkText) {
            return linkText;
        } else {
            return url;
        }
    };

    return (
        <>
            <ToiButton
                sx={{
                    alignSelf: 'center',
                    justifySelf: 'right',
                    justifyContent: 'center',
                }}
                onClick={() => setClicked(true)}
            >
                {getLinkText()}
            </ToiButton>

            <Dialog
                fullWidth={true}
                open={clicked}
                onClose={() => setClicked(false)}
            >
                <DialogTitle style={{textAlign: 'center'}}>
                    <Typography style={{fontSize: 22}}>
                        Du forlater nå arealplaner.no
                    </Typography>
                </DialogTitle>
                <DialogActions
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 20,
                    }}
                >
                    <ToiButton onClick={() => setClicked(false)}>
                        Avbryt
                    </ToiButton>
                    <ToiButton onClick={handleClick}>Gå videre</ToiButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ExternalLink;
