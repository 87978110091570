import {Link} from '@mui/material';
import {ColumnDef} from '@tanstack/react-table';
import * as React from 'react';
import {format} from 'date-fns';
import {ArealplanReport} from '../../../../hooks/arealplaner/types';

const baseUrl = window.location.origin;

export default function PlanColumns(kundeId: string, kommunenummer: string) {
    return React.useMemo<ColumnDef<ArealplanReport>[]>(
        () => [
            {
                id: 'planId',
                header: () => 'PlanId',
                cell: (r) => (
                    <Link
                        key={r.row.id + 'link'}
                        href={`${baseUrl}/${kundeId}/gi?funksjon=VisPlan&planidentifikasjon=${r.row.original?.planId}&kommunenummer=${kommunenummer}`}
                        target='_blank'
                        variant='body1'
                        underline='none'
                        fontSize='0.85rem'
                    >
                        {r.row.original?.planId ? r.row.original?.planId : null}
                    </Link>
                ),
                accessorFn: (r, i) => r.planId,
                enableSorting: true,
                size: 50,
            },
            {
                id: 'planNavn',
                cell: (r) => <>{r.row.original?.planNavn}</>,
                header: () => 'Plannavn',
                enableSorting: true,
                accessorFn: (r, i) => r.planNavn,
                size: 100,
            },
            {
                id: 'planStatus',
                cell: (r) => <>{r.row.original?.planStatus}</>,
                header: () => 'Planstatus',
                enableSorting: true,
                accessorFn: (r, i) => r.planStatus,
                size: 50,
            },
            {
                id: 'planType',
                cell: (r) => <>{r.row.original?.planType}</>,
                header: () => 'Plantype',
                enableSorting: true,
                accessorFn: (r, i) => r.planType,
                size: 50,
            },
            {
                id: 'iKraft',
                cell: (r) => (
                    <>
                        {r.row.original?.iKraft
                            ? format(
                                  new Date(r.row.original.iKraft),
                                  'dd.MM.yyyy'
                              )
                            : ''}
                    </>
                ),
                accessorFn: (r, i) => r.iKraft && new Date(r.iKraft),
                header: () => 'Ikrafttredelsesdato',
                enableSorting: true,
                sortingFn: 'datetime',
                size: 50,
            },
            {
                id: 'lovreferanse',
                cell: (r) => <>{r.row.original?.lovreferanse}</>,
                header: () => 'Lovreferanse',
                enableSorting: true,
                accessorFn: (r, i) => r.lovreferanse,
                size: 50,
            },
        ],
        []
    );
}
