import {DialogContent} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import * as React from 'react';
import {useQueryClient} from 'react-query';
import {connect} from 'react-redux';
import {ToiButton} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import {openSnackbarMessage} from '../../../components/SnackbarMessage';
import useArealplanDokumenter from '../../../hooks/dokumenter/useArealplanDokumenter';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import {deleteDokument} from '../../../services/api';
import {ApplicationState} from '../../../store/index';
import {
    emptyDocumentSelection,
    removeDocument,
    SelectedDocument,
} from '../../../store/selectedDocumentsTable/reducer';
import {
    documentHasBehandling,
    documentIsArvet,
} from '../../../utils/documentIsArvet';

export interface DeleteDocumentsDialogProps {
    open: boolean;
    onClose: () => void;
    selectedDocuments: SelectedDocument[];
    emptyDocumentSelection: () => void;
    removeDocumentFromSelected: (docId: number) => void;
    plan: ArealplanDto;
}

function DeleteDocumentsDialog({
    open,
    onClose,
    emptyDocumentSelection,
    plan,
    selectedDocuments,
    removeDocumentFromSelected,
}: DeleteDocumentsDialogProps) {
    const {data: dokumenter} = useArealplanDokumenter();
    const [deleteDocumentsPending, setDeleteDocumentsPending] =
        React.useState(false);
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();

    const deleteSelectedDocuments = () => {
        setDeleteDocumentsPending(true);
        asyncForEach(documentsToBeDeleted, deleteDocument, () => {
            setDeleteDocumentsPending(false);
            onClose();
            queryClient.invalidateQueries([
                'dokumenter',
                {kundeId, arealplanId: plan.id},
            ]);
            queryClient.invalidateQueries([
                'behandlinger',
                {kundeId, arealplanId: plan.id},
            ]);
            // Hvis dokument er relatert
            queryClient.invalidateQueries([
                'arealplan',
                {kundeId, arealplanId: plan.id},
            ]);
        });
    };

    const deleteDocument = async (doc: SelectedDocument) => {
        if (!doc.dokumentId) return;
        try {
            const res = await deleteDokument(doc.dokumentId);
            removeDocumentFromSelected(doc.dokumentId);
            return true;
        } catch (err) {
            openSnackbarMessage({
                variant: 'error',
                message: 'Kunne ikke slette ett eller flere dokument',
            });
            onClose();
            setDeleteDocumentsPending(false);
            return false;
        }
    };

    async function asyncForEach(array: any[], callback, onSuccess) {
        let successfulRequest;
        for (let i = 0; i < array.length; i++) {
            successfulRequest = await callback(array[i]);
            if (!successfulRequest) {
                break;
            }
        }
        if (successfulRequest) {
            onSuccess();
        }
    }

    const documentsToBeDeleted = selectedDocuments.filter(
        (doc) =>
            doc.dokumentId &&
            !documentIsArvet(doc.dokumentId, dokumenter) &&
            !documentHasBehandling(doc.dokumentId, dokumenter)
    );
    const arvetDocuments = selectedDocuments.filter(
        (doc) => doc.dokumentId && documentIsArvet(doc.dokumentId, dokumenter)
    );
    const documentsWithBehandling = selectedDocuments.filter(
        (doc) =>
            doc.dokumentId && documentHasBehandling(doc.dokumentId, dokumenter)
    );
    const documentsNotToDelete = arvetDocuments.concat(documentsWithBehandling);

    return (
        <Dialog open={open} fullWidth={true}>
            {deleteDocumentsPending && (
                <DialogContent>
                    <LoadingSpinner
                        text={`${
                            documentsToBeDeleted.length === 1
                                ? 'Sletter dokument'
                                : 'Sletter valgte dokumenter'
                        }`}
                    />
                </DialogContent>
            )}
            {!deleteDocumentsPending && (
                <DialogContent>
                    <p>
                        {documentsNotToDelete.length &&
                        !documentsToBeDeleted.length ? (
                            <p>{`${
                                documentsNotToDelete.length === 1
                                    ? 'Dokumentet'
                                    : 'Dokumentene'
                            } kan ikke slettes fordi ${
                                documentsNotToDelete.length === 1
                                    ? 'det er arvet fra en annen plan, eller allerede er knyttet til en behandling'
                                    : 'de er arvet fra en annen plan, eller allerede er knyttet til en behandling'
                            }.`}</p>
                        ) : (
                            ''
                        )}
                        {documentsNotToDelete.length &&
                        documentsToBeDeleted.length ? (
                            <div>
                                <p>{`${documentsNotToDelete.length} ${
                                    documentsNotToDelete.length === 1
                                        ? 'dokument kan ikke slettes fordi det er arvet fra en annen plan, eller allerede er knyttet til en behandling'
                                        : 'dokumenter kan ikke slettes fordi de er arvet fra en annen plan, eller allerede er knyttet til en behandling'
                                }: `}</p>
                                <ul>
                                    {documentsNotToDelete.map((doc) => (
                                        <li key={doc.dokumentId}>
                                            {doc.filename}
                                        </li>
                                    ))}
                                </ul>
                                <p>{`${documentsToBeDeleted.length} kan slettes:`}</p>
                                <ul>
                                    {documentsToBeDeleted.map((doc) => (
                                        <li key={doc.dokumentId}>
                                            {doc.filename}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            ''
                        )}

                        {documentsToBeDeleted.length
                            ? `${
                                  documentsToBeDeleted.length > 1
                                      ? 'Dokumentene'
                                      : 'Dokumentet'
                              } vil slettes helt fra planregisteret, og du kan ikke angre. Er du sikker på at du vil slette ${
                                  documentsToBeDeleted.length > 0 &&
                                  documentsToBeDeleted.length
                              } ${
                                  documentsToBeDeleted.length > 1
                                      ? 'dokumenter'
                                      : 'dokument'
                              }?`
                            : ''}
                    </p>
                    <DialogActions>
                        {documentsToBeDeleted.length > 0 ? (
                            <>
                                <ToiButton
                                    onClick={() => onClose()}
                                    variant='secondary'
                                >
                                    Nei
                                </ToiButton>
                                <ToiButton onClick={deleteSelectedDocuments}>
                                    Ja
                                </ToiButton>
                            </>
                        ) : (
                            <ToiButton
                                onClick={() => onClose()}
                                variant='secondary'
                            >
                                Avbryt
                            </ToiButton>
                        )}
                    </DialogActions>
                </DialogContent>
            )}
        </Dialog>
    );
}

export default connect(
    (state: ApplicationState, ownProps: any) => ({
        selectedDocuments: state.tableSelection.selectedDocuments,
    }),
    (dispatch) => ({
        emptyDocumentSelection: () => {
            dispatch(emptyDocumentSelection());
        },
        removeDocumentFromSelected: (docId: number) => {
            dispatch(removeDocument(docId));
        },
    })
)(DeleteDocumentsDialog);
