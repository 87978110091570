import {Components, ExtraProps} from 'react-markdown';
import React from 'react';
import {TableCell, TableHead, TableRow, Theme, Table, TableBody, TableFooter} from '@mui/material';
import {NkSyntaxHighlighter} from '@norkart/syntax-highlighter';
import {ToiTypography} from '../ToiTypography';
import {ToiLink} from '../ToiLink';
import {ToiDivider, ToiDividerProps} from '../ToiDivider';
import {ToiBox, ToiBoxProps} from '../pureMuiComponents';
import {ToiList, ToiListItem} from '../ToiList';

const listItemStyle = (theme: Theme) => ({
  ...theme.typography.body2,
  display: 'list-item',
  listStyleType: 'disc',
  marginLeft: '28px',
  paddingRight: '52px',
  paddingBlock: 0,
  '&:not(:last-child)': {
    marginBottom: '5px',
  },
});

export const components: Components = {
  h1: ({children}) => (
    <ToiTypography variant='h1' marginBottom={(theme) => theme.toi.spacing.m}>
      {children}
    </ToiTypography>
  ),
  h2: ({children}) => (
    <ToiTypography variant='h2' marginBottom={(theme) => theme.toi.spacing.s}>
      {children}
    </ToiTypography>
  ),
  h3: ({children}) => (
    <ToiTypography variant='h3' marginBottom={(theme) => theme.toi.spacing.s}>
      {children}
    </ToiTypography>
  ),
  h4: ({children}) => (
    <ToiTypography variant='h4' marginBottom={(theme) => theme.toi.spacing.s}>
      {children}
    </ToiTypography>
  ),
  h5: ({children}) => (
    <ToiTypography variant='subtitle1' component='h5' marginBottom='1em'>
      {children}
    </ToiTypography>
  ),
  h6: ({children}) => (
    <ToiTypography variant='subtitle2' component='h6' marginBottom='1em'>
      {children}
    </ToiTypography>
  ),
  i: ({children}) => <ToiTypography fontStyle='italic'>{children}</ToiTypography>,
  b: ({children}) => <ToiTypography fontWeight='bold'>{children}</ToiTypography>,
  p: ({children}) => <ToiTypography variant='body2'>{children}</ToiTypography>,
  label: ({children}) => <ToiTypography variant='label'>{children}</ToiTypography>,
  a: ({href, children}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <ToiLink inline href={href}>
      {children}
    </ToiLink>
  ),
  hr: (props: Omit<ToiDividerProps, 'ref'>) => <ToiDivider {...props} />,
  div: (props: Omit<ToiBoxProps, 'ref'>) => <ToiBox {...props} />,
  span: (props: Omit<ToiBoxProps, 'ref' | 'component'>) => <ToiBox component='span' {...props} />,
  pre: ({children, node}: React.HTMLAttributes<HTMLPreElement> & ExtraProps) => {
    const firstChild = node?.children?.[0] as any;

    if (firstChild && firstChild.tagName === 'code') {
      const className =
        Array.isArray(firstChild.properties?.className) && firstChild.properties.className.length
          ? firstChild.properties.className[0]
          : undefined;

      const codeValue =
        Array.isArray(firstChild.children) && firstChild.children.length ? firstChild.children[0]?.value : undefined;

      if (typeof className === 'string' && typeof codeValue === 'string') {
        return <NkSyntaxHighlighter language={className}>{codeValue}</NkSyntaxHighlighter>;
      }
    }

    return <pre>{children}</pre>;
  },
  ul: ({children}) => <ToiList>{children}</ToiList>,
  ol: ({children}) => (
    <ToiList
      component='ol'
      sx={(theme) => ({
        '& .MuiListItem-root': {
          fontSize: theme.typography.body2.fontSize,
          listStyle: 'decimal',
        },
      })}
    >
      {children}
    </ToiList>
  ),
  li: ({children}) => <ToiListItem sx={listItemStyle}>{children}</ToiListItem>,
  table: ({children}) => <Table>{children}</Table>,
  thead: ({children}) => <TableHead>{children}</TableHead>,
  tbody: ({children}) => <TableBody>{children}</TableBody>,
  tfoot: ({children}) => <TableFooter>{children}</TableFooter>,
  tr: ({children}) => <TableRow>{children}</TableRow>,
  td: ({children}) => <TableCell>{children}</TableCell>,
};
