import React, {useCallback, useEffect, useState} from 'react';
import {ToiBox} from '@norkart/toi-components';
import {PlusIcon} from '@norkart/toi-icons';
import {TokenDto, TokenType} from '@norkart/nkapi-arealplaner-api';
import ActionButton from '../../../components/ActionButtons/ActionButton';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useTokens from '../../../hooks/tokens/useTokens';
import TokenDialog from './TokenDialog';
import TokenShareDialog from './TokenShareDialog';
import TokensTable from './TokensTable';

type Props = {
    tokenType: TokenType;
    description: string | React.ReactNode;
};

function TokensTab({tokenType, description}: Props) {
    const tokens = useTokens(tokenType);
    const [tokenDialogOpen, setTokenDialogOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [selectedToken, setSelectedToken] = useState<TokenDto | undefined>(
        undefined
    );

    useEffect(() => {
        if (!tokenDialogOpen && !shareDialogOpen) setSelectedToken(undefined);
    }, [tokenDialogOpen, shareDialogOpen]);

    const handleShareDialogClose = useCallback(
        () => setShareDialogOpen(false),
        []
    );

    const handleShare = (token: TokenDto) => {
        setSelectedToken(token);
        setShareDialogOpen(true);
    };

    const handleTokenDialogClose = useCallback(
        () => setTokenDialogOpen(false),
        []
    );
    const handleEdit = (token: TokenDto) => {
        setSelectedToken(token);
        setTokenDialogOpen(true);
    };
    const handleAdd = () => {
        setSelectedToken(undefined);
        setTokenDialogOpen(true);
    };

    if (tokens.isLoading) return <LoadingSpinner />;

    return (
        <>
            {description}
            <ToiBox marginBottom={'10px'} marginTop={'10px'}>
                <ActionButton
                    title={
                        <>
                            <PlusIcon /> Ny tilgang
                        </>
                    }
                    label={'Ny tilgang'}
                    onClick={handleAdd}
                />
            </ToiBox>

            <TokensTable
                data={tokens.data}
                handleShare={handleShare}
                handleEdit={handleEdit}
            />
            <TokenDialog
                isOpen={tokenDialogOpen}
                token={selectedToken}
                tokenType={tokenType}
                handleClose={handleTokenDialogClose}
            />
            {selectedToken && (
                <TokenShareDialog
                    tokenType={tokenType}
                    token={selectedToken}
                    isOpen={shareDialogOpen}
                    handleClose={handleShareDialogClose}
                />
            )}
        </>
    );
}

export default TokensTab;
