import React from 'react';
import {ToiBox, ToiTab, ToiTabs, ToiTypography} from '@norkart/toi-components';
import {AccessRestrictionsForm} from './AccessRestrictionsForm';

type TabPanelProps = {
    children?: React.ReactNode;
    index: number;
    value: number;
};

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`journalposter-tabpanel-${index}`}
            aria-labelledby={`journalposter-tab-${index}`}
            {...other}
        >
            {value === index && <ToiBox sx={{p: 3}}>{children}</ToiBox>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `journalposter-tab-${index}`,
        'aria-controls': `journalposter-tabpanel-${index}`,
    };
}

type Props = {
    arkivId: number;
};
export const JournalposterSettings = ({arkivId}: Props) => {
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <ToiBox sx={{width: '100%'}}>
            <ToiTypography variant='h2'>Journalposter</ToiTypography>
            <ToiTypography>
                Hvem kan se ulike journalposttyper, journalpoststatuser og
                tilgangsrestriksjoner? Innstillingene som settes her vil påvirke
                hvilke journalposttyper og dokumenter som vises i fane for
                saksdokumenter på en plan, samt ved opplasting av dokumenter fra
                arkiv. Pass på å ikke sette <em>Alle</em> på noe som ikke er
                ment for offentligheten. Pass også på å ikke laste opp
                dokumenter som ikke er ment for offentligheten til
                arealplaner.no uten å sette tilgangsnivå i opplastingsprosessen.
                Dette følger ikke automatisk det som er satt i arkivet.
            </ToiTypography>
            <ToiBox sx={{borderBottom: 1, borderColor: 'divider'}}>
                <ToiTabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label='Journalposter tab'
                    indicatorColor='secondary'
                    textColor='primary'
                >
                    <ToiTab label='Journalposttyper' {...a11yProps(0)} />
                    <ToiTab label='Journalstatus' {...a11yProps(1)} />
                    <ToiTab label='Tilgangsrestriksjoner' {...a11yProps(2)} />
                </ToiTabs>
            </ToiBox>
            <TabPanel value={value} index={0}>
                <AccessRestrictionsForm
                    headerText='Journalposttype'
                    arkivId={arkivId}
                    kodelisteType='Journalposttyper'
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AccessRestrictionsForm
                    headerText='Journalstatus'
                    arkivId={arkivId}
                    kodelisteType='Journalstatuser'
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AccessRestrictionsForm
                    headerText='Tilgangsrestriksjoner'
                    arkivId={arkivId}
                    kodelisteType='Tilgangsrestriksjoner'
                />
            </TabPanel>
        </ToiBox>
    );
};
