import {
    ToiCard,
    ToiCardActionArea,
    ToiCardContent,
} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import PlanSummary from './PlanSummary';

type Props = {
    plan: ArealplanDto;
    customerId: string;
    handlePlanClick: (plan: ArealplanDto) => void;
    highlight?: boolean;
};

export default function PlanCard({
    plan,
    customerId,
    handlePlanClick,
    highlight,
}: Props) {
    return (
        <ToiCard
            onClick={() => handlePlanClick(plan)}
            key={plan.id}
            sx={(theme) => ({
                marginBottom: '1px',
                backgroundColor: highlight
                    ? '#f0f0f0'
                    : theme.palette.background.paper,
            })}
        >
            <ToiCardActionArea>
                <ToiCardContent
                    sx={(theme) => ({
                        maxWidth: '700px',
                        display: 'flex',
                        alignItems: 'center',
                    })}
                >
                    <PlanSummary plan={plan} />
                </ToiCardContent>
            </ToiCardActionArea>
        </ToiCard>
    );
}
