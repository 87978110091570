import {saveAs} from 'file-saver';
import JSZip from 'jszip';
import React from 'react';
import {useDispatch} from 'react-redux';
import {
    ContextMenuHorizontalIcon,
    DeleteRecycleIcon,
    DownloadIcon,
    ErrorWarningIcon,
    PlusIcon,
    TurnDirectionSortingIcon,
} from '@norkart/toi-icons';
import {
    ToiBox,
    ToiButton,
    ToiDialog,
    ToiIconButton,
    ToiListItemIcon,
    ToiListItemText,
    ToiMenu,
    ToiMenuItem,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useDokuments from '../../../hooks/dokumenter/useDokuments';
import useMeta from '../../../hooks/meta/useMeta';
import {showDialog} from '../../../store/dialog/actions';
import {SelectedDocument} from '../../../store/selectedDocumentsTable/reducer';
import useCan from '../../../utils/auth/useCan';
import DeleteDocumentsDialog from './DeleteDocumentsDialog';

type Props = {
    selectedDocuments: SelectedDocument[];
    plan: ArealplanDto;
    handleDocumentFromCatalog: () => void;
    handleDocumentFromArchive?: () => void;
    handleRearrangeDocuments?: () => void;
};

const TableMenu = ({
    selectedDocuments,
    plan,
    handleDocumentFromCatalog,
    handleDocumentFromArchive,
    handleRearrangeDocuments,
    ...props
}: Props) => {
    const meta = useMeta();
    const can = useCan();
    const dispatch = useDispatch();
    const {getDokumentUrl} = useDokuments();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [downloadError, setDownloadError] = React.useState<boolean>(false);
    const [deleteDialogVisible, setDeleteDialogVisible] =
        React.useState<boolean>(false);
    const [anchorElement, setAnchorElement] = React.useState<
        Element | undefined
    >(undefined);
    const isEdge = window.navigator.userAgent.indexOf('Edge') > -1;

    const createZipFile = (blobs: any[]) =>
        blobs.reduce((zip: any, blob: any) => {
            zip.file(blob.filename, blob.blob, {
                binary: true,
            });
            return zip;
        }, new JSZip());

    const downloadFile = async (documentId: number, filename: string) => {
        const url = await getDokumentUrl(documentId);
        if (url) {
            const response = await fetch(url);
            const blob = await response.blob();
            if (isEdge) {
                saveAs(blob, filename);
            } else {
                return {blob, filename};
            }
        }
    };

    const downloadSelectedDocuments = () => {
        setLoading(true);
        setDownloadError(false);
        const downloadFiles = async (
            docs: SelectedDocument[],
            filename: string
        ) => {
            try {
                const blobs = await Promise.all(
                    docs.map(
                        (doc) =>
                            doc.dokumentId &&
                            downloadFile(
                                doc.dokumentId,
                                doc.filepath ?? '' + doc.filename
                            )
                    )
                );
                if (!isEdge) {
                    const zipData = await createZipFile(blobs).generateAsync({
                        type: 'blob',
                    });
                    saveAs(zipData, filename);
                }
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                setDownloadError(true);
                setTimeout(function () {
                    setDownloadError(false);
                }, 5000);
            }
        };
        downloadFiles(
            selectedDocuments,
            'plan-' + plan.komnr + '_' + plan.planId + '-dokumenter'
        );
    };

    const moveSelectedDocuments = () => {
        //open move dialog
        dispatch(
            showDialog({
                dialogType: 'SELECT_BEHANDLING',
                dialogProps: {
                    selectedDocuments,
                    plan,
                },
            })
        );
    };

    const handleOnDelete = () => {
        //open delete dialog
        setDeleteDialogVisible(true);
    };

    const hasSelected = selectedDocuments.length > 0;
    const menuButtons = [
        {
            icon: <DownloadIcon />,
            title: 'Last ned valgte dokumenter',
            label: 'Last ned',
            onClick: downloadSelectedDocuments,
        },
    ];
    if (can.edit) {
        menuButtons.push({
            icon: <DeleteRecycleIcon />,
            title: 'Slett valgte dokumenter',
            label: 'Slett',
            onClick: handleOnDelete,
        });
        menuButtons.push({
            icon: <TurnDirectionSortingIcon />,
            title: 'Flytt valgte dokumenter',
            label: 'Flytt til',
            onClick: moveSelectedDocuments,
        });
    }

    const handleMenu = (event) => {
        setAnchorElement(event.currentTarget);
        // setOpenMenu(true)
    };
    const handleClose = () => setAnchorElement(undefined);

    return (
        <>
            <ToiStack
                direction='row'
                justifyContent={'space-between'}
                p={1}
                sx={(theme) => ({
                    backgroundColor: theme.toi.palette.backgrounds.generic.grey,
                    marginBottom: '20px',
                })}
            >
                <ToiStack
                    gap={1}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                >
                    {menuButtons.map((btn, index) => (
                        <ToiButton
                            key={index}
                            startIcon={btn.icon}
                            title={btn.title}
                            variant='secondary'
                            disabled={!hasSelected}
                            onClick={btn.onClick}
                        >
                            {btn.label}
                        </ToiButton>
                    ))}
                </ToiStack>

                {can.edit && (
                    <ToiIconButton
                        aria-label='Åpne meny'
                        onClick={handleMenu}
                        sx={{alignSelf: 'center', justifySelf: 'flex-end'}}
                    >
                        <ContextMenuHorizontalIcon />
                    </ToiIconButton>
                )}
            </ToiStack>

            <DeleteDocumentsDialog
                plan={plan}
                open={deleteDialogVisible}
                onClose={() => setDeleteDialogVisible(false)}
            />
            <ToiDialog open={loading} fullWidth={true}>
                <LoadingSpinner text={'Laster ned valgte dokumenter'} />
            </ToiDialog>
            <ToiDialog
                open={downloadError}
                fullWidth={true}
                onClose={() => setDownloadError(false)}
            >
                <ToiBox
                    sx={{
                        display: 'flex',
                        padding: '23px',
                    }}
                >
                    <ErrorWarningIcon />
                    <ToiTypography
                        sx={{
                            paddingLeft: '15px',
                            paddingTop: '2px',
                        }}
                    >
                        Nedlastningen feilet, prøv igjen.
                    </ToiTypography>
                </ToiBox>
            </ToiDialog>
            <ToiMenu
                id='add-document'
                anchorEl={anchorElement}
                open={Boolean(anchorElement)}
                onClose={() => handleClose()}
            >
                <ToiMenuItem
                    onClick={() => {
                        handleClose();
                        handleDocumentFromCatalog();
                    }}
                >
                    <ToiListItemIcon>
                        <PlusIcon />
                    </ToiListItemIcon>
                    <ToiListItemText>Legg til</ToiListItemText>
                </ToiMenuItem>
                {handleDocumentFromArchive && meta.hasArkivIntegrasjon() && (
                    <ToiMenuItem
                        onClick={() => {
                            handleClose();
                            handleDocumentFromArchive();
                        }}
                    >
                        <ToiListItemIcon>
                            <PlusIcon />
                        </ToiListItemIcon>
                        <ToiListItemText>Legg til fra arkiv</ToiListItemText>
                    </ToiMenuItem>
                )}
                {handleRearrangeDocuments && (
                    <ToiMenuItem
                        onClick={() => {
                            handleClose();
                            handleRearrangeDocuments();
                        }}
                    >
                        <ToiListItemIcon>
                            <TurnDirectionSortingIcon />{' '}
                        </ToiListItemIcon>
                        <ToiListItemText>Endre rekkefølge</ToiListItemText>
                    </ToiMenuItem>
                )}
            </ToiMenu>
        </>
    );
};

export default TableMenu;
