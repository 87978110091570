import {useEffect, useState} from 'react';
import {ToiBox, ToiIconButton, ToiList, ToiSpinner, ToiTab, ToiTabs} from '@norkart/toi-components';
import {PlusIcon} from '@norkart/toi-icons';
import Post from './Components/Post';
import PostDialog from './Components/PostDialog';
import authContext from './Hooks/authContext';
import {usePosts} from './Hooks/usePosts';
import {useTags} from './Hooks/useTags';
import {checkAccess} from './Requests/AccessRequests';
import postType from './Types/PostType';
import DeletePostDialog from './Components/DeletePostDialog';

type props = {
  /**name of the client accessing the releasenote service */
  clientId: string;
  /**the clients setup.norkart app profile */
  applicationProfile?: string;
  /**a jwt token identifying the current authorized user if any */
  bearerToken?: string;
};

export function PostFeed({clientId, applicationProfile, bearerToken}: props) {
  const tags = useTags();
  const {posts, removePost} = usePosts(clientId, applicationProfile, bearerToken);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentPost, setCurrentPost] = useState<postType>();
  const [category, setCategory] = useState(0);

  useEffect(() => {
    async function setAccess() {
      if (applicationProfile && bearerToken) {
        setIsAuthenticated(await checkAccess(clientId, applicationProfile, bearerToken));
      } else {
        setIsAuthenticated(false);
      }
    }

    setAccess();
  }, [clientId, applicationProfile, bearerToken, setIsAuthenticated]);

  function onEdit(post: postType) {
    setShowDialog(true);
    setCurrentPost(post);
  }

  function onDelete(post: postType) {
    setCurrentPost(post);
    setShowDeleteDialog(true);
  }

  const handleDelete = () => {
    if (currentPost) removePost.mutate(currentPost);
    setCurrentPost(undefined);
    setShowDeleteDialog(false);
  };

  function renderPosts() {
    return (
      <>
        {isAuthenticated ? (
          <ToiBox textAlign={'center'}>
            <ToiIconButton
              aria-label='Legg til'
              onClick={() => {
                setCurrentPost(undefined);
                setShowDialog(true);
              }}
            >
              <PlusIcon fontSize='large' />
            </ToiIconButton>
          </ToiBox>
        ) : null}
        {posts.isLoading ? (
          <ToiSpinner />
        ) : (
          posts.data.map((post) => {
            if (post.tagIds?.filter((tagId) => tagId === category).length > 0 || category === 0) {
              return (
                <Post key={post.id} post={post} onEdit={onEdit} onDelete={onDelete} isAuthenticated={isAuthenticated} />
              );
            } else {
              return null;
            }
          })
        )}
      </>
    );
  }

  return (
    <authContext.Provider value={{clientId, applicationProfile, bearerToken}}>
      <ToiBox>
        <PostDialog
          post={currentPost}
          open={showDialog}
          onClose={() => {
            setShowDialog(false);
            setCurrentPost(undefined);
          }}
        />
        <DeletePostDialog
          isOpen={showDeleteDialog}
          onClose={() => {
            setShowDeleteDialog(false);
            setCurrentPost(undefined);
          }}
          handleDelete={handleDelete}
        ></DeletePostDialog>

        <ToiTabs value={category} onChange={(event, value) => setCategory(value)}>
          <ToiTab label='Alle' value={0} />
          {tags.data.map((tag) => (
            <ToiTab key={tag.id} label={tag.name} value={tag.id} />
          ))}
        </ToiTabs>

        <ToiList sx={{display: 'flex', flexDirection: 'column'}}>{renderPosts()}</ToiList>
      </ToiBox>
    </authContext.Provider>
  );
}
