import {useParams} from 'react-router-dom';
import {ToiBox, ToiLink, ToiTypography} from '@norkart/toi-components';
import LoadingSpinner from '../../components/LoadingSpinner';
import useMeta from '../../hooks/meta/useMeta';
import Footer from '../footer';
import PredefinedFilters from '../NewFrontpage/PredefinedFilters';
import {usePropertySearch} from '../search/Search/filters/PropertySearchFilter/usePropertySearch';
import {useFilters} from '../search/Search/filters/useFilters';
import Search from '../search/Search/Search';
import SearchResult from '../search/SearchResult';
import ChangeToMapButton from './components/ChangeToMapButton';
import ChangeToMapCard from './components/ChangeToMapCard';
import Header from './header';

function Home() {
    const {customerId} = useParams();
    const meta = useMeta();
    const filters = useFilters();
    const propertyFilter = usePropertySearch();

    if (!meta.isFetched) {
        return (
            <ToiBox mt={4} mb={4}>
                <LoadingSpinner />
            </ToiBox>
        );
    }

    return (
        <>
            <ToiBox
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <ChangeToMapButton />
                <Header />
                <Search />
                <ToiBox>
                    {filters.activeFiltersFromUrl.length ||
                    propertyFilter.get() ? (
                        <SearchResult />
                    ) : (
                        <>
                            <PredefinedFilters />
                            <ToiBox
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: '20px',
                                    maxHeight: '230px',
                                }}
                            >
                                <ChangeToMapCard />
                                <ToiBox
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '10px',
                                        backgroundColor: '#F2F5F0',
                                        width: '512px',
                                        marginX: '10px',
                                    }}
                                >
                                    <ToiTypography
                                        variant='h3'
                                        sx={{marginBottom: 5}}
                                    >
                                        Andre nyttige sider
                                    </ToiTypography>
                                    <ToiLink href={`/${customerId}/faq`}>
                                        Hjelp
                                    </ToiLink>
                                    <ToiLink
                                        href={`/${customerId}/relevanteLenker`}
                                    >
                                        Eksterne sider
                                    </ToiLink>
                                </ToiBox>
                            </ToiBox>
                        </>
                    )}
                </ToiBox>
            </ToiBox>
            <Footer />
        </>
    );
}

export default Home;
