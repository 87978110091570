import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const MapIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M16.9423 5.23859C16.9423 5.23532 16.9421 5.23204 16.9416 5.2288C16.7217 3.7092 15.8765 2.46762 14.3861 1.60319C13.839 1.2849 13.2124 1.08597 12.5858 1.02629C10.8252 0.867149 9.38298 1.4341 8.30875 2.62769C7.3141 3.73175 6.8864 5.0248 7.02565 6.50684C7.08533 7.15336 7.25442 7.63079 7.3141 7.77999C7.50309 8.28727 7.75175 8.67518 7.95068 8.94374C8.90326 10.2115 9.85092 11.4842 10.8006 12.7549C11.399 13.5556 12.5982 13.5552 13.1977 12.7553L15.9576 9.07304C16.2361 8.72491 16.6539 8.07839 16.8727 7.17325C16.9323 6.92503 17.0811 6.19115 16.9431 5.24864C16.9426 5.24533 16.9423 5.24194 16.9423 5.23859V5.23859Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9987 7.6308C12.8941 7.6308 13.62 6.90492 13.62 6.00951C13.62 5.1141 12.8941 4.38822 11.9987 4.38822C11.1033 4.38822 10.3774 5.1141 10.3774 6.00951C10.3774 6.90492 11.1033 7.6308 11.9987 7.6308Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.5 11.5H5.36061M17.5 11.5L19.7449 11.5105C20.4713 11.5139 21.0908 12.0372 21.2156 12.7528L21.6337 15.1504M8.47 22.9862L5.36061 11.5M5.36061 11.5H4.26139C3.53224 11.5 2.9087 12.0243 2.78362 12.7427L1.63059 19.3648M1.63059 19.3648L1.30598 21.229C1.14623 22.1466 1.85244 22.9864 2.78375 22.9864H20.3638M1.63059 19.3648C5.75121 18.4976 9.87529 17.645 13.9964 16.7788M21.6337 15.1504L22.6935 21.2287C22.8535 22.1463 22.1473 22.9864 21.2158 22.9864H20.3638M21.6337 15.1504C19.0901 15.7028 16.5438 16.2434 13.9964 16.7788M20.3638 22.9864L13.9964 16.7788'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default MapIcon;
