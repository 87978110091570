import {useQuery} from 'react-query';
import {ArealplanDto, arealplanerGetById} from '@norkart/nkapi-arealplaner-api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useSelectedArealplan(id?: number) {
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const {client} = useArealplanerApiClientContext();

    const fetchArealplan = async ({id}) => {
        const {data, error} = await arealplanerGetById({
            path: {kundeId, arealplanId: id},
            query: {includePlanforhold: true},
            client: client,
        });
        if (error) throw new Error(String(error.message));
        return data ?? ({} as ArealplanDto);
    };

    const query = useQuery<ArealplanDto>(
        ['arealplan', {kundeId, arealplanId: id}],
        () => fetchArealplan({id}),
        {
            enabled: !!kundeId && !!id,
            onError: notify,
            keepPreviousData: !!id, // hvis vi går fra en planid til en annen så behold gammel data til ny data kommer inn
        }
    );
    return {...query, data: query?.data};
}
