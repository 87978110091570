import {Autocomplete} from '@mui/material';
import {format} from 'date-fns';
import * as React from 'react';
import {
    PlanstatusVerdiDto,
    PlantypeVerdiDto,
} from '@norkart/nkapi-arealplaner-api';
import {ToiStack, ToiTextField} from '@norkart/toi-components';
import DateRangePickerMui, {
    StartOrEndDate,
} from '../../../../components/DatePicker/DateRangePickerMui/DateRangePickerMui';
import {BehandlingReportFilters} from '../../../../hooks/behandlinger/useBehandlingerReport';
import {
    Behandlingstyper,
    useKodelisterBehandlingstyper,
} from '../../../../hooks/kodelister/useKodelisterBehandlingstyper';
import useMeta from '../../../../hooks/meta/useMeta';

type BehandlingerFilterProps = {
    filterValues?: BehandlingReportFilters;
    setFilterValues: React.Dispatch<BehandlingReportFilters>;
    clearFilter: boolean;
};

type SelectedFiltersProps = {
    behandlingstype?: Behandlingstyper[];
    planstatus?: PlanstatusVerdiDto[];
    plantype?: PlantypeVerdiDto[];
    behandlingBeskrivelse?: string;
    fraDatoStart?: Date;
    fraDatoEnd?: Date;
    tilDatoStart?: Date;
    tilDatoEnd?: Date;
};

export function BehandlingerFilter({
    filterValues,
    setFilterValues,
    clearFilter,
}: BehandlingerFilterProps) {
    const [selectedFilters, setSelectedFilters] = React.useState<
        SelectedFiltersProps | undefined
    >();

    const {
        data: {plantyper, planstatuser},
    } = useMeta();
    const behandlingstyper = useKodelisterBehandlingstyper();

    const handleDatoChange = (
        dates?: StartOrEndDate,
        fromOrTo?: 'from' | 'to'
    ) => {
        if (fromOrTo === 'from') {
            setSelectedFilters({
                ...selectedFilters,
                fraDatoEnd: dates?.end,
                fraDatoStart: dates?.start,
            });
        }
        if (fromOrTo === 'to') {
            setSelectedFilters({
                ...selectedFilters,
                tilDatoEnd: dates?.end,
                tilDatoStart: dates?.start,
            });
        }
    };

    //Cleare beskrivelse feltet
    const clearSearch = () => {
        setSelectedFilters({...selectedFilters, behandlingBeskrivelse: ''});
    };

    React.useEffect(() => {
        if (clearFilter) setSelectedFilters(undefined);
    }, [clearFilter]);

    const handleChange = (e, newValue) => {
        setSelectedFilters({
            ...selectedFilters,
            behandlingstype: newValue,
        });
    };

    React.useEffect(() => {
        const newFilterValues = {
            behandlingstypeId: selectedFilters?.behandlingstype?.length
                ? selectedFilters?.behandlingstype
                      ?.flatMap((b) => b.id)
                      .join(',')
                : null,
            plantypeId: selectedFilters?.plantype?.length
                ? selectedFilters?.plantype?.flatMap((b) => b.id).join(',')
                : null,
            planstatusId: selectedFilters?.planstatus?.length
                ? selectedFilters?.planstatus?.flatMap((b) => b.id).join(',')
                : null,
            beskrivelse: selectedFilters?.behandlingBeskrivelse,
            fraDatoStart:
                selectedFilters?.fraDatoStart &&
                format(selectedFilters?.fraDatoStart, 'yyyy-MM-dd'),
            fraDatoEnd:
                selectedFilters?.fraDatoEnd &&
                format(selectedFilters?.fraDatoEnd, 'yyyy-MM-dd'),
            tilDatoStart:
                selectedFilters?.tilDatoStart &&
                format(selectedFilters?.tilDatoStart, 'yyyy-MM-dd'),
            tilDatoEnd:
                selectedFilters?.tilDatoEnd &&
                format(selectedFilters?.tilDatoEnd, 'yyyy-MM-dd'),
        } as BehandlingReportFilters;
        setFilterValues(newFilterValues);
    }, [selectedFilters]);

    return (
        <ToiStack direction='row' gap={(theme) => theme.toi.spacing.m}>
            <ToiStack width={'100%'}>
                <Autocomplete
                    size='small'
                    options={behandlingstyper.data}
                    value={
                        selectedFilters?.behandlingstype
                            ? selectedFilters.behandlingstype
                            : []
                    }
                    fullWidth={true}
                    limitTags={1}
                    multiple={true}
                    getOptionLabel={(option) =>
                        `${option.id} - ${option?.beskrivelse}`
                    }
                    onChange={handleChange}
                    renderInput={(params) => (
                        <ToiTextField
                            {...params}
                            size='small'
                            label='Behandlingstype'
                            variant='outlined'
                        />
                    )}
                />
                <Autocomplete
                    size='small'
                    options={planstatuser ? planstatuser : []}
                    value={
                        selectedFilters?.planstatus
                            ? selectedFilters.planstatus
                            : []
                    }
                    limitTags={1}
                    fullWidth={true}
                    multiple={true}
                    getOptionLabel={(option) =>
                        `${option.id} - ${option?.beskrivelse}`
                    }
                    onChange={(event, newValue) =>
                        setSelectedFilters({
                            ...selectedFilters,
                            planstatus: newValue,
                        })
                    }
                    renderInput={(params) => (
                        <ToiTextField
                            {...params}
                            size='small'
                            label='Planstatus'
                            variant='outlined'
                        />
                    )}
                />
                <Autocomplete
                    size='small'
                    options={plantyper ? plantyper : []}
                    limitTags={1}
                    value={
                        selectedFilters?.plantype
                            ? selectedFilters.plantype
                            : []
                    }
                    multiple={true}
                    fullWidth={true}
                    getOptionLabel={(option) =>
                        `${option.id} - ${option?.beskrivelse}`
                    }
                    onChange={(event, newValue) =>
                        setSelectedFilters({
                            ...selectedFilters,
                            plantype: newValue,
                        })
                    }
                    renderInput={(params) => (
                        <ToiTextField
                            {...params}
                            size='small'
                            label='Plantype'
                            variant='outlined'
                        />
                    )}
                />
            </ToiStack>
            <ToiStack width={'100%'}>
                <ToiTextField
                    variant='outlined'
                    label='Beskrivelse'
                    fullWidth={true}
                    onChange={(e) =>
                        setSelectedFilters({
                            ...selectedFilters,
                            behandlingBeskrivelse: e.target.value,
                        })
                    }
                    onKeyUp={(e) => {
                        if (e.key === 'Escape')
                            setSelectedFilters({
                                ...selectedFilters,
                                behandlingBeskrivelse: '',
                            });
                    }}
                    value={
                        selectedFilters?.behandlingBeskrivelse
                            ? selectedFilters.behandlingBeskrivelse
                            : ''
                    }
                />
                <DateRangePickerMui
                    onChange={(d) => {
                        handleDatoChange(d, 'from');
                    }}
                    label='Fra dato'
                    startDate={selectedFilters?.fraDatoStart}
                    endDate={selectedFilters?.fraDatoEnd}
                />
                <DateRangePickerMui
                    onChange={(d) => {
                        handleDatoChange(d, 'to');
                    }}
                    label='Til dato'
                    startDate={selectedFilters?.tilDatoStart}
                    endDate={selectedFilters?.tilDatoEnd}
                />
            </ToiStack>
        </ToiStack>
    );
}

export default BehandlingerFilter;
