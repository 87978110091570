import {styled, Switch as MuiSwitch, SwitchProps as MuiSwitchProps} from '@mui/material';

export type ToiSwitchProps = MuiSwitchProps;

export const ToiSwitch = styled(MuiSwitch)(({theme}) => {
  const colors = theme.vars.toi.palette;

  return {
    width: 40,
    height: 24,
    padding: 0,
    margin: 0,
    overflow: 'visible',

    '& .MuiSwitch-track': {
      height: 'unset',
      borderRadius: 12,
      opacity: 1,
      border: `1px solid ${colors.border.input}`,
      backgroundColor: colors.backgrounds.component.fill,
      transitionDuration: '0.2s',
    },
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16,
      marginTop: 4,
      marginLeft: 4,
      borderRadius: 8,
      backgroundColor: colors.border.input,
      transition: 'background-color 200ms',
      boxShadow: 'none',
    },

    '& .MuiSwitch-switchBase': {
      padding: 0,

      // The Mui-checked, Mui-disabled and Mui-focusVisible class is applied to the switchBase element instead of the root element.
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        '&+.MuiSwitch-track': {
          opacity: 1,
          backgroundColor: colors.backgrounds.component.active,
          border: 'none',
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: 'white',
        },
      },

      '&.Mui-focusVisible': {
        '&+.MuiSwitch-track': {
          outline: `1px dashed ${colors.focus.border}`,
          outlineOffset: 2,
        },
      },

      '&.Mui-disabled': {
        '&+.MuiSwitch-track': {
          border: `1px solid ${colors.disabled.fill}`,
          opacity: 1,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: colors.disabled.fill,
        },
        '&.Mui-checked': {
          '&+.MuiSwitch-track': {
            backgroundColor: colors.disabled.fill,
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: 'white',
          },
        },
      },
    },

    '&:hover': {
      '& .MuiSwitch-track': {
        borderColor: colors.border.inputHover,
      },
      '& .Mui-checked+.MuiSwitch-track': {
        backgroundColor: colors.backgrounds.component.activeHover,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: colors.border.inputHover,
      },
    },
  };
});

export default ToiSwitch;
