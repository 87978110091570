import {Grid} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {orderBy} from 'lodash-es';
import {DispensasjonDto} from '@norkart/nkapi-arealplaner-api';
import {RoutePinsIcon} from '@norkart/toi-icons';
import DesktopContainer from '../../../containers/DesktopContainer';
import DisplayPosInMap from '../../map/DisplayPosInMap';
import ExpansionPanel from '../../../components/ExpansionPanel';
import HelpButton from '../../../components/HelpButton/HelpButton';
import HelpText from '../../../components/HelpText/HelpText';
import MobileContainer from '../../../containers/MobileContainer';
import {helpObject} from '../../../help/helpObject';
import useArealplan from '../../../hooks/arealplaner/useArealplan';
import useDispensasjoner from '../../../hooks/dispensasjoner/useDispensasjoner';
import {setMapMode} from '../../../store/map/actions';
import ActionButtons from '../../../components/ActionButtons';
import {ActionButtonProps} from '../../../components/ActionButtons/ActionButton';
import {DispensasjonTable} from './DispensasjonTable';
import DispensasjonListItem from './DispensasjonListItem';

const DispensasjonList = () => {
    const [expandedId, setExpandedId] = React.useState<number | undefined>();
    const [displayPosFor, setDisplayPosFor] =
        useState<DispensasjonDto | null>();

    const {data: plan} = useArealplan();
    const {data: dispensasjoner} = useDispensasjoner();

    const dispatch = useDispatch();

    const handleSetMapMode = (mode: boolean) => {
        dispatch(setMapMode(mode, true));
    };

    const onChange = (isExpanded: boolean, rowIndex?: number | null) => {
        if (isExpanded && rowIndex) {
            setExpandedId(rowIndex);
        } else if (expandedId && !isExpanded) {
            setExpandedId(undefined);
        }
    };

    const mobileActionButtons = [
        {
            title: (
                <>
                    <RoutePinsIcon /> Vis i kart
                </>
            ),
            label: 'Vis i kart',
            onClick: () => {
                handleSetMapMode(true);
            },
        },
    ] as ActionButtonProps[];

    return (
        <>
            {displayPosFor?.posisjon && (
                <DisplayPosInMap
                    title='Posisjonen til dispensasjonen'
                    plan={plan}
                    onCloseMap={() => setDisplayPosFor(null)}
                    position={displayPosFor.posisjon}
                    vertikalnivaId={displayPosFor.vertikalnivaId}
                />
            )}
            <MobileContainer>
                <ExpansionPanel
                    helpButtonText={
                        helpObject.Faner.Dispensasjoner.MeromDispensasjoner
                    }
                    title={`Dispensasjoner (${
                        dispensasjoner && dispensasjoner.length
                    })`}
                    padding='0'
                    margin='10px'
                >
                    <ActionButtons actionButtons={mobileActionButtons} />
                    {dispensasjoner &&
                        orderBy(dispensasjoner, 'vedtaksdato', ['desc']).map(
                            (dispensasjon) => {
                                return (
                                    <DispensasjonListItem
                                        dispensasjon={dispensasjon}
                                        key={dispensasjon.id}
                                        expandedOptions={{
                                            expandedId: expandedId,
                                            onChange: (isExpanded) =>
                                                onChange(
                                                    isExpanded,
                                                    dispensasjon.id
                                                ),
                                            allowMultipleExpanded: false,
                                            rowId: dispensasjon.id,
                                        }}
                                    />
                                );
                            }
                        )}
                </ExpansionPanel>
            </MobileContainer>
            <DesktopContainer>
                <Grid
                    container={true}
                    direction={'column'}
                    style={{display: 'flex', marginBottom: 40}}
                >
                    <HelpText
                        style={{marginRight: 50}}
                        helpText={helpObject.Faner.Dispensasjoner.hovedtekst}
                    />
                    <HelpButton
                        helpText={
                            helpObject.Faner.Dispensasjoner.MeromDispensasjoner
                        }
                        type='MeromDispensasjoner'
                        buttonVariant='tekst'
                    />
                </Grid>
                <DispensasjonTable />
            </DesktopContainer>
        </>
    );
};

export {DispensasjonList};
