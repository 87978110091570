import {Box} from '@mui/material';
import {useState} from 'react';
import {ToiNavigationCard, ToiStack} from '@norkart/toi-components';
import {
    CheckSuccessIcon,
    CoatOfArmsIcon,
    RotateIcon,
    RoutePinsIcon,
} from '@norkart/toi-icons';
import LoadingSpinner from '../../components/LoadingSpinner';
import {openSnackbarMessage} from '../../components/SnackbarMessage';
import useTrackArealplanerEvent from '../../hooks/appinsights/useTrackArealplanerEvent';
import useMeta from '../../hooks/meta/useMeta';
import MapDialogMyPosition from '../MapDialogMyPosition/MapDialogMyPosition';
import {findMyLocation} from '../home/infoButtons/utils/myLocation';
import {usePropertySearch} from '../search/Search/filters/PropertySearchFilter/usePropertySearch';
import {useFilter} from '../search/Search/filters/useFilter';

function NavigationCardStack({children}: {children: React.ReactNode}) {
    return (
        <ToiStack
            direction='row'
            gap={(theme) => theme.toi.spacing.xs}
            flexWrap='wrap'
            justifyContent={'center'}
            sx={{
                '& .MuiCard-root': {
                    height: 'inherit',
                    width: '344px',
                },
            }}
        >
            {children}
        </ToiStack>
    );
}

export default function PredefinedFilters({}) {
    const meta = useMeta();
    const propertySearch = usePropertySearch();
    const plantypeFilter = useFilter('plantype');
    const planstatusFilter = useFilter('planstatus');
    const trackEvent = useTrackArealplanerEvent('forside_Filter', 'button');

    const [displayMapDialog, setDisplayMapDialog] = useState(false);
    const [myPosition, setMyPosition] = useState<
        {lat: number; lng: number} | undefined
    >(undefined);

    const getPlanForMyLocation = async () => {
        trackEvent('Filter_MinPosisjon');
        findMyLocation(async (position: {lat: number; lng: number}) => {
            setMyPosition(position);
            setDisplayMapDialog(true);
        });
    };

    const getPlanerUnderArbeid = () => {
        trackEvent('Filter_PlanerUnderArbeid');
        planstatusFilter.set('0,1,2,6');
    };

    const getKommuneplan = () => {
        trackEvent('Filter_Kommuneplan');
        plantypeFilter.set('20,21,22');
    };

    const getGjeldendePlaner = () => {
        trackEvent('Filter_GjeldendePlaner');
        planstatusFilter.set('3');
    };

    if (meta.isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Box
            sx={(theme) => ({
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
                padding: 10,
            })}
        >
            {myPosition && (
                <MapDialogMyPosition
                    onCloseDialog={() => {
                        setDisplayMapDialog(false);
                    }}
                    onLocationConfirmed={(coords: {
                        lat: number;
                        lng: number;
                    }) => {
                        propertySearch.activatePropertyFilterFromPosition(
                            coords,
                            (error) =>
                                openSnackbarMessage({
                                    message: error,
                                    variant: 'error',
                                })
                        );
                        setDisplayMapDialog(false);
                    }}
                    open={displayMapDialog}
                    calculatedPosition={myPosition}
                />
            )}
            <ToiStack
                direction='row'
                gap={(theme) => theme.toi.spacing.xs}
                flexWrap='wrap'
                justifyContent={'center'}
            >
                <NavigationCardStack>
                    <ToiNavigationCard
                        title='Planer for min posisjon'
                        description='Vi finner eiendommen som er nærmest din posisjon og viser deg planer for eiendommen.'
                        img={<RoutePinsIcon />}
                        component={'button'}
                        onClick={getPlanForMyLocation}
                        size='medium'
                    />
                    <ToiNavigationCard
                        title='Kommuneplan'
                        description='Se kommuneplan/kommunedelplaner, kommunens overordnede strategiske styringsdokument.'
                        img={<CoatOfArmsIcon />}
                        component={'button'}
                        onClick={getKommuneplan}
                        size='medium'
                    />
                </NavigationCardStack>

                <NavigationCardStack>
                    <ToiNavigationCard
                        title='Planer under arbeid'
                        description='Se planer som er under arbeid. Dette kan være planer som er igangsatt eller planforslag.'
                        img={<RotateIcon />}
                        component={'button'}
                        onClick={getPlanerUnderArbeid}
                        size='medium'
                    />
                    <ToiNavigationCard
                        title='Gjeldende planer'
                        description='Se gjeldende arealplaner i kommunen. Dette er planer som er endelig vedtatt.'
                        img={<CheckSuccessIcon />}
                        component={'button'}
                        onClick={getGjeldendePlaner}
                        size='medium'
                    />
                </NavigationCardStack>
            </ToiStack>
        </Box>
    );
}
