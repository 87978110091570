import React from 'react';
import {ToiBox} from '@norkart/toi-components';
import {ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import FormSelectInput from '../../../../components/FormSelectInput';
import {isLovRefRequired, isLovRefDefault} from '../helpers';
import useMeta from '../../../../hooks/meta/useMeta';
import {ArealplanFormValues} from '../PlanForm';
import Warning from './Warning';

export default function Lovreferanse({
    values,
    plan,
}: {
    values: ArealplanFormValues;
    plan: ArealplanDto;
}) {
    const meta = useMeta();
    return (
        <ToiBox>
            <FormSelectInput
                name='lovreferanseId'
                title='Lovreferanse'
                options={meta.data.lovreferanser}
                keyCol='id'
                valueCol='beskrivelse'
                disabled={
                    values.planTypeId
                        ? isLovRefRequired(values.planTypeId)
                            ? isLovRefDefault(values.planTypeId)
                            : true
                        : true
                }
            />
            {!plan.lovreferanseId &&
                values.lovreferanseId &&
                isLovRefDefault(values.planTypeId) && (
                    <Warning message='Lovreferanse vil bli lagret' />
                )}
            {values.lovreferanseId && !isLovRefRequired(values.planTypeId) && (
                <Warning message='Lovreferanse vil bli slettet' />
            )}
        </ToiBox>
    );
}
