import {Menu} from '@base-ui-components/react/menu';
import {styled} from '@mui/material';

export type ToiMenuPopupProps = Menu.Popup.Props & {
  width?: string;
  container?: Menu.Portal.Props['container'];
  anchor?: Menu.Positioner.Props['anchor'];
  position?: Menu.Positioner.Props;
};
const StyledMenuPopup = styled(Menu.Popup)<ToiMenuPopupProps>(({theme, width}) => ({
  width: width ?? 248,
  backgroundColor: theme.vars.toi.palette.backgrounds.component.overlay,
  boxSizing: 'border-box',
  borderRadius: '4px',
  transition: 'transform 150ms, opacity 150ms',
  outline: `1px solid ${theme.toi.palette.border.component}`,
  boxShadow: theme.toi.shadow[2],
}));

export const ToiMenuPopup = (props: ToiMenuPopupProps) => {
  const {children, container, anchor, position, ...popupProps} = props;
  return (
    <Menu.Portal container={container}>
      <Menu.Positioner
        anchor={anchor}
        sideOffset={6}
        sticky
        align='start'
        {...position}
        style={{zIndex: 'var(--zIndex-modal)', ...position?.style}}
      >
        <StyledMenuPopup {...popupProps}>{children}</StyledMenuPopup>
      </Menu.Positioner>
    </Menu.Portal>
  );
};
