import React from 'react';
import {Field} from 'react-final-form';
import {ToiSwitchOption} from '@norkart/toi-components';

export default function FormSwitchField({
    name,
    label,
    disabled,
}: {
    name: string;
    label?: React.ReactNode;
    disabled?: boolean;
}) {
    return (
        <Field name={name} type='checkbox'>
            {({input}) => (
                <ToiSwitchOption
                    label={label}
                    labelPlacement='end'
                    disabled={disabled}
                    {...input}
                />
            )}
        </Field>
    );
}
