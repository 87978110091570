import {Autocomplete, Popper} from '@mui/material';
import * as React from 'react';
import {ToiStack} from '@norkart/toi-components';
import {PlantypeVerdiDto} from '@norkart/nkapi-arealplaner-api';
import {DateRangePickerFlexDates} from '../../../../components/DatePicker/DateRangePickerMui/DateRangePickerFlexDates';
import DateRangePickerMui from '../../../../components/DatePicker/DateRangePickerMui/DateRangePickerMui';
import {ToiLikeStyledTextField} from '../../../../components/TextField/ToiLikeStyledTextField';
import {Behandlingstyper} from '../../../../hooks/kodelister/useKodelisterBehandlingstyper';
import FilterWrapper from '../FilterWrapper';
import {useFilterQL} from '../ReportTools/FilterQL';

type SaksbehandlingstidFilterType = {
    //filterFn: (any) => any
    plantyper?: PlantypeVerdiDto[];
    behandlingstyperTil?: Behandlingstyper[];
    behandlingstyperFra?: Behandlingstyper[];
    setFilterValues: React.Dispatch<any>;
    setInputParamsValues: React.Dispatch<any>;
    clearFilter: boolean;
};

type SelectedFiltersType = {
    plantype?: PlantypeVerdiDto[];
    fraDato?: Date;
    tilDato?: Date;
};

type SelectedInputParamsType = {
    behandlingstypeFra?: Behandlingstyper;
    behandlingstypeTil?: Behandlingstyper;
};

const dateIntervalPopper = (open: boolean, children: any, anchor: any) => {
    return (
        <Popper open={open} anchorEl={anchor}>
            {children}
        </Popper>
    );
};

export function SaksbehandlingstidFilter({
    behandlingstyperFra,
    behandlingstyperTil,
    plantyper,
    setFilterValues,
    setInputParamsValues,
    clearFilter,
}: SaksbehandlingstidFilterType) {
    const filterQl = useFilterQL();
    const [selectedFilters, setSelectedFilters] = React.useState<
        SelectedFiltersType | undefined
    >();
    const [selectedInputParams, setSelectedInputParams] = React.useState<
        SelectedInputParamsType | undefined
    >();
    const [openDateInterval, setOpenDateInterval] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);

    //Lager array av objekter som skal brukes som filtere
    const handleFiltering = React.useCallback(
        (filters: SelectedFiltersType | undefined) => {
            const newFilter: any[] = [];

            filters?.plantype &&
                filters.plantype.length !== 0 &&
                newFilter.push({
                    or: filters.plantype.map((pt) => ({
                        plantype: {
                            id: {eq: pt.id},
                        },
                    })),
                });

            filters?.fraDato &&
                filters.fraDato.toString() !== 'Invalid Date' &&
                newFilter.push({
                    tilDato: {
                        gte: filters.fraDato.toJSON(),
                    },
                });

            filters?.tilDato &&
                filters.tilDato.toString() !== 'Invalid Date' &&
                newFilter.push({
                    tilDato: {
                        lte: filters.tilDato.toJSON(),
                    },
                });

            if (newFilter.length === 0) filterQl.updateFilter(undefined);
            else filterQl.updateFilter(newFilter);
        },
        [selectedFilters]
    );

    const handleInputParams = React.useCallback(
        (inputParams: SelectedInputParamsType | undefined) => {
            if (inputParams) {
                filterQl.updateInputParams({
                    behandlingstypeIdFra: inputParams.behandlingstypeFra?.id,
                    behandlingstypeIdTil: inputParams.behandlingstypeTil?.id,
                });
            }
        },
        [selectedInputParams]
    );

    //Filter for dato/plantype - bruker vanlig filterfunksjon til graph
    React.useMemo(() => {
        handleFiltering(selectedFilters);
    }, [
        selectedFilters?.plantype,
        selectedFilters?.fraDato,
        selectedFilters?.tilDato,
    ]);

    //Filter på behandlingstype - er inputparamtere til queryen
    React.useMemo(() => {
        handleInputParams(selectedInputParams);
    }, [
        selectedInputParams?.behandlingstypeFra,
        selectedInputParams?.behandlingstypeTil,
    ]);

    //Setter oppdaterte filterverdier så det kan brukes som variabler i graphql request
    React.useEffect(() => {
        setFilterValues(filterQl.filterReturn);
    }, [filterQl.filterReturn]);

    //Setter oppdaterte filterverdier så det kan brukes som variabler i graphql request
    React.useEffect(() => {
        setInputParamsValues(filterQl.inputParamsReturn);
    }, [filterQl.inputParamsReturn]);

    React.useEffect(() => {
        if (clearFilter) setSelectedFilters(undefined);
    }, [clearFilter]);

    React.useEffect(() => {
        dateIntervalPopper(
            openDateInterval,
            <DateRangePickerFlexDates
                intervalSelected={(d) => {
                    setSelectedFilters({
                        ...selectedFilters,
                        tilDato: d.end,
                        fraDato: d.start,
                    });
                }}
            />,
            anchorEl
        );
    }, [openDateInterval]);

    return (
        <FilterWrapper>
            <ToiStack direction='row' gap={(theme) => theme.toi.spacing.m}>
                <ToiStack width={'100%'}>
                    <Autocomplete
                        size='small'
                        autoHighlight={true}
                        options={behandlingstyperFra ? behandlingstyperFra : []}
                        value={
                            selectedInputParams?.behandlingstypeFra
                                ? selectedInputParams.behandlingstypeFra
                                : null
                        }
                        fullWidth={false}
                        limitTags={1}
                        multiple={false}
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedInputParams({
                                ...selectedInputParams,
                                behandlingstypeFra:
                                    newValue !== null ? newValue : undefined,
                            })
                        }
                        renderInput={(params) => (
                            <ToiLikeStyledTextField
                                {...params}
                                size='small'
                                label='Behandlingstype fra'
                                variant='outlined'
                            />
                        )}
                    />
                    <Autocomplete
                        size='small'
                        autoHighlight={true}
                        options={plantyper ? plantyper : []}
                        limitTags={1}
                        value={
                            selectedFilters?.plantype
                                ? selectedFilters.plantype
                                : []
                        }
                        multiple={true}
                        fullWidth={true}
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                plantype: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiLikeStyledTextField
                                {...params}
                                size='small'
                                fullWidth={true}
                                label='Plantype'
                                variant='outlined'
                            />
                        )}
                    />
                    <Autocomplete
                        size='small'
                        autoHighlight={true}
                        options={behandlingstyperTil ? behandlingstyperTil : []}
                        value={
                            selectedInputParams?.behandlingstypeTil
                                ? selectedInputParams.behandlingstypeTil
                                : null
                        }
                        fullWidth={false}
                        limitTags={1}
                        multiple={false}
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedInputParams({
                                ...selectedInputParams,
                                behandlingstypeTil:
                                    newValue !== null ? newValue : undefined,
                            })
                        }
                        renderInput={(params) => (
                            <ToiLikeStyledTextField
                                {...params}
                                size='small'
                                label='Behandlingstype til'
                                variant='outlined'
                            />
                        )}
                    />
                </ToiStack>
                <ToiStack width={'100%'}>
                    <DateRangePickerMui
                        textFieldProps={{sx: {width: 400}}}
                        onChange={(d) => {
                            setSelectedFilters({
                                ...selectedFilters,
                                fraDato: d?.start,
                                tilDato: d?.end,
                            });
                        }}
                        label='Dato intervall'
                        startDate={selectedFilters?.fraDato}
                        endDate={selectedFilters?.tilDato}
                        visibleIntervals={'years'}
                    />
                </ToiStack>
            </ToiStack>
        </FilterWrapper>
    );
}
