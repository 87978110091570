import {ToiBox, ToiTypography} from '@norkart/toi-components';

interface KonsistenssjekkFailedInfoProps {
    reportJobFailedMessage?: {
        message?: string;
        kategoriFeil?: string;
        qmUrl?: string;
        qmsBrukernavn?: string;
        qmsOppgavenavn?: string;
        verdiArealplaner?: string;
        verdiQms?: string;
        planId?: string;
    } | null;
}

export default function KonsistenssjekkFailedInfo({
    reportJobFailedMessage,
}: KonsistenssjekkFailedInfoProps) {
    if (!reportJobFailedMessage) return null;

    const fields = [
        {label: 'Melding', value: reportJobFailedMessage.message},
        {label: 'Kategori', value: reportJobFailedMessage.kategoriFeil},
        {label: 'QMS URL', value: reportJobFailedMessage.qmUrl},
        {label: 'QMS Brukernavn', value: reportJobFailedMessage.qmsBrukernavn},
        {
            label: 'QMS Oppgavenavn',
            value: reportJobFailedMessage.qmsOppgavenavn,
        },
        {
            label: 'Verdi Arealplaner',
            value: reportJobFailedMessage.verdiArealplaner,
        },
        {label: 'Verdi QMS', value: reportJobFailedMessage.verdiQms},
        {label: 'PlanId', value: reportJobFailedMessage.planId},
    ];

    return (
        <ToiBox>
            <ToiTypography variant='body2' fontWeight={'bold'} color='error'>
                Forrige kjøring feilet:
            </ToiTypography>
            {fields.map(
                (field, index) =>
                    field.value != null && (
                        <ToiTypography key={index} color={'error'}>
                            <ToiTypography
                                component='span'
                                fontWeight='semibold'
                                color={'error'}
                            >
                                {field.label}
                            </ToiTypography>
                            : {field.value}
                        </ToiTypography>
                    )
            )}
        </ToiBox>
    );
}
