import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const MapLayersIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M3.30534 10.7983C2.97251 10.9718 2.63035 11.1501 2.27612 11.3347C1.73831 11.615 1.73829 12.3846 2.2761 12.6649L11.6489 17.5499C11.866 17.6631 12.1247 17.6631 12.3419 17.55L21.7226 12.6651C22.2607 12.3848 22.2607 11.6149 21.7226 11.3346L21.1633 11.0434L20.7488 10.8276'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.30534 15.6052C2.97251 15.7787 2.63035 15.957 2.27612 16.1417C1.73831 16.422 1.73829 17.1915 2.2761 17.4718L11.6489 22.3568C11.866 22.47 12.1247 22.47 12.3419 22.3569L21.7226 17.472C22.2607 17.1917 22.2607 16.4218 21.7226 16.1415L21.1633 15.8503L20.7488 15.6345'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.7226 6.52778C22.2607 6.80801 22.2607 7.57797 21.7226 7.8582L12.3419 12.7431C12.1247 12.8562 11.866 12.8562 11.6489 12.743L2.2761 7.85808C1.73829 7.57777 1.73829 6.8082 2.27611 6.5279L11.6489 1.64295C11.866 1.52978 12.1247 1.52973 12.3419 1.64283L21.7226 6.52778Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default MapLayersIcon;
