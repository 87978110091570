import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const FullscreenExitIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M3 8H6.5C7.32843 8 8 7.32843 8 6.5V3M3 16H6.5C7.32843 16 8 16.6716 8 17.5V21M16 3V6.5C16 7.32843 16.6716 8 17.5 8H21M16 21V17.5C16 16.6716 16.6716 16 17.5 16H21'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </ToiIcon>
));

export default FullscreenExitIcon;
