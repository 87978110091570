import {useState} from 'react';
import {
    ToiBox,
    ToiCard,
    ToiDivider,
    ToiLink,
    ToiStack,
    ToiTypography,
} from '@norkart/toi-components';
import {ExternalIcon} from '@norkart/toi-icons';
import {AiAnswer, Chunk, Feedback} from '../types';
import {ReferenceButton} from './ReferenceButton';
import {AiChatAnswer} from './ChatAnswer';

type ReferenceChatItemProps = {
    answer: AiAnswer;
    setCurrentSelectedChunk: (chunk: Chunk) => void;
    chatElementIndex: string;
    answerIndex: number;
    displayFeedbackButtons?: boolean;
    onSubmitFeedback?: (feedback: Feedback) => void;
};

export const ReferenceChatItem = ({
    answer,
    setCurrentSelectedChunk,
    chatElementIndex,
    answerIndex,
}: ReferenceChatItemProps) => {
    const [openReference, setOpenReference] = useState(false);

    const getCitedChunks = () => {
        return answer.reference.chunks.filter((chunk) => chunk.is_used);
    };

    const getPreviewChunkName = (chunk: Chunk) => {
        const max_characters_to_preview = 75;
        const content_preview =
            chunk.content.length < max_characters_to_preview
                ? chunk.content
                : chunk.content.substring(0, max_characters_to_preview) + '...';
        const name = `${content_preview.replaceAll('#', ' ')}`;
        return name;
    };

    return (
        <ToiStack gap={1}>
            <ToiTypography variant='body2' color='black' bold>
                {answer.title} sier:
            </ToiTypography>
            <AiChatAnswer
                aiAnswer={answer}
                chatElementIndex={chatElementIndex.toString()}
                answerIndex={answerIndex}
            />
            <ToiBox
                mb={2}
                display={'flex'}
                //justifyContent={'space-between'}
                gap={2}
            >
                <ReferenceButton
                    onClick={() => setOpenReference((prevState) => !prevState)}
                />
            </ToiBox>
            {openReference && (
                <ToiCard
                    sx={{
                        height: 'min-content',
                        mb: 2,
                        padding: 2,
                        maxWidth: '700px',
                    }}
                >
                    <ToiTypography sx={{m: 1}} variant='h4'>
                        Referanser
                    </ToiTypography>
                    <ToiDivider sx={{m: 1}} />
                    {answer.reference.link ? (
                        <ToiTypography sx={{m: 1}} variant='body2'>
                            {answer.title}:{' '}
                            <ToiLink
                                inline
                                href={answer.reference.link.url}
                                target='_blank'
                                sx={{maxWidth: '250px'}}
                            >
                                {answer.reference.link.title}
                                <ExternalIcon size='small' />
                            </ToiLink>
                        </ToiTypography>
                    ) : (
                        <ToiTypography
                            sx={{mt: 1, ml: 1, mb: 2}}
                            variant='body2'
                        >
                            {answer.title}:
                        </ToiTypography>
                    )}
                    {getCitedChunks().length > 0 && (
                        <ToiStack gap={1} ml={2} mb={1}>
                            {getCitedChunks().map((chunk, i) => {
                                return (
                                    <ToiBox display={'flex'} gap={1} key={i}>
                                        <ToiTypography>
                                            [{chunk.id}]
                                        </ToiTypography>
                                        <ToiLink
                                            key={i}
                                            variant='body2'
                                            onClick={(e) =>
                                                setCurrentSelectedChunk(chunk)
                                            }
                                        >
                                            {getPreviewChunkName(chunk)}
                                        </ToiLink>
                                    </ToiBox>
                                );
                            })}
                        </ToiStack>
                    )}
                </ToiCard>
            )}
        </ToiStack>
    );
};
