/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {ListItemNode, ListNode} from '@lexical/list';
import {LinkNode} from '@lexical/link';
import {$getRoot, $insertNodes} from 'lexical';
import {$generateNodesFromDOM, $generateHtmlFromNodes} from '@lexical/html';
import {styled} from '@mui/material/styles';
import '@emotion/react';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {ToiBox, ToiTypography} from '@norkart/toi-components';
import editorTheme from './EditorTheme';
import EditorToolbar from './EditorToolbar/EditorToolbar';

function AutoFocusPlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        editor.focus();
    }, [editor]);

    return null;
}

function PrePopulatePlugin({
    initialEditorContent,
}: {
    initialEditorContent: string;
}) {
    const [editor] = useLexicalComposerContext();
    let populate = true;

    useEffect(() => {
        if (populate)
            editor.update(() => {
                populate = false;
                const parser = new DOMParser();
                const dom = parser.parseFromString(
                    initialEditorContent,
                    'text/html'
                );
                const nodes = $generateNodesFromDOM(editor, dom);

                $getRoot().getFirstDescendant()?.remove();
                $getRoot().select();
                $insertNodes(nodes);
            });
    }, []);
    return null;
}

function SerializationPlugin({
    currentEditorState,
}: {
    currentEditorState: (newState: string) => void;
}) {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return editor.registerUpdateListener(() => {
            editor.getEditorState().read(() => {
                const htmlString = $generateHtmlFromNodes(editor, null);
                currentEditorState(htmlString);
            });
        });
    }, [editor, currentEditorState]);

    return null;
}

const CustomContentEditable = styled(ContentEditable)({
    minHeight: 200,
    paddingLeft: 10,
    outline: 1,
});

type TextEditorProps = {
    initialEditorContent?: string;
    toolbarOptions: string[];
    currentEditorState: (newState: string) => void;
};

export default function Editor({
    initialEditorContent,
    toolbarOptions,
    currentEditorState,
}: TextEditorProps) {
    const initialConfig = {
        namespace: 'EditorNamespace',
        theme: {
            editorTheme,
        },
        onError(error: Error) {
            console.error('Lexical editor error:', error);
        },
        nodes: [ListItemNode, ListNode, LinkNode],
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <ToiBox
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    resize: 'both',
                    overflow: 'auto',
                }}
            >
                <EditorToolbar toolbarOptions={toolbarOptions} />
                <ToiBox
                    sx={{
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                        background: '#ffffff',
                        border: '1px solid #e0e0e0',
                        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                    }}
                >
                    <RichTextPlugin
                        contentEditable={<CustomContentEditable />}
                        placeholder={<ToiTypography></ToiTypography>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <PrePopulatePlugin
                        initialEditorContent={initialEditorContent || ''}
                    />
                    <HistoryPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <AutoFocusPlugin />
                    <SerializationPlugin
                        currentEditorState={currentEditorState}
                    />
                </ToiBox>
            </ToiBox>
        </LexicalComposer>
    );
}
