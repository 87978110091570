import {useQuery} from 'react-query';
import {KundeDto, kunderGetKundeById} from '@norkart/nkapi-arealplaner-api';
import useErrorHandler from '../errors/useErrorHandler';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedKundeId from './useSelectedKundeId';

export default function useKunde() {
    const kundeId = useSelectedKundeId();
    const {notify} = useErrorHandler();
    const {client} = useArealplanerApiClientContext();
    const getKunde = async () => {
        const {data, error} = await kunderGetKundeById({
            path: {kundeId},
            client,
        });
        if (error) throw new Error(String(error.message));
        return data;
    };

    const query = useQuery<KundeDto>(
        ['kundeStatus', {kundeId}],
        () => getKunde(),
        {
            enabled: !!kundeId,
            onError: notify,
            keepPreviousData: !!kundeId, // hvis vi går fra en kunde til en annen så behold gammel data til ny data kommer inn
        }
    );
    return {...query, data: query?.data, kunde: query?.data};
}
