import {ColumnDef, RowData} from '@tanstack/react-table';
import {ChevronUpIcon, ChevronDownIcon} from '@norkart/toi-icons';
import {ToiCheckbox} from '../ToiCheckbox';
import {ToiIconButton} from '../ToiIconButton';
import {ToiTableOptions} from './useToiTable';

export function getSelectRowColumn<TData extends RowData>(): ColumnDef<TData> {
  return {
    id: 'select', // Any unique ID
    header: ({table}) => (
      <ToiCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({row}) => (
      <ToiCheckbox
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    ),
    size: 24,
    enableSorting: false,
  };
}

export function getExpandRowColumn<TData extends RowData>(options: ToiTableOptions<TData>): ColumnDef<TData> {
  return {
    id: 'expand',
    header: ({table}) =>
      !options.meta?.hideExpandAll && (
        <ToiIconButton
          disabled={!table.getCanSomeRowsExpand()}
          aria-label='Utvid alle rader'
          onClick={() => table.toggleAllRowsExpanded()}
          color='transparent'
          sx={{margin: '-4px'}}
        >
          {table.getIsAllRowsExpanded() ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </ToiIconButton>
      ),
    cell: ({row}) =>
      row.getCanExpand() && (
        <ToiIconButton
          aria-label='Utvid rad'
          aria-expanded={row.getIsExpanded()}
          onClick={() => row.toggleExpanded()}
          color='transparent'
          sx={{margin: '-4px'}}
        >
          {row.getIsExpanded() ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </ToiIconButton>
      ),
    size: 24,
    enableSorting: false,
    meta: {
      disableRowClick: true,
    },
  };
}
