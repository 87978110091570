import {useQuery} from 'react-query';
import {arealplanerGetById, ArealplanDto} from '@norkart/nkapi-arealplaner-api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedArealplanId from './useSelectedArealplanId';

export default function useArealplan(id?: number) {
    const kundeId = useSelectedKundeId();
    const arealplanId = useSelectedArealplanId();
    const {notify} = useErrorHandler();

    const {client} = useArealplanerApiClientContext();

    const fetchArealplan = async ({id}) => {
        const {data, error} = await arealplanerGetById({
            path: {kundeId, arealplanId: id},
            client: client,
            query: {includePlanforhold: true},
        });
        if (error) throw new Error(String(error.message));
        return data ?? ({} as ArealplanDto);
    };

    const query = useQuery<ArealplanDto>(
        ['arealplan', {kundeId, arealplanId: id ? id : arealplanId}],
        () => fetchArealplan({id: id ? id : arealplanId}),
        {
            enabled: !!arealplanId && !!kundeId,
            onError: notify,
        }
    );
    return {...query, data: query?.data || ({} as ArealplanDto)};
}
