import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const ErrorWarningIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M16.3787 1H7.62132C7.2235 1 6.84196 1.15804 6.56066 1.43934L1.43934 6.56066C1.15804 6.84196 1 7.2235 1 7.62132V16.3787C1 16.7765 1.15804 17.158 1.43934 17.4393L6.56066 22.5607C6.84196 22.842 7.2235 23 7.62132 23H16.3787C16.7765 23 17.158 22.842 17.4393 22.5607L22.5607 17.4393C22.842 17.158 23 16.7765 23 16.3787V7.62132C23 7.2235 22.842 6.84196 22.5607 6.56066L17.4393 1.43934C17.158 1.15804 16.7765 1 16.3787 1Z'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
    />
    <path
      d='M12 7V13M12 16V16.5'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </ToiIcon>
));

export default ErrorWarningIcon;
