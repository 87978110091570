import * as React from 'react';
import {ToiDialogSimple} from '@norkart/toi-components';

export interface DeletePostDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => void;
}

const DeletePostDialog = ({isOpen, onClose, handleDelete}: DeletePostDialogProps) => {
  const handleOk = () => {
    handleDelete();
  };
  return (
    <ToiDialogSimple
      open={isOpen}
      onClose={onClose}
      aria-labelledby='delete-dialog-title'
      title='Er du sikker på at du vil slette innlegget?'
      confirmText='Ja'
      onConfirm={handleOk}
      cancelText='Nei'
    ></ToiDialogSimple>
  );
};

export default DeletePostDialog;
