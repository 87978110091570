import * as React from 'react';
import {connect} from 'react-redux';
import {ToiCheckbox} from '@norkart/toi-components';
import {ApplicationState} from '../../store/index';
import {DocumentTableType} from '../../features/plan/PlanDocumentList/PlanDocumentList';

interface Props extends stateProps {
    name: string;
    onChange: (e) => void;
    tableType: DocumentTableType;
}

const TableHeaderCheckboxRender = ({
    name,
    onChange,
    tableType,
    allGjeldendeDocumentsSelected,
    allOtherDocumentsSelected,
}: Props) => {
    const checked =
        tableType === 'GjeldendeBest'
            ? allGjeldendeDocumentsSelected
            : allOtherDocumentsSelected;
    return <ToiCheckbox name={name} checked={checked} onChange={onChange} />;
};

type stateProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => {
    return {
        allGjeldendeDocumentsSelected:
            state.tableSelection.allGjeldendeBestDocsSelected,
        allOtherDocumentsSelected: state.tableSelection.allOtherDocsSelected,
    };
};
const TableHeaderCheckbox = connect(mapStateToProps)(TableHeaderCheckboxRender);

export default TableHeaderCheckbox;
