import {useMemo} from 'react';
import {useInfiniteQuery} from 'react-query';
import {ArealplanDto, SakVerdiDto} from '@norkart/nkapi-arealplaner-api';
import {fetchBehandlingerReport} from '../../services/api';
import useDebounce from '../useDebounce';

type Pagination = {
    TotalCount: number;
    PageSize: number;
    CurrentPage: number;
    TotalPages: number;
    HasNext: boolean;
    HasPrevious: boolean;
    NextPage: number;
};

type PagedBehandlingerReportResponse = {
    behandling: BehandlingReportResponse[];
    pagination: Pagination;
    pageParam: number;
};

export type BehandlingReportResponse = {
    id: number;
    behandlingstypeId: number;
    behandlingstype: string;
    fraDato?: Date;
    tilDato?: Date;
    rekkefolge: number;
    sak: SakVerdiDto;
    beskrivelse: string;
    endringstypeId?: number;
    endringstype: string;
    myndighetId?: number;
    myndighet: string;
    tilgangId?: number;
    tilgang: string;
    arealplan: ArealplanDto;
    posisjon?: {x: number; y: number; koordsys: string};
};

export type BehandlingReportFilters = {
    behandlingstypeId?: string | null;
    planstatusId?: string | null;
    plantypeId?: string | null;
    beskrivelse?: string | null;
    fraDatoStart?: string | null;
    fraDatoEnd?: string | null;
    tilDatoStart?: string | null;
    tilDatoEnd?: string | null;
};

export type BehandlingReportSorting = {
    order: 'asc' | 'desc' | 'ASC' | 'DESC';
    orderBy?: string | null;
};

type BehandlingReportProps = {
    take?: number;
    page?: number;
    filters?: BehandlingReportFilters;
    sorting?: BehandlingReportSorting;
};

const getBehandlinger = async (props: BehandlingReportProps) => {
    const response = await fetchBehandlingerReport(
        props.take,
        props.page,
        props.sorting?.order,
        props.sorting?.orderBy,
        props.filters?.behandlingstypeId,
        props.filters?.plantypeId,
        props.filters?.planstatusId,
        props.filters?.beskrivelse,
        props.filters?.fraDatoStart,
        props.filters?.fraDatoEnd,
        props.filters?.tilDatoStart,
        props.filters?.tilDatoEnd
    );
    const pagination = JSON.parse(
        response.headers['x-pagination']
    ) as Pagination;
    const data = response.data as BehandlingReportResponse[];
    return {
        behandling: data,
        pagination: pagination,
    } as PagedBehandlingerReportResponse;
};

export default function useBehandlingerReport(props: BehandlingReportProps) {
    const debouncedProps = useDebounce(props, 500);
    const query = useInfiniteQuery<PagedBehandlingerReportResponse>(
        ['behandlingreport', debouncedProps],
        ({pageParam = 1}) =>
            getBehandlinger({...debouncedProps, page: pageParam}),
        {
            getNextPageParam: (response) => {
                if (response?.pagination?.HasNext) {
                    return response.pagination.NextPage;
                } else return undefined;
            },
            keepPreviousData: true,
            staleTime: 0,
            cacheTime: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
        }
    );

    const behandlinger = useMemo(() => {
        return query.data?.pages.flatMap((a) => a.behandling);
    }, [query.data?.pages]);

    return {
        ...query,
        results: behandlinger || [],
        totalCount: query.data?.pages[0].pagination.TotalCount,
    };
}
