import {
    ColumnDef,
    getSortedRowModel,
    SortingState,
} from '@tanstack/react-table';
import * as React from 'react';
import {EditIcon} from '@norkart/toi-icons';
import {
    ToiIconButton,
    ToiStack,
    ToiTable,
    ToiTooltip,
    useToiTable,
} from '@norkart/toi-components';
import {InnstillingDto} from '@norkart/nkapi-arealplaner-api';
import FrontendSettingDialog from './FrontendSettingDialog';

interface FrontendSettingsTableProps {
    innstillinger: InnstillingDto[] | undefined;
}

export function FrontendSettingsTable({
    innstillinger,
}: FrontendSettingsTableProps) {
    const columns = React.useMemo<ColumnDef<InnstillingDto>[]>(
        () => [
            {
                accessorKey: 'beskrivelse',
                header: () => 'Innstilling',
                enableSorting: true,
            },
            {
                accessorKey: 'verdi',
                header: () => 'Verdi',
                enableSorting: false,
            },
            {
                id: 'editColumn',
                header: () => '',
                cell: (r) => editVerdiButton(r.row.original),
            },
        ],
        []
    );

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [data, setData] = React.useState<InnstillingDto[] | undefined>();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectedInnstilling, setSelectedInnstilling] =
        React.useState<InnstillingDto>();

    const editVerdiButton = (row: InnstillingDto | undefined) => {
        if (row)
            return (
                <ToiStack direction={'row'} justifyContent={'flex-end'}>
                    <ToiIconButton
                        aria-label='Rediger verdi'
                        color='transparent'
                        onClick={() => {
                            handleEdit(row);
                        }}
                    >
                        <ToiTooltip title='Endre'>
                            <EditIcon />
                        </ToiTooltip>
                    </ToiIconButton>
                </ToiStack>
            );
    };

    const handleEdit = (innstilling: InnstillingDto) => {
        setDialogOpen(true);
        setSelectedInnstilling(innstilling);
    };

    React.useEffect(() => {
        setData(innstillinger);
    }, [innstillinger]);

    const table = useToiTable({
        data: data ? data : [],
        columns,
        state: {sorting},
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
    });
    if (data)
        return (
            <>
                <ToiTable table={table} ariaLabel='Innstillinger table' />

                <FrontendSettingDialog
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    innstilling={selectedInnstilling}
                />
            </>
        );
    return (
        <p style={{paddingLeft: '15px'}}>
            Ingen innstillinger tilgjengelig for redigering
        </p>
    );
}
