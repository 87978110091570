import {useQueries, UseQueryResult} from 'react-query';
import {
    arealplanerGetById,
    ArealplanSearchResultDto,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useArealplaner(ids: number[]) {
    const kundeId = useSelectedKundeId();
    const {client} = useArealplanerApiClientContext();

    function mapArealplaner(id: number) {
        return {
            queryKey: ['arealplan', kundeId, id],
            queryFn: async () => {
                const {data, error} = await arealplanerGetById({
                    path: {kundeId, arealplanId: id},
                    query: {includePlanforhold: true},
                    client,
                });
                if (error) throw new Error(String(error.message));
                return data;
            },
        };
    }

    const query = useQueries(ids.map(mapArealplaner)) as UseQueryResult<
        ArealplanSearchResultDto,
        unknown
    >[];
    return query;
}
