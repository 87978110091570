import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const PolygonIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title}</title>
    <desc>{props.desc}</desc>
    <path
      d='M16 21.5C16 22.3284 16.6716 23 17.5 23C18.3284 23 19 22.3284 19 21.5C19 20.8517 18.5887 20.2994 18.0128 20.0899M16 21.5C16 20.6716 16.6716 20 17.5 20C17.6801 20 17.8528 20.0317 18.0128 20.0899M16 21.5H8M8 21.5C8 22.3284 7.32843 23 6.5 23C5.67157 23 5 22.3284 5 21.5C5 20.8517 5.41132 20.2994 5.98725 20.0899M8 21.5C8 20.6716 7.32843 20 6.5 20C6.31991 20 6.14723 20.0317 5.98725 20.0899M13.1474 3.46623C13.3674 3.20522 13.5 2.86809 13.5 2.5C13.5 1.67157 12.8284 1 12 1C11.1716 1 10.5 1.67157 10.5 2.5C10.5 2.86809 10.6326 3.20522 10.8526 3.46623M13.1474 3.46623C12.8722 3.79264 12.4603 4 12 4C11.5397 4 11.1278 3.79264 10.8526 3.46623M13.1474 3.46623L20.3526 9.53378M10.8526 3.46623L3.64739 9.53378M3.64739 9.53378C3.37224 9.20736 2.96033 9 2.5 9C1.67157 9 1 9.67157 1 10.5C1 11.3284 1.67157 12 2.5 12C2.68009 12 2.85277 11.9683 3.01275 11.9101M3.64739 9.53378C3.86741 9.79478 4 10.1319 4 10.5C4 11.1483 3.58868 11.7006 3.01275 11.9101M3.01275 11.9101L5.98725 20.0899M18.0128 20.0899L20.9872 11.9101M20.9872 11.9101C21.1472 11.9683 21.3199 12 21.5 12C22.3284 12 23 11.3284 23 10.5C23 9.67157 22.3284 9 21.5 9C21.0397 9 20.6278 9.20736 20.3526 9.53378M20.9872 11.9101C20.4113 11.7006 20 11.1483 20 10.5C20 10.1319 20.1326 9.79478 20.3526 9.53378'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </ToiIcon>
));

export default PolygonIcon;
