import {useNavigate} from 'react-router-dom';
import {ToiListPicker} from '@norkart/toi-components';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useKunder from '../../../hooks/kunder/useKunder';
import Crest from './Crest';

function CustomerSelector() {
    const navigate = useNavigate();

    const {data: customers, isLoading, isFetched} = useKunder();

    if (isLoading || !isFetched) return <LoadingSpinner />;

    return (
        <ToiListPicker
            variant='scrollable'
            onSelectedListItem={(customer) => navigate(`/${customer.id}`)}
            list={customers.map((customer) => ({
                id: customer?.id ?? '',
                name: customer?.navn ?? '',
                avatar: <Crest src={customer?.crest} />,
            }))}
            title={'Velg kommune'}
            searchbarPlaceholder='Søk etter kommune'
            emptyListText='Fant ingen kommuner med dette navnet'
        />
    );
}

export default CustomerSelector;
