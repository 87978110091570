import {styled, SxProps} from '@mui/material';
import {forwardRef, ReactNode, useState} from 'react';
import {ChevronRightIcon} from '@norkart/toi-icons';
import {ToiMenuList, ToiStack} from '../pureMuiComponents';
import {ToiAvatar} from '../ToiAvatar';
import {ToiListItemAvatar, ToiListItemButton, ToiListItemText} from '../ToiList';
import {ToiSearchBar} from '../ToiSearch';
import {ToiTypography} from '../ToiTypography';

type ToiListPickerVariant = 'default' | 'scrollable';
type ToiListPickerIconType = 'avatar' | ReactNode;

export type ToiListPickerProps<TElement extends ToiListPickerItemType> = ListProps<TElement> & {
  title: string;
  description?: string;
  searchbarPlaceholder?: string;
  disableSearch?: boolean;
  variant?: ToiListPickerVariant;
  sx?: SxProps;
};

export type ToiListPickerItemType = {
  name: string;
  id: string;
  description?: string;
  avatar?: ToiListPickerIconType;
};

type ListProps<TElement extends ToiListPickerItemType> = {
  list: TElement[];
  onSelectedListItem: (selected: TElement) => void;
  emptyListText?: string;
};

const StyledComponent = styled('div')(({theme}) => ({
  background: theme.vars.toi.palette.backgrounds.generic.default,
  width: `min(500px, 100%)`,
  minHeight: 400,
  borderRadius: 16,
  border: `1px solid ${theme.vars.toi.palette.divider.grey}`,
}));

export const ToiListPicker = forwardRef(
  <TElement extends ToiListPickerItemType>(
    props: ToiListPickerProps<TElement>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const {
      title,
      description = 'Vennligst velg et alternativ for å gå videre',
      variant = 'default',
      searchbarPlaceholder = 'Søk',
      disableSearch = false,
      list,
      sx,
      ...rest
    } = props;
    const [searchTerm, setSearchTerm] = useState<string>('');

    const handleSearching = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchTerm(e.target.value);
    };

    const disableScroll = variant !== 'scrollable' || list.length < 6;

    return (
      <StyledComponent ref={forwardedRef} sx={sx}>
        <ToiStack
          height={disableScroll ? '100%' : 500}
          p={(theme) => theme.toi.spacing.xl}
          gap={(theme) => theme.toi.spacing.m}
          alignItems={'center'}
        >
          <ToiStack textAlign='center'>
            <ToiTypography variant='h4'>{title}</ToiTypography>
            {disableSearch && <ToiTypography>{description}</ToiTypography>}
          </ToiStack>
          {!disableSearch && (
            <ToiSearchBar value={searchTerm} onChange={handleSearching} fullWidth placeholder={searchbarPlaceholder} />
          )}

          <List searchTerm={searchTerm} disableScroll={disableScroll} list={list} {...rest} />
        </ToiStack>
      </StyledComponent>
    );
  },
);

const List = <TElement extends ToiListPickerItemType>(
  props: ListProps<TElement> & {searchTerm: string; disableScroll?: boolean},
) => {
  const {searchTerm, emptyListText, list, onSelectedListItem, disableScroll = false} = props;
  const filteredList = list.filter((c) => c.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <ToiStack
      sx={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: (theme) => theme.vars.toi.palette.bars.fill,
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: (theme) => theme.vars.toi.palette.bars.handle,
        },
      }}
      width='100%'
      overflow={disableScroll ? 'hidden' : 'auto'}
    >
      <ToiMenuList>
        {filteredList.length === 0 && <ToiListItemButton>{emptyListText ?? 'Ingen resultater'}</ToiListItemButton>}
        {filteredList.map((c, i) => (
          <ToiListItemButton key={i} onClick={() => onSelectedListItem(c)} divider={i < filteredList.length - 1}>
            {c.avatar && (
              <ToiListItemAvatar sx={{mr: (theme) => theme.toi.spacing.xs}}>
                {c.avatar === 'avatar' ? <ToiAvatar fullname={c.name} /> : c.avatar}
              </ToiListItemAvatar>
            )}
            <ToiListItemText
              sx={{py: (theme) => theme.toi.spacing.xs}}
              primary={c.name}
              secondary={c.description ?? ''}
            />
            <ChevronRightIcon />
          </ToiListItemButton>
        ))}
      </ToiMenuList>
    </ToiStack>
  );
};

export default ToiListPicker;
