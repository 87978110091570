import {ToiBox, ToiButton, ToiTypography} from '@norkart/toi-components';
import {ConversationType} from '../types';

type Props = {
    setExampleQuestion: (q: string) => void;
    selectedConversation: ConversationType;
    chatInputRef?: React.RefObject<HTMLDivElement>;
};

export const ExampleQuestions = ({
    setExampleQuestion,
    selectedConversation,
    chatInputRef,
}: Props) => {
    const onClickExampleQuestion = (question: string) => {
        setExampleQuestion(question);
        focusChatInput();
    };

    const focusChatInput = () => {
        if (chatInputRef?.current) {
            chatInputRef.current.focus();
        }
    };

    return (
        <>
            <ToiTypography variant='h4' pb={1}>
                Eksempler på spørsmål
            </ToiTypography>
            <ToiBox display={'flex'} gap={2} flexWrap={'wrap'}>
                {selectedConversation.sampleQuestions.map((q, index) => (
                    <ToiButton
                        key={index}
                        variant='secondary'
                        size='medium'
                        sx={{boxShadow: 'none'}}
                        onClick={() => onClickExampleQuestion(q)}
                    >
                        {q}
                    </ToiButton>
                ))}
            </ToiBox>
        </>
    );
};
