import {useQuery} from 'react-query';
import {TokenDto} from '@norkart/nkapi-arealplaner-api';
import {fetchToken} from '../../services/api';
import useErrorHandler from '../errors/useErrorHandler';
import useSelectedKundeId from '../kunder/useSelectedKundeId';

const getToken = async (id?: number | null) => {
    if (!id) return {};
    const {data} = await fetchToken(id);
    return data;
};

export default function useToken(id?: number | null) {
    const {notify} = useErrorHandler();
    const kundeId = useSelectedKundeId();

    const query = useQuery<TokenDto>(
        ['tokens', {kundeId, id}],
        () => getToken(id),
        {
            onError: notify,
            enabled: !!kundeId && !!id,
            staleTime: undefined,
            cacheTime: undefined,
        }
    );

    return {
        ...query,
        data: query.data || ({} as TokenDto),
    };
}
