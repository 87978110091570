import {useContext, useMemo} from 'react';
import {NkAuthContext} from '../context/NkAuthContext';
import {TokenType, getTokenFetchFunction} from '../getApiTokenFetchFunction';

export const useFetchTokenHeaderWithAuthData = (tokenType: TokenType = 'accessToken') => {
  const context = useContext(NkAuthContext);

  if (context === undefined) {
    throw new Error('useFetchTokenHeaderWithAuthData can only be used under a NkAuthProvider.');
  }

  const authData = context.authData;

  const fetchHeader = useMemo(
    () => async () => {
      const fetchToken = getTokenFetchFunction(authData.msalApp, authData, tokenType);
      const token = fetchToken && (await fetchToken());

      const baseHeader: Record<string, string> = {
        Authorization: token ? `Bearer ${token}` : '',
        'X-WAAPI-Profile': authData.setupNorkartProfile,
      };

      if (authData.isMultiCustomerContext) {
        return {
          ...baseHeader,
          'X-WAAPI-CONTEXT': context.customerContextData?.currentCustomerContext?.CustomerId ?? '',
        };
      }

      return baseHeader;
    },
    [authData, context.customerContextData?.currentCustomerContext?.CustomerId, tokenType],
  );

  return fetchHeader;
};
