import {CircularProgress} from '@mui/material';
import {ToiButton, ToiButtonProps} from '@norkart/toi-components';

export interface SpinnerButtonProps extends ToiButtonProps {
    label: string;
    disabled?: boolean;
    loading?: boolean;
}

const SpinnerButton = ({label, loading, ...props}: SpinnerButtonProps) => {
    return (
        <ToiButton {...props}>
            {label}
            {loading && (
                <CircularProgress
                    size={24}
                    sx={(theme) => ({
                        color: theme.palette.secondary.main,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    })}
                />
            )}
        </ToiButton>
    );
};

export default SpinnerButton;
