import * as React from 'react';
import {
    ToiDatePicker,
    ToiStack,
    ToiTag,
    ToiTypography,
} from '@norkart/toi-components';

type Props = {
    input: {
        name: string;
        value: string | null;
        onChange: (date: Date | null) => void;
    };
    label?: string;
    onClear?: () => void;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
    placeholder?: string;
    fullWidth?: boolean;
    optional?: boolean;
};
const DatePickerComp = ({
    input: {name, value, onChange},
    label,
    onClear,
    minDate,
    maxDate,
    disabled,
    placeholder,
    fullWidth = true,
    optional = false,
}: Props) => {
    const _handleSetDate = (date: Date | null) => {
        onChange(date);
    };

    return (
        <>
            <ToiStack
                direction={'row'}
                justifyContent={'space-between'}
                pb={'4px'}
                flexWrap={'nowrap'}
            >
                <ToiTypography variant='label'>{label}</ToiTypography>
                {optional && <ToiTag value={'Valgfri'} />}
            </ToiStack>
            <ToiDatePicker
                name={name}
                value={value ? new Date(value) : null}
                onChange={_handleSetDate}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                slotProps={{
                    textField: {
                        placeholder: placeholder || 'Velg dato',
                        fullWidth: true,
                    },
                    field: {clearable: !!onClear, onClear: onClear},
                    popper: {sx: {zIndex: 2000}},
                }}
            />
        </>
    );
};

export default DatePickerComp;
