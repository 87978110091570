import {Column, RowData} from '@tanstack/react-table';
import {styled} from '@mui/material';
import {FilterIcon} from '@norkart/toi-icons';
import {ToiBaseUIMenu as Menu} from '../../ToiBaseUIMenu';
import {StyledIconButton} from '../../ToiIconButton/StyledIconButton';
import ToiBadge from '../../ToiBadge/ToiBadge';
import {SelectFilter} from './SelectFilter';
import {RangeFilter} from './RangeFilter';
import {TextFilter} from './TextFilter';

export interface ToiTableFilterProps<TData extends RowData> {
  column: Column<TData, unknown>;
  noMargin?: boolean;
}

export function ToiTableFilter<TData extends RowData>({column, noMargin}: ToiTableFilterProps<TData>) {
  const {filterVariant, FilterComponent} = column.columnDef.meta ?? {};

  return (
    <Menu.Root modal={false}>
      <Menu.Trigger
        render={
          <StyledIconButton
            sx={{ml: (theme) => theme.toi.spacing.xs, width: '24px', height: '24px', ...(noMargin && {ml: 0})}}
            aria-label={`Filter by ${column.id}`}
            color='transparent'
          >
            <ToiBadge invisible={!column.getFilterValue()} variant='dot' color='primary'>
              <FilterIcon />
            </ToiBadge>
          </StyledIconButton>
        }
      />
      <StyledPopup>
        {FilterComponent ? (
          <FilterComponent column={column} />
        ) : (
          filterVariant &&
          {
            select: <SelectFilter column={column} />,
            text: <TextFilter column={column} />,
            range: <RangeFilter column={column} />,
          }[filterVariant]
        )}
      </StyledPopup>
    </Menu.Root>
  );
}

const StyledPopup = styled(Menu.Popup)(({theme}) => ({
  padding: theme.toi.spacing.s,
}));
